var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"loader-wrapper",class:{ loderhide: !_vm.show }},[_vm._m(0)]),_c('router-view'),(_vm.ipShow)?_c('div',{style:(_vm.isPCShow
        ? 'height: 100vh;width: 100vw;min-width:500px'
        : 'height: 100vh;width: 100vw;'),attrs:{"id":"item-modal"}},[_c('div',{staticClass:"bigBox",style:(_vm.isPCShow
          ? {
              position: 'absolute',
              width: '50vw',
              height: '65vh',
              left: '25vw',
              top: '5vh',
              'min-width': '500px',
              'min-height': '450px',
            }
          : {
              position: 'absolute',
              top: '12vh',
              left: '5vw',
            })},[_c('img',{style:(_vm.isPCShow
            ? {
                height: '96%',
                width: '80%',
                position: 'absolute',
                top: '5vh',
                left: '5vw',
                'min-width': '500px',
                'min-height': '480px',
              }
            : {
                height: '23rem',
                width: '90vw',
                position: 'absolute',
                top: '0',
              }),attrs:{"src":_vm.getImgUrl('noticBG.png'),"alt":""}}),_c('div',{staticClass:"modal",style:(_vm.isPCShow
            ? {
                width: '80%',
                'margin-left': '10%',
                'margin-top': '50px',
              }
            : {
                width: '90vw',
                height: '400px',
                overflow: 'auto',
                'flex- direction': 'column',
                'justify-content': 'flex-start',
                top: '90px',
                padding: '0px 38px 34px',
              })},[_c('div',{staticClass:"to-users",style:(_vm.isPCShow
              ? 'font-weight: bold'
              : 'font-weight: bold;font-size:13px;    margin-bottom: 6px;')},[_c('span',{staticClass:"user-title",style:(_vm.isPCShow ? '' : 'font-size:13px')},[_vm._v("尊敬的用户：")]),_c('br'),_c('div',{staticClass:"content-en",style:(_vm.isPCShow ? '' : 'font-size:11px;margin-bottom: 10px;')},[_vm._v(" 本系统现已限制中国大陆地区网络访问，请遵守当地法律法规， 感谢您的理解！如果您有任何其他问题，请联系： "),_c('a',{attrs:{"target":"_blank","href":"https://t.me/haiwangAppBot"}},[_vm._v("https://t.me/haiwangAppBot")])]),_c('div',{staticClass:"from-us",style:(_vm.isPCShow ? '' : 'font-size:11px;line-height: 1;')},[_vm._v(" 新加坡 海王出海 科技 ")])]),_c('div',{staticClass:"content-en",style:(_vm.isPCShow ? '' : 'font-size:11px;margin-bottom: 10px;'),attrs:{"id":"content-En"}},[_c('span',{staticClass:"user-title",style:(_vm.isPCShow ? '' : 'font-size:13px')},[_vm._v("Dear users:")]),_c('br'),_c('div',{staticClass:"content-en",style:(_vm.isPCShow ? '' : 'font-size:11px;margin-bottom: 10px;')},[_vm._v(" The system access to IPs in mainland China is now restricted. Thank you for your understanding!If you have any other questions, please contact: "),_c('a',{attrs:{"target":"_blank","href":"https://t.me/haiwangAppBot"}},[_vm._v("https://t.me/haiwangAppBot")])]),_c('div',{staticClass:"from-us",style:(_vm.isPCShow ? '' : 'font-size:11px;line-height: 1;')},[_vm._v(" HaiWanG Technology PTE. LTD. ")])]),_c('div',{staticClass:"your-ip",style:(_vm.isPCShow ? '' : 'font-size:11px;margin-top:10px;')},[_c('span',{attrs:{"data-translate":"your_ip"}},[_vm._v(" • 您的IP："+_vm._s(_vm.ip)+" "),_c('span',{attrs:{"id":"IP-From"}}),_vm._v(" • 您的国家: CN •")])])])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loader-index"},[_c('span')])
}]

export { render, staticRenderFns }