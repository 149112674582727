<template>
  <div>
    <Breadcrumbs title="Search Website" />
    <!-- Container-fluid starts-->
    <div class="container-fluid search-page">
      <div class="row">
        <div class="col-sm-12">
          <px-card :actions="false">
            <div slot="headerCustom">
              <form class="theme-form">
                <div class="input-group m-0">
                  <input
                    class="form-control-plaintext"
                    type="search"
                    placeholder="Pixelstrap .."
                  />
                  <div class="input-group-append">
                    <span class="btn btn-primary mt-0">Search</span>
                  </div>
                </div>
              </form>
            </div>
            <div slot="with-padding">
              <b-tabs content-class="mt-3" class="search-list">
                <b-tab active>
                  <template #title>
                    <i class="icon-target"></i> <strong>All</strong>
                  </template>
                  <div
                    class="search-links tab-pane fade show active"
                    id="all-links"
                    role="tabpanel"
                    aria-labelledby="all-link"
                  >
                    <div class="row">
                      <div class="col-xxl-8 col-xl-7 box-col-12">
                        <h6 class="mb-2">Search result for "Pixelstrap"</h6>
                        <div class="info-block">
                          <a href=""
                            >https://themeforest.net/user/pixelstrap/portfolio/</a
                          >
                          <h6>PixelStrap - Portfolio | ThemeForest</h6>
                          <p>
                            2020's Best Selling Creative WP Themes. The #1
                            Source of Premium WP Themes! ThemeForest 45,000+ WP
                            Themes & Website Templates From $2. Check it Out!
                          </p>
                          <div class="star-ratings">
                            <ul class="search-info">
                              <li>3 stars</li>
                              <li>590 votes</li>
                              <li>Theme</li>
                            </ul>
                          </div>
                        </div>
                        <div class="info-block">
                          <a href=""
                            >PixelStrap - Portfolio | ThemeForestthemeforest.net
                            › user ›
                          </a>
                          <h6>PixelStrap - Portfolio | ThemeForest</h6>
                          <p>
                            The #1 marketplace for premium website templates,
                            including themes for WordPress, Magento, Drupal,
                            Joomla, and more. Create a website, fast.
                          </p>
                          <div class="star-ratings">
                            <ul class="search-info">
                              <li>3 stars</li>
                              <li>590 votes</li>
                              <li>Theme</li>
                            </ul>
                          </div>
                        </div>
                        <div class="info-block">
                          <a href=""
                            >https://themeforest.net/user/pixelstrap/portfolio</a
                          >
                          <h6>Morbi feugiat mauris vel semper fringilla.</h6>
                          <p>
                            Cuba introduces a IELTS Coaching, TOEFL Coaching,
                            GRE Coaching, GMAT Coaching, SAT Coaching in Surat.
                          </p>
                          <div class="star-ratings">
                            <ul class="search-info">
                              <li>
                                <i class="icofont icofont-ui-rating"></i
                                ><i class="icofont icofont-ui-rating"></i
                                ><i class="icofont icofont-ui-rating"></i
                                ><i class="icofont icofont-ui-rate-blank"></i
                                ><i class="icofont icofont-ui-rate-blank"></i>
                              </li>
                              <li>3 stars</li>
                              <li>590 votes</li>
                              <li>Theme</li>
                            </ul>
                          </div>
                        </div>
                        <div class="info-block">
                          <a href=""
                            >https://themeforest.net/user/pixelstrap/portfolio</a
                          >
                          <h6>Morbi feugiat mauris vel semper fringilla.</h6>
                          <p>
                            Cuba introduces a IELTS Coaching, TOEFL Coaching,
                            GRE Coaching, GMAT Coaching, SAT Coaching in Surat.
                          </p>
                          <div class="star-ratings">
                            <ul class="search-info">
                              <li>
                                <i class="icofont icofont-ui-rating"></i
                                ><i class="icofont icofont-ui-rating"></i
                                ><i class="icofont icofont-ui-rating"></i
                                ><i class="icofont icofont-ui-rate-blank"></i
                                ><i class="icofont icofont-ui-rate-blank"></i>
                              </li>
                              <li>3 stars</li>
                              <li>590 votes</li>
                              <li>Theme</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-xxl-4 col-xl-5 box-col-12 mt-4">
                        <div class="card o-hidden">
                          <div class="blog-box blog-shadow">
                            <img
                              class="img-fluid"
                              src="../assets/images/blog/blog.jpg"
                              alt=""
                            />
                            <div class="blog-details">
                              <p>25 July 2018</p>
                              <h4>
                                Accusamus et iusto odio dignissimos ducimus qui
                                blanditiis.
                              </h4>
                              <ul class="blog-social">
                                <li>
                                  <i class="icofont icofont-user"></i>Mark Jecno
                                </li>
                                <li>
                                  <i class="icofont icofont-thumbs-up"></i>02
                                  Hits
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="info-block">
                          <a href=""
                            >https://themeforest.net/user/pixelstrap/portfolio</a
                          >
                          <h6>Pixelstrap Website Templates from ThemeForest</h6>
                          <p>
                            Get 59 pixelstrap website templates on ThemeForest.
                            Buy pixelstrap website templates from $7. All
                            created by our Global Community of independent Web
                            ...
                          </p>
                          <div class="star-ratings">
                            <ul class="search-info">
                              <li>
                                <i class="icofont icofont-ui-rating"></i
                                ><i class="icofont icofont-ui-rating"></i
                                ><i class="icofont icofont-ui-rating"></i
                                ><i class="icofont icofont-ui-rate-blank"></i
                                ><i class="icofont icofont-ui-rate-blank"></i>
                              </li>
                              <li>3 stars</li>
                              <li>590 votes</li>
                              <li>Theme</li>
                            </ul>
                          </div>
                        </div>
                        <div class="info-block">
                          <a href=""
                            >https://themeforest.net/user/pixelstrap/portfolio</a
                          >
                          <h6>Morbi feugiat mauris vel semper fringilla.</h6>
                          <p>
                            Cuba introduces a IELTS Coaching, TOEFL Coaching,
                            GRE Coaching, GMAT Coaching, SAT Coaching in Surat.
                          </p>
                          <div class="star-ratings">
                            <ul class="search-info">
                              <li>
                                <i class="icofont icofont-ui-rating"></i
                                ><i class="icofont icofont-ui-rating"></i
                                ><i class="icofont icofont-ui-rating"></i
                                ><i class="icofont icofont-ui-rate-blank"></i
                                ><i class="icofont icofont-ui-rate-blank"></i>
                              </li>
                              <li>3 stars</li>
                              <li>590 votes</li>
                              <li>Theme</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 m-t-30">
                        <nav aria-label="...">
                          <ul class="pagination pagination-primary">
                            <li class="page-item disabled">
                              <a class="page-link" href="#" tabindex="-1"
                                >Previous</a
                              >
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item active">
                              <a class="page-link" href="#"
                                >2<span class="sr-only">(current)</span></a
                              >
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">3</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">Next</a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    <i class="icon-image"></i> <strong>Images</strong>
                  </template>
                  <div>
                    <div>
                      <h6 class="mb-2">About 12,120 results (0.50 seconds)</h6>
                      <div class="my-gallery row gallery-with-description">
										<figure class="col-xl-3 col-sm-6 m-0" v-for="(n,index) in lightgallery" :key="index" @click="gindex = index">
											<a>
												<img :src="getpath(n.image)" alt="Image description">
												<div class="caption">
													<h4 v-text="n.title"></h4>
													<p v-text="n.description"></p>
												</div>
											</a>
										</figure>
										<VueGallery :images="biglightgallery" :index="gindex" @close="gindex = null" :options="galleryoptions"  ></VueGallery>
									</div>
                    </div>
                    <div class="m-t-30">
                      <nav aria-label="...">
                        <ul class="pagination pagination-primary">
                          <li class="page-item disabled">
                            <a class="page-link" href="#" tabindex="-1"
                              >Previous</a
                            >
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">1</a>
                          </li>
                          <li class="page-item active">
                            <a class="page-link" href="#"
                              >2 <span class="sr-only">(current)</span></a
                            >
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">3</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">Next</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    <i class="icon-video-clapper"></i> <strong>Videos</strong>
                  </template>
                  <div>
                    <div class="row">
                      <div class="col-xl-6 box-col-12">
                        <h6 class="mb-2">About 6,000 results (0.60 seconds)</h6>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/CJnfAXlBRTE"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/wpmHZspl4EM"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing.
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/-L4gEk7cOfk"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>Morbi eget quam et purus commodo dapibus.</h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 box-col-12">
                        <p class="pb-4">About 6,000 results (0.60 seconds)</p>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/CJnfAXlBRTE"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/-L4gEk7cOfk"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>Morbi eget quam et purus commodo dapibus.</h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/wpmHZspl4EM"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing.
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 m-t-30">
                        <div>
                          <nav aria-label="...">
                            <ul class="pagination pagination-primary">
                              <li class="page-item disabled">
                                <a class="page-link" href="#" tabindex="-1"
                                  >Previous</a
                                >
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">1</a>
                              </li>
                              <li class="page-item active">
                                <a class="page-link" href="#"
                                  >2 <span class="sr-only">(current)</span></a
                                >
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">3</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    <i class="icon-map-alt"></i> <strong>Audios</strong>
                  </template>
                  <div>
                    <div class="row">
                      <div class="col-xl-6 box-col-12">
                        <h6 class="mb-2">About 6,000 results (0.60 seconds)</h6>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/CJnfAXlBRTE"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/wpmHZspl4EM"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing.
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/-L4gEk7cOfk"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>Morbi eget quam et purus commodo dapibus.</h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 box-col-12">
                        <p class="pb-4">About 6,000 results (0.60 seconds)</p>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/CJnfAXlBRTE"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/-L4gEk7cOfk"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>Morbi eget quam et purus commodo dapibus.</h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/wpmHZspl4EM"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing.
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 m-t-30">
                        <div>
                          <nav aria-label="...">
                            <ul class="pagination pagination-primary">
                              <li class="page-item disabled">
                                <a class="page-link" href="#" tabindex="-1"
                                  >Previous</a
                                >
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">1</a>
                              </li>
                              <li class="page-item active">
                                <a class="page-link" href="#"
                                  >2 <span class="sr-only">(current)</span></a
                                >
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">3</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    <strong>Settings</strong>
                  </template>
                  <div>
                    <div class="row">
                      <div class="col-xl-6 box-col-12">
                        <h6 class="mb-2">About 6,000 results (0.60 seconds)</h6>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/CJnfAXlBRTE"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/wpmHZspl4EM"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing.
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/-L4gEk7cOfk"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>Morbi eget quam et purus commodo dapibus.</h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 box-col-12">
                        <p class="pb-4">About 6,000 results (0.60 seconds)</p>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/CJnfAXlBRTE"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/-L4gEk7cOfk"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>Morbi eget quam et purus commodo dapibus.</h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/wpmHZspl4EM"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing.
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 m-t-30">
                        <div>
                          <nav aria-label="...">
                            <ul class="pagination pagination-primary">
                              <li class="page-item disabled">
                                <a class="page-link" href="#" tabindex="-1"
                                  >Previous</a
                                >
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">1</a>
                              </li>
                              <li class="page-item active">
                                <a class="page-link" href="#"
                                  >2 <span class="sr-only">(current)</span></a
                                >
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">3</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    <strong>Tools</strong>
                  </template>
                  <div>
                    <div class="row">
                      <div class="col-xl-6 box-col-12">
                        <h6 class="mb-2">About 6,000 results (0.60 seconds)</h6>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/CJnfAXlBRTE"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/wpmHZspl4EM"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing.
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/-L4gEk7cOfk"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>Morbi eget quam et purus commodo dapibus.</h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 box-col-12">
                        <p class="pb-4">About 6,000 results (0.60 seconds)</p>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/CJnfAXlBRTE"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/-L4gEk7cOfk"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>Morbi eget quam et purus commodo dapibus.</h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="media info-block">
                          <iframe
                            class="mr-3"
                            width="200"
                            height="100"
                            src="https://www.youtube.com/embed/wpmHZspl4EM"
                          ></iframe>
                          <div class="media-body">
                            <a href=""
                              >https://themeforest.net/user/pixelstrap/portfolio</a
                            >
                            <h6>
                              Lorem Ipsum is simply dummy text of the printing.
                            </h6>
                            <div class="star-ratings">
                              <ul class="search-info">
                                <li>3 stars</li>
                                <li>590 votes</li>
                                <li>Theme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 m-t-30">
                        <div>
                          <nav aria-label="...">
                            <ul class="pagination pagination-primary">
                              <li class="page-item disabled">
                                <a class="page-link" href="#" tabindex="-1"
                                  >Previous</a
                                >
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">1</a>
                              </li>
                              <li class="page-item active">
                                <a class="page-link" href="#"
                                  >2 <span class="sr-only">(current)</span></a
                                >
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">3</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>
<script>
  import VueGallery from 'vue-gallery';
  
  export default {
    components: {
      VueGallery,
    },
    data() {
      return {
        gindex: null,
        lightgallery: [
          {
            image: '01.jpg',
            title: 'Portfolio Title',
            description:
              'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy.',
          },
          {
            image: '02.jpg',
            title: 'Portfolio Title',
            description:
              'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy.',
          },
          {
            image: '03.jpg',
            title: 'Portfolio Title',
            description:
              'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy.',
          },
          {
            image: '04.jpg',
            title: 'Portfolio Title',
            description:
              'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy.',
          },
          {
            image: '05.jpg',
            title: 'Portfolio Title',
            description:
              'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy.',
          },
          {
            image: '06.jpg',
            title: 'Portfolio Title',
            description:
              'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy.',
          },
          {
            image: '07.jpg',
            title: 'Portfolio Title',
            description:
              'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy.',
          },
          {
            image: '08.jpg',
            title: 'Portfolio Title',
            description:
              'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy.',
          },
        ],
        biglightgallery: [
          '01.jpg',
          '02.jpg',
          '03.jpg',
          '04.jpg',
          '05.jpg',
          '06.jpg',
          '07.jpg',
          '08.jpg',
        ],
        galleryoptions: {
          prevClass: 'prev',
          nextClass: 'next',
        },
      };
    },
    mounted() {
      this.setimagesparth();
    },
    methods: {
      setimagesparth() {
        for (var i = 0; i < this.biglightgallery.length; i++) {
          this.biglightgallery[i] = this.getbigimgpath(this.biglightgallery[i]);
        }
      },
      getpath(filename) {
        return require('@/assets/images/lightgallry/' + filename);
      },
      getbigimgpath(filename) {
        return require('@/assets/images/big-lightgallry/' + filename);
      },
    },
  };
</script>
