<template>
  <a
    class="nav-link"
    target="_blank"
    href="https://docs.pixelstrap.com/cuba/all_in_one/document/getting-started-vue.html"
  >
    <div>
      <i class="icon-settings"></i>
    </div>
    <span>Document</span>
  </a>
</template>

<script>
  export default {
    name: 'Document',
  };
</script>
