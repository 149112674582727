<template>
  <div v-loading="listLoading">
    <Breadcrumbs
      main=""
      :title="$t('Invitation Code') + '-' + listQuery.sharecode"
    />
    <!-- Container-fluid starts 邀请码列表-查看 -->
    <div id="mainContainer" class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div v-if="option.showSearchBt" style="margin: 10px 0 30px 0">
                <!--  style="top: -4px; width: 12rem;" -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    flex-wrap: wrap;
                  "
                >
                  <div style="flex: 1.7; min-width: 350px; margin-left: -5px">
                    <el-date-picker
                      v-model="listQuery.createdatstart"
                      size="small"
                      :style="
                        isPCShow
                          ? { width: '12rem', 'margin-left': '5px' }
                          : { width: '9rem', 'margin-left': '5px' }
                      "
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :placeholder="$t('Please select a start time')"
                    />
                    <el-date-picker
                      v-model="listQuery.createdatend"
                      size="small"
                      :style="
                        isPCShow
                          ? { width: '12rem', 'margin-left': '5px' }
                          : { width: '9rem', 'margin-left': '5px' }
                      "
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :placeholder="$t('Please select an end time')"
                    />
                    <span
                      v-for="(searchItem, searchIndex) in listSearchServer"
                      :key="searchIndex"
                      style="margin-left: 5px; line-height: 45px"
                    >
                      <el-input
                        v-if="searchItem.htype === 'text'"
                        v-model="listQuery[searchItem.key]"
                        type="text"
                        size="small"
                        :style="
                          isPCShow ? { width: '12rem' } : { width: '9rem' }
                        "
                        :placeholder="$t('Please enter the') + searchItem.label"
                        class="filter-item"
                        @keyup.enter.native="handleFilter"
                      />

                      <el-input
                        v-if="searchItem.htype === 'number'"
                        v-model="listQuery[searchItem.key]"
                        type="number"
                        :style="
                          isPCShow ? { width: '12rem' } : { width: '9rem' }
                        "
                        :placeholder="$t('Please enter the') + searchItem.label"
                        class="filter-item"
                        @keyup.enter.native="handleFilter"
                      />

                      <el-select
                        v-if="searchItem.htype === 'select'"
                        v-model="listQuery[searchItem.key]"
                        :placeholder="$t('Please select a') + searchItem.label"
                        clearable
                        size="small"
                        :style="
                          isPCShow ? { width: '12rem' } : { width: '9rem' }
                        "
                        class="filter-item"
                      >
                        <el-option
                          v-for="(item, key) in searchItem.select"
                          :key="key"
                          :label="item.title"
                          :value="item.id"
                        />
                      </el-select>
                    </span>
                  </div>
                  <div
                    style="
                      flex: 0.3;
                      display: flex;
                      height: 36px;
                      margin-top: 3px;
                    "
                  >
                    <el-button
                      style="flex: 1"
                      class="filter-item"
                      icon="el-icon-delete-solid"
                      type="success"
                      plain
                      @click="resetFun"
                      >{{ $t("Reset") }}
                    </el-button>
                    <el-button
                      style="flex: 1"
                      class="filter-item"
                      type="primary"
                      icon="el-icon-search"
                      @click="handleFilter"
                      >{{ $t("Search") }}
                    </el-button>
                    <!--                    <el-button
                                            class="filter-item"
                                            style="flex: 1;"
                                            type="danger"
                                            @click="toggleSelection()"
                                            icon="el-icon-delete"
                                            plain
                                        >{{ $t("Remove selected") }}
                                        </el-button>-->
                  </div>
                </div>
              </div>
              <div class="filter-container" style="margin-top: 8px">
                <el-button
                  v-if="option.showAddBt"
                  class="filter-item"
                  style="margin-left: 10px"
                  type="primary"
                  icon="el-icon-edit"
                  @click="handleCreate"
                  plain
                  >{{ $t("Creating an Invitation Code") }}
                </el-button>

                <!--      <el-button class="filter-item" style="margin-left: 10px;" type="warning" icon="el-icon-delete" @click="handleDelete">删除</el-button>-->
                <el-button
                  v-if="option.showExportBt"
                  v-waves
                  :loading="downloadLoading"
                  class="filter-item"
                  type="info"
                  icon="el-icon-download"
                  plain
                  @click="handleDownload"
                  >{{ $t("Export") }}
                </el-button>
                <el-button
                  v-if="!isPCShow"
                  class="filter-item"
                  type="primary"
                  @click="handleBatchClearing"
                  icon="el-icon-brush"
                  >{{ $t("Batch clearing") }}
                </el-button>
                <el-button
                  v-if="!isPCShow"
                  class="filter-item"
                  type="primary"
                  @click="handleBatchRemoval"
                  icon="el-icon-brush"
                  >{{ $t("Batch removal") }}
                </el-button>
                <el-dropdown type="primary" v-if="!isPCShow">
                  <el-button icon="el-icon-download" type="primary"
                    >{{ $t("Export") + $t("Excel")
                    }}<i class="el-icon-arrow-down el-icon--right"></i
                  ></el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native="handleDownloadExcel(index)"
                      :loading="downloadLoading"
                      v-for="(item, index) in repetition"
                      :key="index"
                    >
                      {{ item }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown
                  v-if="!isPCShow"
                  style="margin-left: 5px"
                  type="primary"
                >
                  <el-button icon="el-icon-magic-stick" type="primary"
                    >{{ $t("Repetition of analysis")
                    }}<i class="el-icon-arrow-down el-icon--right"></i
                  ></el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native="repetClick(index)"
                      v-for="(item, index) in repetition"
                      :key="index"
                    >
                      {{ item }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div>
                <el-table
                  :key="tableKey"
                  :data="list"
                  border
                  fit
                  highlight-current-row
                  :show-summary="summar.switch"
                  :summary-method="getSummar"
                  style="width: 100%; border-radius: 10px"
                  @sort-change="sortChange"
                  @selection-change="handleSelectionChange"
                  ref="multipleTable"
                >
                  <el-table-column
                    type="selection"
                    width="55"
                  ></el-table-column>
                  <el-table-column
                    v-for="(item, index) in listModelServer"
                    v-if="
                      item.show === '1' &&
                      showColums[item.key] &&
                      item.key !== 'acclist_shareid'
                    "
                    :key="index"
                    :label="item.label"
                    :prop="item.key"
                    :sortable="item.sort === '1'"
                    align="center"
                    show-overflow-tooltip
                    :min-width="
                      item.key == 'acclist_created'
                        ? '220px'
                        : item.showtype === 'img'
                        ? '80px'
                        : '110px'
                    "
                    :width="item.twidth"
                  >
                    <!-- {{ $t("Edit") }}  acclist_content -->
                    <template slot-scope="{ row }">
                      <div
                        v-if="item.showtype === 'html'"
                        v-html="row[item.key]"
                      ></div>
                      <span v-if="item.showtype === 'edittext'"
                        >{{ row[item.key] }}
                        <el-button
                          type="text"
                          icon="el-icon-edit"
                          @click="handleUpdate(row, $index)"
                        ></el-button>
                      </span>
                      <span
                        style="cursor: pointer"
                        @click="copyHandle(row[item.key])"
                        v-if="
                          item.showtype === 'text' &&
                          item.key !== 'acclist_logined'
                        "
                        >{{ row[item.key] }}</span
                      >
                      <span
                        style="cursor: pointer"
                        @click="copyHandle(row[item.key])"
                        v-if="item.showtype === 'textAnalysis'"
                      >
                        <el-tooltip
                          v-if="row['acclist_analysis'] === 1"
                          content="已开启数据分析"
                          ><i
                            class="el-icon-magic-stick"
                            style="color: #00b961; margin-right: 5px"
                          ></i></el-tooltip
                        >{{ row[item.key] }}</span
                      >
                      <span v-if="item.showtype === 'textImport'">
                        {{ row[item.key] }}
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="$t('Imported vermicelli')"
                          placement="top"
                          ><el-button
                            type="text"
                            icon="el-icon-upload2"
                            @click="handleImportedContact(row)"
                          ></el-button
                        ></el-tooltip>
                      </span>

                      <span
                        style="font-size: 12px; line-height: 16px"
                        v-if="
                          item.showtype === 'date' &&
                          item.key !== 'acclist_created' &&
                          item.key !== 'acclist_logined'
                        "
                      >
                        {{
                          row[item.key]
                            ? row[item.key]
                                .replace("+08:00", "")
                                .replace("T", " ")
                            : row[item.key]
                        }}
                      </span>
                      <span v-if="item.key == 'acclist_created'">
                        {{
                          row[item.key]
                            ? row[item.key]
                                .replace("+08:00", "")
                                .replace("T", " ")
                            : row[item.key]
                        }}
                        <br />
                        {{
                          row["acclist_logined"]
                            ? row["acclist_logined"]
                                .replace("+08:00", "")
                                .replace("T", " ")
                            : row["acclist_logined"]
                        }}
                      </span>

                      <span
                        v-for="(arritem, arrindex) in item.showarr"
                        :key="arritem.id + arrindex"
                        v-show="arritem.id === row[item.key]"
                      >
                        <span
                          :class="computeBg(item.showarr[arrindex])"
                          v-if="item.showtype === 'arr' && !arritem.entitle"
                          >{{ item.showarr[arrindex].title }}</span
                        >
                      </span>
                      <span
                        v-for="(arritem, arrindex) in item.showarr"
                        :key="`${arrindex + arritem.id}_span`"
                        v-show="arritem.id === row[item.key]"
                      >
                        <span
                          :class="computedStyle(arritem)"
                          v-if="item.showtype === 'arr' && arritem.entitle"
                          >{{ item.showarr[arrindex].title }}</span
                        >
                      </span>
                      <span v-if="item.showtype === 'img'">
                        <div style="position: relative">
                          <el-image
                            style="
                              height: 40px;
                              width: 40px;
                              border-radius: 50%;
                            "
                            :preview-src-list="[row[item.key]]"
                            fit="fill"
                            @error="loadHeadimgErr(row, item.key)"
                            :src="row[item.key] ? row[item.key] : noheadimg"
                          />

                          <!-- -->
                          <div
                            v-if="row.acclist_status == 2"
                            class="coin"
                            style="
                              width: 10px;
                              height: 10px;
                              background: rgb(0, 185, 97);
                              border-radius: 50%;
                              position: absolute;
                              bottom: 6px;
                              right: 12px;
                              border: 1px solid #fff;
                            "
                          ></div></div
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="acclist_username_url"
                    align="center"
                    :label="$t('Homepage')"
                    show-overflow-tooltip
                    width="150"
                  >
                    <template v-slot="scope">
                      <p
                        style="cursor: pointer"
                        @click="copyHandle(scope.row.acclist_username_url)"
                      >
                        {{ scope.row.acclist_username_url }}
                      </p>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="acclist_qrcode"
                    align="center"
                    :label="$t('QrCode')"
                    width="100"
                  >
                    <template v-slot="scope">
                      <div @click="QRHandle(scope.row.acclist_username_url)">
                        <qrcode-vue
                          :value="scope.row.acclist_username_url"
                          size="30"
                          level="H"
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 操作 -->
                  <el-table-column
                    :label="$t('Operation')"
                    align="center"
                    :width="isPCShow ? 300 : 110"
                    :fixed="tableFixed"
                  >
                    <!-- $t("Display of columns") -->
                    <template slot="header" slot-scope="scope">
                      <span>{{ $t("Operation") }}</span>
                      <el-dropdown style="margin-left: 15px">
                        <i class="el-icon-setting"></i>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            style="border-bottom: 1px solid #dcdfe6"
                            command="sendModifyData"
                          >
                            <el-checkbox
                              v-model="allStatus"
                              @change="selectAllFun"
                              >{{ allStatus ? $t("CancelFull") : $t("Full") }}
                            </el-checkbox>
                          </el-dropdown-item>

                          <el-dropdown-item
                            command="sendModifyData"
                            v-for="(item, index) in listModelServer"
                            :key="index"
                            v-show="item.show === '1'"
                          >
                            <el-checkbox
                              @change="saveColmun"
                              v-model="showColums[item.key]"
                              >{{ item.label }}
                            </el-checkbox>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </template>

                    <template slot-scope="{ row, $index }">
                      <el-button
                        type="text"
                        icon="el-icon-pie-chart"
                        @click="handleLog(row, $index)"
                        >{{ $t("Log record") }}
                      </el-button>
                      <el-button
                        type="text"
                        icon="el-icon-user"
                        style="margin-left: 5px"
                        @click="getContactRenderData(row)"
                        >{{ $t("Contact Details") }}
                      </el-button>
                      <el-button
                        type="text"
                        icon="el-icon-brush"
                        v-if="row.acclist_num > 0"
                        @click="handleClear(row)"
                        >{{ $t("Clearing of statistics") }}
                      </el-button>
                      <el-button
                        type="text"
                        icon="el-icon-paperclip"
                        v-if="row.acclist_status === 2"
                        @click="handleOffline(row)"
                        >{{ $t("Offline") }}
                      </el-button>
                      <el-button
                        type="text"
                        icon="el-icon-delete"
                        v-if="row.acclist_status !== 2"
                        @click="handleRemove(row)"
                        >{{ $t("Remove APP") }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>

                <div style="display: flex; align-items: center">
                  <pagination
                    v-show="total > 0"
                    :total="total"
                    :page.sync="listQuery.page"
                    :limit.sync="listQuery.limit"
                    :page-sizes="pageSizelist"
                    @pagination="getList"
                  />
                  <!--                  <el-button
                      v-waves
                      :loading="downloadLoading"
                      style="margin-left: 0px;margin-top: 30px;"

                      size="mini"
                      icon="el-icon-download"
                      plain
                      @click="handleDownloadExcel"
                  >{{ $t("Export") + $t("Excel") }}
                  </el-button>-->
                  <el-dropdown
                    v-if="isPCShow"
                    style="margin-left: 5px; margin-top: 30px"
                    type="primary"
                  >
                    <el-button icon="el-icon-download" size="mini"
                      >{{ $t("Export") + $t("Excel")
                      }}<i class="el-icon-arrow-down el-icon--right"></i
                    ></el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        @click.native="handleDownloadExcel(index)"
                        :loading="downloadLoading"
                        v-for="(item, index) in repetition"
                        :key="index"
                      >
                        {{ item }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <el-button
                    v-if="isPCShow"
                    class="filter-item"
                    size="mini"
                    @click="handleBatchClearing"
                    icon="el-icon-brush"
                    style="margin-left: 5px; margin-top: 30px"
                    >{{ $t("Batch clearing") }}
                  </el-button>
                  <el-button
                    v-if="isPCShow"
                    class="filter-item"
                    size="mini"
                    @click="handleBatchRemoval"
                    icon="el-icon-brush"
                    style="margin-left: 5px; margin-top: 30px"
                    >{{ $t("Batch removal") }}
                  </el-button>
                  <el-dropdown
                    style="margin-left: 5px; margin-top: 30px"
                    v-if="isPCShow"
                    type="primary"
                  >
                    <el-button icon="el-icon-magic-stick" size="mini"
                      >{{ $t("Repetition of analysis")
                      }}<i class="el-icon-arrow-down el-icon--right"></i
                    ></el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        @click.native="repetClick(index)"
                        v-for="(item, index) in repetition"
                        :key="index"
                      >
                        {{ item }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <el-dropdown
                      style="margin-left: 5px; margin-top: 30px"
                      type="primary"
                  >
                    <el-button  size="mini" border>
                      {{ $t("Rank first") }}
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown"  :hideOnClick="false" >
                      <el-dropdown-item>
                        <el-checkbox @change="topCommand('todayTop')" :true-label="1" :false-label="0" v-model="listQuery.todayTop" size="mini">{{$t('In the powder front today')}}</el-checkbox>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <el-checkbox  @change="topCommand('onlineTop')" :true-label="1" :false-label="0"  v-model="listQuery.onlineTop" size="mini">{{$t('Online APP front')}}</el-checkbox>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <!--开始-->
              <b-modal
                id="tag-modal-example"
                size="xl"
                hide-footer
                :centered="true"
              >
                <template #modal-title>
                  {{
                    dialogStatus === "create"
                      ? $t("Creating an Invitation Code")
                      : $t("Edit an Invitation Code")
                  }}
                </template>
                <div class="modal-body">
                  <form class="form-bookmark needs-validation" novalidate="">
                    <div class="form-row">
                      <div
                        class="form-group col-md-12"
                        v-for="(item, index) in listModelServer"
                        :key="index"
                        v-if="item.edit === '1'"
                      >
                        <label>{{ item.label }}</label>
                        <label v-if="item.edittype === 'disable'">{{
                          temp[item.key]
                        }}</label>
                        <input
                          class="form-control"
                          v-if="item.edittype === 'text'"
                          v-model="temp[item.key]"
                          type="text"
                          :placeholder="$t('Please enter the') + item.label"
                          autocomplete="off"
                        />
                        <input
                          class="form-control"
                          v-if="item.edittype === 'number'"
                          v-model="temp[item.key]"
                          type="number"
                          :placeholder="$t('Please enter the') + item.label"
                          autocomplete="off"
                        />
                        <el-select
                          style="margin-left: 5px"
                          v-if="item.edittype === 'select'"
                          v-model="temp[item.key]"
                          class="filter-item"
                          :placeholder="$t('Please enter the') + item.label"
                        >
                          <el-option
                            v-for="(selectitem, selectkey) in item.editselect"
                            :key="selectkey"
                            :label="selectitem.title"
                            :value="selectitem.id"
                          />
                        </el-select>
                        <el-date-picker
                          v-if="item.edittype === 'date'"
                          v-model="temp[item.key]"
                          style="top: -4px; width: 12rem"
                          type="date"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          :placeholder="$t('Please enter the') + item.label"
                        />
                        <el-time-picker
                          v-if="item.edittype === 'time'"
                          v-model="temp[item.key]"
                          style="top: -4px; width: 12rem"
                          format="HH:mm:ss"
                          value-format="HH:mm:ss"
                          :placeholder="$t('Please enter the') + item.label"
                        />
                        <el-date-picker
                          v-if="item.edittype === 'datetime'"
                          v-model="temp[item.key]"
                          style="top: -4px; width: 12rem"
                          type="datetime"
                          format="yyyy-MM-dd HH:mm:ss"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          :placeholder="$t('Please enter the') + item.label"
                        />
                        <el-radio-group
                          v-if="item.edittype === 'radio'"
                          v-model="temp[item.key]"
                        >
                          <el-radio
                            v-for="(radioItem, radioIndex) in item.editselect"
                            :key="radioIndex"
                            :label="radioItem.id"
                          >
                            {{ radioItem.title }}
                          </el-radio>
                        </el-radio-group>
                        <el-checkbox-group
                          v-if="item.edittype === 'checkbox'"
                          v-model="temp[item.key]"
                        >
                          <el-checkbox
                            v-for="(chboxItem, chboxIndex) in item.editselect"
                            :key="chboxIndex"
                            :label="chboxItem.id"
                          >
                            {{ chboxItem.title }}
                          </el-checkbox>
                        </el-checkbox-group>
                      </div>
                    </div>
                    <button
                      class="btn btn-secondary"
                      type="button"
                      @click="
                        dialogStatus === 'create' ? createData() : updateData()
                      "
                    >
                      {{ $t("Save") }}
                    </button>
                    <button
                      class="btn btn-primary ml-2"
                      type="button"
                      data-dismiss="modal"
                      @click="$bvModal.hide('tag-modal-example')"
                    >
                      {{ $t("Cancel") }}
                    </button>
                  </form>
                </div>
              </b-modal>
              <!--结束       -->
              <b-modal
                id="select-time-list-model"
                style="width: 90%"
                body-class="bodymodel"
                hide-footer
                scrollable
                :centered="true"
              >
                <template #modal-title> 仅显示最近50条记录</template>
                <div style="margin-left: 10%" v-loading="logLoadding">
                  <el-timeline>
                    <el-timeline-item
                      v-for="(activity, index) in logList"
                      :key="index"
                      :icon="getTimeTagicon(activity.runtimelog_onlinestatus)"
                      :type="getTimeTagtype(activity.runtimelog_onlinestatus)"
                      :color="getTimeTagcolor(activity.runtimelog_onlinestatus)"
                      size="xl"
                      placement="bottom"
                      :timestamp="computedTime(activity)"
                    >
                      <el-tag
                        :type="getTimeTagtype(activity.runtimelog_onlinestatus)"
                      >
                        {{ activity.runtimelog_onlinestatus }}
                      </el-tag>
                      <span
                        style="color: gray; font-size: 13px; margin-left: 10px"
                        >{{ activity.runtimelog_remark }}</span
                      >
                    </el-timeline-item>
                  </el-timeline>
                </div>
              </b-modal>
              <el-dialog
                :visible.sync="dialogSwitch.contactListModel"
                :width="isPCShow ? '80%' : '90%'"
                :title="contact.title"
                :append-to-body="true"
              >
                <div v-loading="contact.listLoading" style="width: 100%">
                  <div class="filter-container">
                    <el-date-picker
                      v-model="contact.listQuery.createdatstart"
                      :style="
                        isPCShow
                          ? { width: '12rem', top: '-4px' }
                          : { width: '9rem' }
                      "
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :placeholder="$t('Please select a start time')"
                    />
                    <el-date-picker
                      v-model="contact.listQuery.createdatend"
                      :style="
                        isPCShow
                          ? {
                              width: '12rem',
                              'margin-left': '5px',
                              top: '-4px',
                            }
                          : { width: '9rem', 'margin-left': '10px' }
                      "
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :placeholder="$t('Please select an end time')"
                    />
                    <span
                      v-for="(
                        searchItem, searchIndex
                      ) in contact.listSearchServer"
                      :key="searchIndex"
                      :style="
                        isPCShow ? 'margin-left: 5px' : 'margin-top: 10px'
                      "
                    >
                      <el-input
                        v-if="searchItem.htype === 'text'"
                        v-model="contact.listQuery[searchItem.key]"
                        type="text"
                        :placeholder="$t('Please enter the') + searchItem.label"
                        :style="
                          isPCShow
                            ? { width: '12rem' }
                            : {
                                width: '9rem',
                                'margin-right': '10px',
                                'margin-top': '10px',
                              }
                        "
                        class="filter-item"
                        @keyup.enter.native="handleContactFilter"
                      />
                      <el-input
                        v-if="searchItem.htype === 'number'"
                        v-model="contact.listQuery[searchItem.key]"
                        type="number"
                        :placeholder="$t('Please enter the') + searchItem.label"
                        :style="
                          isPCShow ? { width: '12rem' } : { width: '9rem' }
                        "
                        class="filter-item"
                        @keyup.enter.native="handleContactFilter"
                      />
                      <el-select
                        v-if="searchItem.htype === 'select'"
                        v-model="contact.listQuery[searchItem.key]"
                        :placeholder="$t('Please select a') + searchItem.label"
                        clearable
                        class="filter-item"
                        :style="
                          isPCShow ? { width: '12rem' } : { width: '9rem' }
                        "
                      >
                        <el-option
                          v-for="(item, key) in searchItem.select"
                          :key="key"
                          :label="item.title"
                          :value="item.id"
                        />
                      </el-select>
                    </span>
                    <el-button
                      class="filter-item"
                      style="margin-left: 10px"
                      type="primary"
                      icon="el-icon-search"
                      @click="handleContactFilter"
                      >{{ $t("Search") }}
                    </el-button>
                  </div>
                  <el-table
                    v-if="!contact.listLoading"
                    :key="1"
                    :data="contact.list"
                    border
                    fit
                    :show-summary="contact.summar.switch"
                    highlight-current-row
                    style="width: 100%; border-radius: 10px"
                    @sort-change="sortContactChange"
                    row-key="contact_id"
                    :tree-props="{
                      children: 'children',
                      hasChildren: 'hasChildren',
                    }"
                  >
                    <el-table-column
                      v-for="(item, index) in contact.listModelServer"
                      v-if="item.show === '1'"
                      :key="index"
                      :label="item.label"
                      :prop="item.key"
                      :sortable="item.sort === '1'"
                      :width="
                        item.showtype === 'img' ||
                        item.key == 'contact_appid' ||
                        item.key == 'contact_account' ||
                        item.key == 'contact_type' ||
                        item.key == 'contact_repeatnum'
                          ? 100
                          : item.key == 'contact_repeat'
                          ? 120
                          : ''
                      "
                      align="center"
                      show-overflow-tooltip
                    >
                      <template slot-scope="{ row }">
                        <span
                          style="cursor: pointer"
                          @click="copyHandle(row[item.key])"
                          v-if="item.showtype === 'text'"
                        >
                          <el-tag
                            type="info"
                            v-if="item.key === 'contact_type'"
                            >{{ row[item.key] }}</el-tag
                          >
                          <span v-else>{{ row[item.key] }}</span>
                        </span>

                        <!-- <span
                          v-if="
                            item.showtype === 'text' &&
                            itme.key === 'contact_type'
                          "
                          >---{{ row[item.key] }}</span
                        > -->
                        <span v-if="item.showtype === 'date'">
                          {{
                            row[item.key]
                              ? row[item.key]
                                  .replace("+08:00", "")
                                  .replace("T", " ")
                              : row[item.key]
                          }}
                        </span>

                        <span v-if="item.showtype === 'arr'">
                          <el-tag
                            v-for="(arritem, arrindex) in item.showarr"
                            :key="arrindex"
                            v-show="arritem.id === row[item.key]"
                            >{{ item.showarr[arrindex].title }}</el-tag
                          >
                        </span>
                        <span v-if="item.showtype === 'img'"
                          ><el-image
                            class="imgPop"
                            fit="fill"
                            :preview-src-list="[row[item.key]]"
                            :src="row[item.key] || noheadimg"
                            @error="loadHeadimgErr(row, item.key)"
                          />
                          <img
                            v-if="countryImg"
                            style="
                              height: 13px;
                              width: 13px;
                              border-radius: 2px;
                              border-radius: 2px;
                              position: absolute;
                              left: calc(50% - 20px);
                              bottom: 10px;
                            "
                            alt=""
                            :src="getImgUrl(row.countryImgUrl)"
                          />
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      v-if="countryImg"
                      prop="countryTxt"
                      align="center"
                      width="110"
                      :label="$t('国家')"
                    >
                      <template slot-scope="{ row }">
                        <div>{{ $t(row.countryTxt) }}</div>
                      </template>
                    </el-table-column>
                  </el-table>

                  <pagination
                    v-show="contact.total > 0"
                    :total="contact.total"
                    :page.sync="contact.listQuery.page"
                    :limit.sync="contact.listQuery.limit"
                    :page-sizes="pageSizelist"
                    @pagination="getContact"
                  />
                </div>
              </el-dialog>
              <el-dialog
                :visible.sync="dialogSwitch.RepeatListModel"
                width="70%"
                :title="$t('Repetition of analysis')"
                :append-to-body="true"
              >
                <div v-loading="repeat.listLoading" style="width: 100%">
                  <div
                    class="staticRepeat"
                    style="display: flex; width: 100%"
                    :style="{ flexDirection: isMobile ? 'column' : 'row' }"
                  >
                    <div class="back" style="flex: 1; width: 100%">
                      <div class="title">{{ $t("General contact") }}</div>
                      <div class="num" style="color: rgb(255, 85, 0)">
                        {{ repeat.totalnum }}
                      </div>
                    </div>
                    <div class="back" style="flex: 1; width: 100%">
                      <div class="title">{{ $t("RateRepetition") }}</div>
                      <div class="num" style="color: rgb(255, 85, 0)">
                        {{
                          Math.ceil(
                            (repeat.totalRepetNum / (repeat.totalnum || 1)) *
                              100
                          )
                        }}%
                      </div>
                    </div>
                    <div class="back" style="flex: 1; width: 100%">
                      <div class="title">{{ $t("DuplicateContact") }}</div>
                      <div class="num" style="color: rgb(45, 183, 245)">
                        {{ repeat.repet }}
                      </div>
                    </div>
                    <div class="back" style="flex: 1; width: 100%">
                      <div class="title">
                        {{
                          $t(
                            "If you add a contact repeatedly, you can add a valid contact only once"
                          )
                        }}
                      </div>
                      <div class="num" style="color: rgb(135, 208, 104)">
                        {{
                          repeat.totalnum - repeat.totalRepetNum + repeat.repetnum
                        }}
                      </div>
                    </div>
                    <div class="back" style="flex: 1; width: 100%">
                      <div class="title">
                        {{
                          $t(
                            "Contacts that are added repeatedly are not counted as valid contacts"
                          )
                        }}
                      </div>
                      <div class="num" style="color: rgb(135, 208, 104)">
                        {{ repeat.totalnum - repeat.totalRepetNum }}
                      </div>
                    </div>
                  </div>

                  <el-table
                    :key="1"
                    :data="repeat.list"
                    border
                    v-if="!repeat.listLoading"
                    fit
                    highlight-current-row
                    style="width: 100%; border-radius: 10px"
                    @sort-change="sortChange"
                    row-key="contact_id"
                    :tree-props="{
                      children: 'children',
                      hasChildren: 'hasChildren',
                    }"
                  >
                    <el-table-column
                      :label="$t('SerialNumber')"
                      prop="contact_id"
                      align="center"
                      show-overflow-tooltip
                    >
                      <template slot-scope="{ row }">
                        <span>{{ row.contact_id }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('UserId')"
                      prop="contact_account"
                      align="center"
                      show-overflow-tooltip
                    >
                      <template slot-scope="{ row }">
                        <span>{{ row.contact_account }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      :label="$t('Account')"
                      prop="contact_username"
                      align="center"
                      show-overflow-tooltip
                    >
                      <template slot-scope="{ row }">
                        <span>{{ row.contact_username }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('Repeat')"
                      prop="contact_repeatnum"
                      align="center"
                      show-overflow-tooltip
                    >
                      <template slot-scope="{ row }">
                        <span>{{ row.contact_repeatnum }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('Portrait')"
                      prop="contact_headimg"
                      align="center"
                      show-overflow-tooltip
                    >
                      <template slot-scope="{ row }">
                        <span>
                          <el-image
                            style="
                              height: 40px;
                              width: 40px;
                              border-radius: 5px;
                            "
                            fit="fill"
                            :preview-src-list="[row['contact_headimg']]"
                            :src="row['contact_headimg'] || noheadimg"
                            @error="loadHeadimgErr(row, 'contact_headimg')"
                          />
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('Nickname')"
                      prop="contact_nickname"
                      align="center"
                      show-overflow-tooltip
                    >
                      <template slot-scope="{ row }">
                        <span>{{ row.contact_nickname || "HaiWang" }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('InputTime')"
                      prop="contact_created"
                      align="center"
                      show-overflow-tooltip
                    >
                      <template slot-scope="{ row }">
                        <span>
                          {{
                            row.contact_created
                              ? row.contact_created
                                  .replace("+08:00", "")
                                  .replace("T", " ")
                              : row.contact_created
                          }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('Source')"
                      prop="contact_created"
                      align="center"
                      show-overflow-tooltip
                    >
                      <template slot-scope="{ row }">
                        <span>
                          {{ row.contact_type }}
                        </span>
                      </template>
                    </el-table-column>
                  </el-table>

                  <div
                    style="display: flex; align-items: center; flex-wrap: wrap"
                  >
                    <pagination
                      v-show="repeat.total > 0"
                      :total="repeat.total"
                      :page.sync="repeat.listQuery.page"
                      :limit.sync="repeat.listQuery.limit"
                      :page-sizes="repeat.pageOptions"
                      @pagination="getRepet"
                    />
                    <!--                  <el-button
                                          v-waves
                                          :loading="downloadLoading"
                                          style="margin-left: 0;margin-top: 30px;"
                                          v-if="in_array(2,shareprivilege)"
                                          size="mini"
                                          icon="el-icon-download"
                                          plain
                                          @click="handleDownloadExcel"
                                      >{{ $t("Export") + $t("Excel") }}
                                      </el-button>-->
                    <el-dropdown
                      style="margin-left: 5px; margin-top: 30px"
                      type="primary"
                    >
                      <el-button icon="el-icon-search" size="mini"
                        >{{ repeat.quickRepeatArr[repeat.listQuery.day]
                        }}<i class="el-icon-arrow-down el-icon--right"></i
                      ></el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          @click.native="getRepet(index)"
                          :loading="downloadLoading"
                          v-for="(item, index) in repeat.quickRepeatArr"
                          :key="index"
                        >
                          {{ item }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </el-dialog>
              <!-- 放大的二维码 -->

              <el-dialog
                :visible.sync="qrcoodeVisible"
                width="240px"
                :modal-append-to-body="false"
                :destroy-on-close="true"
                :append-to-body="true"
                :title="$t('QrCode')"
              >
                <div style="width: 100%">
                  <qrcode-vue :value="qrcode" size="200" level="H" />
                </div>
              </el-dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination';
  import noheadimg from '@/assets/images/noheadimg.png';
  import download from 'downloadjs';
  import QrcodeVue from 'qrcode.vue';

  let t;
  export default {
    components: { Pagination, QrcodeVue },
    data() {
      return {
        isPCShow: true,
        windowWidth: window.innerWidth,
        value: '77777', //　二维码显示大小
        size: 50,
        countryImg: false,
        qrcodeImg: null,
        qrcode: null,
        qrcoodeVisible: false,
        isMobile: false,
        logLoadding: false,
        tableFixed: 'right',
        noheadimg: noheadimg,
        allStatus: false,
        downloadLoading: false,
        dialogSwitch: {
          contactListModel: false,
          RepeatListModel: false,
        },
        repeat: {
          quickRepeatArr: [
            this.$t('All'),
            this.$t('Today'),
            this.$t('Yesterday'),
          ],
          repet: 0,
          totalnum: 0,
          repetnum: 0,
          today: 0,
          todayRepetion: 0,
          listQuery: {
            day: 1,
            page: 1,
            limit: 5,
            sort: '',
            rtype: 0,
            rselectid: [],
            rselectappid: 0,
            shareid: 0,
          },
          listLoading: true,
          list: [],
          showColums: {},
          summar: {
            switch: false,
            data: {},
          },
          total: 0,
          pageOptions: [5, 10,20,50],
          listModelServer: [],
          listSearchServer: [],
          title: '',
          option: {
            showAddBt: false,
            showCatchEditBt: false,
            showExportBt: false,
            showSearchBt: true,
          },
        },
        listQuery: {
          page: 1,
          limit: 10,
          sort: '',
          sharecode: '',
          shareid: '',
          createdatstart: '',
          createdatend: '',
          onlineTop:1,
          todayTop:1,
        },
        listModelNum: 0,
        showColums: {},
        dialogStatus: '',
        contact: {
          listQuery: {
            page: 1,
            limit: 20,
            sort: '',
            contactappid: 0,
            contactaccid: 0,
            createdatstart: '',
            createdatend: '',
          },

          listLoading: false,
          list: [],
          showColums: {},
          summar: {
            switch: false,
            data: {},
          },
          total: 0,
          listModelServer: [],
          listSearchServer: [],
          title: '',
          option: {
            showAddBt: false,
            showCatchEditBt: false,
            showExportBt: false,
            showSearchBt: true,
          },
        },
        repetition: ['选中的号码', '所有在线号码', '所有离线号码', '所有号码'],
        option: {
          showAddBt: false,
          showCatchEditBt: false,
          showExportBt: false,
          showSearchBt: true,
        },
        listLoading: true,
        pageSizelist: [],
        listSearchServer: [],
        listModelServer: [],
        filter: null,
        list: [],
        total: 0,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15],
        tableKey: 0,
        selectMode: 'multi',
        selected: [],
        listdata: [],
        temp: {},
        logList: [],
        selectDate: [],
        summar: {
          switch: false,
          data: {},
        },
        acclist_created_label: '',
        countryList: [
          { name: '阿富汗', code: '93' },
          // { name: "奥兰群岛", code: "358" },
          { name: '阿尔巴尼亚', code: '355' },
          { name: '阿尔及利亚', code: '213' },
          { name: '美属萨摩亚', code: '1-684' },
          { name: '安道尔', code: '376' },
          { name: '安哥拉', code: '244' },
          { name: '安圭拉', code: '1-264' },
          { name: '南极洲', code: '672' },
          { name: '安提瓜和巴布达', code: '1-268' },
          { name: '阿根廷', code: '54' },
          { name: '亚美尼亚', code: '374' },
          { name: '阿鲁巴', code: '297' },
          { name: '澳大利亚', code: '61' },
          { name: '奥地利', code: '43' },
          { name: '阿塞拜疆', code: '994' },
          { name: '巴哈马', code: '1-242' },
          { name: '巴林', code: '973' },
          { name: '孟加拉', code: '880' },
          { name: '巴巴多斯', code: '1-246' },
          { name: '白俄罗斯', code: '375' },
          { name: '比利时', code: '32' },
          { name: '伯利兹', code: '501' },
          { name: '贝宁', code: '229' },
          { name: '百慕大', code: '1-441' },
          { name: '不丹', code: '975' },
          { name: '玻利维亚', code: '591' },
          { name: '波斯尼亚和黑塞哥维那', code: '387' },
          { name: '博茨瓦纳', code: '267' },
          { name: '布维岛', code: '47' },
          { name: '巴西', code: '55' },
          { name: '英属印度洋领地', code: '246' },
          { name: '文莱', code: '673' },
          { name: '保加利亚', code: '359' },
          { name: '布基纳法索', code: '226' },
          { name: '布隆迪', code: '257' },
          { name: '柬埔寨', code: '855' },
          { name: '喀麦隆', code: '237' },
          { name: '加拿大', code: '1' },
          { name: '佛得角', code: '238' },
          { name: '开曼群岛', code: '1-345' },
          { name: '中非', code: '236' },
          { name: '乍得', code: '235' },
          { name: '智利', code: '56' },
          { name: '中国', code: '86' },
          { name: '圣诞岛', code: '61', remark: 'repeat' },
          // { name: "科科斯（基林）群岛", code: "61", remark: "repeat" },
          { name: '哥伦比亚', code: '57' },
          { name: '科摩罗', code: '269' },
          { name: '刚果', code: '242' },
          { name: '刚果（金）', code: '243' },
          { name: '库克群岛', code: '682' },
          { name: '哥斯达黎加', code: '506' },
          { name: '科特迪瓦', code: '225' },
          { name: '克罗地亚', code: '385' },
          { name: '古巴', code: '53' },
          { name: '塞浦路斯', code: '357' },
          { name: '捷克', code: '420' },
          { name: '丹麦', code: '45' },
          { name: '吉布提', code: '253' },
          { name: '多米尼加', code: '1-767' },
          { name: '多明尼加共和国', code: '1-809' },
          { name: '东帝汶', code: '670' },
          { name: '厄瓜多尔', code: '593' },
          { name: '埃及', code: '20' },
          { name: '萨尔瓦多', code: '503' },
          { name: '赤道几内亚', code: '240' },
          { name: '厄立特里亚', code: '291' },
          { name: '爱沙尼亚', code: '372' },
          { name: '埃塞俄比亚', code: '251' },
          { name: '福克兰群岛', code: '500' },
          { name: '法罗群岛', code: '298' },
          { name: '斐济', code: '679' },
          { name: '芬兰', code: '358', remark: 'repeat' },
          { name: '法国', code: '33' },
          { name: '法属圭亚那', code: '594' },
          { name: '法属波利尼西亚', code: '689' },
          { name: '法属南部领地', code: '262' },
          { name: '德国', code: '49' },
          { name: '加纳', code: '233' },
          { name: '直布罗陀', code: '350' },
          { name: '希腊', code: '30' },
          { name: '格陵兰岛', code: '299' },
          { name: '格林纳达', code: '1-473' },
          { name: '瓜德罗普岛', code: '590' },
          { name: '关岛', code: '1-671' },
          { name: '危地马拉', code: '502' },
          { name: '根西岛', code: '44-1481' },
          { name: '几内亚', code: '224' },
          { name: '几内亚比绍', code: '245' },
          { name: '圭亚那', code: '592' },
          { name: '海地', code: '509' },
          { name: '赫德·唐纳岛及麦唐纳岛', code: '672' },
          { name: '洪都拉斯', code: '504' },
          { name: '中国香港', code: '852' },
          { name: '匈牙利', code: '36' },
          { name: '冰岛', code: '354' },
          { name: '印度', code: '91' },
          { name: '印度尼西亚', code: '62' },
          { name: '伊朗', code: '98' },
          { name: '伊拉克', code: '964' },
          { name: '爱尔兰', code: '353' },
          { name: '以色列', code: '972' },
          { name: '意大利', code: '39' },
          { name: '牙买加', code: '1-876' },
          { name: '日本', code: '81' },
          { name: '泽西岛', code: '44-1534' },
          { name: '约旦', code: '962' },
          { name: '哈萨克斯坦', code: '7' },
          { name: '肯尼亚', code: '254' },
          { name: '基里巴斯', code: '686' },
          { name: '朝鲜', code: '850' },
          { name: '韩国', code: '82' },
          { name: '科威特', code: '965' },
          { name: '吉尔吉斯斯坦', code: '996' },
          { name: '老挝', code: '856' },
          { name: '拉脱维亚', code: '371' },
          { name: '黎巴嫩', code: '961' },
          { name: '莱索托', code: '266' },
          { name: '利比里亚', code: '231' }, //开始挑着啦
          { name: '利比亚', code: '218' },
          { name: '列支敦士登', code: '423' },
          { name: '立陶宛', code: '370' },
          { name: '卢森堡', code: '352' },
          { name: '中国澳门', code: '853' },
          { name: '北马其顿', code: '389' },
          { name: '马达加斯加', code: '261' },
          { name: '马拉维', code: '265' },
          { name: '马来西亚', code: '60' },
          { name: '马尔代夫', code: '960' }, //1
          { name: '马里', code: '223' },
          { name: '马耳他', code: '356' },
          { name: '马恩岛', code: '44-1624' },
          { name: '马绍尔群岛', code: '692' },
          { name: '马提尼克岛', code: '596' },
          { name: '毛里塔尼亚', code: '222' },
          { name: '毛里求斯', code: '230' },
          { name: '马约特', code: '262' },
          { name: '墨西哥', code: '52' },
          { name: '密克罗尼西亚', code: '691' },
          { name: '摩尔多瓦', code: '373' },
          { name: '摩纳哥', code: '377' },
          { name: '蒙古', code: '976' },
          { name: '黑山', code: '382' },
          { name: '蒙特塞拉特', code: '1-664' },
          { name: '摩洛哥', code: '212' },
          { name: '莫桑比克', code: '258' },
          { name: '缅甸', code: '95' },
          { name: '纳米比亚', code: '264' },
          { name: '瑙鲁', code: '674' },
          { name: '尼泊尔', code: '977' },
          { name: '博内尔岛、圣尤斯特歇斯和萨巴岛', code: '599' },
          { name: '荷兰', code: '31' },
          { name: '新喀里多尼亚', code: '687' },
          { name: '新西兰', code: '64' },
          { name: '尼加拉瓜', code: '505' },
          { name: '尼日尔', code: '227' },
          { name: '尼日利亚', code: '234' },
          { name: '纽埃', code: '683' },
          { name: '诺福克岛', code: '672' },
          { name: '北马里亚纳群岛', code: '1-670' },
          { name: '挪威', code: '47' },
          { name: '阿曼', code: '968' },
          { name: '巴基斯坦', code: '92' },
          { name: '帕劳', code: '680' },
          { name: '巴勒斯坦', code: '970' },
          { name: '巴拿马', code: '507' },
          { name: '巴布亚新几内亚', code: '675' },
          { name: '巴拉圭', code: '595' },
          { name: '秘鲁', code: '51' },
          { name: '菲律宾', code: '63' },
          { name: '皮特凯恩群岛', code: '64' },
          { name: '波兰', code: '48' },
          { name: '葡萄牙', code: '351' },
          { name: '波多黎各', code: '1-787' },
          { name: '卡塔尔', code: '974' },
          { name: '留尼汪岛', code: '262' },
          { name: '罗马尼亚', code: '40' },
          { name: '俄罗斯联邦', code: '7' },
          { name: '卢旺达', code: '250' },
          { name: '圣赫勒拿', code: '290' },
          { name: '圣基茨和尼维斯', code: '1-869' },
          { name: '圣卢西亚', code: '1-758' },
          { name: '圣皮埃尔和密克隆', code: '508' },
          { name: '圣文森特和格林纳丁斯', code: '1-784' },
          { name: '圣巴泰勒米', code: '590' },
          { name: '密克罗尼西亚', code: '691' },
          { name: '萨摩亚', code: '685' },
          { name: '圣马力诺', code: '378' },
          { name: '圣多美和普林西比', code: '239' },
          { name: '沙特阿拉伯', code: '966' },
          { name: '塞内加尔', code: '221' },
          { name: '塞尔维亚', code: '381' },
          { name: '塞舌尔', code: '248' },
          { name: '塞拉利昂', code: '232' },
          { name: '新加坡', code: '65' },
          { name: '斯洛伐克', code: '421' },
          { name: '斯洛文尼亚', code: '386' },
          { name: '所罗门群岛', code: '677' },
          { name: '索马里', code: '252' },
          { name: '南非', code: '27' },
          { name: '南乔治亚', code: '500' },
          { name: '南苏丹', code: '211' },
          { name: '西班牙', code: '34' },
          { name: '斯里兰卡', code: '94' },
          { name: '苏丹', code: '249' },
          { name: '苏里南', code: '597' },
          { name: '斯瓦尔巴和扬马延群岛', code: '47' },
          { name: '斯威士兰', code: '268' },
          { name: '瑞典', code: '46' },
          { name: '瑞士', code: '41' },
          { name: '叙利亚', code: '963' },
          { name: '中国台湾', code: '886' },
          { name: '塔吉克斯坦', code: '992' },
          { name: '坦桑尼亚', code: '255' },
          { name: '泰国', code: '66' },
          { name: '多哥', code: '228' },
          { name: '托克劳', code: '690' },
          { name: '汤加', code: '676' },
          { name: '特立尼达和多巴哥', code: '1-868' },
          { name: '突尼斯', code: '216' },
          { name: '土耳其', code: '90' },
          { name: '土库曼斯坦', code: '993' },
          { name: '特克斯和凯科斯群岛', code: '1-649' },
          { name: '图瓦卢', code: '688' },
          { name: '乌干达', code: '256' },
          { name: '乌克兰', code: '380' },
          { name: '阿拉伯联合酋长国', code: '971' },
          { name: '英国', code: '44' },
          { name: '美国', code: '1' },
          { name: '美国本土外小岛屿', code: '1-808' },
          { name: '乌拉圭', code: '598' },
          { name: '乌兹别克斯坦', code: '998' },
          { name: '瓦努阿图', code: '678' },
          { name: '梵蒂冈', code: '39' },
          { name: '委内瑞拉', code: '58' },
          { name: '越南', code: '84' },
          { name: '圣文森特和格林纳丁斯', code: '1-784' },
          { name: '维尔京群岛（美国）', code: '1-340' },
          { name: '瓦利斯群岛和富图纳群岛', code: '681' },
          { name: '西撒哈拉', code: '212' },
          { name: '也门', code: '967' },
          { name: '赞比亚', code: '260' },
          { name: '津巴布韦', code: '263' },
          { name: '科索沃', code: '383' },
          { name: '库拉索', code: '599' },
          { name: '圣马丁岛（荷兰部分）', code: '1-721' },
        ],
      };
    },
    computed: {
      computedTime() {
        return (item) => {
          return item.runtimelog_created.replace('+08:00', '').replace('T', ' ');
        };
      },
      computedStyle() {
        return (item) => {
          if (item.id === 0) {
            return 'red';
          } else if (item.id === 2) {
            return 'lu';
          } else {
            return 'huang';
          }
        };
      },
      computeBg() {
        return (item) => {
          if (item.id === 1) {
            return 'WhatsApp';
          } else if (item.id === 2) {
            return 'zalo';
          } else if (item.id === 3) {
            return 'LINE';
          } else if (item.id === 4) {
            return 'telegram';
          } else if (item.id === 5) {
            return 'telegram';
          } else if (item.id === 6) {
            return 'TikTok';
          } else if (item.id === 7) {
            return 'Instagram';
          } else if (item.id === 8) {
            return 'Facebook';
          } else if (item.id === 9) {
            return 'Twitter';
          } else if (item.id === 10) {
            return 'Messenger';
          } else if (item.id === 11) {
            return 'Meta-Business';
          } else if (item.id === 12) {
            return 'web';
          } else {
            return 'skype';
          }
        };
      },
    },
    mounted() {
      // Set the initial number of items
      //console.log(this.$route.params);
      if (!this.$route.params.shareid) {
        this.$router.back();
        return;
      }
      // var browerWidth = window.innerWidth; //浏览器可视宽度
      // var baseWidth = 1920; //设计稿宽度
      // var zoomValue = browerWidth / baseWidth; //缩放比例计算
      // document.getElementById("mainContainer").style.transform = "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
      // window.onresize = function () { //窗口尺寸变化时，重新计算和缩放
      //   browerWidth = window.innerWidth;
      //   zoomValue = browerWidth / baseWidth;
      //   document.getElementById("mainContainer").style.transform = "scale(" + zoomValue + "," + zoomValue + ")";
      // }
      // console.log(browerWidth,zoomValue,'======================')
      this.listQuery.sharecode = this.$route.params.sharecode;
      this.listQuery.shareid = Number(this.$route.params.shareid);
      t = this;
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      t.getRenderData();
      t.getColumn();
      this.isMobile = this.checkMobile();
      t.tableFixed = t._isMobile() ? false : 'right';
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      getImgUrl(path) {
        try {
          return require('@/assets/images/guoqi/' + path);
        }catch (e) {
          return '';
        }
      },
      handleResize() {
        t.windowWidth = window.innerWidth;
        // const windowWidth = window.innerWidth;
        t.isPCShow = t.windowWidth >= 768;
      },

      QRHandle(val) {
        t.qrcode = val;
        t.qrcoodeVisible = true;
      },
      copyHandle(val) {
        navigator.clipboard.writeText(val);
        this.$message.success('复制成功');
      },

      handleBatchClearing() {
        if (t.repeat.listQuery.rselectid.length <= 0) {
          return t.$toasted.show(t.$t('Please select the account first'), {
            theme: 'bubble',
            position: 'bottom-center',
            type: 'error',
            duration: 3000,
          });
        }

        this.$confirm(`确认要批量删除清空统计吗?不可恢复`, this.$t('warning'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning',
        })
          .then(async () => {
            await t.request
              .post('/accountlist/batchStaClear', {
                acclist_id: t.repeat.listQuery.rselectid,
              })
              .then((response) => {
                t.getList();
              })
              .catch((res) => {
                if (res.errMsg) {
                  t.$toasted.show(res.errMsg, {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                } else {
                  t.$toasted.show(t.$t('Server Error'), {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                }
              });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      handleBatchRemoval() {
        if (t.repeat.listQuery.rselectid.length <= 0) {
          return t.$toasted.show(t.$t('Please select the account first'), {
            theme: 'bubble',
            position: 'bottom-center',
            type: 'error',
            duration: 3000,
          });
        }
        this.$confirm(
          `确认要批量移除吗?不可恢复(在线的账号会自动跳过)`,
          this.$t('warning'),
          {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning',
          }
        )
          .then(async () => {
            await t.request
              .post('/accountlist/batchStaRemove', {
                acclist_id: t.repeat.listQuery.rselectid,
              })
              .then((response) => {
                t.getList();
              })
              .catch((res) => {
                if (res.errMsg) {
                  t.$toasted.show(res.errMsg, {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                } else {
                  t.$toasted.show(t.$t('Server Error'), {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                }
              });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      checkMobile() {
        return navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
      },
      loadHeadimgErr(e, key) {
        e[key] = t.noheadimg;
      },
      in_array(search, array = []) {
        // eslint-disable-next-line no-unused-vars
        for (const val of array) {
          if (val == search) {
            return true;
          }
        }
        return false;
      },
      _isMobile() {
        const flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        return flag;
      },
      repetClick(index) {
        t.repeat.listQuery.shareid = t.listQuery.shareid;
        t.repeat.listQuery.rselectappid = t.listQuery.AcclistAppid;
        if (
          !t.repeat.listQuery.rselectappid ||
          t.repeat.listQuery.rselectappid <= 0
        ) {
          return t.$toasted.show(t.$t('Please select an APP'), {
            theme: 'bubble',
            position: 'bottom-center',
            type: 'error',
            duration: 3000,
          });
        }
        t.repeat.listQuery.rtype = index;
        if (index === 0) {
          if (t.repeat.listQuery.rselectid.length === 0) {
            return t.$toasted.show(t.$t('Please select the account first'), {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'error',
              duration: 3000,
            });
          }
        }
        t.dialogSwitch.RepeatListModel = true;
        t.getRepet(1);
      },
      getRepet(day = null) {

        if (!isNaN(day)) {
          this.repeat.listQuery.page = 1;
          this.repeat.listQuery.day = day;
        }
        t.repeat.listLoading = true;
        t.request
          .post('/accountlist/repetition', t.repeat.listQuery)
          .then((response) => {
            const res = response.data;
            t.repeat.list = res.items || [];
            t.repeat.listLoading = false;
            t.repeat.total = res.count;
            t.repeat.repet = res.repet;
            t.repeat.totalnum = res.totalnum;
            t.repeat.repetnum = res.repetnum;
            t.repeat.today = res.today;
            t.repeat.todayRepetion = res.todayRepetion;
            t.repeat.totalRepetNum = res.totalRepetNum;
          })
          .catch((res) => {
            t.repeat.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      handleDownloadExcel(index = 0) {
        if (!t.listQuery.AcclistAppid || t.listQuery.AcclistAppid <= 0) {
          return t.$toasted.show(t.$t('Please select an APP'), {
            theme: 'bubble',
            position: 'bottom-center',
            type: 'error',
            duration: 3000,
          });
        }

        if (index === 0) {
          if (t.repeat.listQuery.rselectid.length === 0) {
            return t.$toasted.show(t.$t('Please select the account first'), {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'error',
              duration: 3000,
            });
          }
          t.listQuery.rselectid = t.repeat.listQuery.rselectid;
        }
        t.listQuery.index = index;
        t.$toasted.show(
          '如果数据过多可能会等待的时间较长，请不要刷新页面，服务端处理完数据会自动进行下载，注意浏览器可能会进行拦截,最多导出数据：'+this.option.exportNum + '条',
          {
            theme: 'outline',
            position: 'top-center',
            type: 'info',
            duration: 10000,
          }
        );
        t.request
          .post('/accountlist/downloadExcel', t.listQuery)
          .then((response) => {
            const res = response.data;

            download(t.config.domain + res.file + '?t=' + new Date().getTime());
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      getTimeTagtype(type) {
        switch (type) {
        case '离线':
          return 'info';
        case '主动下线':
          return 'danger';
        case '上线':
          return 'success';
        case '清零':
          return 'warning';
        default:
          return 'primary';
        }
      },
      getTimeTagicon(type) {
        switch (type) {
        case '离线':
          return 'el-icon-link';
        case '主动下线':
          return 'el-icon-switch-button';
        case '上线':
          return 'el-icon-bell';
        case '清零':
          return 'el-icon-delete';
        default:
          return 'el-icon-more-outline';
        }
      },
      getTimeTagcolor(type) {
        switch (type) {
        case '离线':
          return '#909399';
        case '主动下线':
          return '#ff4949';
        case '上线':
          return 'rgb(7, 181, 59)';
        case '清零':
          return '#d4b524';
        default:
          return 'rgb(0, 143, 243)';
        }
      },
      resetFun() {
        // eslint-disable-next-line no-unused-vars
        for (const listSearchServerElement of t.listSearchServer) {
          t.$set(t.listQuery, listSearchServerElement.key, '');
        }
      },
      handleSelectionChange(e) {
        this.selectDate = e;
        t.repeat.listQuery.rselectid = [];
        //eslint-disable-next-line no-unused-vars
        for (const selectionElement of e) {
          t.repeat.listQuery.rselectid.push(selectionElement.acclist_id);
        }
      },
      toggleSelection() {
        this.$refs.multipleTable.clearSelection();
      },
      getColumn() {
        t.showColums = JSON.parse(
          window.localStorage.getItem('accountlist_colmun') || '{}'
        );
      },
      selectAllFun(e) {
        !t.allStatus;
        let i = 0;
        t.listModelServer.forEach((item) => {
          if (item.show === '1') {
            if (!t.allStatus && i <= 5) {
              t.$set(t.showColums, item.key, true);
            } else if (!t.allStatus && i > 5) {
              t.$set(t.showColums, item.key, false);
            } else {
              t.$set(t.showColums, item.key, true);
            }
            i++;
          }
        });
        window.localStorage.setItem(
          'accountlist_colmun',
          JSON.stringify(t.showColums)
        );
      },
      saveColmun() {
        let arr = Object.values(t.showColums).filter((item) => item);
        t.allStatus = arr.length === t.listModelNum;
        window.localStorage.setItem(
          'accountlist_colmun',
          JSON.stringify(t.showColums)
        );
      },
      getSummar(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            // 只找第一列放合计
            sums[index] = `${t.$t('combined')}`;
            return;
          }
          if (column['property'] === 'acclist_contact_today') {
            const values = data.map((item) => item[column.property]); // 把对应一列中的之全部取出，放到一个数组中
            let total = 0;
            let today = 0;
            values.reduce((prev, curr) => {
              const value = curr.split('/'); // 将values中的每个值转换为number类型
              total += Number(value[0]);
              today += Number(value[1]);
            }, 0);
            sums[index] = `${total}/${today}`;
            return;
          }
          if (column['property'] === 'acclist_repeat_to_day') {
            const values = data.map((item) => item[column.property]); // 把对应一列中的之全部取出，放到一个数组中
            let total = 0;
            let today = 0;
            values.reduce((prev, curr) => {
              const value = curr.split('/'); // 将values中的每个值转换为number类型
              total += Number(value[0]);
              today += Number(value[1]);
            }, 0);
            sums[index] = `${total}/${today}`;
            return;
          }
          if (t.summar.data[column.property]) {
            // 判断当前prop绑定的ID
            const values = data.map((item) => item[column.property]); // 把对应一列中的之全部取出，放到一个数组中
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr); // 将values中的每个值转换为number类型
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          }
        });
        return sums;
      },
      handleLog(item, index) {
        t.$bvModal.show('select-time-list-model');
        t.logLoadding = true;
        t.request
          .post('/accountlist/loglist', item)
          .then((response) => {
            const res = response.data;
            t.logList = res.logList || [];
            t.logLoadding = false;
          })
          .catch((res) => {
            t.logLoadding = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      handleClear(item) {
        this.$confirm(`确认要删除清空统计吗?不可恢复`, this.$t('warning'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning',
        })
          .then(async () => {
            t.request
              .post('/accountlist/staClear', item)
              .then((response) => {
                t.getList();
              })
              .catch((res) => {
                if (res.errMsg) {
                  t.$toasted.show(res.errMsg, {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                } else {
                  t.$toasted.show(t.$t('Server Error'), {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                }
              });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      handleRemove(item) {
        this.$confirm(
          `确认要移除账号吗?其下的统计数据与粉丝通讯录都将删除且不可恢复`,
          this.$t('warning'),
          {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning',
          }
        )
          .then(async () => {
            t.request
              .post('/accountlist/remove', item)
              .then((response) => {
                t.getList();
              })
              .catch((res) => {
                if (res.errMsg) {
                  t.$toasted.show(res.errMsg, {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                } else {
                  t.$toasted.show(t.$t('Server Error'), {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                }
              });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      handleImportedContact(row) {
        this.$prompt(
          '联系人格式为(一行一个): 用户名----昵称----来源',
          this.$t('Imported vermicelli'),
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            inputType: 'textarea',
            type: 'warning',
            inputPlaceholder: `请在这里填入导入的联系人，一行一个最多${this.option.importNum}行`,
            inputValidator: 'required',
          }
        )
          .then(async ({ value }) => {
            await t.request
              .post('/accountlist/uploadContact', {
                acclist_id: row.acclist_id,
                acclist_appid: row.acclist_appid,
                contact_txt: value,
              })
              .then((response) => {
                t.$toasted.show(response.msg, {
                  theme: 'bubble',
                  position: 'bottom-center',
                  type: 'success',
                  duration: 10000,
                });
                t.getList();
              })
              .catch((res) => {
                if (res.errMsg) {
                  t.$toasted.show(res.errMsg, {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                } else {
                  t.$toasted.show(t.$t('Server Error'), {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                }
              });
          })
          .catch((err) => {
            t.$toasted.show('已取消导入', {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'default',
              duration: 3000,
            });
          });
      },
      handleDownload() {
        this.downloadLoading = true;
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = ['timestamp', 'title', 'type', 'importance', 'status'];
        const filterVal = [
          'timestamp',
          'title',
          'type',
          'importance',
          'status',
        ];
        const data = this.formatJson(filterVal);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'table-list',
        });
        this.downloadLoading = false;
      });
      },
      formatJson(filterVal) {
        return this.list.map((v) =>
          filterVal.map((j) => {
            if (j === 'timestamp') {
              return parseTime(v[j]);
            } else {
              return v[j];
            }
          })
        );
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleContactFilter() {
        this.contact.listQuery.page = 1;
        this.getContact();
      },
      handleUpdate(row) {
        this.temp = Object.assign({}, row); // copy obj
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in this.temp) {
          try {
            if (typeof JSON.parse(this.temp[tempKey]) === 'object') {
              this.temp[tempKey] = JSON.parse(this.temp[tempKey]);
            }
          // eslint-disable-next-line no-empty
          } catch (e) {}
        }
        this.temp.password = '';
        this.temp.walletpassword = '';
        this.dialogStatus = 'update';
        t.$bvModal.show('tag-modal-example');
      },
      handleCreate() {
        this.resetTemp();
        this.dialogStatus = 'create';
        t.$bvModal.show('tag-modal-example');
      },
      resetTemp() {
        const jsonTemp = {};
        // eslint-disable-next-line no-unused-vars
        for (const listModelServerKey in this.listModelServer) {
          switch (this.listModelServer[listModelServerKey]['edittype']) {
          case 'checkbox':
            jsonTemp[this.listModelServer[listModelServerKey]['key']] = [];
            break;
          default:
            jsonTemp[this.listModelServer[listModelServerKey]['key']] = '';
            break;
          }
        }
        this.temp = jsonTemp;
      },
      createData() {
        const tempData = Object.assign({}, this.temp);
        for (const tempKey in tempData) {
          try {
            for (const modelDatum of this.listModelServer) {
              if (
                modelDatum.key === tempKey &&
                (modelDatum.edittype === 'number' || modelDatum.edittype.indexOf('Number') !== -1)
              ) {
                tempData[tempKey] = Number(tempData[tempKey]);
                break;
              }
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
          // eslint-disable-next-line no-empty
          } catch (e) {}
        }
        t.request
          .post('/accountlist/add', tempData)
          .then((response) => {
            t.getList();
            t.$bvModal.hide('tag-modal-example');
            t.$toasted.show('添加成功', {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'success',
              duration: 3000,
            });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      updateData() {
        const tempData = Object.assign({}, this.temp);
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in tempData) {
          try {
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of this.listModelServer) {
              if (
                modelDatum.key === tempKey &&
                (modelDatum.edittype === 'number' || modelDatum.edittype.indexOf('Number') !== -1)
              ) {
                tempData[tempKey] = Number(tempData[tempKey]);
                break;
              }
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
          } catch (e) {
            console.error(e);
          }
        /* if (typeof JSON.parse(this.temp[tempKey]) === 'array') {

        }*/
        }
        t.request
          .post('/accountlist/edit', tempData)
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            t.getList();
            t.$bvModal.hide('tag-modal-example');
            t.$toasted.show('编辑成功', {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'success',
              duration: 3000,
            });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      handleDelete(row, index) {
        this.$confirm(`确认要删除吗?`, this.$t('warning'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning',
        })
          .then(async () => {
            t.request
              .post('/accountlist/delete', row)
              // eslint-disable-next-line no-unused-vars
              .then((response) => {
                this.list.splice(index, 1);
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                });
              })
              .catch((res) => {
                if (res.errMsg) {
                  t.$toasted.show(res.errMsg, {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                } else {
                  t.$toasted.show(t.$t('Server Error'), {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                }
              });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      handleOffline(row) {
        this.$confirm(`确认要下线吗?`, this.$t('warning'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning',
        })
          .then(async () => {
            t.request
              .post('/accountlist/offline', row)
              .then((response) => {
                const res = response.data;
                t.getList();
              })
              .catch((res) => {
                if (res.errMsg) {
                  t.$toasted.show(res.errMsg, {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                } else {
                  t.$toasted.show(t.$t('Server Error'), {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                }
              });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      getRenderData() {
        t.request
          .get('/accountlist/render')
          .then((response) => {
            const res = response.data;
            let i = 0;
            // eslint-disable-next-line no-unused-vars
            res['modelData'].find((x) => x.key == 'acclist_region').twidth = 100;
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of res.modelData) {
              if (modelDatum.key == 'acclist_created') {
                modelDatum.label = `${
                  res.modelData.find((x) => x.key == 'acclist_created').label
                }/${res.modelData.find((x) => x.key == 'acclist_logined').label}`;
                res['modelData'].find(
                  (x) => x.key == 'acclist_created'
                ).twidth = 200;
                this.acclist_created_label = modelDatum.label;
              }
              if (
                modelDatum.show === '1' &&
                modelDatum.key != 'acclist_logined'
              ) {
                t.listModelNum = i + 1;

                // eslint-disable-next-line no-prototype-builtins
                if (!t.showColums.hasOwnProperty(modelDatum.key)) {
                  t.$set(t.showColums, modelDatum.key, true);
                }
                i++;
              }
            }
            t.saveColmun();
            t.option = res.option ? res.option : [];
            t.summar = res.summar || {};
            t.listModelServer = res.modelData.filter((x) => x.key != 'acclist_logined') || [];
            t.listSearchServer = res.searchData || [];
            t.listQuery.sort = res.defaultSort || [];
            t.pageSizelist = res.pageSizelist || [];
            t.getList();
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      getContactRenderData(item) {
        t.countryImg = false;
        t.contact.title = '';
        t.dialogSwitch.contactListModel = true;
        t.contact.listLoading = true;
        t.request
          .get('/contact/render')
          .then((response) => {
            const res = response.data;
            t.contact.summar = res.summar || {};
            t.contact.listModelServer = res.modelData || [];
            t.contact.listSearchServer = res.searchData || [];
            t.contact.listQuery.sort = res.defaultSort || [];
            const applist = res.appList;
            // 当返回是whasapp 平台 显示国家图片
            t.countryImg = applist[item.acclist_appid - 1].id == 1;
            applist.splice(3, 0, { title: '' });
            t.contact.title = `平台：${
              applist.find((x) => x.id == item.acclist_appid).title
            }  用户名：${item.acclist_username} 昵称：${item.acclist_nickname}`;
            t.contact.listQuery.contactaccid = item.acclist_id;
            t.contact.listQuery.contactappid = item.acclist_appid;
            t.getContact();
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      getList() {
        this.listLoading = true;
        t.request
          .get('/accountlist/list', this.listQuery)
          .then((response) => {
            response.data.items.map((item) => {
              //  {id: 1, title: 'WhatsApp'} https://wa.me/用户名
              //  {id: 2, title: 'Zalo'} https://zalo.me/用户名
              // {id: 3, title: 'Line'} https://line.me/ti/p/~用户名
              //  {id: 4, title: 'Telegram'} https://t.me/用户名  @去掉
              // {id: 6, title: 'TikTok'} https://www.tiktok.com/@用户名   加个@
              //  {id: 7, title: 'Instagram'} https://www.instagram.com/用户名
              //  {id: 8, title: 'Facebook'} https://www.facebook.com/用户名
              // {id: 9, title: 'Twitter'} https://twitter.com/用户名
              // {id: 10, title: 'Messenger'} https://www.facebook.com/用户名
              // {id: 11, title: 'Meta Business'} 和Messenger一样
              // {id: 12, title: 'Google Voice'} 不支持
              // {id: 13, title: 'BoTim'} 不支持
              // {id: 14, title: 'WEB'} 不支持
              // {id: 16, title: 'Word'} 不支持
              item.acclist_username_url = '--';
              if (item.acclist_appid == 1) {
                item.acclist_username_url = `https://wa.me/${item.acclist_username}`;
              }
              if (item.acclist_appid == 2) {
                item.acclist_username_url = `https://zalo.me/${item.acclist_username}`;
              }
              if (item.acclist_appid == 3) {
                item.acclist_username_url = `https://line.me/ti/p/~${item.acclist_username}`;
              }
              if (item.acclist_appid == 4) {
                let aa = item.acclist_username.replace(/@/g, '');
                item.acclist_username_url = `https://t.me/${aa}`;
              }
              if (item.acclist_appid == 6) {
                item.acclist_username_url = `https://www.tiktok.com/@${item.acclist_username}`;
              }
              if (item.acclist_appid == 7) {
                item.acclist_username_url = `https://www.instagram.com/${item.acclist_username}`;
              }
              if (
                item.acclist_appid == 8 ||
                item.acclist_appid == 10 ||
                item.acclist_appid == 11
              ) {
                item.acclist_username_url = `https://www.facebook.com/${item.acclist_username}`;
              }
              if (item.acclist_appid == 9) {
                item.acclist_username_url = `https://twitter.com/${item.acclist_username}`;
              }

              if (item.acclist_appid == 16) {
                item.acclist_username_url = `https://twitter.com/${item.acclist_username}`;
              }

              return item;
            });
            this.list = response.data.items;
            this.total = response.data.total;
            // Just to simulate the time of the request
            this.listLoading = false;
          })
          .catch((res) => {
            this.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      getContact() {
        t.contact.listLoading = true;
        t.request
          .get('/contact/list', t.contact.listQuery)
          .then((response) => {
            t.contact.list = response.data.items.map((item) => {
              item.countryImgUrl = 'none.png';
              item.countryTxt = '';
              t.countryList.forEach((i) => {
                if (
                  item.contact_account.slice(0, 2) == '1-' &&
                  i.code == item.contact_account.slice(0, 5)
                ) {
                  item.countryImgUrl = `${i.code}.png`;
                  item.countryTxt = i.name;
                }
                if (i.code == item.contact_account.slice(0, 3)) {
                  item.countryImgUrl = `${i.code}.png`;
                  item.countryTxt = i.name;
                }
                if (i.code == item.contact_account.slice(0, 2)) {
                  item.countryImgUrl = `${i.code}.png`;

                  item.countryTxt = i.name;
                }
              });
              if (item.children && item.children.length > 0) {
                item.children = item.children.map((cItem) => {
                  cItem.countryImgUrl = 'none.png';
                  cItem.countryTxt = '';
                  t.countryList.forEach((i) => {
                    if (
                      cItem.contact_account.slice(0, 2) == '1-' &&
                      i.code == cItem.contact_account.slice(0, 5)
                    ) {
                      cItem.countryImgUrl = `${i.code}.png`;
                      cItem.countryTxt = i.name;
                      return;
                    }
                    if (i.code == cItem.contact_account.slice(0, 3)) {
                      cItem.countryImgUrl = `${i.code}.png`;
                      cItem.countryTxt = i.name;
                      return;
                    }
                    if (i.code == cItem.contact_account.slice(0, 2)) {
                      cItem.countryImgUrl = `${i.code}.png`;
                      cItem.countryTxt = i.name;
                      return;
                    }
                  });
                  return cItem;
                });
              }

              return item;
            });
            // let aa = t.countryList.map((x) => {
            //   let ii = x.name.trim();
            //   //   return `"${ii}":"${ii}"`;
            //   return ii;
            // });
            // console.log("contact.list", t.contact.list);
            t.contact.total = response.data.total;
            // Just to simulate the time of the request
            t.contact.listLoading = false;
          })
          .catch((res) => {
            t.contact.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      sortChange(data) {
        const { prop, order } = data;
        t.sortByID(order, prop);
      },
      sortByID(order, prop) {
        if (order === 'ascending') {
          t.listQuery.sort = `${prop} ASC`;
        } else {
          t.listQuery.sort = `${prop} DESC`;
        }
        t.handleFilter();
      },
      sortContactChange(data) {
        const { prop, order } = data;
        t.sortContactByID(order, prop);
      },
      sortContactByID(order, prop) {
        if (order === 'ascending') {
          t.listQuery.sort = `${prop} ASC`;
        } else {
          t.listQuery.sort = `${prop} DESC`;
        }
        t.handleFilter();
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    },
  };
</script>

<style lang="scss">
.staticRepeat {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .back {
    display: flex;
    flex-direction: column;
    width: 32%;
    margin-left: 2%;
    border: 1px solid #f0f0f0;
    padding: 24px;
    zoom: 1;

    .title {
      margin-bottom: 4px;
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
    }

    .num {
      font-size: 24px;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
      Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
      sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    }
  }

  .back:first-of-type {
    margin-left: 0;
  }
}

.bodymodel {
  height: 30rem;
}

.red {
  color: #d9ecff;
  border: 1px solid #d9ecff;
  background-color: #409eff;
  padding: 3px 5px;
  border-radius: 5px;
}

.lu {
  background-color: #67c23a;
  border-color: #e1f3d8;
  color: #e1f3d8;
  padding: 3px 5px;
  border-radius: 5px;
}

.huang {
  background-color: #e6a23c;
  border-color: #faecd8;
  color: #faecd8;
  padding: 3px 5px;
  border-radius: 5px;
}

.WhatsApp {
  background: rgb(28, 179, 155);
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
}

.TikTok {
  background: rgb(24, 24, 24);
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
}

.Instagram {
  background: rgb(255, 94, 94);
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
}

.Facebook {
  background: rgb(0, 180, 220);
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
}

.Twitter {
  background: rgb(89, 92, 93);
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
}

.Messenger {
  background: rgb(255, 94, 94);
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
}

.Meta-Business {
  background: rgb(0, 28, 220);
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
}

.web {
  background: rgb(251, 192, 23);
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
}

.LINE {
  background: rgb(7, 181, 59);
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
}

.telegram {
  background: rgb(65, 183, 246);
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
}

.skype {
  background: rgb(0, 120, 202);
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
}

.zalo {
  background: rgb(0, 143, 243);
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
}

.el-table__cell {
  background-color: unset;
}

.el-steps-warp {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: 100%;
}

.imgPop {
  height: 40px;
  width: 40px;
  border-radius: 5px;
}

th {
  font-weight: normal;
}
</style>
