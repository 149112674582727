<template>
  <div id="dataView-Daping" ref="vantaContainer" class="body">
    <!-- 头部模块 -->
    <header>
      <div style="position: absolute; left: 3rem; top: 1rem">
        <img class="flagvn" v-if="vnShow" :src="getImgUrl('84.png')" alt="" />
        <Language style="margin-left: -50px" />
      </div>
      <h1>
        <span>{{ $t("海王大屏标头") }}</span>
      </h1>
      <p>
        <span>Data visualization page design</span>
      </p>
      <dv-decoration-6
        class="headerBottom"
        style="
          width: 300px;
          height: 16px;
          position: absolute;

          bottom: 0;
          left: calc(50% - 150px);
        "
      />
      <p class="singTime">
        {{ $t("新加坡时间") }}(GMT+8) {{ ymd }}({{ week }}) 【{{
          newYorkTime.slice(-9)
        }}】
      </p>
    </header>

    <div class="main">
      <!-- top5 -->
      <div class="top5">
        <div class="top5-title">
          <span>{{ $t("Invitation Code") }}:{{ share_key }}</span>
        </div>
        <div class="top5-content">
          <ul>
            <li>
              <div
                :class="in_array(2, shareprivilege) ? 'cicle-active' : 'cicle'"
              ></div>
              <div
                :class="
                  in_array(2, shareprivilege)
                    ? 'li-content-active'
                    : 'li-content'
                "
              >
                <i
                  style="margin-right: 5px; font-size: 14px"
                  class="el-icon-user"
                  :style="
                    in_array(2, shareprivilege)
                      ? 'color:#f29701;font-weight:600'
                      : ''
                  "
                ></i>
                <span
                  :style="
                    in_array(2, shareprivilege)
                      ? 'color:#f29701;font-weight:600'
                      : ''
                  "
                  >{{ $t("Contact Details") }}</span
                >
                <!--  <span>15862</span>
                <span>82%</span> -->
                <!-- <span><img src="./images/up.png" alt="" /></span> -->
              </div>
            </li>
            <li>
              <div
                :class="in_array(4, shareprivilege) ? 'cicle-active' : 'cicle'"
              ></div>
              <div
                :class="
                  in_array(4, shareprivilege)
                    ? 'li-content-active'
                    : 'li-content'
                "
              >
                <i
                  style="margin-right: 5px; font-size: 14px"
                  class="el-icon-delete"
                  :style="
                    in_array(4, shareprivilege)
                      ? 'color:#f29701;font-weight:600'
                      : ''
                  "
                ></i>
                <span
                  :style="
                    in_array(4, shareprivilege)
                      ? 'color:#f29701;font-weight:600'
                      : ''
                  "
                  >{{ $t("Remove APP") }}</span
                >
                <!--   <span>15862</span>
                <span>82%</span> -->
                <!-- <span><img src="./images/up.png" alt="" /></span> -->
              </div>
            </li>
            <li>
              <div
                :class="in_array(5, shareprivilege) ? 'cicle-active' : 'cicle'"
              ></div>
              <div
                :class="
                  in_array(5, shareprivilege)
                    ? 'li-content-active'
                    : 'li-content'
                "
              >
                <i
                  style="margin-right: 5px; font-size: 14px"
                  class="el-icon-magic-stick"
                  :style="
                    in_array(5, shareprivilege)
                      ? 'color:#f29701;font-weight:600'
                      : ''
                  "
                ></i>
                <span
                  :style="
                    in_array(5, shareprivilege)
                      ? 'color:#f29701;font-weight:600'
                      : ''
                  "
                  >{{ $t("Data analysis") }}</span
                >
                <!--  <span>15862</span>
                <span>82%</span> -->
                <!-- <span><img src="./images/up.png" alt="" /></span> -->
              </div>
            </li>
            <li>
              <div
                :class="in_array(3, shareprivilege) ? 'cicle-active' : 'cicle'"
              ></div>
              <div
                :class="
                  in_array(3, shareprivilege)
                    ? 'li-content-active'
                    : 'li-content'
                "
              >
                <i
                  style="margin-right: 5px; font-size: 14px"
                  class="el-icon-platform-eleme"
                  :style="
                    in_array(3, shareprivilege)
                      ? 'color:#f29701;font-weight:600'
                      : ''
                  "
                ></i>
                <span
                  :style="
                    in_array(3, shareprivilege)
                      ? 'color:#f29701;font-weight:600'
                      : ''
                  "
                  >{{ $t("Repetition of analysis") }}</span
                >
              </div>
            </li>
            <li>
              <div
                :class="in_array(6, shareprivilege) ? 'cicle-active' : 'cicle'"
              ></div>
              <div
                :class="
                  in_array(6, shareprivilege)
                    ? 'li-content-active'
                    : 'li-content'
                "
              >
                <i
                  style="margin-right: 5px; font-size: 14px"
                  class="el-icon-s-opportunity"
                  :style="
                    in_array(6, shareprivilege)
                      ? 'color:#f29701;font-weight:600'
                      : ''
                  "
                ></i>
                <span
                  :style="
                    in_array(6, shareprivilege)
                      ? 'color:#f29701;font-weight:600'
                      : ''
                  "
                  >{{ $t("Heavy powder labeling") }}</span
                >
              </div>
            </li>
          </ul>
        </div>
        <div class="top5-title">
          <span> 端口型报表 </span>
        </div>
      </div>
      <!-- 使用情况 -->
      <div class="total">
        <div class="data1">
          <span> {{ $t("Due date") }}</span>
          <p>{{ sharedata.exptime }}</p>
        </div>
        <div class="data2">
          <span> {{ $t("Distribution") + $t("Port") }}</span>
          <p style="font-size: 2.5rem">{{ sharedata.portnum }}</p>
        </div>
        <div class="data3">
          <span>{{ $t("Useds a Port") }}</span>
          <p style="font-size: 2.5rem">{{ sharedata.usednum }}</p>
        </div>
        <div class="data4">
          <span>{{ $t("Creation date") }}</span>
          <p>{{ sharedata.share_created }}</p>
        </div>
        <canvas class="rain"></canvas>
        <canvas class="dashed"></canvas>
        <div class="sphere">
          <div class="sphere-bg"></div>
          <div class="sum">
            <span>{{ $t("Powder in today") }}</span>
            <p>{{ sharedata.contacttoday }}</p>
          </div>
        </div>
        <div class="cicle3"></div>
        <div class="cicle4"></div>
        <div class="cicle5"></div>
        <div class="cicle6"></div>
        <div class="cicle7"></div>
        <div class="cicle8">
          <span>{{ sharedata.apponline }}</span>
          <p>{{ $t("Online APP") }}</p>
        </div>
        <div class="cicle9">
          <span>{{ sharedata.apptotal }}</span>
          <p>{{ $t("Total number of apps") }}</p>
        </div>
        <div class="cicle10">
          <span>{{ sharedata.repeat }}</span>
          <p>{{ $t("Repeat fans") }}</p>
        </div>
        <div class="cicle11">
          <span>{{
            sharedata.contactnum - sharedata.allrepeat + sharedata.repeat
          }}</span>
          <p>{{ $t("Duplicate removal") }}</p>
        </div>
      </div>
      <!-- 会销分析 -->
      <div class="analyse">
        <div class="analyse-title">
          <span>{{ $t("Fan situation") }}</span>
        </div>
        <ul>
          <li>
            <img src="./images/s1.png" alt="" />
            <h5>{{ $t("Total fans") }}</h5>
            <br />
            <span>Marketing Target</span><br />
            <em>{{ sharedata?.contactnum }}</em>
          </li>
          <li>
            <img src="./images/s3.png" alt="" />
            <h5>
              {{ $t("Duplicate removal") }}/ {{ $t("Today's heavy powder") }}
            </h5>
            <br />
            <span>Marketing Target</span><br />
            <em
              >{{
                sharedata.contacttoday -
                sharedata.todayAllRepeat +
                sharedata.todayrepeat
              }}/{{ sharedata?.contacttoday }}</em
            >
          </li>
          <li>
            <img src="./images/s2.png" alt="" />
            <h5>{{ $t("Work order reset (daily)") }}</h5>
            <br />
            <span>Target Date</span><br />
            <em>{{ sharedata.zerotime }}</em>
          </li>
        </ul>
      </div>
      <!-- 备注 -->
      <div class="sale">
        <ul>
          <li>
            <div class="showImg">
              <img src="./images/remark.png" alt="" />
            </div>
            <div class="data">
              <span>{{ $t("Remark") }} :</span>
              <span>{{ sharedata.remark }}</span>
            </div>
            <!-- <div class="shoeChart"></div> -->
          </li>
          <li>
            <div class="showImg">
              <img src="./images/online.png" alt="" />
            </div>
            <div class="data">
              <span>{{ $t("Online APP") }} :</span>
              <span>{{ sharedata.apponline }}</span>
            </div>
            <!-- <div class="shoeChart"></div> -->
          </li>
          <li>
            <div class="showImg">
              <img src="./images/nologin.png" alt="" />
            </div>
            <div class="data">
              <span>{{ $t("Offline APP") }} :</span>
              <span> {{ sharedata.apptotal - sharedata.apponline }}</span>
            </div>
            <!-- <div class="clothesChart"></div> -->
          </li>
          <li>
            <div class="showImg">
              <img src="./images/lixian.png" alt="" />
            </div>
            <div class="data">
              <span
                >{{
                  $t("Have not logged into the APP for more than seven days")
                }}
                :</span
              >
              <span>{{ sharedata.seven_day_nologin }}</span>
            </div>
            <!-- <div class="mzChart"></div> -->
          </li>
        </ul>
      </div>
      <!-- 图 -->

      <div id="listTitle" class="analyse-title">
        <span>实时列表数据</span>
      </div>
      <dv-border-box-9 class="lineChart">
        <!-- <div class="leftContent"> -->
        <div class="serviceForm">
          <dv-scroll-board
            :config="config"
            ref="scrollBoard"
            style="
              width: 500px;
              height: 200px;
              margin-top: 14px;
              margin-left: 15px;
            "
          />
          <!-- <div v-if="false" class="statusList">
              <ul class="seTable">
                <li>APP</li>
                <li>用户名</li>
                <li>昵称</li>
                <li>首登时间</li>
                <li>最后活跃时间</li>
                <li>联系人/今日</li>
                <li>总重复/今天</li>
                <li>在线状态</li>
                <li>地区</li>
              </ul>
              <div id="orderItems" class="outlineBorder">
                <ul class="rolling rolStyle">
                  <li>2018/12/11 12:01</li>
                  <li>杨久量</li>
                  <li>上门理发</li>
                  <li>义工联合会</li>
                  <li>已上线</li>
                </ul>
              </div>
            </div> -->
        </div>
        <!-- </div> -->
      </dv-border-box-9>
    </div>
    <div class="bottom">
      <h5><span>数据状况</span></h5>
      <p>{{ ip }}-{{ country }}</p>
      <!-- <p class="ipCoun">{{ ip }}-{{ country }}</p> -->
    </div>
  </div>
</template>
<!-- <script src="./js/jquery-1.11.0.min.js"></script> -->
<!-- <script src="/web/jquery.min.js" type="text/javascript"></script> -->
<!-- <script src="./js/echarts.min.js"></script> -->
<!-- <script src="./js/main.js"></script> -->
<!-- <script src="./node_modules/echarts-liquidfill/dist/echarts-liquidfill.min.js"></script>
<script src="./js/echarts.min.js"></script> -->
<script>
// import { mapState } from "vuex";
  let t;
  import Language from '@/components/header/language';
  export default {
    name: 'dataView',
    components: { Language },
    data() {
      return {
        retrievedArray: null,
        vnShow: false,
        share_key: null,
        layout: null,
        applist: null,
        shareprivilege: [],
        showarr: [],
        timerId: null,

        ip: localStorage.getItem('ip'),
        country: localStorage.getItem('countryCode'),
        newYorkTime: new Date().toLocaleString('en-US', {
          timeZone: 'Asia/Singapore',
          hour12: false,
        }),

        options: {
          timeZone: 'Asia/Singapore',
          hour12: false, // 使用24小时制
        },
        // newYorkTime:'',
        newYorkTime: new Date().toLocaleString('en-US', {
          timeZone: 'Asia/Singapore',
          hour12: false,
        }), // 获取新加坡时间
        nowTime: '',
        hms: '', //时分秒
        ymd: '', //年月日
        week: '', //星期英文
        sharedata: {
          bytetotal: null,
          exptime: null,
          portnum: 0,
          remaining: null,
          remark: null,
          seven_day_nologin: '',
          share_created: null,
          sharetype: null,
          sharetypeid: 0,
          usednum: 0,
          zerotime: null,
        },
        config: {
          header: [],
          columnWidth: [90, 120, 90, 130],
          data: [['', '', '', '']],
          align: ['', '', '', 'center', ''],
        },
      };
    },
    created() {},
    mounted() {
      t = this;
      this.vnShow = localStorage.getItem('currentLanguage') == 'VI';

      this.retrievedArray = JSON.parse(localStorage.getItem('newsArrayKey'));

      this.share_key = this.retrievedArray.sharekey;
      this.getContactRenderData();
      this.getRenderData(this.retrievedArray);
      t.timerId = setInterval(this.getRenderData, 15000);
      this.timer = setInterval(() => {
        let timeDate = new Date();
        let year = timeDate.getFullYear();
        let mounth = timeDate.getMonth() + 1;
        let day = timeDate.getDate();
        let hours = timeDate.getHours();
        hours = hours >= 10 ? hours : '0' + hours;
        let minutes = timeDate.getMinutes();
        minutes = minutes >= 10 ? minutes : '0' + minutes;
        let seconds = timeDate.getSeconds();
        seconds = seconds >= 10 ? seconds : '0' + seconds;
        let chinaDate = timeDate.toDateString();
        let chinaDateArray = chinaDate.split(' ')[0];
        let week = timeDate.getDay();
        let weekArr = [
          '星期日',
          '星期一',
          '星期二',
          '星期三',
          '星期四',
          '星期五',
          '星期六',
        ];
        this.nowTime = `${year}/${mounth}/${day} (${weekArr[week]}) ${hours}:${minutes}:${seconds}`;

        this.hms = `${hours}:${minutes}:${seconds}`;
        this.ymd = `${year}/${mounth}/${day}`;
        this.week = `${chinaDateArray}`;
        let aa = new Date().toLocaleString('en-US', {
          timeZone: 'Asia/Singapore',
          hour12: false,
        });
        this.newYorkTime = aa + ' ';
      
      }, 1000);
    },
    // computed: {
    //   ...mapState({
    //     share_key: (state) => {
    //       state.dataview.sharekey;
    //     },
    //   }),
    // },
    beforeDestroy() {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
      // 在组件销毁前清除定时器
      clearInterval(this.timer);
      clearInterval(this.interval);
    },
    methods: {
      getImgUrl(path) {
        return require('@/assets/images/guoqi/' + path);
      },
      in_array(search, array = []) {
        // eslint-disable-next-line no-unused-vars
        for (const val of array) {
          if (val == search) {
            return true;
          }
        }
        return false;
      },
      formatDate() {
        let timeDate = new Date()
          .toLocaleString('en-US', {
            timeZone: 'Asia/Singapore',
            hour12: false,
          })
          .split(',');
        let ymd = timeDate[0].split('/');
        let aa = `${ymd[2]}/${ymd[1]}/${ymd[0]} ${timeDate[1]}`;
        this.newYorkTime = aa;
      },
      getContactRenderData() {
        t.request
          .get('/contact/render')
          .then((response) => {
            const res = response.data;
            const applist = res.appList;
            applist.splice(3, 0, { title: '' });
            this.applist = applist;
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      getRenderData() {
        let val = JSON.parse(localStorage.getItem('newsArrayKey'));
        this.ip = localStorage.getItem('ip') || '';
        this.country = localStorage.getItem('countryCode') || '';
        this.config.header = [
          'APP',
          t.$t('用户名'),
          t.$t('昵称'),
          t.$t('联系人'),
          t.$t('在线'),
        ];
        t.request
          .get('/accountshow/render', { sharekey: val.sharekey })
          .then((response) => {
            const res = response.data;
            t.shareprivilege = res.shareprivilege || []; // 权限
            this.showarr = res['modelData'].find(
              (x) => x.key == 'acclist_status'
            ).showarr;
            for (let key in res.sharedata) {
              this.sharedata[key] = res.sharedata[key];
            }
            // this.sharedata = res.sharedata;
            this.getList();
          })
          .catch((res) => {

            this.$toasted.show(this.$t('Server Error'), {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'error',
              duration: 3000,
            });
          });
      },

      getList() {
        let val = JSON.parse(localStorage.getItem('newsArrayKey'));
        t.request
          .get('/accountshow/list', val)
          .then((response) => {
            let arr = [];
            //
            response.data.items.map((item) => {
              let x = [
                //[item.acclist_appid - 1]
                this.applist.find((x) => item.acclist_appid == x.id).title, // APP
                item.acclist_username, // 用户名
                item.acclist_nickname, // 昵称
                item.acclist_contact_today, // 联系人
                this.showarr[item.acclist_status].title, // 在线状态
              ];
              arr.push(x);
            });
            this.config.data = arr;
            this.total = response.data.total;
            t.sharedata.apptotal = response.data.apptotal;
            t.sharedata.apponline = response.data.apponline;
            t.sharedata.contactnum = response.data.contactnum;
            // t.sharedata.contacttoday = response.data.contacttoday;
            t.sharedata.repeat = response.data.repeat;
            t.sharedata.todayrepeat = response.data.todayrepeat;
            t.sharedata.allrepeat = response.data.allrepeat;
            t.sharedata.todayAllRepeat = response.data.todayAllRepeat;

            this.config = { ...this.config };
            this.$nextTick(() => {
              t.sharedata.contacttoday = response.data.contacttoday;
              this.config = { ...this.config };
            });
          })
          .catch((res) => {
            // this.listLoading = false;
            if (res.errMsg) {
              this.retrievedArray.password = '';
              this.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              this.$toasted.show(this.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.body {
  width: 100%;
  min-width: 1360px;
  overflow: hidden;
  position: relative;
  height: 100vh;
}
.body {
  background: url(./images/53bg.png) no-repeat;
  background-size: 100%;
}
ul,
li {
  list-style: none;
}
@font-face {
  font-family: "LCdd";
  src: url("./fonts/LCdd.TTF");
}
header {
  height: 12%;
  width: 100%;
  background-color: #fff;
  background: url(./images/53titlebg.png) no-repeat top center;
  background-size: 100%;
  text-align: center;
  position: relative;
}
header .headerBottom {
  position: absolute;
  bottom: 0;
}
header h1 {
  font-size: 2rem;
  height: 50%;
  margin: auto;
  display: table;
  font-weight: 500;
  background-image: -webkit-linear-gradient(bottom, #86919e, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.flagvn {
  position: absolute;
  right: 20px;
  width: 20px;
  top: 3px;
}
header h1 span {
  display: table-cell;
  vertical-align: middle;
}
header p {
  font-size: 1rem;
  background-image: -webkit-linear-gradient(bottom, #86919e, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.singTime {
  position: absolute;
  right: 2rem;
  top: 1rem;
}
.main {
  width: 100%;
  height: 78%;
  padding: 0 2.5%;
  box-sizing: border-box;
  position: relative;
}
.main .top5 {
  width: 17.5%;
  height: 70%;
  float: left;
}
.main .top5 .top5-title {
  width: 100%;
  height: 10%;
  background: url(./images/title.png) no-repeat center;
  background-size: 100%;
  color: #fff;
  text-align: center;
  display: table;
  font-size: 1.1rem;
}
.main .top5 .top5-title span {
  display: table-cell;
  vertical-align: middle;
}
.main .top5 .top5-content {
  width: 100%;
  height: 80%;
}
.main .top5 .top5-content ul {
  width: 100%;
  height: 100%;
}
.main .top5 .top5-content ul li {
  width: 100%;
  height: 15%;
  margin-top: 5%;
  position: relative;
  margin-left: -1rem;
}
.main .top5 .top5-content ul li .li-content {
  width: 90%;
  height: 100%;
  margin-left: 5%;
  background: url(./images/border.png) no-repeat center;
  background-size: contain;
  font-size: 0.8rem;
  padding-left: 20%;
  padding-top: 5%;
  color: #fff;
  box-sizing: border-box;
}
.main .top5 .top5-content ul li .li-content-active {
  width: 90%;
  height: 100%;
  margin-left: 5%;
  background: url(./images/border2.png) no-repeat center;
  background-size: contain;
  font-size: 0.8rem;
  padding-left: 20%;
  padding-top: 5%;
  color: #fff;
  box-sizing: border-box;
}
.main .top5 .top5-content ul li .li-content span {
  margin-right: 2%;
}
.main .top5 .top5-content ul li .cicle {
  content: "";
  display: block;
  position: absolute;
  width: 1.7rem;
  height: 1.7rem;
  background: url(./images/green.png) no-repeat center;
  background-size: 100%;
  left: 1.5rem;
  bottom: 0.5rem;
}
.main .top5 .top5-content ul li .cicle-active {
  background: url(./images/orange.png) no-repeat center;
  background-size: 100%;
  left: 1.5rem;
  bottom: 0.5rem;
  content: "";
  display: block;
  position: absolute;
  width: 1.7rem;
  height: 1.7rem;
}
.main1 .top5 .top5-content ul li:nth-of-type(1) .li-content {
  background: url(./images/border2.png) no-repeat center;
  background-size: contain;
}
.main1 .top5 .top5-content ul li:nth-of-type(1) .cicle-active {
  background: url(./images/orange.png) no-repeat center;
  background-size: 100%;
}
.main .total {
  width: 58%;
  height: 100%;
  float: left;
  font-weight: 900;
  position: relative;
}
.main .total .rain {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.main .total .data1,
.main .total .data2,
.main .total .data3,
.main .total .data4 {
  width: 8rem;
  height: 4rem;
  position: absolute;
}
.main .total .data1 span,
.main .total .data2 span,
.main .total .data3 span,
.main .total .data4 span {
  color: #0ac1c7;
  font-size: 0.8rem;
  font-family: "宋体";
}
.main .total .data1 p,
.main .total .data2 p,
.main .total .data3 p,
.main .total .data4 p {
  font-family: "LCdd";
  font-size: 1.5rem;
  color: #f29701;
}
.main .total .data1 {
  left: 26%;
  top: 18%;
}
.main .total .data2 {
  left: 42%;
  top: 3%;
}
.main .total .data3 {
  left: 62%;
  top: 5%;
}
.main .total .data4 {
  left: 73%;
  top: 28%;
}
.main .total .dashed {
  position: absolute;
  border: none;
  left: 0;
  top: 0;
}
.main .total .sphere {
  width: 32rem;
  height: 32rem;
  position: relative;
  margin: 5% auto 0;
}
.main .total .sphere .sphere-bg {
  position: absolute;
  z-index: 100;
  left: 30px;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(./images/53earth.png) no-repeat center;
  background-size: contain;
}
.main .total .sphere .sum {
  position: absolute;
  z-index: 100;
  left: 30px;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(./images/53cloud.png) no-repeat center;
  background-size: 55%;
}
.main .total .sphere .sum span {
  display: block;
  margin-top: 30%;
  padding-left: 32%;
  color: #005a79;
  font-size: 0.9rem;
}
.main .total .sphere .sum p {
  margin-top: 1%;
  text-align: center;
  color: #003c63;
  font-family: "LCdd";
  font-size: 3rem;
}
.main .total .cicle3 {
  width: 35rem;
  height: 35rem;
  background: url(./images/circle.png) no-repeat center;
  background-size: 100%;
  position: absolute;
  top: 26%;
  left: 50%;
  transform-style: preserve-3d;
  transform: translateX(-50%) rotateX(75deg);
  animation: rotate3 20s linear infinite;
}
.main .total .cicle4 {
  width: 15rem;
  height: 15rem;
  position: absolute;
  top: 62%;
  left: 50%;
  transform-style: preserve-3d;
  background: url(./images/53gqright.png) no-repeat center;
  background-size: 100%;
  transform: translateX(-50%) rotateX(75deg);
  animation: rotate3 2s linear infinite;
}
.main .total .cicle5 {
  width: 15rem;
  height: 15rem;
  position: absolute;
  top: 64%;
  left: 50%;
  transform-style: preserve-3d;
  background: url(./images/53gqleft.png) no-repeat center;
  background-size: 100%;
  transform: translateX(-50%) rotateX(75deg);
  animation: rotate4 2s linear infinite;
}
.main .total .cicle6 {
  width: 12rem;
  height: 12rem;
  position: absolute;
  top: 72%;
  left: 50%;
  transform-style: preserve-3d;
  background: url(./images/535gqbottomright.png) no-repeat center;
  background-size: 100%;
  transform: translateX(-50%) rotateX(75deg);
  animation: rotate3 2s linear infinite;
}
.main .total .cicle7 {
  width: 12rem;
  height: 12rem;
  position: absolute;
  top: 75%;
  left: 50%;
  transform-style: preserve-3d;
  background: url(./images/53gqbottomleft.png) no-repeat center;
  background-size: 100%;
  transform: translateX(-50%) rotateX(75deg);
  animation: rotate4 2s linear infinite;
}
.main .total .cicle8,
.main .total .cicle9,
.main .total .cicle10,
.main .total .cicle11 {
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 52%;
  left: 50%;
  z-index: 100;
  margin-left: -2.5rem;
  border-radius: 50%;
}
.main .total .cicle8 span,
.main .total .cicle9 span,
.main .total .cicle10 span,
.main .total .cicle11 span {
  font-family: "LCdd";
  display: block;
  margin-left: 25%;
  font-size: 1.5rem;
  margin-top: 20%;
}
.main .total .cicle8 p,
.main .total .cicle9 p,
.main .total .cicle10 p,
.main .total .cicle11 p {
  text-align: center;
  font-size: 0.8rem;
}
.main .total .cicle8 {
  background: url(./images/circle1.png) no-repeat;
  background-size: 100%;
  animation: rotate5 20s linear infinite;
  color: #f29701;
  span {
    margin-left: 40%;
  }
}
.main .total .cicle9 {
  background: url(./images/circle2.png) no-repeat;
  background-size: 100%;
  animation: rotate6 20s linear infinite;
  color: #0ac1c7;
}
.main .total .cicle10 {
  background: url(./images/circle1.png) no-repeat;
  background-size: 100%;
  animation: rotate7 20s linear infinite;
  color: #f29701;
  span {
    margin-left: 40%;
  }
}
.main .total .cicle11 {
  background: url(./images/circle2.png) no-repeat;
  background-size: 100%;
  color: #0ac1c7;
  animation: rotate8 20s linear infinite;
}
.main .analyse {
  width: 22%;
  height: 70%;
  float: left;
  min-width: 310px;
}
.main .analyse .analyse-title {
  width: 100%;
  height: 10%;
  background: url(./images/title.png) no-repeat center;
  background-size: 100%;
  color: #fff;
  text-align: center;
  display: table;
  font-size: 1.1rem;
}
.main .analyse h5 {
  font-size: 0.8rem;
}
#listTitle span {
  display: table-cell;
  vertical-align: middle;
}

#listTitle {
  width: 540px;
  /* height: 10px; */
  background: url(./images/title.png) no-repeat center;
  background-size: 100%;
  color: #fff;
  text-align: center;
  display: table;
  font-size: 1.1rem;
  position: absolute;
  top: 58%;
  right: 2.5%;
}
.main .analyse .analyse-title span {
  display: table-cell;
  vertical-align: middle;
}
.main .analyse ul {
  width: 100%;
  height: 60%;
}
.main .analyse ul li {
  width: 100%;
  height: 30%;
  margin: 3% 0;
}
.main .analyse ul li img {
  height: 80%;
  float: left;
  vertical-align: 0%;
}
.main .analyse ul li h5 {
  float: left;
  color: #fff;
  margin-left: 5%;
}
.main .analyse ul li span {
  font-size: 0.5rem;
  float: left;
  color: #fff;
  margin-left: 5%;
}
.main .analyse ul li em {
  font-style: normal;
  font-size: 1.3rem;
  font-weight: 900;
  margin-left: 5%;
  color: #0ac1c7;
}
.main .analyse ul li:nth-of-type(1) em {
  color: #f29701;
}
.main .analyse .execution {
  width: 100%;
  height: 30%;
  background: url(./images/execution.png) no-repeat;
  background-size: contain;
  overflow: hidden;
  position: relative;
}
.main .analyse .execution .title {
  writing-mode: vertical-lr;
  font-size: 0.8rem;
  margin-left: 3%;
  margin-top: 3%;
  color: #fff;
}
.main .analyse .execution .cicle1,
.main .analyse .execution .cicle2 {
  position: absolute;
  width: 4rem;
  height: 4rem;
  background: url(./images/2222.png) no-repeat center;
  background-size: 100%;
  transform-style: preserve-3d;
  transform: rotateX(75deg);
  animation: rotate2 1s linear infinite;
}
.main .analyse .execution .cicle1 {
  top: 47%;
  left: 20%;
}
.main .analyse .execution .cicle2 {
  top: 47%;
  left: 63%;
}
.main .analyse .execution .waterChart1,
.main .analyse .execution .waterChart2 {
  position: absolute;
  width: 4rem;
  height: 5rem;
}
.main .analyse .execution .waterChart1 .chart-title,
.main .analyse .execution .waterChart2 .chart-title {
  height: 20%;
  font-size: 0.6rem;
  text-align: center;
  color: #fff;
}
.main .analyse .execution .waterChart1 .chart1,
.main .analyse .execution .waterChart2 .chart1,
.main .analyse .execution .waterChart1 .chart2,
.main .analyse .execution .waterChart2 .chart2 {
  width: 100%;
  height: 80%;
}
.main .analyse .execution .waterChart1 {
  top: 0%;
  left: 20%;
}
.main .analyse .execution .waterChart2 {
  top: 0%;
  left: 63%;
}
.main .sale {
  width: 27.5%;
  height: 30%;
  position: absolute;
  bottom: 0px;
  left: 2.5%;
}
.main .sale ul {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.main .sale ul li {
  width: 100%;
  height: 22%;
  margin-top: 1%;
}
.main .sale ul li .showImg {
  float: left;
  width: 7%;
  height: 100%;
  text-align: center;
}
.main .sale ul li .showImg img {
  vertical-align: 0%;
  width: 70%;
  margin-top: 30%;
}
.main .sale ul li .data {
  float: left;
  width: 50%;
  height: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}
.main .sale ul li .data span {
  color: #fff;
  font-size: 1rem;
  line-height: 1rem;
}
.main .sale ul li .data span:nth-of-type(2) {
  color: #0ac1c7;
}
.main .sale ul li .shoeChart,
.main .sale ul li .clothesChart,
.main .sale ul li .mzChart {
  height: 100%;
  width: 75%;
  float: left;
}
.main .lineChart {
  width: 540px;
  height: 230px;
  position: absolute;
  top: 65%;
  right: 2.5%;
  padding: 5px;
}
.bottom {
  width: 100%;
  height: 10%;
  background: url(./images/53bottomsjbg.png) no-repeat bottom center;
  background-size: 100%;
  text-align: center;
}
.bottom h5 {
  background-image: -webkit-linear-gradient(bottom, #86919e, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 40%;
  display: table;
  margin: auto;
}
.bottom h5 span {
  display: table-cell;
  vertical-align: middle;
}
.bottom p {
  color: #0ac1c7;
  font-size: 0.6rem;
}
.bottom .ipCoun {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  font-size: 0.8rem;
}
// .leftContent .serviceItem {
//  width: 100%;
//  height: 340px;
//  background-image: url(./images/k_2all.png);
//  margin-top: 20px;
//  background-size: 100%;
//  background-repeat: no-repeat;
// }
.serviceForm {
  width: 100%;
}
.seHeader {
  padding-top: 18px;
  overflow: hidden;
}
.seHeader span:first-child {
  float: left;
  margin-top: 8px;
}
.seHeader span:first-child .p-1 {
  font-size: 20px;
  color: #fff;
}
.seHeader span:first-child .p-2 {
  font-size: 14px;
  color: #66dffb;
}
.seHeader span:last-child {
  float: right;
  font-size: 46px;
  color: #7bb9ff;
  margin-right: 28px;
}

.statusList {
  width: 90%;
  margin: 0 auto;
  margin-top: 5px;
}

.statusList .seTable {
  width: 100%;
  padding-bottom: 0px;
  overflow: hidden;
  display: flex;
}
.statusList .seTable li {
  float: left;
  width: 20%;
  font-size: 11px;
  color: #66dffb;
  background: #093e79;
  padding: 4px 0;
  margin-right: 6px;
  text-align: center;
}
.statusList .seTable .outlineBorder {
  font-size: 14px;
}
.statusList .outlineBorder ul {
  height: 40px;
}
.statusList .outlineBorder ul:nth-child(odd) {
  color: #3e90f7;
}
.statusList .outlineBorder ul:nth-child(even) {
  color: #8ec0ff;
}
.statusList .outlineBorder ul li {
  float: left;
  width: 20%;
  height: 20px;
  line-height: 30px;
  padding: 10px 0;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.statusList .outlineBorder ul li:first-child {
  font-size: 12px !important;
}

/* 无缝滚动  */
@-webkit-keyframes scrollText1 {
  0% {
    -webkit-transform: translateY(0px);
  }
  20% {
    -webkit-transform: translateY(-40px);
  }
  40% {
    -webkit-transform: translateY(-80px);
  }
  60% {
    -webkit-transform: translateY(-120px);
  }
  80% {
    -webkit-transform: translateY(-160px);
  }
  100% {
    -webkit-transform: translateY(-200px);
  }
}

@keyframes scrollText1 {
  0% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(-40px);
  }
  40% {
    transform: translateY(-80px);
  }
  60% {
    transform: translateY(-120px);
  }
  80% {
    transform: translateY(-160px);
  }
  100% {
    transform: translateY(-200px);
  }
}
.outlineBorder {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 400px;
}
.rolling {
  top: 0px;
  -webkit-animation: scrollText1 10s infinite cubic-bezier(1, 0, 0.5, 0);
  animation: scrollText1 10s infinite cubic-bezier(1, 0, 0.5, 0);
}
.outlineBorder:hover .rolling {
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
}

/* 地图 */
.implantation {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 500;
}

.serData {
  margin-top: 7px;
  overflow: hidden;
  color: #66dffb;
}
.serData > div {
  float: left;
  font-size: 18px;
  text-align: center;
}
.serData > .serDataLeft {
  margin-left: 15%;
  padding: 0 12px;
}
.serDataLeft .serNum {
  font-size: 60px;
  color: #f0bd54;
}
.phoneNum {
  width: 90%;
  height: 250px;
  margin: 0 auto;
}
/* 折线图  */
.brokenLine {
  width: 100%;
  height: 300px;
}
.redClass {
  background: red;
}
.greenClass {
  /*background: green;*/
}
/* 水波扩散效果 */
@keyframes warn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
@-webkit-keyframes warn {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotateX(75deg) rotateZ(0);
  }
  100% {
    transform: rotateX(75deg) rotateZ(360deg);
  }
}
@keyframes rotate3 {
  0% {
    transform: translateX(-50%) rotateX(75deg) rotateZ(0);
  }
  100% {
    transform: translateX(-50%) rotateX(75deg) rotateZ(360deg);
  }
}
@keyframes rotate4 {
  0% {
    transform: translateX(-50%) rotateX(75deg) rotateZ(0);
  }
  100% {
    transform: translateX(-50%) rotateX(75deg) rotateZ(-360deg);
  }
}
@keyframes rotate5 {
  0% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 270, 0, 0, 1);
    z-index: 100;
  }
  1% {
    transform: matrix3d(
      0.92093,
      0,
      0,
      0,
      0,
      0.92093,
      0,
      0,
      0,
      0,
      1,
      0,
      269.46722,
      6.27905,
      0,
      1
    );
    z-index: 100.06279;
  }
  2% {
    transform: matrix3d(
      0.94178,
      0,
      0,
      0,
      0,
      0.94178,
      0,
      0,
      0,
      0,
      1,
      0,
      267.87097,
      12.53332,
      0,
      1
    );
    z-index: 100.12533;
  }
  3% {
    transform: matrix3d(
      0.96246,
      0,
      0,
      0,
      0,
      0.96246,
      0,
      0,
      0,
      0,
      1,
      0,
      265.21756,
      18.73813,
      0,
      1
    );
    z-index: 100.18738;
  }
  4% {
    transform: matrix3d(
      0.9829,
      0,
      0,
      0,
      0,
      0.9829,
      0,
      0,
      0,
      0,
      1,
      0,
      261.51745,
      24.86899,
      0,
      1
    );
    z-index: 100.24869;
  }
  5% {
    transform: matrix3d(
      1.00301,
      0,
      0,
      0,
      0,
      1.00301,
      0,
      0,
      0,
      0,
      1,
      0,
      256.78526,
      30.9017,
      0,
      1
    );
    z-index: 100.30902;
  }
  6% {
    transform: matrix3d(
      1.02271,
      0,
      0,
      0,
      0,
      1.02271,
      0,
      0,
      0,
      0,
      1,
      0,
      251.03965,
      36.81246,
      0,
      1
    );
    z-index: 100.36812;
  }
  7% {
    transform: matrix3d(
      1.04193,
      0,
      0,
      0,
      0,
      1.04193,
      0,
      0,
      0,
      0,
      1,
      0,
      244.3033,
      42.57793,
      0,
      1
    );
    z-index: 100.42578;
  }
  8% {
    transform: matrix3d(
      1.06058,
      0,
      0,
      0,
      0,
      1.06058,
      0,
      0,
      0,
      0,
      1,
      0,
      236.6028,
      48.17537,
      0,
      1
    );
    z-index: 100.48175;
  }
  9% {
    transform: matrix3d(
      1.07861,
      0,
      0,
      0,
      0,
      1.07861,
      0,
      0,
      0,
      0,
      1,
      0,
      227.96854,
      53.58268,
      0,
      1
    );
    z-index: 100.53583;
  }
  10% {
    transform: matrix3d(
      1.09593,
      0,
      0,
      0,
      0,
      1.09593,
      0,
      0,
      0,
      0,
      1,
      0,
      218.43459,
      58.77853,
      0,
      1
    );
    z-index: 100.58779;
  }
  11% {
    transform: matrix3d(
      1.11247,
      0,
      0,
      0,
      0,
      1.11247,
      0,
      0,
      0,
      0,
      1,
      0,
      208.03858,
      63.7424,
      0,
      1
    );
    z-index: 100.63742;
  }
  12% {
    transform: matrix3d(
      1.12818,
      0,
      0,
      0,
      0,
      1.12818,
      0,
      0,
      0,
      0,
      1,
      0,
      196.82153,
      68.45471,
      0,
      1
    );
    z-index: 100.68455;
  }
  13% {
    transform: matrix3d(
      1.14299,
      0,
      0,
      0,
      0,
      1.14299,
      0,
      0,
      0,
      0,
      1,
      0,
      184.82772,
      72.89686,
      0,
      1
    );
    z-index: 100.72897;
  }
  14% {
    transform: matrix3d(
      1.15684,
      0,
      0,
      0,
      0,
      1.15684,
      0,
      0,
      0,
      0,
      1,
      0,
      172.10448,
      77.05132,
      0,
      1
    );
    z-index: 100.77051;
  }
  15% {
    transform: matrix3d(
      1.16967,
      0,
      0,
      0,
      0,
      1.16967,
      0,
      0,
      0,
      0,
      1,
      0,
      158.70202,
      80.9017,
      0,
      1
    );
    z-index: 100.80902;
  }
  16% {
    transform: matrix3d(
      1.18144,
      0,
      0,
      0,
      0,
      1.18144,
      0,
      0,
      0,
      0,
      1,
      0,
      144.67323,
      84.43279,
      0,
      1
    );
    z-index: 100.84433;
  }
  17% {
    transform: matrix3d(
      1.1921,
      0,
      0,
      0,
      0,
      1.1921,
      0,
      0,
      0,
      0,
      1,
      0,
      130.07349,
      87.63067,
      0,
      1
    );
    z-index: 100.87631;
  }
  18% {
    transform: matrix3d(
      1.20161,
      0,
      0,
      0,
      0,
      1.20161,
      0,
      0,
      0,
      0,
      1,
      0,
      114.96041,
      90.48271,
      0,
      1
    );
    z-index: 100.90483;
  }
  19% {
    transform: matrix3d(
      1.20993,
      0,
      0,
      0,
      0,
      1.20993,
      0,
      0,
      0,
      0,
      1,
      0,
      99.39363,
      92.97765,
      0,
      1
    );
    z-index: 100.92978;
  }
  20% {
    transform: matrix3d(
      1.21702,
      0,
      0,
      0,
      0,
      1.21702,
      0,
      0,
      0,
      0,
      1,
      0,
      83.43459,
      95.10565,
      0,
      1
    );
    z-index: 100.95106;
  }
  21% {
    transform: matrix3d(
      1.22286,
      0,
      0,
      0,
      0,
      1.22286,
      0,
      0,
      0,
      0,
      1,
      0,
      67.14627,
      96.85832,
      0,
      1
    );
    z-index: 100.96858;
  }
  22% {
    transform: matrix3d(
      1.22743,
      0,
      0,
      0,
      0,
      1.22743,
      0,
      0,
      0,
      0,
      1,
      0,
      50.59295,
      98.22873,
      0,
      1
    );
    z-index: 100.98229;
  }
  23% {
    transform: matrix3d(
      1.2307,
      0,
      0,
      0,
      0,
      1.2307,
      0,
      0,
      0,
      0,
      1,
      0,
      33.83997,
      99.21147,
      0,
      1
    );
    z-index: 100.99211;
  }
  24% {
    transform: matrix3d(
      1.23268,
      0,
      0,
      0,
      0,
      1.23268,
      0,
      0,
      0,
      0,
      1,
      0,
      16.95344,
      99.80267,
      0,
      1
    );
    z-index: 100.99803;
  }
  25% {
    transform: matrix3d(
      1.23333,
      0,
      0,
      0,
      0,
      1.23333,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      100,
      0,
      1
    );
    z-index: 101;
  }
  26% {
    transform: matrix3d(
      1.23268,
      0,
      0,
      0,
      0,
      1.23268,
      0,
      0,
      0,
      0,
      1,
      0,
      -16.95344,
      99.80267,
      0,
      1
    );
    z-index: 100.99803;
  }
  27% {
    transform: matrix3d(
      1.2307,
      0,
      0,
      0,
      0,
      1.2307,
      0,
      0,
      0,
      0,
      1,
      0,
      -33.83997,
      99.21147,
      0,
      1
    );
    z-index: 100.99211;
  }
  28% {
    transform: matrix3d(
      1.22743,
      0,
      0,
      0,
      0,
      1.22743,
      0,
      0,
      0,
      0,
      1,
      0,
      -50.59295,
      98.22873,
      0,
      1
    );
    z-index: 100.98229;
  }
  29% {
    transform: matrix3d(
      1.22286,
      0,
      0,
      0,
      0,
      1.22286,
      0,
      0,
      0,
      0,
      1,
      0,
      -67.14627,
      96.85832,
      0,
      1
    );
    z-index: 100.96858;
  }
  30% {
    transform: matrix3d(
      1.21702,
      0,
      0,
      0,
      0,
      1.21702,
      0,
      0,
      0,
      0,
      1,
      0,
      -83.43459,
      95.10565,
      0,
      1
    );
    z-index: 100.95106;
  }
  31% {
    transform: matrix3d(
      1.20993,
      0,
      0,
      0,
      0,
      1.20993,
      0,
      0,
      0,
      0,
      1,
      0,
      -99.39363,
      92.97765,
      0,
      1
    );
    z-index: 100.92978;
  }
  32% {
    transform: matrix3d(
      1.20161,
      0,
      0,
      0,
      0,
      1.20161,
      0,
      0,
      0,
      0,
      1,
      0,
      -114.96041,
      90.48271,
      0,
      1
    );
    z-index: 100.90483;
  }
  33% {
    transform: matrix3d(
      1.1921,
      0,
      0,
      0,
      0,
      1.1921,
      0,
      0,
      0,
      0,
      1,
      0,
      -130.07349,
      87.63067,
      0,
      1
    );
    z-index: 100.87631;
  }
  34% {
    transform: matrix3d(
      1.18144,
      0,
      0,
      0,
      0,
      1.18144,
      0,
      0,
      0,
      0,
      1,
      0,
      -144.67323,
      84.43279,
      0,
      1
    );
    z-index: 100.84433;
  }
  35% {
    transform: matrix3d(
      1.16967,
      0,
      0,
      0,
      0,
      1.16967,
      0,
      0,
      0,
      0,
      1,
      0,
      -158.70202,
      80.9017,
      0,
      1
    );
    z-index: 100.80902;
  }
  36% {
    transform: matrix3d(
      1.15684,
      0,
      0,
      0,
      0,
      1.15684,
      0,
      0,
      0,
      0,
      1,
      0,
      -172.10448,
      77.05132,
      0,
      1
    );
    z-index: 100.77051;
  }
  37% {
    transform: matrix3d(
      1.14299,
      0,
      0,
      0,
      0,
      1.14299,
      0,
      0,
      0,
      0,
      1,
      0,
      -184.82772,
      72.89686,
      0,
      1
    );
    z-index: 100.72897;
  }
  38% {
    transform: matrix3d(
      1.12818,
      0,
      0,
      0,
      0,
      1.12818,
      0,
      0,
      0,
      0,
      1,
      0,
      -196.82153,
      68.45471,
      0,
      1
    );
    z-index: 100.68455;
  }
  39% {
    transform: matrix3d(
      1.11247,
      0,
      0,
      0,
      0,
      1.11247,
      0,
      0,
      0,
      0,
      1,
      0,
      -208.03858,
      63.7424,
      0,
      1
    );
    z-index: 100.63742;
  }
  40% {
    transform: matrix3d(
      1.09593,
      0,
      0,
      0,
      0,
      1.09593,
      0,
      0,
      0,
      0,
      1,
      0,
      -218.43459,
      58.77853,
      0,
      1
    );
    z-index: 100.58779;
  }
  41% {
    transform: matrix3d(
      1.07861,
      0,
      0,
      0,
      0,
      1.07861,
      0,
      0,
      0,
      0,
      1,
      0,
      -227.96854,
      53.58268,
      0,
      1
    );
    z-index: 100.53583;
  }
  42% {
    transform: matrix3d(
      1.06058,
      0,
      0,
      0,
      0,
      1.06058,
      0,
      0,
      0,
      0,
      1,
      0,
      -236.6028,
      48.17537,
      0,
      1
    );
    z-index: 100.48175;
  }
  43% {
    transform: matrix3d(
      1.04193,
      0,
      0,
      0,
      0,
      1.04193,
      0,
      0,
      0,
      0,
      1,
      0,
      -244.3033,
      42.57793,
      0,
      1
    );
    z-index: 100.42578;
  }
  44% {
    transform: matrix3d(
      1.02271,
      0,
      0,
      0,
      0,
      1.02271,
      0,
      0,
      0,
      0,
      1,
      0,
      -251.03965,
      36.81246,
      0,
      1
    );
    z-index: 100.36812;
  }
  45% {
    transform: matrix3d(
      1.00301,
      0,
      0,
      0,
      0,
      1.00301,
      0,
      0,
      0,
      0,
      1,
      0,
      -256.78526,
      30.9017,
      0,
      1
    );
    z-index: 100.30902;
  }
  46% {
    transform: matrix3d(
      0.9829,
      0,
      0,
      0,
      0,
      0.9829,
      0,
      0,
      0,
      0,
      1,
      0,
      -261.51745,
      24.86899,
      0,
      1
    );
    z-index: 100.24869;
  }
  47% {
    transform: matrix3d(
      0.96246,
      0,
      0,
      0,
      0,
      0.96246,
      0,
      0,
      0,
      0,
      1,
      0,
      -265.21756,
      18.73813,
      0,
      1
    );
    z-index: 100.18738;
  }
  48% {
    transform: matrix3d(
      0.94178,
      0,
      0,
      0,
      0,
      0.94178,
      0,
      0,
      0,
      0,
      1,
      0,
      -267.87097,
      12.53332,
      0,
      1
    );
    z-index: 100.12533;
  }
  49% {
    transform: matrix3d(
      0.92093,
      0,
      0,
      0,
      0,
      0.92093,
      0,
      0,
      0,
      0,
      1,
      0,
      -269.46722,
      6.27905,
      0,
      1
    );
    z-index: 100.06279;
  }
  50% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, -270, 0, 0, 1);
    z-index: 100;
  }
  51% {
    transform: matrix3d(
      0.87907,
      0,
      0,
      0,
      0,
      0.87907,
      0,
      0,
      0,
      0,
      1,
      0,
      -269.46722,
      -6.27905,
      0,
      1
    );
    z-index: 99.93721;
  }
  52% {
    transform: matrix3d(
      0.85822,
      0,
      0,
      0,
      0,
      0.85822,
      0,
      0,
      0,
      0,
      1,
      0,
      -267.87097,
      -12.53332,
      0,
      1
    );
    z-index: 99.87467;
  }
  53% {
    transform: matrix3d(
      0.83754,
      0,
      0,
      0,
      0,
      0.83754,
      0,
      0,
      0,
      0,
      1,
      0,
      -265.21756,
      -18.73813,
      0,
      1
    );
    z-index: 99.81262;
  }
  54% {
    transform: matrix3d(
      0.8171,
      0,
      0,
      0,
      0,
      0.8171,
      0,
      0,
      0,
      0,
      1,
      0,
      -261.51745,
      -24.86899,
      0,
      1
    );
    z-index: 99.75131;
  }
  55% {
    transform: matrix3d(
      0.79699,
      0,
      0,
      0,
      0,
      0.79699,
      0,
      0,
      0,
      0,
      1,
      0,
      -256.78526,
      -30.9017,
      0,
      1
    );
    z-index: 99.69098;
  }
  56% {
    transform: matrix3d(
      0.77729,
      0,
      0,
      0,
      0,
      0.77729,
      0,
      0,
      0,
      0,
      1,
      0,
      -251.03965,
      -36.81246,
      0,
      1
    );
    z-index: 99.63188;
  }
  57% {
    transform: matrix3d(
      0.75807,
      0,
      0,
      0,
      0,
      0.75807,
      0,
      0,
      0,
      0,
      1,
      0,
      -244.3033,
      -42.57793,
      0,
      1
    );
    z-index: 99.57422;
  }
  58% {
    transform: matrix3d(
      0.73942,
      0,
      0,
      0,
      0,
      0.73942,
      0,
      0,
      0,
      0,
      1,
      0,
      -236.6028,
      -48.17537,
      0,
      1
    );
    z-index: 99.51825;
  }
  59% {
    transform: matrix3d(
      0.72139,
      0,
      0,
      0,
      0,
      0.72139,
      0,
      0,
      0,
      0,
      1,
      0,
      -227.96854,
      -53.58268,
      0,
      1
    );
    z-index: 99.46417;
  }
  60% {
    transform: matrix3d(
      0.70407,
      0,
      0,
      0,
      0,
      0.70407,
      0,
      0,
      0,
      0,
      1,
      0,
      -218.43459,
      -58.77853,
      0,
      1
    );
    z-index: 99.41221;
  }
  61% {
    transform: matrix3d(
      0.68753,
      0,
      0,
      0,
      0,
      0.68753,
      0,
      0,
      0,
      0,
      1,
      0,
      -208.03857,
      -63.7424,
      0,
      1
    );
    z-index: 99.36258;
  }
  62% {
    transform: matrix3d(
      0.67182,
      0,
      0,
      0,
      0,
      0.67182,
      0,
      0,
      0,
      0,
      1,
      0,
      -196.82153,
      -68.45471,
      0,
      1
    );
    z-index: 99.31545;
  }
  63% {
    transform: matrix3d(
      0.65701,
      0,
      0,
      0,
      0,
      0.65701,
      0,
      0,
      0,
      0,
      1,
      0,
      -184.82772,
      -72.89686,
      0,
      1
    );
    z-index: 99.27103;
  }
  64% {
    transform: matrix3d(
      0.64316,
      0,
      0,
      0,
      0,
      0.64316,
      0,
      0,
      0,
      0,
      1,
      0,
      -172.10447,
      -77.05132,
      0,
      1
    );
    z-index: 99.22949;
  }
  65% {
    transform: matrix3d(
      0.63033,
      0,
      0,
      0,
      0,
      0.63033,
      0,
      0,
      0,
      0,
      1,
      0,
      -158.70201,
      -80.9017,
      0,
      1
    );
    z-index: 99.19098;
  }
  66% {
    transform: matrix3d(
      0.61856,
      0,
      0,
      0,
      0,
      0.61856,
      0,
      0,
      0,
      0,
      1,
      0,
      -144.67323,
      -84.43279,
      0,
      1
    );
    z-index: 99.15567;
  }
  67% {
    transform: matrix3d(
      0.6079,
      0,
      0,
      0,
      0,
      0.6079,
      0,
      0,
      0,
      0,
      1,
      0,
      -130.07348,
      -87.63067,
      0,
      1
    );
    z-index: 99.12369;
  }
  68% {
    transform: matrix3d(
      0.59839,
      0,
      0,
      0,
      0,
      0.59839,
      0,
      0,
      0,
      0,
      1,
      0,
      -114.96039,
      -90.4827,
      0,
      1
    );
    z-index: 99.09517;
  }
  69% {
    transform: matrix3d(
      0.59007,
      0,
      0,
      0,
      0,
      0.59007,
      0,
      0,
      0,
      0,
      1,
      0,
      -99.39361,
      -92.97765,
      0,
      1
    );
    z-index: 99.07022;
  }
  70% {
    transform: matrix3d(
      0.58298,
      0,
      0,
      0,
      0,
      0.58298,
      0,
      0,
      0,
      0,
      1,
      0,
      -83.43456,
      -95.10565,
      0,
      1
    );
    z-index: 99.04894;
  }
  71% {
    transform: matrix3d(
      0.57714,
      0,
      0,
      0,
      0,
      0.57714,
      0,
      0,
      0,
      0,
      1,
      0,
      -67.14622,
      -96.85831,
      0,
      1
    );
    z-index: 99.03142;
  }
  72% {
    transform: matrix3d(
      0.57257,
      0,
      0,
      0,
      0,
      0.57257,
      0,
      0,
      0,
      0,
      1,
      0,
      -50.59289,
      -98.22872,
      0,
      1
    );
    z-index: 99.01771;
  }
  73% {
    transform: matrix3d(
      0.5693,
      0,
      0,
      0,
      0,
      0.5693,
      0,
      0,
      0,
      0,
      1,
      0,
      -33.83989,
      -99.21146,
      0,
      1
    );
    z-index: 99.00789;
  }
  74% {
    transform: matrix3d(
      0.56732,
      0,
      0,
      0,
      0,
      0.56732,
      0,
      0,
      0,
      0,
      1,
      0,
      -16.95333,
      -99.80266,
      0,
      1
    );
    z-index: 99.00197;
  }
  75% {
    transform: matrix3d(
      0.56667,
      0,
      0,
      0,
      0,
      0.56667,
      0,
      0,
      0,
      0,
      1,
      0,
      0.00015,
      -99.99999,
      0,
      1
    );
    z-index: 99;
  }
  76% {
    transform: matrix3d(
      0.56732,
      0,
      0,
      0,
      0,
      0.56732,
      0,
      0,
      0,
      0,
      1,
      0,
      16.95364,
      -99.80266,
      0,
      1
    );
    z-index: 99.00197;
  }
  77% {
    transform: matrix3d(
      0.5693,
      0,
      0,
      0,
      0,
      0.5693,
      0,
      0,
      0,
      0,
      1,
      0,
      33.84024,
      -99.21145,
      0,
      1
    );
    z-index: 99.00789;
  }
  78% {
    transform: matrix3d(
      0.57257,
      0,
      0,
      0,
      0,
      0.57257,
      0,
      0,
      0,
      0,
      1,
      0,
      50.59331,
      -98.2287,
      0,
      1
    );
    z-index: 99.01771;
  }
  79% {
    transform: matrix3d(
      0.57714,
      0,
      0,
      0,
      0,
      0.57714,
      0,
      0,
      0,
      0,
      1,
      0,
      67.14674,
      -96.85828,
      0,
      1
    );
    z-index: 99.03142;
  }
  80% {
    transform: matrix3d(
      0.58298,
      0,
      0,
      0,
      0,
      0.58298,
      0,
      0,
      0,
      0,
      1,
      0,
      83.4352,
      -95.1056,
      0,
      1
    );
    z-index: 99.04894;
  }
  81% {
    transform: matrix3d(
      0.59007,
      0,
      0,
      0,
      0,
      0.59007,
      0,
      0,
      0,
      0,
      1,
      0,
      99.39444,
      -92.97758,
      0,
      1
    );
    z-index: 99.07022;
  }
  82% {
    transform: matrix3d(
      0.59839,
      0,
      0,
      0,
      0,
      0.59839,
      0,
      0,
      0,
      0,
      1,
      0,
      114.96147,
      -90.48262,
      0,
      1
    );
    z-index: 99.09517;
  }
  83% {
    transform: matrix3d(
      0.6079,
      0,
      0,
      0,
      0,
      0.6079,
      0,
      0,
      0,
      0,
      1,
      0,
      130.07487,
      -87.63055,
      0,
      1
    );
    z-index: 99.12369;
  }
  84% {
    transform: matrix3d(
      0.61856,
      0,
      0,
      0,
      0,
      0.61856,
      0,
      0,
      0,
      0,
      1,
      0,
      144.67503,
      -84.43264,
      0,
      1
    );
    z-index: 99.15567;
  }
  85% {
    transform: matrix3d(
      0.63033,
      0,
      0,
      0,
      0,
      0.63033,
      0,
      0,
      0,
      0,
      1,
      0,
      158.70434,
      -80.9015,
      0,
      1
    );
    z-index: 99.19099;
  }
  86% {
    transform: matrix3d(
      0.64316,
      0,
      0,
      0,
      0,
      0.64316,
      0,
      0,
      0,
      0,
      1,
      0,
      172.10748,
      -77.05106,
      0,
      1
    );
    z-index: 99.22949;
  }
  87% {
    transform: matrix3d(
      0.65701,
      0,
      0,
      0,
      0,
      0.65701,
      0,
      0,
      0,
      0,
      1,
      0,
      184.83158,
      -72.89652,
      0,
      1
    );
    z-index: 99.27103;
  }
  88% {
    transform: matrix3d(
      0.67182,
      0,
      0,
      0,
      0,
      0.67182,
      0,
      0,
      0,
      0,
      1,
      0,
      196.82649,
      -68.45427,
      0,
      1
    );
    z-index: 99.31546;
  }
  89% {
    transform: matrix3d(
      0.68753,
      0,
      0,
      0,
      0,
      0.68753,
      0,
      0,
      0,
      0,
      1,
      0,
      208.04493,
      -63.74182,
      0,
      1
    );
    z-index: 99.36258;
  }
  90% {
    transform: matrix3d(
      0.70407,
      0,
      0,
      0,
      0,
      0.70407,
      0,
      0,
      0,
      0,
      1,
      0,
      218.4427,
      -58.77778,
      0,
      1
    );
    z-index: 99.41222;
  }
  91% {
    transform: matrix3d(
      0.72139,
      0,
      0,
      0,
      0,
      0.72139,
      0,
      0,
      0,
      0,
      1,
      0,
      227.97887,
      -53.58172,
      0,
      1
    );
    z-index: 99.46418;
  }
  92% {
    transform: matrix3d(
      0.73942,
      0,
      0,
      0,
      0,
      0.73942,
      0,
      0,
      0,
      0,
      1,
      0,
      236.61593,
      -48.17414,
      0,
      1
    );
    z-index: 99.51826;
  }
  93% {
    transform: matrix3d(
      0.75808,
      0,
      0,
      0,
      0,
      0.75808,
      0,
      0,
      0,
      0,
      1,
      0,
      244.31993,
      -42.57636,
      0,
      1
    );
    z-index: 99.57424;
  }
  94% {
    transform: matrix3d(
      0.7773,
      0,
      0,
      0,
      0,
      0.7773,
      0,
      0,
      0,
      0,
      1,
      0,
      251.06067,
      -36.81045,
      0,
      1
    );
    z-index: 99.6319;
  }
  95% {
    transform: matrix3d(
      0.797,
      0,
      0,
      0,
      0,
      0.797,
      0,
      0,
      0,
      0,
      1,
      0,
      256.81175,
      -30.89914,
      0,
      1
    );
    z-index: 99.69101;
  }
  96% {
    transform: matrix3d(
      0.81711,
      0,
      0,
      0,
      0,
      0.81711,
      0,
      0,
      0,
      0,
      1,
      0,
      261.55076,
      -24.86574,
      0,
      1
    );
    z-index: 99.75134;
  }
  97% {
    transform: matrix3d(
      0.83755,
      0,
      0,
      0,
      0,
      0.83755,
      0,
      0,
      0,
      0,
      1,
      0,
      265.25934,
      -18.73401,
      0,
      1
    );
    z-index: 99.81266;
  }
  98% {
    transform: matrix3d(
      0.85824,
      0,
      0,
      0,
      0,
      0.85824,
      0,
      0,
      0,
      0,
      1,
      0,
      267.92326,
      -12.52811,
      0,
      1
    );
    z-index: 99.87472;
  }
  99% {
    transform: matrix3d(
      0.87909,
      0,
      0,
      0,
      0,
      0.87909,
      0,
      0,
      0,
      0,
      1,
      0,
      269.5325,
      -6.27248,
      0,
      1
    );
    z-index: 99.93728;
  }
  100% {
    transform: matrix3d(
      0.90003,
      0,
      0,
      0,
      0,
      0.90003,
      0,
      0,
      0,
      0,
      1,
      0,
      270.08133,
      0.00827,
      0,
      1
    );
    z-index: 100.00008;
  }
}
@keyframes rotate6 {
  0% {
    transform: matrix3d(
      1.23268,
      0,
      0,
      0,
      0,
      1.23268,
      0,
      0,
      0,
      0,
      1,
      0,
      -16.95344,
      99.80267,
      0,
      1
    );
    z-index: 100.99803;
  }
  1% {
    transform: matrix3d(
      1.2307,
      0,
      0,
      0,
      0,
      1.2307,
      0,
      0,
      0,
      0,
      1,
      0,
      -33.83997,
      99.21147,
      0,
      1
    );
    z-index: 100.99211;
  }
  2% {
    transform: matrix3d(
      1.22743,
      0,
      0,
      0,
      0,
      1.22743,
      0,
      0,
      0,
      0,
      1,
      0,
      -50.59295,
      98.22873,
      0,
      1
    );
    z-index: 100.98229;
  }
  3% {
    transform: matrix3d(
      1.22286,
      0,
      0,
      0,
      0,
      1.22286,
      0,
      0,
      0,
      0,
      1,
      0,
      -67.14627,
      96.85832,
      0,
      1
    );
    z-index: 100.96858;
  }
  4% {
    transform: matrix3d(
      1.21702,
      0,
      0,
      0,
      0,
      1.21702,
      0,
      0,
      0,
      0,
      1,
      0,
      -83.43459,
      95.10565,
      0,
      1
    );
    z-index: 100.95106;
  }
  5% {
    transform: matrix3d(
      1.20993,
      0,
      0,
      0,
      0,
      1.20993,
      0,
      0,
      0,
      0,
      1,
      0,
      -99.39363,
      92.97765,
      0,
      1
    );
    z-index: 100.92978;
  }
  6% {
    transform: matrix3d(
      1.20161,
      0,
      0,
      0,
      0,
      1.20161,
      0,
      0,
      0,
      0,
      1,
      0,
      -114.96041,
      90.48271,
      0,
      1
    );
    z-index: 100.90483;
  }
  7% {
    transform: matrix3d(
      1.1921,
      0,
      0,
      0,
      0,
      1.1921,
      0,
      0,
      0,
      0,
      1,
      0,
      -130.07349,
      87.63067,
      0,
      1
    );
    z-index: 100.87631;
  }
  8% {
    transform: matrix3d(
      1.18144,
      0,
      0,
      0,
      0,
      1.18144,
      0,
      0,
      0,
      0,
      1,
      0,
      -144.67323,
      84.43279,
      0,
      1
    );
    z-index: 100.84433;
  }
  9% {
    transform: matrix3d(
      1.16967,
      0,
      0,
      0,
      0,
      1.16967,
      0,
      0,
      0,
      0,
      1,
      0,
      -158.70202,
      80.9017,
      0,
      1
    );
    z-index: 100.80902;
  }
  10% {
    transform: matrix3d(
      1.15684,
      0,
      0,
      0,
      0,
      1.15684,
      0,
      0,
      0,
      0,
      1,
      0,
      -172.10448,
      77.05132,
      0,
      1
    );
    z-index: 100.77051;
  }
  11% {
    transform: matrix3d(
      1.14299,
      0,
      0,
      0,
      0,
      1.14299,
      0,
      0,
      0,
      0,
      1,
      0,
      -184.82772,
      72.89686,
      0,
      1
    );
    z-index: 100.72897;
  }
  12% {
    transform: matrix3d(
      1.12818,
      0,
      0,
      0,
      0,
      1.12818,
      0,
      0,
      0,
      0,
      1,
      0,
      -196.82153,
      68.45471,
      0,
      1
    );
    z-index: 100.68455;
  }
  13% {
    transform: matrix3d(
      1.11247,
      0,
      0,
      0,
      0,
      1.11247,
      0,
      0,
      0,
      0,
      1,
      0,
      -208.03858,
      63.7424,
      0,
      1
    );
    z-index: 100.63742;
  }
  14% {
    transform: matrix3d(
      1.09593,
      0,
      0,
      0,
      0,
      1.09593,
      0,
      0,
      0,
      0,
      1,
      0,
      -218.43459,
      58.77853,
      0,
      1
    );
    z-index: 100.58779;
  }
  15% {
    transform: matrix3d(
      1.07861,
      0,
      0,
      0,
      0,
      1.07861,
      0,
      0,
      0,
      0,
      1,
      0,
      -227.96854,
      53.58268,
      0,
      1
    );
    z-index: 100.53583;
  }
  16% {
    transform: matrix3d(
      1.06058,
      0,
      0,
      0,
      0,
      1.06058,
      0,
      0,
      0,
      0,
      1,
      0,
      -236.6028,
      48.17537,
      0,
      1
    );
    z-index: 100.48175;
  }
  17% {
    transform: matrix3d(
      1.04193,
      0,
      0,
      0,
      0,
      1.04193,
      0,
      0,
      0,
      0,
      1,
      0,
      -244.3033,
      42.57793,
      0,
      1
    );
    z-index: 100.42578;
  }
  18% {
    transform: matrix3d(
      1.02271,
      0,
      0,
      0,
      0,
      1.02271,
      0,
      0,
      0,
      0,
      1,
      0,
      -251.03965,
      36.81246,
      0,
      1
    );
    z-index: 100.36812;
  }
  19% {
    transform: matrix3d(
      1.00301,
      0,
      0,
      0,
      0,
      1.00301,
      0,
      0,
      0,
      0,
      1,
      0,
      -256.78526,
      30.9017,
      0,
      1
    );
    z-index: 100.30902;
  }
  20% {
    transform: matrix3d(
      0.9829,
      0,
      0,
      0,
      0,
      0.9829,
      0,
      0,
      0,
      0,
      1,
      0,
      -261.51745,
      24.86899,
      0,
      1
    );
    z-index: 100.24869;
  }
  21% {
    transform: matrix3d(
      0.96246,
      0,
      0,
      0,
      0,
      0.96246,
      0,
      0,
      0,
      0,
      1,
      0,
      -265.21756,
      18.73813,
      0,
      1
    );
    z-index: 100.18738;
  }
  22% {
    transform: matrix3d(
      0.94178,
      0,
      0,
      0,
      0,
      0.94178,
      0,
      0,
      0,
      0,
      1,
      0,
      -267.87097,
      12.53332,
      0,
      1
    );
    z-index: 100.12533;
  }
  23% {
    transform: matrix3d(
      0.92093,
      0,
      0,
      0,
      0,
      0.92093,
      0,
      0,
      0,
      0,
      1,
      0,
      -269.46722,
      6.27905,
      0,
      1
    );
    z-index: 100.06279;
  }
  24% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, -270, 0, 0, 1);
    z-index: 100;
  }
  25% {
    transform: matrix3d(
      0.87907,
      0,
      0,
      0,
      0,
      0.87907,
      0,
      0,
      0,
      0,
      1,
      0,
      -269.46722,
      -6.27905,
      0,
      1
    );
    z-index: 99.93721;
  }
  26% {
    transform: matrix3d(
      0.85822,
      0,
      0,
      0,
      0,
      0.85822,
      0,
      0,
      0,
      0,
      1,
      0,
      -267.87097,
      -12.53332,
      0,
      1
    );
    z-index: 99.87467;
  }
  27% {
    transform: matrix3d(
      0.83754,
      0,
      0,
      0,
      0,
      0.83754,
      0,
      0,
      0,
      0,
      1,
      0,
      -265.21756,
      -18.73813,
      0,
      1
    );
    z-index: 99.81262;
  }
  28% {
    transform: matrix3d(
      0.8171,
      0,
      0,
      0,
      0,
      0.8171,
      0,
      0,
      0,
      0,
      1,
      0,
      -261.51745,
      -24.86899,
      0,
      1
    );
    z-index: 99.75131;
  }
  29% {
    transform: matrix3d(
      0.79699,
      0,
      0,
      0,
      0,
      0.79699,
      0,
      0,
      0,
      0,
      1,
      0,
      -256.78526,
      -30.9017,
      0,
      1
    );
    z-index: 99.69098;
  }
  30% {
    transform: matrix3d(
      0.77729,
      0,
      0,
      0,
      0,
      0.77729,
      0,
      0,
      0,
      0,
      1,
      0,
      -251.03965,
      -36.81246,
      0,
      1
    );
    z-index: 99.63188;
  }
  31% {
    transform: matrix3d(
      0.75807,
      0,
      0,
      0,
      0,
      0.75807,
      0,
      0,
      0,
      0,
      1,
      0,
      -244.3033,
      -42.57793,
      0,
      1
    );
    z-index: 99.57422;
  }
  32% {
    transform: matrix3d(
      0.73942,
      0,
      0,
      0,
      0,
      0.73942,
      0,
      0,
      0,
      0,
      1,
      0,
      -236.6028,
      -48.17537,
      0,
      1
    );
    z-index: 99.51825;
  }
  33% {
    transform: matrix3d(
      0.72139,
      0,
      0,
      0,
      0,
      0.72139,
      0,
      0,
      0,
      0,
      1,
      0,
      -227.96854,
      -53.58268,
      0,
      1
    );
    z-index: 99.46417;
  }
  34% {
    transform: matrix3d(
      0.70407,
      0,
      0,
      0,
      0,
      0.70407,
      0,
      0,
      0,
      0,
      1,
      0,
      -218.43459,
      -58.77853,
      0,
      1
    );
    z-index: 99.41221;
  }
  35% {
    transform: matrix3d(
      0.68753,
      0,
      0,
      0,
      0,
      0.68753,
      0,
      0,
      0,
      0,
      1,
      0,
      -208.03857,
      -63.7424,
      0,
      1
    );
    z-index: 99.36258;
  }
  36% {
    transform: matrix3d(
      0.67182,
      0,
      0,
      0,
      0,
      0.67182,
      0,
      0,
      0,
      0,
      1,
      0,
      -196.82153,
      -68.45471,
      0,
      1
    );
    z-index: 99.31545;
  }
  37% {
    transform: matrix3d(
      0.65701,
      0,
      0,
      0,
      0,
      0.65701,
      0,
      0,
      0,
      0,
      1,
      0,
      -184.82772,
      -72.89686,
      0,
      1
    );
    z-index: 99.27103;
  }
  38% {
    transform: matrix3d(
      0.64316,
      0,
      0,
      0,
      0,
      0.64316,
      0,
      0,
      0,
      0,
      1,
      0,
      -172.10447,
      -77.05132,
      0,
      1
    );
    z-index: 99.22949;
  }
  39% {
    transform: matrix3d(
      0.63033,
      0,
      0,
      0,
      0,
      0.63033,
      0,
      0,
      0,
      0,
      1,
      0,
      -158.70201,
      -80.9017,
      0,
      1
    );
    z-index: 99.19098;
  }
  40% {
    transform: matrix3d(
      0.61856,
      0,
      0,
      0,
      0,
      0.61856,
      0,
      0,
      0,
      0,
      1,
      0,
      -144.67323,
      -84.43279,
      0,
      1
    );
    z-index: 99.15567;
  }
  41% {
    transform: matrix3d(
      0.6079,
      0,
      0,
      0,
      0,
      0.6079,
      0,
      0,
      0,
      0,
      1,
      0,
      -130.07348,
      -87.63067,
      0,
      1
    );
    z-index: 99.12369;
  }
  42% {
    transform: matrix3d(
      0.59839,
      0,
      0,
      0,
      0,
      0.59839,
      0,
      0,
      0,
      0,
      1,
      0,
      -114.96039,
      -90.4827,
      0,
      1
    );
    z-index: 99.09517;
  }
  43% {
    transform: matrix3d(
      0.59007,
      0,
      0,
      0,
      0,
      0.59007,
      0,
      0,
      0,
      0,
      1,
      0,
      -99.39361,
      -92.97765,
      0,
      1
    );
    z-index: 99.07022;
  }
  44% {
    transform: matrix3d(
      0.58298,
      0,
      0,
      0,
      0,
      0.58298,
      0,
      0,
      0,
      0,
      1,
      0,
      -83.43456,
      -95.10565,
      0,
      1
    );
    z-index: 99.04894;
  }
  45% {
    transform: matrix3d(
      0.57714,
      0,
      0,
      0,
      0,
      0.57714,
      0,
      0,
      0,
      0,
      1,
      0,
      -67.14622,
      -96.85831,
      0,
      1
    );
    z-index: 99.03142;
  }
  46% {
    transform: matrix3d(
      0.57257,
      0,
      0,
      0,
      0,
      0.57257,
      0,
      0,
      0,
      0,
      1,
      0,
      -50.59289,
      -98.22872,
      0,
      1
    );
    z-index: 99.01771;
  }
  47% {
    transform: matrix3d(
      0.5693,
      0,
      0,
      0,
      0,
      0.5693,
      0,
      0,
      0,
      0,
      1,
      0,
      -33.83989,
      -99.21146,
      0,
      1
    );
    z-index: 99.00789;
  }
  48% {
    transform: matrix3d(
      0.56732,
      0,
      0,
      0,
      0,
      0.56732,
      0,
      0,
      0,
      0,
      1,
      0,
      -16.95333,
      -99.80266,
      0,
      1
    );
    z-index: 99.00197;
  }
  49% {
    transform: matrix3d(
      0.56667,
      0,
      0,
      0,
      0,
      0.56667,
      0,
      0,
      0,
      0,
      1,
      0,
      0.00015,
      -99.99999,
      0,
      1
    );
    z-index: 99;
  }
  50% {
    transform: matrix3d(
      0.56732,
      0,
      0,
      0,
      0,
      0.56732,
      0,
      0,
      0,
      0,
      1,
      0,
      16.95364,
      -99.80266,
      0,
      1
    );
    z-index: 99.00197;
  }
  51% {
    transform: matrix3d(
      0.5693,
      0,
      0,
      0,
      0,
      0.5693,
      0,
      0,
      0,
      0,
      1,
      0,
      33.84024,
      -99.21145,
      0,
      1
    );
    z-index: 99.00789;
  }
  52% {
    transform: matrix3d(
      0.57257,
      0,
      0,
      0,
      0,
      0.57257,
      0,
      0,
      0,
      0,
      1,
      0,
      50.59331,
      -98.2287,
      0,
      1
    );
    z-index: 99.01771;
  }
  53% {
    transform: matrix3d(
      0.57714,
      0,
      0,
      0,
      0,
      0.57714,
      0,
      0,
      0,
      0,
      1,
      0,
      67.14674,
      -96.85828,
      0,
      1
    );
    z-index: 99.03142;
  }
  54% {
    transform: matrix3d(
      0.58298,
      0,
      0,
      0,
      0,
      0.58298,
      0,
      0,
      0,
      0,
      1,
      0,
      83.4352,
      -95.1056,
      0,
      1
    );
    z-index: 99.04894;
  }
  55% {
    transform: matrix3d(
      0.59007,
      0,
      0,
      0,
      0,
      0.59007,
      0,
      0,
      0,
      0,
      1,
      0,
      99.39444,
      -92.97758,
      0,
      1
    );
    z-index: 99.07022;
  }
  56% {
    transform: matrix3d(
      0.59839,
      0,
      0,
      0,
      0,
      0.59839,
      0,
      0,
      0,
      0,
      1,
      0,
      114.96147,
      -90.48262,
      0,
      1
    );
    z-index: 99.09517;
  }
  57% {
    transform: matrix3d(
      0.6079,
      0,
      0,
      0,
      0,
      0.6079,
      0,
      0,
      0,
      0,
      1,
      0,
      130.07487,
      -87.63055,
      0,
      1
    );
    z-index: 99.12369;
  }
  58% {
    transform: matrix3d(
      0.61856,
      0,
      0,
      0,
      0,
      0.61856,
      0,
      0,
      0,
      0,
      1,
      0,
      144.67503,
      -84.43264,
      0,
      1
    );
    z-index: 99.15567;
  }
  59% {
    transform: matrix3d(
      0.63033,
      0,
      0,
      0,
      0,
      0.63033,
      0,
      0,
      0,
      0,
      1,
      0,
      158.70434,
      -80.9015,
      0,
      1
    );
    z-index: 99.19099;
  }
  60% {
    transform: matrix3d(
      0.64316,
      0,
      0,
      0,
      0,
      0.64316,
      0,
      0,
      0,
      0,
      1,
      0,
      172.10748,
      -77.05106,
      0,
      1
    );
    z-index: 99.22949;
  }
  61% {
    transform: matrix3d(
      0.65701,
      0,
      0,
      0,
      0,
      0.65701,
      0,
      0,
      0,
      0,
      1,
      0,
      184.83158,
      -72.89652,
      0,
      1
    );
    z-index: 99.27103;
  }
  62% {
    transform: matrix3d(
      0.67182,
      0,
      0,
      0,
      0,
      0.67182,
      0,
      0,
      0,
      0,
      1,
      0,
      196.82649,
      -68.45427,
      0,
      1
    );
    z-index: 99.31546;
  }
  63% {
    transform: matrix3d(
      0.68753,
      0,
      0,
      0,
      0,
      0.68753,
      0,
      0,
      0,
      0,
      1,
      0,
      208.04493,
      -63.74182,
      0,
      1
    );
    z-index: 99.36258;
  }
  64% {
    transform: matrix3d(
      0.70407,
      0,
      0,
      0,
      0,
      0.70407,
      0,
      0,
      0,
      0,
      1,
      0,
      218.4427,
      -58.77778,
      0,
      1
    );
    z-index: 99.41222;
  }
  65% {
    transform: matrix3d(
      0.72139,
      0,
      0,
      0,
      0,
      0.72139,
      0,
      0,
      0,
      0,
      1,
      0,
      227.97887,
      -53.58172,
      0,
      1
    );
    z-index: 99.46418;
  }
  66% {
    transform: matrix3d(
      0.73942,
      0,
      0,
      0,
      0,
      0.73942,
      0,
      0,
      0,
      0,
      1,
      0,
      236.61593,
      -48.17414,
      0,
      1
    );
    z-index: 99.51826;
  }
  67% {
    transform: matrix3d(
      0.75808,
      0,
      0,
      0,
      0,
      0.75808,
      0,
      0,
      0,
      0,
      1,
      0,
      244.31993,
      -42.57636,
      0,
      1
    );
    z-index: 99.57424;
  }
  68% {
    transform: matrix3d(
      0.7773,
      0,
      0,
      0,
      0,
      0.7773,
      0,
      0,
      0,
      0,
      1,
      0,
      251.06067,
      -36.81045,
      0,
      1
    );
    z-index: 99.6319;
  }
  69% {
    transform: matrix3d(
      0.797,
      0,
      0,
      0,
      0,
      0.797,
      0,
      0,
      0,
      0,
      1,
      0,
      256.81175,
      -30.89914,
      0,
      1
    );
    z-index: 99.69101;
  }
  70% {
    transform: matrix3d(
      0.81711,
      0,
      0,
      0,
      0,
      0.81711,
      0,
      0,
      0,
      0,
      1,
      0,
      261.55076,
      -24.86574,
      0,
      1
    );
    z-index: 99.75134;
  }
  71% {
    transform: matrix3d(
      0.83755,
      0,
      0,
      0,
      0,
      0.83755,
      0,
      0,
      0,
      0,
      1,
      0,
      265.25934,
      -18.73401,
      0,
      1
    );
    z-index: 99.81266;
  }
  72% {
    transform: matrix3d(
      0.85824,
      0,
      0,
      0,
      0,
      0.85824,
      0,
      0,
      0,
      0,
      1,
      0,
      267.92326,
      -12.52811,
      0,
      1
    );
    z-index: 99.87472;
  }
  73% {
    transform: matrix3d(
      0.87909,
      0,
      0,
      0,
      0,
      0.87909,
      0,
      0,
      0,
      0,
      1,
      0,
      269.5325,
      -6.27248,
      0,
      1
    );
    z-index: 99.93728;
  }
  74% {
    transform: matrix3d(
      0.90003,
      0,
      0,
      0,
      0,
      0.90003,
      0,
      0,
      0,
      0,
      1,
      0,
      270.08133,
      0.00827,
      0,
      1
    );
    z-index: 100.00008;
  }
  75% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 270, 0, 0, 1);
    z-index: 100;
  }
  76% {
    transform: matrix3d(
      0.92093,
      0,
      0,
      0,
      0,
      0.92093,
      0,
      0,
      0,
      0,
      1,
      0,
      269.46722,
      6.27905,
      0,
      1
    );
    z-index: 100.06279;
  }
  77% {
    transform: matrix3d(
      0.94178,
      0,
      0,
      0,
      0,
      0.94178,
      0,
      0,
      0,
      0,
      1,
      0,
      267.87097,
      12.53332,
      0,
      1
    );
    z-index: 100.12533;
  }
  78% {
    transform: matrix3d(
      0.96246,
      0,
      0,
      0,
      0,
      0.96246,
      0,
      0,
      0,
      0,
      1,
      0,
      265.21756,
      18.73813,
      0,
      1
    );
    z-index: 100.18738;
  }
  79% {
    transform: matrix3d(
      0.9829,
      0,
      0,
      0,
      0,
      0.9829,
      0,
      0,
      0,
      0,
      1,
      0,
      261.51745,
      24.86899,
      0,
      1
    );
    z-index: 100.24869;
  }
  80% {
    transform: matrix3d(
      1.00301,
      0,
      0,
      0,
      0,
      1.00301,
      0,
      0,
      0,
      0,
      1,
      0,
      256.78526,
      30.9017,
      0,
      1
    );
    z-index: 100.30902;
  }
  81% {
    transform: matrix3d(
      1.02271,
      0,
      0,
      0,
      0,
      1.02271,
      0,
      0,
      0,
      0,
      1,
      0,
      251.03965,
      36.81246,
      0,
      1
    );
    z-index: 100.36812;
  }
  82% {
    transform: matrix3d(
      1.04193,
      0,
      0,
      0,
      0,
      1.04193,
      0,
      0,
      0,
      0,
      1,
      0,
      244.3033,
      42.57793,
      0,
      1
    );
    z-index: 100.42578;
  }
  83% {
    transform: matrix3d(
      1.06058,
      0,
      0,
      0,
      0,
      1.06058,
      0,
      0,
      0,
      0,
      1,
      0,
      236.6028,
      48.17537,
      0,
      1
    );
    z-index: 100.48175;
  }
  84% {
    transform: matrix3d(
      1.07861,
      0,
      0,
      0,
      0,
      1.07861,
      0,
      0,
      0,
      0,
      1,
      0,
      227.96854,
      53.58268,
      0,
      1
    );
    z-index: 100.53583;
  }
  85% {
    transform: matrix3d(
      1.09593,
      0,
      0,
      0,
      0,
      1.09593,
      0,
      0,
      0,
      0,
      1,
      0,
      218.43459,
      58.77853,
      0,
      1
    );
    z-index: 100.58779;
  }
  86% {
    transform: matrix3d(
      1.11247,
      0,
      0,
      0,
      0,
      1.11247,
      0,
      0,
      0,
      0,
      1,
      0,
      208.03858,
      63.7424,
      0,
      1
    );
    z-index: 100.63742;
  }
  87% {
    transform: matrix3d(
      1.12818,
      0,
      0,
      0,
      0,
      1.12818,
      0,
      0,
      0,
      0,
      1,
      0,
      196.82153,
      68.45471,
      0,
      1
    );
    z-index: 100.68455;
  }
  88% {
    transform: matrix3d(
      1.14299,
      0,
      0,
      0,
      0,
      1.14299,
      0,
      0,
      0,
      0,
      1,
      0,
      184.82772,
      72.89686,
      0,
      1
    );
    z-index: 100.72897;
  }
  89% {
    transform: matrix3d(
      1.15684,
      0,
      0,
      0,
      0,
      1.15684,
      0,
      0,
      0,
      0,
      1,
      0,
      172.10448,
      77.05132,
      0,
      1
    );
    z-index: 100.77051;
  }
  90% {
    transform: matrix3d(
      1.16967,
      0,
      0,
      0,
      0,
      1.16967,
      0,
      0,
      0,
      0,
      1,
      0,
      158.70202,
      80.9017,
      0,
      1
    );
    z-index: 100.80902;
  }
  91% {
    transform: matrix3d(
      1.18144,
      0,
      0,
      0,
      0,
      1.18144,
      0,
      0,
      0,
      0,
      1,
      0,
      144.67323,
      84.43279,
      0,
      1
    );
    z-index: 100.84433;
  }
  92% {
    transform: matrix3d(
      1.1921,
      0,
      0,
      0,
      0,
      1.1921,
      0,
      0,
      0,
      0,
      1,
      0,
      130.07349,
      87.63067,
      0,
      1
    );
    z-index: 100.87631;
  }
  93% {
    transform: matrix3d(
      1.20161,
      0,
      0,
      0,
      0,
      1.20161,
      0,
      0,
      0,
      0,
      1,
      0,
      114.96041,
      90.48271,
      0,
      1
    );
    z-index: 100.90483;
  }
  94% {
    transform: matrix3d(
      1.20993,
      0,
      0,
      0,
      0,
      1.20993,
      0,
      0,
      0,
      0,
      1,
      0,
      99.39363,
      92.97765,
      0,
      1
    );
    z-index: 100.92978;
  }
  95% {
    transform: matrix3d(
      1.21702,
      0,
      0,
      0,
      0,
      1.21702,
      0,
      0,
      0,
      0,
      1,
      0,
      83.43459,
      95.10565,
      0,
      1
    );
    z-index: 100.95106;
  }
  96% {
    transform: matrix3d(
      1.22286,
      0,
      0,
      0,
      0,
      1.22286,
      0,
      0,
      0,
      0,
      1,
      0,
      67.14627,
      96.85832,
      0,
      1
    );
    z-index: 100.96858;
  }
  97% {
    transform: matrix3d(
      1.22743,
      0,
      0,
      0,
      0,
      1.22743,
      0,
      0,
      0,
      0,
      1,
      0,
      50.59295,
      98.22873,
      0,
      1
    );
    z-index: 100.98229;
  }
  98% {
    transform: matrix3d(
      1.2307,
      0,
      0,
      0,
      0,
      1.2307,
      0,
      0,
      0,
      0,
      1,
      0,
      33.83997,
      99.21147,
      0,
      1
    );
    z-index: 100.99211;
  }
  99% {
    transform: matrix3d(
      1.23268,
      0,
      0,
      0,
      0,
      1.23268,
      0,
      0,
      0,
      0,
      1,
      0,
      16.95344,
      99.80267,
      0,
      1
    );
    z-index: 100.99803;
  }
  100% {
    transform: matrix3d(
      1.23333,
      0,
      0,
      0,
      0,
      1.23333,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      100,
      0,
      1
    );
    z-index: 101;
  }
}
@keyframes rotate7 {
  0% {
    transform: matrix3d(
      0.85822,
      0,
      0,
      0,
      0,
      0.85822,
      0,
      0,
      0,
      0,
      1,
      0,
      -267.87097,
      -12.53332,
      0,
      1
    );
    z-index: 99.87467;
  }
  1% {
    transform: matrix3d(
      0.83754,
      0,
      0,
      0,
      0,
      0.83754,
      0,
      0,
      0,
      0,
      1,
      0,
      -265.21756,
      -18.73813,
      0,
      1
    );
    z-index: 99.81262;
  }
  2% {
    transform: matrix3d(
      0.8171,
      0,
      0,
      0,
      0,
      0.8171,
      0,
      0,
      0,
      0,
      1,
      0,
      -261.51745,
      -24.86899,
      0,
      1
    );
    z-index: 99.75131;
  }
  3% {
    transform: matrix3d(
      0.79699,
      0,
      0,
      0,
      0,
      0.79699,
      0,
      0,
      0,
      0,
      1,
      0,
      -256.78526,
      -30.9017,
      0,
      1
    );
    z-index: 99.69098;
  }
  4% {
    transform: matrix3d(
      0.77729,
      0,
      0,
      0,
      0,
      0.77729,
      0,
      0,
      0,
      0,
      1,
      0,
      -251.03965,
      -36.81246,
      0,
      1
    );
    z-index: 99.63188;
  }
  5% {
    transform: matrix3d(
      0.75807,
      0,
      0,
      0,
      0,
      0.75807,
      0,
      0,
      0,
      0,
      1,
      0,
      -244.3033,
      -42.57793,
      0,
      1
    );
    z-index: 99.57422;
  }
  6% {
    transform: matrix3d(
      0.73942,
      0,
      0,
      0,
      0,
      0.73942,
      0,
      0,
      0,
      0,
      1,
      0,
      -236.6028,
      -48.17537,
      0,
      1
    );
    z-index: 99.51825;
  }
  7% {
    transform: matrix3d(
      0.72139,
      0,
      0,
      0,
      0,
      0.72139,
      0,
      0,
      0,
      0,
      1,
      0,
      -227.96854,
      -53.58268,
      0,
      1
    );
    z-index: 99.46417;
  }
  8% {
    transform: matrix3d(
      0.70407,
      0,
      0,
      0,
      0,
      0.70407,
      0,
      0,
      0,
      0,
      1,
      0,
      -218.43459,
      -58.77853,
      0,
      1
    );
    z-index: 99.41221;
  }
  9% {
    transform: matrix3d(
      0.68753,
      0,
      0,
      0,
      0,
      0.68753,
      0,
      0,
      0,
      0,
      1,
      0,
      -208.03857,
      -63.7424,
      0,
      1
    );
    z-index: 99.36258;
  }
  10% {
    transform: matrix3d(
      0.67182,
      0,
      0,
      0,
      0,
      0.67182,
      0,
      0,
      0,
      0,
      1,
      0,
      -196.82153,
      -68.45471,
      0,
      1
    );
    z-index: 99.31545;
  }
  11% {
    transform: matrix3d(
      0.65701,
      0,
      0,
      0,
      0,
      0.65701,
      0,
      0,
      0,
      0,
      1,
      0,
      -184.82772,
      -72.89686,
      0,
      1
    );
    z-index: 99.27103;
  }
  12% {
    transform: matrix3d(
      0.64316,
      0,
      0,
      0,
      0,
      0.64316,
      0,
      0,
      0,
      0,
      1,
      0,
      -172.10447,
      -77.05132,
      0,
      1
    );
    z-index: 99.22949;
  }
  13% {
    transform: matrix3d(
      0.63033,
      0,
      0,
      0,
      0,
      0.63033,
      0,
      0,
      0,
      0,
      1,
      0,
      -158.70201,
      -80.9017,
      0,
      1
    );
    z-index: 99.19098;
  }
  14% {
    transform: matrix3d(
      0.61856,
      0,
      0,
      0,
      0,
      0.61856,
      0,
      0,
      0,
      0,
      1,
      0,
      -144.67323,
      -84.43279,
      0,
      1
    );
    z-index: 99.15567;
  }
  15% {
    transform: matrix3d(
      0.6079,
      0,
      0,
      0,
      0,
      0.6079,
      0,
      0,
      0,
      0,
      1,
      0,
      -130.07348,
      -87.63067,
      0,
      1
    );
    z-index: 99.12369;
  }
  16% {
    transform: matrix3d(
      0.59839,
      0,
      0,
      0,
      0,
      0.59839,
      0,
      0,
      0,
      0,
      1,
      0,
      -114.96039,
      -90.4827,
      0,
      1
    );
    z-index: 99.09517;
  }
  17% {
    transform: matrix3d(
      0.59007,
      0,
      0,
      0,
      0,
      0.59007,
      0,
      0,
      0,
      0,
      1,
      0,
      -99.39361,
      -92.97765,
      0,
      1
    );
    z-index: 99.07022;
  }
  18% {
    transform: matrix3d(
      0.58298,
      0,
      0,
      0,
      0,
      0.58298,
      0,
      0,
      0,
      0,
      1,
      0,
      -83.43456,
      -95.10565,
      0,
      1
    );
    z-index: 99.04894;
  }
  19% {
    transform: matrix3d(
      0.57714,
      0,
      0,
      0,
      0,
      0.57714,
      0,
      0,
      0,
      0,
      1,
      0,
      -67.14622,
      -96.85831,
      0,
      1
    );
    z-index: 99.03142;
  }
  20% {
    transform: matrix3d(
      0.57257,
      0,
      0,
      0,
      0,
      0.57257,
      0,
      0,
      0,
      0,
      1,
      0,
      -50.59289,
      -98.22872,
      0,
      1
    );
    z-index: 99.01771;
  }
  21% {
    transform: matrix3d(
      0.5693,
      0,
      0,
      0,
      0,
      0.5693,
      0,
      0,
      0,
      0,
      1,
      0,
      -33.83989,
      -99.21146,
      0,
      1
    );
    z-index: 99.00789;
  }
  22% {
    transform: matrix3d(
      0.56732,
      0,
      0,
      0,
      0,
      0.56732,
      0,
      0,
      0,
      0,
      1,
      0,
      -16.95333,
      -99.80266,
      0,
      1
    );
    z-index: 99.00197;
  }
  23% {
    transform: matrix3d(
      0.56667,
      0,
      0,
      0,
      0,
      0.56667,
      0,
      0,
      0,
      0,
      1,
      0,
      0.00015,
      -99.99999,
      0,
      1
    );
    z-index: 99;
  }
  24% {
    transform: matrix3d(
      0.56732,
      0,
      0,
      0,
      0,
      0.56732,
      0,
      0,
      0,
      0,
      1,
      0,
      16.95364,
      -99.80266,
      0,
      1
    );
    z-index: 99.00197;
  }
  25% {
    transform: matrix3d(
      0.5693,
      0,
      0,
      0,
      0,
      0.5693,
      0,
      0,
      0,
      0,
      1,
      0,
      33.84024,
      -99.21145,
      0,
      1
    );
    z-index: 99.00789;
  }
  26% {
    transform: matrix3d(
      0.57257,
      0,
      0,
      0,
      0,
      0.57257,
      0,
      0,
      0,
      0,
      1,
      0,
      50.59331,
      -98.2287,
      0,
      1
    );
    z-index: 99.01771;
  }
  27% {
    transform: matrix3d(
      0.57714,
      0,
      0,
      0,
      0,
      0.57714,
      0,
      0,
      0,
      0,
      1,
      0,
      67.14674,
      -96.85828,
      0,
      1
    );
    z-index: 99.03142;
  }
  28% {
    transform: matrix3d(
      0.58298,
      0,
      0,
      0,
      0,
      0.58298,
      0,
      0,
      0,
      0,
      1,
      0,
      83.4352,
      -95.1056,
      0,
      1
    );
    z-index: 99.04894;
  }
  29% {
    transform: matrix3d(
      0.59007,
      0,
      0,
      0,
      0,
      0.59007,
      0,
      0,
      0,
      0,
      1,
      0,
      99.39444,
      -92.97758,
      0,
      1
    );
    z-index: 99.07022;
  }
  30% {
    transform: matrix3d(
      0.59839,
      0,
      0,
      0,
      0,
      0.59839,
      0,
      0,
      0,
      0,
      1,
      0,
      114.96147,
      -90.48262,
      0,
      1
    );
    z-index: 99.09517;
  }
  31% {
    transform: matrix3d(
      0.6079,
      0,
      0,
      0,
      0,
      0.6079,
      0,
      0,
      0,
      0,
      1,
      0,
      130.07487,
      -87.63055,
      0,
      1
    );
    z-index: 99.12369;
  }
  32% {
    transform: matrix3d(
      0.61856,
      0,
      0,
      0,
      0,
      0.61856,
      0,
      0,
      0,
      0,
      1,
      0,
      144.67503,
      -84.43264,
      0,
      1
    );
    z-index: 99.15567;
  }
  33% {
    transform: matrix3d(
      0.63033,
      0,
      0,
      0,
      0,
      0.63033,
      0,
      0,
      0,
      0,
      1,
      0,
      158.70434,
      -80.9015,
      0,
      1
    );
    z-index: 99.19099;
  }
  34% {
    transform: matrix3d(
      0.64316,
      0,
      0,
      0,
      0,
      0.64316,
      0,
      0,
      0,
      0,
      1,
      0,
      172.10748,
      -77.05106,
      0,
      1
    );
    z-index: 99.22949;
  }
  35% {
    transform: matrix3d(
      0.65701,
      0,
      0,
      0,
      0,
      0.65701,
      0,
      0,
      0,
      0,
      1,
      0,
      184.83158,
      -72.89652,
      0,
      1
    );
    z-index: 99.27103;
  }
  36% {
    transform: matrix3d(
      0.67182,
      0,
      0,
      0,
      0,
      0.67182,
      0,
      0,
      0,
      0,
      1,
      0,
      196.82649,
      -68.45427,
      0,
      1
    );
    z-index: 99.31546;
  }
  37% {
    transform: matrix3d(
      0.68753,
      0,
      0,
      0,
      0,
      0.68753,
      0,
      0,
      0,
      0,
      1,
      0,
      208.04493,
      -63.74182,
      0,
      1
    );
    z-index: 99.36258;
  }
  38% {
    transform: matrix3d(
      0.70407,
      0,
      0,
      0,
      0,
      0.70407,
      0,
      0,
      0,
      0,
      1,
      0,
      218.4427,
      -58.77778,
      0,
      1
    );
    z-index: 99.41222;
  }
  39% {
    transform: matrix3d(
      0.72139,
      0,
      0,
      0,
      0,
      0.72139,
      0,
      0,
      0,
      0,
      1,
      0,
      227.97887,
      -53.58172,
      0,
      1
    );
    z-index: 99.46418;
  }
  40% {
    transform: matrix3d(
      0.73942,
      0,
      0,
      0,
      0,
      0.73942,
      0,
      0,
      0,
      0,
      1,
      0,
      236.61593,
      -48.17414,
      0,
      1
    );
    z-index: 99.51826;
  }
  41% {
    transform: matrix3d(
      0.75808,
      0,
      0,
      0,
      0,
      0.75808,
      0,
      0,
      0,
      0,
      1,
      0,
      244.31993,
      -42.57636,
      0,
      1
    );
    z-index: 99.57424;
  }
  42% {
    transform: matrix3d(
      0.7773,
      0,
      0,
      0,
      0,
      0.7773,
      0,
      0,
      0,
      0,
      1,
      0,
      251.06067,
      -36.81045,
      0,
      1
    );
    z-index: 99.6319;
  }
  43% {
    transform: matrix3d(
      0.797,
      0,
      0,
      0,
      0,
      0.797,
      0,
      0,
      0,
      0,
      1,
      0,
      256.81175,
      -30.89914,
      0,
      1
    );
    z-index: 99.69101;
  }
  44% {
    transform: matrix3d(
      0.81711,
      0,
      0,
      0,
      0,
      0.81711,
      0,
      0,
      0,
      0,
      1,
      0,
      261.55076,
      -24.86574,
      0,
      1
    );
    z-index: 99.75134;
  }
  45% {
    transform: matrix3d(
      0.83755,
      0,
      0,
      0,
      0,
      0.83755,
      0,
      0,
      0,
      0,
      1,
      0,
      265.25934,
      -18.73401,
      0,
      1
    );
    z-index: 99.81266;
  }
  46% {
    transform: matrix3d(
      0.85824,
      0,
      0,
      0,
      0,
      0.85824,
      0,
      0,
      0,
      0,
      1,
      0,
      267.92326,
      -12.52811,
      0,
      1
    );
    z-index: 99.87472;
  }
  47% {
    transform: matrix3d(
      0.87909,
      0,
      0,
      0,
      0,
      0.87909,
      0,
      0,
      0,
      0,
      1,
      0,
      269.5325,
      -6.27248,
      0,
      1
    );
    z-index: 99.93728;
  }
  48% {
    transform: matrix3d(
      0.90003,
      0,
      0,
      0,
      0,
      0.90003,
      0,
      0,
      0,
      0,
      1,
      0,
      270.08133,
      0.00827,
      0,
      1
    );
    z-index: 100.00008;
  }
  49% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 270, 0, 0, 1);
    z-index: 100;
  }
  50% {
    transform: matrix3d(
      0.92093,
      0,
      0,
      0,
      0,
      0.92093,
      0,
      0,
      0,
      0,
      1,
      0,
      269.46722,
      6.27905,
      0,
      1
    );
    z-index: 100.06279;
  }
  51% {
    transform: matrix3d(
      0.94178,
      0,
      0,
      0,
      0,
      0.94178,
      0,
      0,
      0,
      0,
      1,
      0,
      267.87097,
      12.53332,
      0,
      1
    );
    z-index: 100.12533;
  }
  52% {
    transform: matrix3d(
      0.96246,
      0,
      0,
      0,
      0,
      0.96246,
      0,
      0,
      0,
      0,
      1,
      0,
      265.21756,
      18.73813,
      0,
      1
    );
    z-index: 100.18738;
  }
  53% {
    transform: matrix3d(
      0.9829,
      0,
      0,
      0,
      0,
      0.9829,
      0,
      0,
      0,
      0,
      1,
      0,
      261.51745,
      24.86899,
      0,
      1
    );
    z-index: 100.24869;
  }
  54% {
    transform: matrix3d(
      1.00301,
      0,
      0,
      0,
      0,
      1.00301,
      0,
      0,
      0,
      0,
      1,
      0,
      256.78526,
      30.9017,
      0,
      1
    );
    z-index: 100.30902;
  }
  55% {
    transform: matrix3d(
      1.02271,
      0,
      0,
      0,
      0,
      1.02271,
      0,
      0,
      0,
      0,
      1,
      0,
      251.03965,
      36.81246,
      0,
      1
    );
    z-index: 100.36812;
  }
  56% {
    transform: matrix3d(
      1.04193,
      0,
      0,
      0,
      0,
      1.04193,
      0,
      0,
      0,
      0,
      1,
      0,
      244.3033,
      42.57793,
      0,
      1
    );
    z-index: 100.42578;
  }
  57% {
    transform: matrix3d(
      1.06058,
      0,
      0,
      0,
      0,
      1.06058,
      0,
      0,
      0,
      0,
      1,
      0,
      236.6028,
      48.17537,
      0,
      1
    );
    z-index: 100.48175;
  }
  58% {
    transform: matrix3d(
      1.07861,
      0,
      0,
      0,
      0,
      1.07861,
      0,
      0,
      0,
      0,
      1,
      0,
      227.96854,
      53.58268,
      0,
      1
    );
    z-index: 100.53583;
  }
  59% {
    transform: matrix3d(
      1.09593,
      0,
      0,
      0,
      0,
      1.09593,
      0,
      0,
      0,
      0,
      1,
      0,
      218.43459,
      58.77853,
      0,
      1
    );
    z-index: 100.58779;
  }
  60% {
    transform: matrix3d(
      1.11247,
      0,
      0,
      0,
      0,
      1.11247,
      0,
      0,
      0,
      0,
      1,
      0,
      208.03858,
      63.7424,
      0,
      1
    );
    z-index: 100.63742;
  }
  61% {
    transform: matrix3d(
      1.12818,
      0,
      0,
      0,
      0,
      1.12818,
      0,
      0,
      0,
      0,
      1,
      0,
      196.82153,
      68.45471,
      0,
      1
    );
    z-index: 100.68455;
  }
  62% {
    transform: matrix3d(
      1.14299,
      0,
      0,
      0,
      0,
      1.14299,
      0,
      0,
      0,
      0,
      1,
      0,
      184.82772,
      72.89686,
      0,
      1
    );
    z-index: 100.72897;
  }
  63% {
    transform: matrix3d(
      1.15684,
      0,
      0,
      0,
      0,
      1.15684,
      0,
      0,
      0,
      0,
      1,
      0,
      172.10448,
      77.05132,
      0,
      1
    );
    z-index: 100.77051;
  }
  64% {
    transform: matrix3d(
      1.16967,
      0,
      0,
      0,
      0,
      1.16967,
      0,
      0,
      0,
      0,
      1,
      0,
      158.70202,
      80.9017,
      0,
      1
    );
    z-index: 100.80902;
  }
  65% {
    transform: matrix3d(
      1.18144,
      0,
      0,
      0,
      0,
      1.18144,
      0,
      0,
      0,
      0,
      1,
      0,
      144.67323,
      84.43279,
      0,
      1
    );
    z-index: 100.84433;
  }
  66% {
    transform: matrix3d(
      1.1921,
      0,
      0,
      0,
      0,
      1.1921,
      0,
      0,
      0,
      0,
      1,
      0,
      130.07349,
      87.63067,
      0,
      1
    );
    z-index: 100.87631;
  }
  67% {
    transform: matrix3d(
      1.20161,
      0,
      0,
      0,
      0,
      1.20161,
      0,
      0,
      0,
      0,
      1,
      0,
      114.96041,
      90.48271,
      0,
      1
    );
    z-index: 100.90483;
  }
  68% {
    transform: matrix3d(
      1.20993,
      0,
      0,
      0,
      0,
      1.20993,
      0,
      0,
      0,
      0,
      1,
      0,
      99.39363,
      92.97765,
      0,
      1
    );
    z-index: 100.92978;
  }
  69% {
    transform: matrix3d(
      1.21702,
      0,
      0,
      0,
      0,
      1.21702,
      0,
      0,
      0,
      0,
      1,
      0,
      83.43459,
      95.10565,
      0,
      1
    );
    z-index: 100.95106;
  }
  70% {
    transform: matrix3d(
      1.22286,
      0,
      0,
      0,
      0,
      1.22286,
      0,
      0,
      0,
      0,
      1,
      0,
      67.14627,
      96.85832,
      0,
      1
    );
    z-index: 100.96858;
  }
  71% {
    transform: matrix3d(
      1.22743,
      0,
      0,
      0,
      0,
      1.22743,
      0,
      0,
      0,
      0,
      1,
      0,
      50.59295,
      98.22873,
      0,
      1
    );
    z-index: 100.98229;
  }
  72% {
    transform: matrix3d(
      1.2307,
      0,
      0,
      0,
      0,
      1.2307,
      0,
      0,
      0,
      0,
      1,
      0,
      33.83997,
      99.21147,
      0,
      1
    );
    z-index: 100.99211;
  }
  73% {
    transform: matrix3d(
      1.23268,
      0,
      0,
      0,
      0,
      1.23268,
      0,
      0,
      0,
      0,
      1,
      0,
      16.95344,
      99.80267,
      0,
      1
    );
    z-index: 100.99803;
  }
  74% {
    transform: matrix3d(
      1.23333,
      0,
      0,
      0,
      0,
      1.23333,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      100,
      0,
      1
    );
    z-index: 101;
  }
  75% {
    transform: matrix3d(
      1.23268,
      0,
      0,
      0,
      0,
      1.23268,
      0,
      0,
      0,
      0,
      1,
      0,
      -16.95344,
      99.80267,
      0,
      1
    );
    z-index: 100.99803;
  }
  76% {
    transform: matrix3d(
      1.2307,
      0,
      0,
      0,
      0,
      1.2307,
      0,
      0,
      0,
      0,
      1,
      0,
      -33.83997,
      99.21147,
      0,
      1
    );
    z-index: 100.99211;
  }
  77% {
    transform: matrix3d(
      1.22743,
      0,
      0,
      0,
      0,
      1.22743,
      0,
      0,
      0,
      0,
      1,
      0,
      -50.59295,
      98.22873,
      0,
      1
    );
    z-index: 100.98229;
  }
  78% {
    transform: matrix3d(
      1.22286,
      0,
      0,
      0,
      0,
      1.22286,
      0,
      0,
      0,
      0,
      1,
      0,
      -67.14627,
      96.85832,
      0,
      1
    );
    z-index: 100.96858;
  }
  79% {
    transform: matrix3d(
      1.21702,
      0,
      0,
      0,
      0,
      1.21702,
      0,
      0,
      0,
      0,
      1,
      0,
      -83.43459,
      95.10565,
      0,
      1
    );
    z-index: 100.95106;
  }
  80% {
    transform: matrix3d(
      1.20993,
      0,
      0,
      0,
      0,
      1.20993,
      0,
      0,
      0,
      0,
      1,
      0,
      -99.39363,
      92.97765,
      0,
      1
    );
    z-index: 100.92978;
  }
  81% {
    transform: matrix3d(
      1.20161,
      0,
      0,
      0,
      0,
      1.20161,
      0,
      0,
      0,
      0,
      1,
      0,
      -114.96041,
      90.48271,
      0,
      1
    );
    z-index: 100.90483;
  }
  82% {
    transform: matrix3d(
      1.1921,
      0,
      0,
      0,
      0,
      1.1921,
      0,
      0,
      0,
      0,
      1,
      0,
      -130.07349,
      87.63067,
      0,
      1
    );
    z-index: 100.87631;
  }
  83% {
    transform: matrix3d(
      1.18144,
      0,
      0,
      0,
      0,
      1.18144,
      0,
      0,
      0,
      0,
      1,
      0,
      -144.67323,
      84.43279,
      0,
      1
    );
    z-index: 100.84433;
  }
  84% {
    transform: matrix3d(
      1.16967,
      0,
      0,
      0,
      0,
      1.16967,
      0,
      0,
      0,
      0,
      1,
      0,
      -158.70202,
      80.9017,
      0,
      1
    );
    z-index: 100.80902;
  }
  85% {
    transform: matrix3d(
      1.15684,
      0,
      0,
      0,
      0,
      1.15684,
      0,
      0,
      0,
      0,
      1,
      0,
      -172.10448,
      77.05132,
      0,
      1
    );
    z-index: 100.77051;
  }
  86% {
    transform: matrix3d(
      1.14299,
      0,
      0,
      0,
      0,
      1.14299,
      0,
      0,
      0,
      0,
      1,
      0,
      -184.82772,
      72.89686,
      0,
      1
    );
    z-index: 100.72897;
  }
  87% {
    transform: matrix3d(
      1.12818,
      0,
      0,
      0,
      0,
      1.12818,
      0,
      0,
      0,
      0,
      1,
      0,
      -196.82153,
      68.45471,
      0,
      1
    );
    z-index: 100.68455;
  }
  88% {
    transform: matrix3d(
      1.11247,
      0,
      0,
      0,
      0,
      1.11247,
      0,
      0,
      0,
      0,
      1,
      0,
      -208.03858,
      63.7424,
      0,
      1
    );
    z-index: 100.63742;
  }
  89% {
    transform: matrix3d(
      1.09593,
      0,
      0,
      0,
      0,
      1.09593,
      0,
      0,
      0,
      0,
      1,
      0,
      -218.43459,
      58.77853,
      0,
      1
    );
    z-index: 100.58779;
  }
  90% {
    transform: matrix3d(
      1.07861,
      0,
      0,
      0,
      0,
      1.07861,
      0,
      0,
      0,
      0,
      1,
      0,
      -227.96854,
      53.58268,
      0,
      1
    );
    z-index: 100.53583;
  }
  91% {
    transform: matrix3d(
      1.06058,
      0,
      0,
      0,
      0,
      1.06058,
      0,
      0,
      0,
      0,
      1,
      0,
      -236.6028,
      48.17537,
      0,
      1
    );
    z-index: 100.48175;
  }
  92% {
    transform: matrix3d(
      1.04193,
      0,
      0,
      0,
      0,
      1.04193,
      0,
      0,
      0,
      0,
      1,
      0,
      -244.3033,
      42.57793,
      0,
      1
    );
    z-index: 100.42578;
  }
  93% {
    transform: matrix3d(
      1.02271,
      0,
      0,
      0,
      0,
      1.02271,
      0,
      0,
      0,
      0,
      1,
      0,
      -251.03965,
      36.81246,
      0,
      1
    );
    z-index: 100.36812;
  }
  94% {
    transform: matrix3d(
      1.00301,
      0,
      0,
      0,
      0,
      1.00301,
      0,
      0,
      0,
      0,
      1,
      0,
      -256.78526,
      30.9017,
      0,
      1
    );
    z-index: 100.30902;
  }
  95% {
    transform: matrix3d(
      0.9829,
      0,
      0,
      0,
      0,
      0.9829,
      0,
      0,
      0,
      0,
      1,
      0,
      -261.51745,
      24.86899,
      0,
      1
    );
    z-index: 100.24869;
  }
  96% {
    transform: matrix3d(
      0.96246,
      0,
      0,
      0,
      0,
      0.96246,
      0,
      0,
      0,
      0,
      1,
      0,
      -265.21756,
      18.73813,
      0,
      1
    );
    z-index: 100.18738;
  }
  97% {
    transform: matrix3d(
      0.94178,
      0,
      0,
      0,
      0,
      0.94178,
      0,
      0,
      0,
      0,
      1,
      0,
      -267.87097,
      12.53332,
      0,
      1
    );
    z-index: 100.12533;
  }
  98% {
    transform: matrix3d(
      0.92093,
      0,
      0,
      0,
      0,
      0.92093,
      0,
      0,
      0,
      0,
      1,
      0,
      -269.46722,
      6.27905,
      0,
      1
    );
    z-index: 100.06279;
  }
  99% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, -270, 0, 0, 1);
    z-index: 100;
  }
  100% {
    transform: matrix3d(
      0.87907,
      0,
      0,
      0,
      0,
      0.87907,
      0,
      0,
      0,
      0,
      1,
      0,
      -269.46722,
      -6.27905,
      0,
      1
    );
    z-index: 99.93721;
  }
}
@keyframes rotate8 {
  0% {
    transform: matrix3d(
      0.57257,
      0,
      0,
      0,
      0,
      0.57257,
      0,
      0,
      0,
      0,
      1,
      0,
      50.59331,
      -98.2287,
      0,
      1
    );
    z-index: 99.01771;
  }
  1% {
    transform: matrix3d(
      0.57714,
      0,
      0,
      0,
      0,
      0.57714,
      0,
      0,
      0,
      0,
      1,
      0,
      67.14674,
      -96.85828,
      0,
      1
    );
    z-index: 99.03142;
  }
  2% {
    transform: matrix3d(
      0.58298,
      0,
      0,
      0,
      0,
      0.58298,
      0,
      0,
      0,
      0,
      1,
      0,
      83.4352,
      -95.1056,
      0,
      1
    );
    z-index: 99.04894;
  }
  3% {
    transform: matrix3d(
      0.59007,
      0,
      0,
      0,
      0,
      0.59007,
      0,
      0,
      0,
      0,
      1,
      0,
      99.39444,
      -92.97758,
      0,
      1
    );
    z-index: 99.07022;
  }
  4% {
    transform: matrix3d(
      0.59839,
      0,
      0,
      0,
      0,
      0.59839,
      0,
      0,
      0,
      0,
      1,
      0,
      114.96147,
      -90.48262,
      0,
      1
    );
    z-index: 99.09517;
  }
  5% {
    transform: matrix3d(
      0.6079,
      0,
      0,
      0,
      0,
      0.6079,
      0,
      0,
      0,
      0,
      1,
      0,
      130.07487,
      -87.63055,
      0,
      1
    );
    z-index: 99.12369;
  }
  6% {
    transform: matrix3d(
      0.61856,
      0,
      0,
      0,
      0,
      0.61856,
      0,
      0,
      0,
      0,
      1,
      0,
      144.67503,
      -84.43264,
      0,
      1
    );
    z-index: 99.15567;
  }
  7% {
    transform: matrix3d(
      0.63033,
      0,
      0,
      0,
      0,
      0.63033,
      0,
      0,
      0,
      0,
      1,
      0,
      158.70434,
      -80.9015,
      0,
      1
    );
    z-index: 99.19099;
  }
  8% {
    transform: matrix3d(
      0.64316,
      0,
      0,
      0,
      0,
      0.64316,
      0,
      0,
      0,
      0,
      1,
      0,
      172.10748,
      -77.05106,
      0,
      1
    );
    z-index: 99.22949;
  }
  9% {
    transform: matrix3d(
      0.65701,
      0,
      0,
      0,
      0,
      0.65701,
      0,
      0,
      0,
      0,
      1,
      0,
      184.83158,
      -72.89652,
      0,
      1
    );
    z-index: 99.27103;
  }
  10% {
    transform: matrix3d(
      0.67182,
      0,
      0,
      0,
      0,
      0.67182,
      0,
      0,
      0,
      0,
      1,
      0,
      196.82649,
      -68.45427,
      0,
      1
    );
    z-index: 99.31546;
  }
  11% {
    transform: matrix3d(
      0.68753,
      0,
      0,
      0,
      0,
      0.68753,
      0,
      0,
      0,
      0,
      1,
      0,
      208.04493,
      -63.74182,
      0,
      1
    );
    z-index: 99.36258;
  }
  12% {
    transform: matrix3d(
      0.70407,
      0,
      0,
      0,
      0,
      0.70407,
      0,
      0,
      0,
      0,
      1,
      0,
      218.4427,
      -58.77778,
      0,
      1
    );
    z-index: 99.41222;
  }
  13% {
    transform: matrix3d(
      0.72139,
      0,
      0,
      0,
      0,
      0.72139,
      0,
      0,
      0,
      0,
      1,
      0,
      227.97887,
      -53.58172,
      0,
      1
    );
    z-index: 99.46418;
  }
  14% {
    transform: matrix3d(
      0.73942,
      0,
      0,
      0,
      0,
      0.73942,
      0,
      0,
      0,
      0,
      1,
      0,
      236.61593,
      -48.17414,
      0,
      1
    );
    z-index: 99.51826;
  }
  15% {
    transform: matrix3d(
      0.75808,
      0,
      0,
      0,
      0,
      0.75808,
      0,
      0,
      0,
      0,
      1,
      0,
      244.31993,
      -42.57636,
      0,
      1
    );
    z-index: 99.57424;
  }
  16% {
    transform: matrix3d(
      0.7773,
      0,
      0,
      0,
      0,
      0.7773,
      0,
      0,
      0,
      0,
      1,
      0,
      251.06067,
      -36.81045,
      0,
      1
    );
    z-index: 99.6319;
  }
  17% {
    transform: matrix3d(
      0.797,
      0,
      0,
      0,
      0,
      0.797,
      0,
      0,
      0,
      0,
      1,
      0,
      256.81175,
      -30.89914,
      0,
      1
    );
    z-index: 99.69101;
  }
  18% {
    transform: matrix3d(
      0.81711,
      0,
      0,
      0,
      0,
      0.81711,
      0,
      0,
      0,
      0,
      1,
      0,
      261.55076,
      -24.86574,
      0,
      1
    );
    z-index: 99.75134;
  }
  19% {
    transform: matrix3d(
      0.83755,
      0,
      0,
      0,
      0,
      0.83755,
      0,
      0,
      0,
      0,
      1,
      0,
      265.25934,
      -18.73401,
      0,
      1
    );
    z-index: 99.81266;
  }
  20% {
    transform: matrix3d(
      0.85824,
      0,
      0,
      0,
      0,
      0.85824,
      0,
      0,
      0,
      0,
      1,
      0,
      267.92326,
      -12.52811,
      0,
      1
    );
    z-index: 99.87472;
  }
  21% {
    transform: matrix3d(
      0.87909,
      0,
      0,
      0,
      0,
      0.87909,
      0,
      0,
      0,
      0,
      1,
      0,
      269.5325,
      -6.27248,
      0,
      1
    );
    z-index: 99.93728;
  }
  22% {
    transform: matrix3d(
      0.90003,
      0,
      0,
      0,
      0,
      0.90003,
      0,
      0,
      0,
      0,
      1,
      0,
      270.08133,
      0.00827,
      0,
      1
    );
    z-index: 100.00008;
  }
  23% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 270, 0, 0, 1);
    z-index: 100;
  }
  24% {
    transform: matrix3d(
      0.92093,
      0,
      0,
      0,
      0,
      0.92093,
      0,
      0,
      0,
      0,
      1,
      0,
      269.46722,
      6.27905,
      0,
      1
    );
    z-index: 100.06279;
  }
  25% {
    transform: matrix3d(
      0.94178,
      0,
      0,
      0,
      0,
      0.94178,
      0,
      0,
      0,
      0,
      1,
      0,
      267.87097,
      12.53332,
      0,
      1
    );
    z-index: 100.12533;
  }
  26% {
    transform: matrix3d(
      0.96246,
      0,
      0,
      0,
      0,
      0.96246,
      0,
      0,
      0,
      0,
      1,
      0,
      265.21756,
      18.73813,
      0,
      1
    );
    z-index: 100.18738;
  }
  27% {
    transform: matrix3d(
      0.9829,
      0,
      0,
      0,
      0,
      0.9829,
      0,
      0,
      0,
      0,
      1,
      0,
      261.51745,
      24.86899,
      0,
      1
    );
    z-index: 100.24869;
  }
  28% {
    transform: matrix3d(
      1.00301,
      0,
      0,
      0,
      0,
      1.00301,
      0,
      0,
      0,
      0,
      1,
      0,
      256.78526,
      30.9017,
      0,
      1
    );
    z-index: 100.30902;
  }
  29% {
    transform: matrix3d(
      1.02271,
      0,
      0,
      0,
      0,
      1.02271,
      0,
      0,
      0,
      0,
      1,
      0,
      251.03965,
      36.81246,
      0,
      1
    );
    z-index: 100.36812;
  }
  30% {
    transform: matrix3d(
      1.04193,
      0,
      0,
      0,
      0,
      1.04193,
      0,
      0,
      0,
      0,
      1,
      0,
      244.3033,
      42.57793,
      0,
      1
    );
    z-index: 100.42578;
  }
  31% {
    transform: matrix3d(
      1.06058,
      0,
      0,
      0,
      0,
      1.06058,
      0,
      0,
      0,
      0,
      1,
      0,
      236.6028,
      48.17537,
      0,
      1
    );
    z-index: 100.48175;
  }
  32% {
    transform: matrix3d(
      1.07861,
      0,
      0,
      0,
      0,
      1.07861,
      0,
      0,
      0,
      0,
      1,
      0,
      227.96854,
      53.58268,
      0,
      1
    );
    z-index: 100.53583;
  }
  33% {
    transform: matrix3d(
      1.09593,
      0,
      0,
      0,
      0,
      1.09593,
      0,
      0,
      0,
      0,
      1,
      0,
      218.43459,
      58.77853,
      0,
      1
    );
    z-index: 100.58779;
  }
  34% {
    transform: matrix3d(
      1.11247,
      0,
      0,
      0,
      0,
      1.11247,
      0,
      0,
      0,
      0,
      1,
      0,
      208.03858,
      63.7424,
      0,
      1
    );
    z-index: 100.63742;
  }
  35% {
    transform: matrix3d(
      1.12818,
      0,
      0,
      0,
      0,
      1.12818,
      0,
      0,
      0,
      0,
      1,
      0,
      196.82153,
      68.45471,
      0,
      1
    );
    z-index: 100.68455;
  }
  36% {
    transform: matrix3d(
      1.14299,
      0,
      0,
      0,
      0,
      1.14299,
      0,
      0,
      0,
      0,
      1,
      0,
      184.82772,
      72.89686,
      0,
      1
    );
    z-index: 100.72897;
  }
  37% {
    transform: matrix3d(
      1.15684,
      0,
      0,
      0,
      0,
      1.15684,
      0,
      0,
      0,
      0,
      1,
      0,
      172.10448,
      77.05132,
      0,
      1
    );
    z-index: 100.77051;
  }
  38% {
    transform: matrix3d(
      1.16967,
      0,
      0,
      0,
      0,
      1.16967,
      0,
      0,
      0,
      0,
      1,
      0,
      158.70202,
      80.9017,
      0,
      1
    );
    z-index: 100.80902;
  }
  39% {
    transform: matrix3d(
      1.18144,
      0,
      0,
      0,
      0,
      1.18144,
      0,
      0,
      0,
      0,
      1,
      0,
      144.67323,
      84.43279,
      0,
      1
    );
    z-index: 100.84433;
  }
  40% {
    transform: matrix3d(
      1.1921,
      0,
      0,
      0,
      0,
      1.1921,
      0,
      0,
      0,
      0,
      1,
      0,
      130.07349,
      87.63067,
      0,
      1
    );
    z-index: 100.87631;
  }
  41% {
    transform: matrix3d(
      1.20161,
      0,
      0,
      0,
      0,
      1.20161,
      0,
      0,
      0,
      0,
      1,
      0,
      114.96041,
      90.48271,
      0,
      1
    );
    z-index: 100.90483;
  }
  42% {
    transform: matrix3d(
      1.20993,
      0,
      0,
      0,
      0,
      1.20993,
      0,
      0,
      0,
      0,
      1,
      0,
      99.39363,
      92.97765,
      0,
      1
    );
    z-index: 100.92978;
  }
  43% {
    transform: matrix3d(
      1.21702,
      0,
      0,
      0,
      0,
      1.21702,
      0,
      0,
      0,
      0,
      1,
      0,
      83.43459,
      95.10565,
      0,
      1
    );
    z-index: 100.95106;
  }
  44% {
    transform: matrix3d(
      1.22286,
      0,
      0,
      0,
      0,
      1.22286,
      0,
      0,
      0,
      0,
      1,
      0,
      67.14627,
      96.85832,
      0,
      1
    );
    z-index: 100.96858;
  }
  45% {
    transform: matrix3d(
      1.22743,
      0,
      0,
      0,
      0,
      1.22743,
      0,
      0,
      0,
      0,
      1,
      0,
      50.59295,
      98.22873,
      0,
      1
    );
    z-index: 100.98229;
  }
  46% {
    transform: matrix3d(
      1.2307,
      0,
      0,
      0,
      0,
      1.2307,
      0,
      0,
      0,
      0,
      1,
      0,
      33.83997,
      99.21147,
      0,
      1
    );
    z-index: 100.99211;
  }
  47% {
    transform: matrix3d(
      1.23268,
      0,
      0,
      0,
      0,
      1.23268,
      0,
      0,
      0,
      0,
      1,
      0,
      16.95344,
      99.80267,
      0,
      1
    );
    z-index: 100.99803;
  }
  48% {
    transform: matrix3d(
      1.23333,
      0,
      0,
      0,
      0,
      1.23333,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      100,
      0,
      1
    );
    z-index: 101;
  }
  49% {
    transform: matrix3d(
      1.23268,
      0,
      0,
      0,
      0,
      1.23268,
      0,
      0,
      0,
      0,
      1,
      0,
      -16.95344,
      99.80267,
      0,
      1
    );
    z-index: 100.99803;
  }
  50% {
    transform: matrix3d(
      1.2307,
      0,
      0,
      0,
      0,
      1.2307,
      0,
      0,
      0,
      0,
      1,
      0,
      -33.83997,
      99.21147,
      0,
      1
    );
    z-index: 100.99211;
  }
  51% {
    transform: matrix3d(
      1.22743,
      0,
      0,
      0,
      0,
      1.22743,
      0,
      0,
      0,
      0,
      1,
      0,
      -50.59295,
      98.22873,
      0,
      1
    );
    z-index: 100.98229;
  }
  52% {
    transform: matrix3d(
      1.22286,
      0,
      0,
      0,
      0,
      1.22286,
      0,
      0,
      0,
      0,
      1,
      0,
      -67.14627,
      96.85832,
      0,
      1
    );
    z-index: 100.96858;
  }
  53% {
    transform: matrix3d(
      1.21702,
      0,
      0,
      0,
      0,
      1.21702,
      0,
      0,
      0,
      0,
      1,
      0,
      -83.43459,
      95.10565,
      0,
      1
    );
    z-index: 100.95106;
  }
  54% {
    transform: matrix3d(
      1.20993,
      0,
      0,
      0,
      0,
      1.20993,
      0,
      0,
      0,
      0,
      1,
      0,
      -99.39363,
      92.97765,
      0,
      1
    );
    z-index: 100.92978;
  }
  55% {
    transform: matrix3d(
      1.20161,
      0,
      0,
      0,
      0,
      1.20161,
      0,
      0,
      0,
      0,
      1,
      0,
      -114.96041,
      90.48271,
      0,
      1
    );
    z-index: 100.90483;
  }
  56% {
    transform: matrix3d(
      1.1921,
      0,
      0,
      0,
      0,
      1.1921,
      0,
      0,
      0,
      0,
      1,
      0,
      -130.07349,
      87.63067,
      0,
      1
    );
    z-index: 100.87631;
  }
  57% {
    transform: matrix3d(
      1.18144,
      0,
      0,
      0,
      0,
      1.18144,
      0,
      0,
      0,
      0,
      1,
      0,
      -144.67323,
      84.43279,
      0,
      1
    );
    z-index: 100.84433;
  }
  58% {
    transform: matrix3d(
      1.16967,
      0,
      0,
      0,
      0,
      1.16967,
      0,
      0,
      0,
      0,
      1,
      0,
      -158.70202,
      80.9017,
      0,
      1
    );
    z-index: 100.80902;
  }
  59% {
    transform: matrix3d(
      1.15684,
      0,
      0,
      0,
      0,
      1.15684,
      0,
      0,
      0,
      0,
      1,
      0,
      -172.10448,
      77.05132,
      0,
      1
    );
    z-index: 100.77051;
  }
  60% {
    transform: matrix3d(
      1.14299,
      0,
      0,
      0,
      0,
      1.14299,
      0,
      0,
      0,
      0,
      1,
      0,
      -184.82772,
      72.89686,
      0,
      1
    );
    z-index: 100.72897;
  }
  61% {
    transform: matrix3d(
      1.12818,
      0,
      0,
      0,
      0,
      1.12818,
      0,
      0,
      0,
      0,
      1,
      0,
      -196.82153,
      68.45471,
      0,
      1
    );
    z-index: 100.68455;
  }
  62% {
    transform: matrix3d(
      1.11247,
      0,
      0,
      0,
      0,
      1.11247,
      0,
      0,
      0,
      0,
      1,
      0,
      -208.03858,
      63.7424,
      0,
      1
    );
    z-index: 100.63742;
  }
  63% {
    transform: matrix3d(
      1.09593,
      0,
      0,
      0,
      0,
      1.09593,
      0,
      0,
      0,
      0,
      1,
      0,
      -218.43459,
      58.77853,
      0,
      1
    );
    z-index: 100.58779;
  }
  64% {
    transform: matrix3d(
      1.07861,
      0,
      0,
      0,
      0,
      1.07861,
      0,
      0,
      0,
      0,
      1,
      0,
      -227.96854,
      53.58268,
      0,
      1
    );
    z-index: 100.53583;
  }
  65% {
    transform: matrix3d(
      1.06058,
      0,
      0,
      0,
      0,
      1.06058,
      0,
      0,
      0,
      0,
      1,
      0,
      -236.6028,
      48.17537,
      0,
      1
    );
    z-index: 100.48175;
  }
  66% {
    transform: matrix3d(
      1.04193,
      0,
      0,
      0,
      0,
      1.04193,
      0,
      0,
      0,
      0,
      1,
      0,
      -244.3033,
      42.57793,
      0,
      1
    );
    z-index: 100.42578;
  }
  67% {
    transform: matrix3d(
      1.02271,
      0,
      0,
      0,
      0,
      1.02271,
      0,
      0,
      0,
      0,
      1,
      0,
      -251.03965,
      36.81246,
      0,
      1
    );
    z-index: 100.36812;
  }
  68% {
    transform: matrix3d(
      1.00301,
      0,
      0,
      0,
      0,
      1.00301,
      0,
      0,
      0,
      0,
      1,
      0,
      -256.78526,
      30.9017,
      0,
      1
    );
    z-index: 100.30902;
  }
  69% {
    transform: matrix3d(
      0.9829,
      0,
      0,
      0,
      0,
      0.9829,
      0,
      0,
      0,
      0,
      1,
      0,
      -261.51745,
      24.86899,
      0,
      1
    );
    z-index: 100.24869;
  }
  70% {
    transform: matrix3d(
      0.96246,
      0,
      0,
      0,
      0,
      0.96246,
      0,
      0,
      0,
      0,
      1,
      0,
      -265.21756,
      18.73813,
      0,
      1
    );
    z-index: 100.18738;
  }
  71% {
    transform: matrix3d(
      0.94178,
      0,
      0,
      0,
      0,
      0.94178,
      0,
      0,
      0,
      0,
      1,
      0,
      -267.87097,
      12.53332,
      0,
      1
    );
    z-index: 100.12533;
  }
  72% {
    transform: matrix3d(
      0.92093,
      0,
      0,
      0,
      0,
      0.92093,
      0,
      0,
      0,
      0,
      1,
      0,
      -269.46722,
      6.27905,
      0,
      1
    );
    z-index: 100.06279;
  }
  73% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, -270, 0, 0, 1);
    z-index: 100;
  }
  74% {
    transform: matrix3d(
      0.87907,
      0,
      0,
      0,
      0,
      0.87907,
      0,
      0,
      0,
      0,
      1,
      0,
      -269.46722,
      -6.27905,
      0,
      1
    );
    z-index: 99.93721;
  }
  75% {
    transform: matrix3d(
      0.85822,
      0,
      0,
      0,
      0,
      0.85822,
      0,
      0,
      0,
      0,
      1,
      0,
      -267.87097,
      -12.53332,
      0,
      1
    );
    z-index: 99.87467;
  }
  76% {
    transform: matrix3d(
      0.83754,
      0,
      0,
      0,
      0,
      0.83754,
      0,
      0,
      0,
      0,
      1,
      0,
      -265.21756,
      -18.73813,
      0,
      1
    );
    z-index: 99.81262;
  }
  77% {
    transform: matrix3d(
      0.8171,
      0,
      0,
      0,
      0,
      0.8171,
      0,
      0,
      0,
      0,
      1,
      0,
      -261.51745,
      -24.86899,
      0,
      1
    );
    z-index: 99.75131;
  }
  78% {
    transform: matrix3d(
      0.79699,
      0,
      0,
      0,
      0,
      0.79699,
      0,
      0,
      0,
      0,
      1,
      0,
      -256.78526,
      -30.9017,
      0,
      1
    );
    z-index: 99.69098;
  }
  79% {
    transform: matrix3d(
      0.77729,
      0,
      0,
      0,
      0,
      0.77729,
      0,
      0,
      0,
      0,
      1,
      0,
      -251.03965,
      -36.81246,
      0,
      1
    );
    z-index: 99.63188;
  }
  80% {
    transform: matrix3d(
      0.75807,
      0,
      0,
      0,
      0,
      0.75807,
      0,
      0,
      0,
      0,
      1,
      0,
      -244.3033,
      -42.57793,
      0,
      1
    );
    z-index: 99.57422;
  }
  81% {
    transform: matrix3d(
      0.73942,
      0,
      0,
      0,
      0,
      0.73942,
      0,
      0,
      0,
      0,
      1,
      0,
      -236.6028,
      -48.17537,
      0,
      1
    );
    z-index: 99.51825;
  }
  82% {
    transform: matrix3d(
      0.72139,
      0,
      0,
      0,
      0,
      0.72139,
      0,
      0,
      0,
      0,
      1,
      0,
      -227.96854,
      -53.58268,
      0,
      1
    );
    z-index: 99.46417;
  }
  83% {
    transform: matrix3d(
      0.70407,
      0,
      0,
      0,
      0,
      0.70407,
      0,
      0,
      0,
      0,
      1,
      0,
      -218.43459,
      -58.77853,
      0,
      1
    );
    z-index: 99.41221;
  }
  84% {
    transform: matrix3d(
      0.68753,
      0,
      0,
      0,
      0,
      0.68753,
      0,
      0,
      0,
      0,
      1,
      0,
      -208.03857,
      -63.7424,
      0,
      1
    );
    z-index: 99.36258;
  }
  85% {
    transform: matrix3d(
      0.67182,
      0,
      0,
      0,
      0,
      0.67182,
      0,
      0,
      0,
      0,
      1,
      0,
      -196.82153,
      -68.45471,
      0,
      1
    );
    z-index: 99.31545;
  }
  86% {
    transform: matrix3d(
      0.65701,
      0,
      0,
      0,
      0,
      0.65701,
      0,
      0,
      0,
      0,
      1,
      0,
      -184.82772,
      -72.89686,
      0,
      1
    );
    z-index: 99.27103;
  }
  87% {
    transform: matrix3d(
      0.64316,
      0,
      0,
      0,
      0,
      0.64316,
      0,
      0,
      0,
      0,
      1,
      0,
      -172.10447,
      -77.05132,
      0,
      1
    );
    z-index: 99.22949;
  }
  88% {
    transform: matrix3d(
      0.63033,
      0,
      0,
      0,
      0,
      0.63033,
      0,
      0,
      0,
      0,
      1,
      0,
      -158.70201,
      -80.9017,
      0,
      1
    );
    z-index: 99.19098;
  }
  89% {
    transform: matrix3d(
      0.61856,
      0,
      0,
      0,
      0,
      0.61856,
      0,
      0,
      0,
      0,
      1,
      0,
      -144.67323,
      -84.43279,
      0,
      1
    );
    z-index: 99.15567;
  }
  90% {
    transform: matrix3d(
      0.6079,
      0,
      0,
      0,
      0,
      0.6079,
      0,
      0,
      0,
      0,
      1,
      0,
      -130.07348,
      -87.63067,
      0,
      1
    );
    z-index: 99.12369;
  }
  91% {
    transform: matrix3d(
      0.59839,
      0,
      0,
      0,
      0,
      0.59839,
      0,
      0,
      0,
      0,
      1,
      0,
      -114.96039,
      -90.4827,
      0,
      1
    );
    z-index: 99.09517;
  }
  92% {
    transform: matrix3d(
      0.59007,
      0,
      0,
      0,
      0,
      0.59007,
      0,
      0,
      0,
      0,
      1,
      0,
      -99.39361,
      -92.97765,
      0,
      1
    );
    z-index: 99.07022;
  }
  93% {
    transform: matrix3d(
      0.58298,
      0,
      0,
      0,
      0,
      0.58298,
      0,
      0,
      0,
      0,
      1,
      0,
      -83.43456,
      -95.10565,
      0,
      1
    );
    z-index: 99.04894;
  }
  94% {
    transform: matrix3d(
      0.57714,
      0,
      0,
      0,
      0,
      0.57714,
      0,
      0,
      0,
      0,
      1,
      0,
      -67.14622,
      -96.85831,
      0,
      1
    );
    z-index: 99.03142;
  }
  95% {
    transform: matrix3d(
      0.57257,
      0,
      0,
      0,
      0,
      0.57257,
      0,
      0,
      0,
      0,
      1,
      0,
      -50.59289,
      -98.22872,
      0,
      1
    );
    z-index: 99.01771;
  }
  96% {
    transform: matrix3d(
      0.5693,
      0,
      0,
      0,
      0,
      0.5693,
      0,
      0,
      0,
      0,
      1,
      0,
      -33.83989,
      -99.21146,
      0,
      1
    );
    z-index: 99.00789;
  }
  97% {
    transform: matrix3d(
      0.56732,
      0,
      0,
      0,
      0,
      0.56732,
      0,
      0,
      0,
      0,
      1,
      0,
      -16.95333,
      -99.80266,
      0,
      1
    );
    z-index: 99.00197;
  }
  98% {
    transform: matrix3d(
      0.56667,
      0,
      0,
      0,
      0,
      0.56667,
      0,
      0,
      0,
      0,
      1,
      0,
      0.00015,
      -99.99999,
      0,
      1
    );
    z-index: 99;
  }
  99% {
    transform: matrix3d(
      0.56732,
      0,
      0,
      0,
      0,
      0.56732,
      0,
      0,
      0,
      0,
      1,
      0,
      16.95364,
      -99.80266,
      0,
      1
    );
    z-index: 99.00197;
  }
  100% {
    transform: matrix3d(
      0.5693,
      0,
      0,
      0,
      0,
      0.5693,
      0,
      0,
      0,
      0,
      1,
      0,
      33.84024,
      -99.21145,
      0,
      1
    );
    z-index: 99.00789;
  }
}
</style>
