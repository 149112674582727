var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Breadcrumbs',{attrs:{"main":"Project","title":_vm.$t('Platform Agreement')}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row",style:(_vm.isPCShow ? '' : 'margin-left:0px')},[_c('div',{staticClass:"col-md-12 project-list"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-sm-12 p-0"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row",style:(_vm.isPCShow
                        ? { width: '100%', position: 'relative' }
                        : {
                            width: '100%',
                            position: 'relative',
                            'margin-left': '0px',
                          })},[_c('div',{domProps:{"innerHTML":_vm._s(
                        _vm.$t(
                          'Offshore System Service Platform Agreement Content'
                        )
                      )}})])])])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }