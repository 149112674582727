<template>
  <div id="app">
    <!-- Loader starts-->
    <div class="loader-wrapper" :class="{ loderhide: !show }">
      <div class="loader-index">
        <span></span>
      </div>
    </div>
    <!-- Loader ends-->

    <!--<Main/>-->
    <router-view></router-view>

    <div
      v-if="ipShow"
      id="item-modal"
      :style="
        isPCShow
          ? 'height: 100vh;width: 100vw;min-width:500px'
          : 'height: 100vh;width: 100vw;'
      "
    >
      <div
        class="bigBox"
        :style="
          isPCShow
            ? {
                position: 'absolute',
                width: '50vw',
                height: '65vh',
                left: '25vw',
                top: '5vh',
                'min-width': '500px',
                'min-height': '450px',
              }
            : {
                position: 'absolute',
                top: '12vh',
                left: '5vw',
              }
        "
      >
        <img
          :src="getImgUrl('noticBG.png')"
          :style="
            isPCShow
              ? {
                  height: '96%',
                  width: '80%',
                  position: 'absolute',
                  top: '5vh',
                  left: '5vw',
                  'min-width': '500px',
                  'min-height': '480px',
                }
              : {
                  height: '23rem',
                  width: '90vw',
                  position: 'absolute',
                  top: '0',
                }
          "
          alt=""
        />
        <div
          class="modal"
          :style="
            isPCShow
              ? {
                  width: '80%',
                  'margin-left': '10%',
                  'margin-top': '50px',
                }
              : {
                  width: '90vw',
                  height: '400px',
                  overflow: 'auto',
                  'flex- direction': 'column',
                  'justify-content': 'flex-start',
                  top: '90px',
                  padding: '0px 38px 34px',
                }
          "
        >
          <div
            class="to-users"
            :style="
              isPCShow
                ? 'font-weight: bold'
                : 'font-weight: bold;font-size:13px;    margin-bottom: 6px;'
            "
          >
            <span class="user-title" :style="isPCShow ? '' : 'font-size:13px'"
              >尊敬的用户：</span
            >
            <br />
            <div
              class="content-en"
              :style="isPCShow ? '' : 'font-size:11px;margin-bottom: 10px;'"
            >
              本系统现已限制中国大陆地区网络访问，请遵守当地法律法规，
              感谢您的理解！如果您有任何其他问题，请联系：
              <a target="_blank" href="https://t.me/haiwangAppBot"
                >https://t.me/haiwangAppBot</a
              >
            </div>
            <div
              class="from-us"
              :style="isPCShow ? '' : 'font-size:11px;line-height: 1;'"
            >
              新加坡 海王出海 科技
            </div>
          </div>
          <div
            class="content-en"
            id="content-En"
            :style="isPCShow ? '' : 'font-size:11px;margin-bottom: 10px;'"
          >
            <span class="user-title" :style="isPCShow ? '' : 'font-size:13px'"
              >Dear users:</span
            >
            <br />
            <div
              class="content-en"
              :style="isPCShow ? '' : 'font-size:11px;margin-bottom: 10px;'"
            >
              The system access to IPs in mainland China is now restricted.
              Thank you for your understanding!If you have any other questions,
              please contact:
              <a target="_blank" href="https://t.me/haiwangAppBot"
                >https://t.me/haiwangAppBot</a
              >
            </div>
            <div
              class="from-us"
              :style="isPCShow ? '' : 'font-size:11px;line-height: 1;'"
            >
              HaiWanG Technology PTE. LTD.
            </div>
          </div>
          <div
            class="your-ip"
            :style="isPCShow ? '' : 'font-size:11px;margin-top:10px;'"
          >
            <span data-translate="your_ip">
              • 您的IP：{{ ip }}
              <span id="IP-From"></span>
              • 您的国家: CN •</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { getMyIp } from "../public/iparea.js";
  import axios from 'axios';
  let t;
  export default {
    name: 'app',
    data() {
      return {
        show: true,
        ipShow: false,
        ip: '',
        country_code: '',
        isPCShow: true,
        windowWidth: window.innerWidth,
        whiteIplist:''
      };
    },
    mounted() {
      t = this;
      this.getWhiteIp();
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      this.timeOut();

    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      async getWhiteIp(){
        await axios.get(this.config.domain + '/web/whiteIp', {
          timeout: 6000, // 设置超时时间为6秒
        }).then((res) => {
          if (res.status===200){
            this.whiteIplist = res.data.data.whiteIp;
          }
        }).catch(err=>{
          console.error(err);
        });
        this.getIp();
      },
      handleResize() {
        this.windowWidth = window.innerWidth;
        // const windowWidth = window.innerWidth;
        this.isPCShow = this.windowWidth >= 768;
      },
      listenF9Key(){
        document.addEventListener('keydown', (e) => {
          if (e.key === 'F9') {
            t.ipShow = false;
            document.removeEventListener('keydown');
          }
        });
      },
      getIp() {
        //console.log(this.whiteIplist);
        axios.get('https://api.ip.sb/geoip/', {
          timeout: 6000, // 设置超时时间为6秒
        }).then((res) => {
          if (res.status == 200) {
            this.ip = res.data.ip;
            this.country_code = res.data.country_code;
            localStorage.setItem('ip', res.data.ip);
            localStorage.setItem('countryCode', res.data.country_code);
            if (res.data.country_code == 'CN' && this.whiteIplist.indexOf(res.data.ip) === -1) {
              this.ipShow = true;
              this.listenF9Key();
            }
          }
        }).catch((err) => {
          axios
            .get('https://get.geojs.io/v1/ip/country.json', {
              timeout: 6000, // 设置超时时间为6秒
            }).then((res1) => {
              if (res1.status == 200) {
                this.ip = res1.data.ip;
                this.country_code = res1.data.country;
                localStorage.setItem('ip', res1.data.ip);
                localStorage.setItem('countryCode', res1.data.country_code);
                if (this.country_code == 'CN' && this.whiteIplist.indexOf(res.data.ip) === -1) {
                  this.ipShow = true;
                  this.listenF9Key();
                }
              } else {
                this.ipShow = false;
              }
            }).catch((secondErr) => {
              this.ipShow = false;
            });
        });
      },
      getImgUrl(path) {
        return require('@/assets/images/' + path);
      },
      timeOut() {
        var self = this;
        setTimeout(function () {
          self.show = false;
        }, 2500);
      },
    },
  };
</script>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}

#item-modal.show {
  height: 100vh;
}
#item-modal.hidden {
  display: none;
}
#item-modal-mobile.show {
  display: flex;
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
}
#item-modal-mobile.hidden {
  display: none;
}
/* top: calc(50% - 220px); */
#item-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  z-index: 10001;
  background: rgba(0, 0, 0, 0.7);

  z-index: 1055;
  border-radius: 0.5em;
} /* top: calc(50% - 220px); */
#item-modal-mobile {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  z-index: 10001;

  z-index: 1055;
  border-radius: 0.5em;
}
#item-modal .bigBox {
  position: relative;
  top: -36vh;
  left: -19vw;
}
.bigBox .modal {
  height: 55vh;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 10px;
  transition: top 0.2s ease;
  width: 550px;
  padding: 54px 38px;
  position: absolute;
  top: 80px;
  left: 0px;
}
.bigBox .modal .to-users {
  color: rgba(0, 0, 0, 0.88);
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
  mix-blend-mode: normal;
}
.bigBox .modal .to-users .content-en {
  font-size: 14px;
  color: grey;
  font-weight: 600;
}
.content-en a {
  color: #597ae6;
  font-size: 12px;
  font-weight: 700;
}
.bigBox .modal .content-en {
  color: rgba(0, 0, 0, 0.56);
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
}
.bigBox .modal .from-us1 {
  color: rgba(0, 0, 0, 0.68);
  text-align: right !important;
  width: 100%;
  color: #131313;
  transform: skewX(-10deg);
  letter-spacing: 2px;
  -webkit-box-reflect: below -1px linear-gradient(transparent 30%, rgba(0, 0, 0, 0.35));
}

.from-us {
  font-size: 14px;
  line-height: 28px;
  color: grey;
  text-align: right;
}
.bigBox .modal .your-ip {
  margin-top: 50px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
}
.user-title {
  font-weight: 900 !important;
  color: #131313;
  font-size: 16px;
}
</style>
