<template>
  <div v-loading="loading">
    <Breadcrumbs main="Project" :title="$t('Download software')" />
    <!-- Container-fluid starts-->
    <!-- class="container-fluid" -->
    <div :class="isPCShow ? 'container-fluid' : 'container-fluid-mobile'">
      <div :class="isPCShow ? 'row' : 'row-mobile'">
        <div
          :class="
            isPCShow
              ? 'col-md-12 project-list'
              : 'project-list-mobile  project-list'
          "
        >
          <div class="card">
            <div>
              <!-- class="row m-0" -->
              <div class="col-sm-12 p-0" id="downloadTabName">
                <b-tabs
                  content-class="mt-3"
                  active-nav-item-class="font-weight-bold text-uppercase text-danger"
                  active-tab-class="font-weight-bold text-success"
                >
                  <b-tab active>
                    <template v-slot:title>
                      <feather type="target"></feather>
                      Windows
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div
                              class="row"
                              style="
                                width: 100%;
                                position: relative;
                                margin-left: 0;
                              "
                            >
                              <div
                                :class="
                                  isPCShow
                                    ? 'downloadImgBack'
                                    : 'downloadImgBackMobile'
                                "
                              >
                                <div class="downloadImg">
                                  <img src="../../assets/images/down.png" />
                                </div>
                                <div class="downloadTips">
                                  <div class="title">
                                    {{ downloadData.name }} For Windows
                                  </div>
                                  <div
                                    class="tip"
                                    v-html="downloadData.content"
                                  ></div>
                                  <div class="down">
                                    <el-button
                                      type=""
                                      @click="
                                        openUrl(
                                          downloadData.windows_version_exe
                                        )
                                      "
                                    >
                                      {{ downloadData.windows_download_name }}
                                    </el-button>
                                  </div>
                                  <div class="version">
                                    {{ downloadData.windows_version }} ({{
                                      downloadData.windows_updated
                                    }})
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <feather type="info"></feather>
                      MacOs
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div
                              class="row"
                              style="width: 100%; position: relative"
                            >
                              <div
                                :class="
                                  isPCShow
                                    ? 'downloadImgBack'
                                    : 'downloadImgBackMobile'
                                "
                              >
                                <div class="downloadImg">
                                  <img src="../../assets/images/down.png" />
                                </div>
                                <div class="downloadTips">
                                  <div class="title">
                                    {{ downloadData.name }} For Mac
                                  </div>
                                  <div
                                    class="tip"
                                    v-html="downloadData.content"
                                  ></div>
                                  <div class="down" style="display: flex">
                                    <el-button
                                      style="flex: 1"
                                      @click="
                                        openUrl(downloadData.mac_version_exe)
                                      "
                                    >
                                      {{ downloadData.mac_download_nmae }}
                                    </el-button>
                                    <el-button
                                      style="flex: 1"
                                      @click="
                                        openUrl(
                                          downloadData.mac_amr_version_exe || ''
                                        )
                                      "
                                    >
                                      {{
                                        downloadData.mac_amr_download_nmae ||
                                        "amr"
                                      }}
                                    </el-button>
                                  </div>
                                  <div class="version">
                                    {{ downloadData.mac_version }} ({{
                                      downloadData.mac_updated
                                    }})
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  let t;
  export default {
    data() {
      return {
        isPCShow: true,
        windowWidth: window.innerWidth,
        downloadData: {
          name: '', //软件名称
          mac_version: '', //mac版本号
          mac_version_exe: '', //mac下载地址
          mac_version_desc: '', //mac更新说明
          mac_download_nmae: '', //mac下载名称
          mac_updated: '', //mac更新日期
          windows_version: '', //windows版本号
          windows_version_exe: '', //windows下载地址
          windows_version_desc: '', //windows更新说明
          windows_download_name: '', //windows下载名称
          windows_updated: '', //windows更新日期
          content: '', //软件说明
        },
        loading: true,
      };
    },
    computed: {
    ...mapState({
      allprojects: (state) => state.common.allprojects,
      doingprojects: (state) => state.common.doingprojects,
      doneprojects: (state) => state.common.doneprojects,
    }),
    },
    mounted() {
      this.$el.querySelector('.nav-tabs').style.justifyContent = 'center';
      t = this;
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      t.getDowninfo();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        t.windowWidth = window.innerWidth;
        // const windowWidth = window.innerWidth;
        t.isPCShow = t.windowWidth >= 768;
      },
      getImgUrl(path) {
        return require('@/assets/images/' + path);
      },
      openUrl(url = '') {
        window.open(url);
      },
      getDowninfo() {
        t.request
          .post('/downloadInfo')
          .then((res) => {
            t.downloadData = res.data;
            t.loading = false;
          })
          .catch((res) => {
            t.loading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
    },
  };
</script>
<style scoped lang="scss">
#downloadTabName .tabs .nav .nav-tabs {
  display: flex !important;
  justify-content: center;
}
.row-mobile {
  width: 92%;
  position: absolute;
  right: 4% !important;
}
@media only screen and (max-width: 998px) {
  .row-mobile {
    width: 92%;
    position: absolute;
    right: 4% !important;
  }
  #downloadTabName .tabs .nav .nav-tabs ul {
    display: flex !important;
    justify-content: center;
  }
  .project-list .tabs ul.nav-tabs {
    display: flex !important;
    justify-content: center;
  }
}
@media only screen and (max-width: 376px) {
  .row-mobile {
    width: 92%;
    position: absolute;
    right: 4% !important;
  }
}
/* .page-wrapper {
  overflow: unset !important;
} */
.container-fluid-mobile {
  width: 100%;
  padding-left: 0px;
  position: relative;
}
.downloadImgBack {
  width: 100%;
  margin-top: 5%;
  display: flex;
  .downloadImg {
    flex: 1.5;

    > img {
      object-fit: scale-down;
      width: 100%;
      height: 100%;
    }
  }

  .downloadTips {
    flex: 0.5;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .title {
      font-size: 1.5em;
      color: #000;
      padding-bottom: 8px;
      font-weight: 500;
      font-family: PingFangSC-Medium, sans-serif;
    }

    .tip {
      font-size: 1em;
      color: #999;
    }

    .down {
      margin-top: 20px;

      > button {
        padding: 0 35px;
        height: 40px;
        font-size: 16px;
        border-radius: 40px;
      }
    }

    .version {
      margin-top: 5px;
      display: inline-block;
      vertical-align: middle;
      color: #000;
      opacity: 0.5;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
    }
  }
}
.downloadImgBackMobile {
  width: 100%;
  margin-top: 5%;

  .downloadImg {
    flex: 1.5;

    > img {
      object-fit: scale-down;
      width: 100%;
      height: 100%;
    }
  }

  .downloadTips {
    flex: 0.5;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .title {
      font-size: 1.5em;
      color: #000;
      padding-bottom: 8px;
      font-weight: 500;
      font-family: PingFangSC-Medium, sans-serif;
    }

    .tip {
      font-size: 1em;
      color: #999;
    }

    .down {
      margin-top: 20px;

      > button {
        padding: 0 25px;
        height: 40px;
        font-size: 16px;
        border-radius: 40px;
      }
    }

    .version {
      margin-top: 5px;
      display: inline-block;
      vertical-align: middle;
      color: #000;
      opacity: 0.5;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
    }
  }
}
</style>
