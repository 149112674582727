<template>
  <div v-loading="listLoading">
    <Breadcrumbs :title="$t('Buy')" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row" v-if="!checkMobile()">
        <div class="col-sm-12">
          <px-card
            :title="$t('Port') + $t('type')"
            :show-button="true"
            @buttonClick2="customPorts"
            :button-title2="$t('Purchase ports')"
          >
            <div slot="with-padding">
              <div
                class="row"
                :style="{ flexDirection: !checkMobile() ? 'row' : 'column' }"
              >
                <div
                  class="col-xl-4 col-sm-6"
                  v-for="(item, index) in list"
                  :key="index"
                  v-if="item.buylist_type === 0"
                >
                  <div class="pricingtable">
                    <div class="pricingtable-header">
                      <h3 class="title">{{ item.buylist_title }}</h3>
                    </div>
                    <div class="price-value">
                      <span class="currency">$</span>
                      <span class="amount" style="font-size: 35px">{{
                        item.buylist_price
                      }}</span>
                      <span class="duration">/{{ $t("Month") }}</span>
                      <el-tag
                        effect="plain"
                        class="discount"
                        type="danger"
                        v-if="item.buylist_discount > 0"
                      >
                        {{ item.buylist_discount }} 折
                      </el-tag>
                    </div>
                    <ul class="pricing-content">
                      <li
                        v-show="lang === 'zh'"
                        v-for="(cnitem, cnindex) in item.buylist_cnremark
                          ? item.buylist_cnremark.split(',')
                          : []"
                        :key="'d' + cnindex"
                      >
                        {{ cnitem }}
                      </li>
                      <li
                        v-show="lang === 'en'"
                        v-for="(cnitem, cnindex) in item.buylist_enremark
                          ? item.buylist_enremark.split(',')
                          : []"
                        :key="'a' + cnindex"
                      >
                        {{ cnitem }}
                      </li>
                    </ul>
                    <div class="pricingtable-signup">
                      <a
                        class="btn btn-primary btn-lg"
                        href="#"
                        @click="handleBuy(item, 0)"
                        >{{ $t("Buy") }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </px-card>
          <px-card :title="$t('Words') + $t('type')" :actions="false">
            <div slot="with-padding">
              <div
                class="row pricing-content"
                :style="{ flexDirection: !checkMobile() ? 'row' : 'column' }"
              >
                <div
                  class="col-xl-3 col-sm-6 xl-50 box-col-6"
                  v-for="(item, index) in list"
                  :key="index"
                  v-if="item.buylist_type === 1"
                >
                  <div class="card text-center pricing-simple">
                    <div class="card-body">
                      <h4>{{ item.buylist_title }}</h4>
                      <h3>${{ item.buylist_price }}</h3>
                      <h6 class="mb-0" v-show="lang === 'zh'">
                        {{ item.buylist_cnremark }}
                      </h6>
                      <h6 class="mb-0" v-show="lang === 'en'">
                        {{ item.buylist_enremark }}
                      </h6>
                    </div>
                    <a
                      class="btn btn-lg btn-primary btn-block"
                      href="#"
                      @click="handleBuy(item, 1)"
                    >
                      <h5 class="mb-0">{{ $t("Buy") }}</h5></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </px-card>
        </div>
      </div>
      <div
        v-if="checkMobile()"
        class="container-fluid"
        style="
          display: flex;
          justify-content: center !important;
          margin-right: -20px;
        "
      >
        <div class="row1">
          <div
            class="col-sm-6 col-xl-12 col-lg-6"
            style="padding-left: 0; padding-right: 0"
          >
            <px-card
              v-if="false"
              :title="$t('Port') + $t('type')"
              :show-button="true"
              @buttonClick2="customPorts"
              :button-title2="$t('Purchase ports')"
            >
              <div slot="with-padding">
                <div
                  class="row"
                  id="pringRowMobile"
                  style="
                    margin-left: 0px !important;
                    margin-right: 0px !important;
                    display: flex;
                    justify-content: space-evenly;
                  "
                >
                  <!-- :style="{ flexDirection: !checkMobile() ? 'row' : 'column' }" -->
                  <div
                    class="duankou"
                    style="width: 49%"
                    v-for="(item, index) in list"
                    :key="index"
                    v-if="item.buylist_type === 0"
                  >
                    <div
                      class="pricingtable"
                      style="padding: 30px 2px 70px; margin: 5px"
                    >
                      <div class="pricingtable-header">
                        <h3 class="title" style="font-size: 20px">
                          {{ item.buylist_title }}
                        </h3>
                      </div>
                      <div
                        class="price-value"
                        style="height: 100px; width: 100px; padding: 30px 0 0"
                      >
                        <span class="currency">$</span>
                        <span class="amount" style="font-size: 35px">{{
                          item.buylist_price
                        }}</span>
                        <span class="duration">/{{ $t("Month") }}</span>
                        <el-tag
                          effect="plain"
                          class="discount"
                          type="danger"
                          v-if="item.buylist_discount > 0"
                        >
                          {{ item.buylist_discount }} 折
                        </el-tag>
                      </div>
                      <ul class="pricing-content">
                        <li
                          v-show="lang === 'zh'"
                          v-for="(cnitem, cnindex) in item.buylist_cnremark
                            ? item.buylist_cnremark.split(',')
                            : []"
                          :key="'d' + cnindex"
                        >
                          {{ cnitem }}
                        </li>
                        <li
                          v-show="lang === 'en'"
                          v-for="(cnitem, cnindex) in item.buylist_enremark
                            ? item.buylist_enremark.split(',')
                            : []"
                          :key="'a' + cnindex"
                        >
                          {{ cnitem }}
                        </li>
                      </ul>
                      <div class="pricingtable-signup">
                        <a
                          class="btn btn-primary btn-lg"
                          href="#"
                          @click="handleBuy(item, 0)"
                          >{{ $t("Buy") }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </px-card>
            <el-card
              style="margin-bottom: 10px; border-radius: 10px"
              body-style="padding: 20px 0;"
            >
              <div slot="header" class="clearfix">
                <h5 style="display: inline">{{ $t("Port") + $t("type") }}</h5>
                <el-button
                  @click="customPorts"
                  style="float: right; padding: 10px"
                  type="primary"
                  >{{ $t("Purchase ports") }}</el-button
                >
              </div>
              <!-- <div slot="with-padding"> -->
              <div
                class="row"
                id="pringRowMobile"
                style="
                  margin-left: 0px !important;
                  margin-right: 0px !important;
                  display: flex;
                  justify-content: space-evenly;
                "
              >
                <!-- :style="{ flexDirection: !checkMobile() ? 'row' : 'column' }" -->
                <div
                  class="duankou"
                  style="width: 49%"
                  v-for="(item, index) in list"
                  :key="index"
                  v-if="item.buylist_type === 0"
                >
                  <div
                    class="pricingtable"
                    style="padding: 30px 2px 70px; margin: 5px"
                  >
                    <div class="pricingtable-header">
                      <h3 class="title" style="font-size: 20px">
                        {{ item.buylist_title }}
                      </h3>
                    </div>
                    <div
                      class="price-value"
                      style="height: 115px; width: 115px; padding: 30px 0 0"
                    >
                      <span class="currency">$</span>
                      <span class="amount" style="font-size: 34px">{{
                        item.buylist_price
                      }}</span>
                      <span class="duration">/{{ $t("Month") }}</span>
                      <el-tag
                        effect="plain"
                        class="discount"
                        type="danger"
                        v-if="item.buylist_discount > 0"
                      >
                        {{ item.buylist_discount }} 折
                      </el-tag>
                    </div>
                    <ul class="pricing-content">
                      <li
                        v-show="lang === 'zh'"
                        v-for="(cnitem, cnindex) in item.buylist_cnremark
                          ? item.buylist_cnremark.split(',')
                          : []"
                        :key="'d' + cnindex"
                      >
                        {{ cnitem }}
                      </li>
                      <li
                        v-show="lang === 'en'"
                        v-for="(cnitem, cnindex) in item.buylist_enremark
                          ? item.buylist_enremark.split(',')
                          : []"
                        :key="'a' + cnindex"
                      >
                        {{ cnitem }}
                      </li>
                    </ul>
                    <div class="pricingtable-signup">
                      <a
                        class="btn btn-primary btn-lg"
                        href="#"
                        @click="handleBuy(item, 0)"
                        >{{ $t("Buy") }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->
            </el-card>

            <div>
              <px-card :title="$t('Words') + $t('type')" :actions="false">
                <div slot="with-padding">
                  <div
                    class="row pricing-content"
                    :style="{
                      flexDirection: !checkMobile() ? 'row' : 'column',
                    }"
                  >
                    <div
                      class="col-xl-3 col-sm-6 xl-50 box-col-6"
                      v-for="(item, index) in list"
                      :key="index"
                      v-if="item.buylist_type === 1"
                    >
                      <div class="card text-center pricing-simple">
                        <div class="card-body">
                          <h4>{{ item.buylist_title }}</h4>
                          <h3>${{ item.buylist_price }}</h3>
                          <h6 class="mb-0" v-show="lang === 'zh'">
                            {{ item.buylist_cnremark }}
                          </h6>
                          <h6 class="mb-0" v-show="lang === 'en'">
                            {{ item.buylist_enremark }}
                          </h6>
                        </div>
                        <a
                          class="btn btn-lg btn-primary btn-block"
                          href="#"
                          @click="handleBuy(item, 1)"
                        >
                          <h5 class="mb-0">{{ $t("Buy") }}</h5></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </px-card>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="showCustomPorts"
      :append-to-body="true"
      :destroy-on-close="true"
      :title="$t('Purchase ports')"
      style="margin-top: -8vh"
      width="70%"
    >
      <template v-slot:title>
        <span style="font-weight: bold;font-size: 16px;">{{$t('Purchase ports')}}</span>
        <span style="margin-left: 20px;">
          <el-checkbox :true-label="1" :false-label="0" v-model="auto_renewal" @change="changeRenewal">到期自动续费</el-checkbox>
          <el-tooltip content="当时间剩余3天时，则进行自动续费，要保证你的余额充足"><i style="margin-left: 5px;" class="el-icon-info"></i></el-tooltip>
        </span>
      </template>
      <div>
        <custom-ports :custom-port="customPort"></custom-ports>
      </div>
    </el-dialog>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
  import CustomPorts from '@/pages/sharecode/custom_ports/custom_ports.vue';

  let t;
  export default {
    components: { CustomPorts },
    data() {
      return {
        isPCShow: true,
        windowWidth: window.innerWidth,
        downloadLoading: false,
        showCustomPorts: false,
        customPort: 0,
        showColums: {},
        listQuery: {
          page: 1,
          limit: 20,
          sort: '',
        },
        lang: 'zh',
        option: {
          showAddBt: false,
          showCatchEditBt: false,
          showExportBt: false,
          showSearchBt: true,
        },
        buylisturlsys: [],
        listLoading: true,
        pageSizelist: [],
        auto_renewal:0,
        listSearchServer: [],
        listModelServer: [],
        filter: null,
        list: [],
        total: 0,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15],
        tableKey: 0,
        selectMode: 'multi',
        selected: [],
        listdata: [],
        temp: {},
        dialogStatus: '',
        summar: {
          switch: false,
          data: {},
        },
        titleModelList: [],
      };
    },
    computed: {},
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
      // Set the initial number of items
      t = this;
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      t.getList();

      t.lang = (localStorage.getItem('currentLanguage') && localStorage.getItem('currentLanguage').toLowerCase()) || 'zh';
    
    },
    methods: {
      handleResize() {
        t.windowWidth = window.innerWidth;
        // const windowWidth = window.innerWidth;
        t.isPCShow = t.windowWidth >= 768;
      },
      checkMobile() {
        return navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
      },
      customPorts() {
        this.showCustomPorts = true;
      },
      changeRenewal(){
        t.request
          .post('/buylist/renewal',{ auto_renewal:this.auto_renewal })
          .then((res) => {
            this.auto_renewal = res.data.auto_renewal;
            t.$toasted.show('Success', {
              theme: 'bubble',
              position: 'top-center',
              type: 'success',
              duration: 10 * 1000,
            });
          }).catch(err=>{
            console.error(err);
          });
      },
      renew() {
        // console.log(r);
        t.request
          .post('/buylog/renew')
          .then((res) => {
            const data = res.data.items;
            let notify = '';
            if (data.buylog_last_day > 0) {
              notify = `${this.$t(
                'Due to the maturity of your previous purchase, surplus'
              )}${data.buylog_last_day}${this.$t('God, you still have to pay')}${
                data.buylog_payprice
              }${this.$t('Is the payment confirmed?')}`;
            } else {
              notify = `${this.$t('You have to pay')}${
                data.buylog_payprice
              }${this.$t('Is the payment confirmed?')}`;
            }
            this.$confirm(notify, this.$t('warning'), {
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('Cancel'),
              type: 'warning',
            })
              .then(async () => {
                t.request
                  .post('/buylog/buy', data)
                  .then((res) => {
                    t.$toasted.show('Success', {
                      theme: 'bubble',
                      position: 'top-center',
                      type: 'success',
                      duration: 10 * 1000,
                    });
                    window.location.reload();
                  })
                  .catch((res) => {
                    if (res.errMsg) {
                      t.$toasted.show(res.errMsg, {
                        theme: 'bubble',
                        position: 'top-center',
                        type: 'error',
                        duration: 10 * 1000,
                      });
                    } else {
                      t.$toasted.show(t.$t('Server Error'), {
                        theme: 'bubble',
                        position: 'top-center',
                        type: 'error',
                        duration: 10 * 1000,
                      });
                    }
                  });
              })
              .catch((err) => {
                console.error(err);
              });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            }
          });
      },
      getSummar(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            // 只找第一列放合计
            sums[index] = `${t.$t('combined')}`;
            return;
          }
          if (t.summar.data[column.property]) {
            // 判断当前prop绑定的ID
            const values = data.map((item) => item[column.property]); // 把对应一列中的之全部取出，放到一个数组中
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr); // 将values中的每个值转换为number类型
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          }
        });
        return sums;
      },
      handleBuy(item, index) {
        if (index === 1) {
          return this.buy(item);
        }
        this.showCustomPorts = true;

        this.$nextTick(() => {
          this.customPort = item.buylist_num;
        });
      /* setTimeout(()=>{
          this.$confirm('请选择类型', '提示', {
            confirmButtonText: '购买/升级',
            cancelButtonText: '增加端口',
            distinguishCancelAndClose: true,
            type: 'info',
            center: true,
            /!*callback:(res,instance)=>{
              console.log(res,instance);
            }*!/
          }).then(() => {
            this.buy(item);
          }).catch((e)=> {
            if (e === 'cancel'){
              this.augment(item);
            }

          });
        },100);*/
      },
      augment(item) {
        t.request
          .post('/buylog/augment', item)
          .then((res) => {
            const data = res.data.items;
            let notify = '';
            if (data.buylog_last_day > 0) {
              notify = `${this.$t(
                'Due to the maturity of your previous purchase, surplus'
              )}${data.buylog_last_day}${this.$t('God, you still have to pay')}${
                data.buylog_payprice
              }${this.$t('Is the payment confirmed?')}`;
            } else {
              notify = `${this.$t('You have to pay')}${
                data.buylog_payprice
              }${this.$t('Is the payment confirmed?')}`;
            }
            this.$confirm(notify, this.$t('warning'), {
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('Cancel'),
              type: 'warning',
            })
              .then(async () => {
                t.request
                  .post('/buylog/buy', data)
                  .then((res) => {
                    t.$toasted.show('Success', {
                      theme: 'bubble',
                      position: 'top-center',
                      type: 'success',
                      duration: 10 * 1000,
                    });
                    window.location.reload();
                  })
                  .catch((res) => {
                    if (res.errMsg) {
                      t.$toasted.show(res.errMsg, {
                        theme: 'bubble',
                        position: 'top-center',
                        type: 'error',
                        duration: 10 * 1000,
                      });
                    } else {
                      t.$toasted.show(t.$t('Server Error'), {
                        theme: 'bubble',
                        position: 'top-center',
                        type: 'error',
                        duration: 10 * 1000,
                      });
                    }
                  });
              })
              .catch((err) => {
                console.error(err);
              });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            }
          });
      },
      buy(item) {
        t.request
          .post('/buylog/add', item)
          .then((res) => {
            const data = res.data.items;
            let notify = '';
            if (data.buylog_last_day > 0) {
              notify = `${this.$t(
                'Due to the maturity of your previous purchase, surplus'
              )}${data.buylog_last_day}${this.$t('God, you still have to pay')}${
                data.buylog_payprice
              }${this.$t('Is the payment confirmed?')}`;
            } else {
              notify = `${this.$t('You have to pay')}${
                data.buylog_payprice
              }${this.$t('Is the payment confirmed?')}`;
            }
            this.$confirm(notify, this.$t('warning'), {
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('Cancel'),
              type: 'warning',
            })
              .then(async () => {
                t.request
                  .post('/buylog/buy', data)
                  .then((res) => {
                    t.$toasted.show('Success', {
                      theme: 'bubble',
                      position: 'top-center',
                      type: 'success',
                      duration: 10 * 1000,
                    });
                    window.location.reload();
                  })
                  .catch((res) => {
                    if (res.errMsg) {
                      t.$toasted.show(res.errMsg, {
                        theme: 'bubble',
                        position: 'top-center',
                        type: 'error',
                        duration: 10 * 1000,
                      });
                    } else {
                      t.$toasted.show(t.$t('Server Error'), {
                        theme: 'bubble',
                        position: 'top-center',
                        type: 'error',
                        duration: 10 * 1000,
                      });
                    }
                  });
              })
              .catch((err) => {
                console.error(err);
              });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            }
          });
      },
      handleDownload() {
        this.downloadLoading = true;
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = ['timestamp', 'title', 'type', 'importance', 'status'];
        const filterVal = [
          'timestamp',
          'title',
          'type',
          'importance',
          'status',
        ];
        const data = this.formatJson(filterVal);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'table-list',
        });
        this.downloadLoading = false;
      });
      },
      formatJson(filterVal) {
        return this.list.map((v) =>
          filterVal.map((j) => {
            if (j === 'timestamp') {
              return parseTime(v[j]);
            } else {
              return v[j];
            }
          })
        );
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleUpdate(row) {
        this.temp = Object.assign({}, row); // copy obj
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in this.temp) {
          try {
            if (typeof JSON.parse(this.temp[tempKey]) === 'object') {
              this.temp[tempKey] = JSON.parse(this.temp[tempKey]);
            }
          // eslint-disable-next-line no-empty
          } catch (e) {}
        }
        this.temp.password = '';
        this.temp.walletpassword = '';
        this.dialogStatus = 'update';
        t.$bvModal.show('tag-modal-example');
      },
      createData() {
        const tempData = Object.assign({}, this.temp);
        tempData['buylist_num'] = Number(tempData['buylist_num']);
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in tempData) {
          try {
            for (const modelDatum of this.listModelServer) {
              if (
                modelDatum.key === tempKey &&
                (modelDatum.edittype === 'number' || modelDatum.edittype.indexOf('Number') !== -1)
              ) {
                tempData[tempKey] = Number(tempData[tempKey]);
                break;
              }
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
          // eslint-disable-next-line no-empty
          } catch (e) {}
        }
        t.request
          .post('/buylist/add', tempData)
          .then(() => {
            this.getList();
            t.$bvModal.hide('tag-modal-example');
            this.$notify({
              title: '完成',
              message: '添加成功',
              type: 'success',
              duration: 2000,
            });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            }
          });
      },
      handleCreate() {
        this.resetTemp();
        this.dialogStatus = 'create';
        t.$bvModal.show('tag-modal-example');
      },
      resetTemp() {
        const jsonTemp = {};
        // eslint-disable-next-line no-unused-vars
        for (const listModelServerKey in this.listModelServer) {
          switch (this.listModelServer[listModelServerKey]['edittype']) {
          case 'checkbox':
            jsonTemp[this.listModelServer[listModelServerKey]['key']] = [];
            break;
          default:
            jsonTemp[this.listModelServer[listModelServerKey]['key']] = '';
            break;
          }
        }
        this.temp = jsonTemp;
      },
      updateData(row, key, value) {
        if (row) {
          row[key] = value;
          this.temp = row;
        }
        const tempData = Object.assign({}, this.temp);

        // eslint-disable-next-line no-unused-vars
        for (const tempKey in tempData) {
          try {
            for (const modelDatum of this.listModelServer) {
              if (
                modelDatum.key === tempKey &&
                (modelDatum.edittype === 'number' || modelDatum.edittype.indexOf('Number') !== -1)
              ) {
                tempData[tempKey] = Number(tempData[tempKey]);
                break;
              }
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
          } catch (e) {
            console.error(e);
          }
        /* if (typeof JSON.parse(this.temp[tempKey]) === 'array') {

        }*/
        }
        t.request
          .post('/buylist/edit', tempData)
          .then((res) => {
            t.getList();
            t.$bvModal.hide('tag-modal-example');
            t.$toasted.show(res.msg, {
              theme: 'bubble',
              position: 'top-center',
              type: 'success',
              duration: 10 * 1000,
            });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            }
          });
      },
      handleShare(row, index = 0) {
        t.temp = row;
        t.$bvModal.show('tag-modal-share');
      },
      handleDelete(row, index) {
        this.$confirm(`确认要删除吗?`, this.$t('warning'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning',
        })
          .then(async () => {
            await t.request.post('/buylist/delete', row);
            this.list.splice(index, 1);
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      getRenderData() {
        t.request
          .get('/buylist/render')
          .then((response) => {
            const res = response.data;
            let i = 0;
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of res.modelData) {
              if (modelDatum.show === '1') {
                if (i > 5) break;
                // eslint-disable-next-line no-prototype-builtins
                if (!t.showColums.hasOwnProperty(modelDatum.key)) {
                  this.$set(t.showColums, modelDatum.key, true);
                }
                i++;
              }
            }
            t.option = res.option || {};
            t.summar = res.summar || {};
            t.listModelServer = res.modelData || [];
            t.listSearchServer = res.searchData || [];
            t.listQuery.sort = res.defaultSort || [];
            t.pageSizelist = res.pageSizelist || [];
            t.buylisturlsys = res.buylisturlsys || [];
            t.getList();
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            }
          });
      },
      getList() {
        this.listLoading = true;
        t.request
          .get('/buylist/list')
          .then((response) => {
            this.list = response.data.items;
            this.total = response.data.total;
            // Just to simulate the time of the request
            this.listLoading = false;
            this.auto_renewal = response.data.auto_renewal;
          })
          .catch((res) => {
            this.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            }
          });
      },
      sortChange(data) {
        const { prop, order } = data;
        t.sortByID(order, prop);
      },
      sortByID(order, prop) {
        if (order === 'ascending') {
          t.listQuery.sort = `${prop} ASC`;
        } else {
          t.listQuery.sort = `${prop} DESC`;
        }
        t.handleFilter();
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    },
  };
</script>
<style scoped>
@media only screen and (max-width: 570.98px) {
  .page-body-wrapper .page-body,
  .page-body-wrapper footer {
    margin-left: -20px !important;
  }
  .page-wrapper .page-header {
    width: 104vw !important;
    /* padding: 12px 15px !important; */
  }
}
@media only screen and (max-width: 375px) {
  .page-body-wrapper .page-body,
  .page-body-wrapper footer {
    margin-left: -20px !important;
  }
}
.row1 {
  margin: 0 auto !important;
  min-width: 350px;
}

.discount {
  position: absolute;
  top: 3%;
  right: 1%;
  background: radial-gradient(
    circle,
    transparent 0,
    transparent 5px,
    #e94560 6px
  );
  background-size: 301px 19px;
  background-position: -151px -3px;
  color: white !important;
  width: 80px;
  height: 33px;
  font-size: 16px;
  line-height: 31px;
}
.discount::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 33.5px;
  width: 10%;
  height: 100%;
  border-right: 2px dashed #fff;
  z-index: 1;
  background-color: transparent;
}

.col-xl-4 {
  flex: 0 0 25%;
}
.col-xl-3 {
  flex: 0 0 20%;
}
/* 
.discount {
  position: absolute;
  top: 3%;
  right: 1%;
  background: radial-gradient(
    circle,
    transparent 0,
    transparent 5px,
    #e94560 6px
  );
  background-size: 301px 19px;
  background-position: -151px -3px;
  color: white !important;
  width: 80px;
  height: 33px;
  font-size: 16px;
  line-height: 31px;
}
.discount::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 33.5px;
  width: 10%;
  height: 100%;
  border-right: 2px dashed #fff;
  z-index: 1;
  background-color: transparent;
}
.col-xl-4 {
  flex: 0 0 25%;
}
.col-xl-3 {
  flex: 0 0 20%;
}*/
</style>
