import Vue from 'vue';
import Router from 'vue-router';

const originalPush = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalPush.call(this, location).catch((err) => err);
};

import Userauth from '../auth/js/index';

import Body from '../components/body';
import Auth from '../auth/auth';

/* Auth */
import login from '../auth/login';
import Register from '../auth/register';

/* maintenance */
import Maintenance from '../pages/maintenance';

/* Error Pages */
import Error400 from '../errors/error_400';
import Error401 from '../errors/error_401';
import Error403 from '../errors/error_403';
import Error404 from '../errors/error_404';
import Error500 from '../errors/error_500';
import Error503 from '../errors/error_503';

/* pricing */
import Pricing from '../pages/pricing/pricing';
/* sample page */
import SamplePage from '../pages/sample_page';

/* Search */
import Search from '../pages/search';

import Unlock from '../pages/authentication/unlock';
import ForgetPassword from '../pages/authentication/forget_password';
import ResetPassword from '../pages/authentication/reset_password';

import Shorturl from '../pages/sharecode/shorturl';
import CodeList from '../pages/sharecode/codelist';
import Fans from '../pages/sharecode/fans';
import workshare from '../pages/sharecode/workshare';
import Accountlist from '../pages/sharecode/accountlist';
import accountshow from '../pages/sharecode/accountshow';
import dataview from '../pages/sharecode/dataView/index.vue';
import download from '../pages/sharecode/download';
import pricing from '../pages/sharecode/pricing';
import agreement from '../pages/sharecode/agreement';
import usdtrecharge from '../pages/sharecode/usdtrecharge';
import agentcenter from '../pages/sharecode/agentcenter';
import index from '../pages/sharecode/index';
import jumprecharge from '../pages/sharecode/jumprecharge';
import workshow from '@/pages/sharecode/workshow.vue';

// component
Vue.use(Router);
const routes = [
  {
    path: '/accountshow/:sharekey',
    name: 'accountshow',
    component: accountshow,
    meta: {
      title: '',
    },
  },
  {
    path: '/dataView/index',
    name: 'dataView',
    component: dataview,
    meta: {
      title: '',
    },
  },
  {
    path: '/workshow/:sharekey',
    name: 'workshow',
    component: workshow,
    meta: {
      title: '',
    },
  },
  { path: '', redirect: { name: 'index' } },
  {
    path: '/sharecode',
    component: Body,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'index',
        name: 'index',
        component: index,
        meta: {
          title: '海王出海 APP',
        },
      },
      {
        path: 'codelist',
        name: 'codelist',
        component: CodeList,
        meta: {
          title: '邀请码列表',
        },
      },
      {
        path: 'fans',
        name: 'fans',
        component: Fans,
        meta: {
          title: '粉丝明细',
        },
      },
      {
        path: 'workshare',
        name: 'workshare',
        component: workshare,
        meta: {
          title: '多工单分享',
        },
      },
      {
        path: 'shorturl',
        name: 'shorturl',
        component: Shorturl,
        meta: {
          title: '分流链接',
        },
      },
      {
        path: 'accountlist/:shareid/:sharecode',
        name: 'accountlist',
        component: Accountlist,
        meta: {
          title: '海王出海 APP',
        },
      },
      {
        path: 'pricing',
        name: 'pricing',
        component: pricing,
        meta: {
          title: '购买套餐',
        },
      },
      {
        path: 'agentcenter',
        name: 'agentcenter',
        component: agentcenter,
        meta: {
          title: '代理中心',
        },
      },
      {
        path: 'download',
        name: 'download',
        component: download,
        meta: {
          title: '下载海王',
        },
      },
      {
        path: 'agreement',
        name: 'agreement',
        component: agreement,
        meta: {
          title: '用户协议',
        },
      },
      {
        path: 'usdtrecharge',
        name: 'usdtrecharge',
        component: usdtrecharge,
        meta: {
          title: '商户支付',
        },
      },
      {
        path: 'jumprecharge',
        name: 'jumprecharge',
        component: jumprecharge,
        meta: {
          title: '跳转支付中',
        },
      },
    ],
  },
  {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: 'login',
        name: 'Login 1',
        component: login,
        meta: {
          title: 'login | HaiWanG SCRM',
        },
      },
      {
        path: 'register',
        name: 'register 1',
        component: Register,
        meta: {
          title: ' Register | HaiWanG SCRM',
        },
      },
    ],
  },
  {
    path: '/pages',
    component: Body,
    children: [
      {
        path: '/pages/sample-page',
        name: 'SamplePage',
        component: SamplePage,
        meta: {
          title: 'SamplePage | HaiWanG SCRM',
        },
      },
      {
        path: '/pages/pricing',
        name: 'Pricing',
        component: Pricing,
        meta: {
          title: 'Pricing | HaiWanG SCRM',
        },
      },
    ],
  },
  {
    path: '/search',
    component: Body,
    children: [
      {
        path: '/pages/search',
        name: 'Search',
        component: Search,
        meta: {
          title: 'Search | HaiWanG SCRM',
        },
      },
    ],
  },
  {
    path: '/error-400',
    name: 'Error400',
    component: Error400,
    meta: {
      title: 'Error400 | HaiWanG SCRM',
    },
  },
  {
    path: '/error-401',
    name: 'Error401',
    component: Error401,
    meta: {
      title: 'Error401 | HaiWanG SCRM',
    },
  },
  {
    path: '/error-403',
    name: 'Error403',
    component: Error403,
    meta: {
      title: 'Error403 | HaiWanG SCRM',
    },
  },
  {
    path: '/error-404',
    name: 'Error404',
    component: Error404,
    meta: {
      title: 'Error404 | HaiWanG SCRM',
    },
  },
  {
    path: '/error-500',
    name: 'Error500',
    component: Error500,
    meta: {
      title: 'Error500 | HaiWanG SCRM',
    },
  },
  {
    path: '/error-503',
    name: 'Error503',
    component: Error503,
    meta: {
      title: 'Error503 | HaiWanG SCRM',
    },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: {
      title: 'Maintenance | HaiWanG SCRM',
    },
  },
  {
    path: '/authentication/unlockuser',
    name: 'Unlock',
    component: Unlock,
    meta: {
      title: 'Unlock | HaiWanG SCRM',
    },
  },
  {
    path: '/authentication/forgetpassword',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: {
      title: 'ForgetPassword | HaiWanG SCRM',
    },
  },
  {
    path: '/authentication/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'ResetPassword | HaiWanG SCRM',
    },
  },
];

const router = new Router({
  routes,
  base: process.env.BASE_URL,
  mode: 'hash',
  linkActiveClass: 'active',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {

    if (to.meta.title) document.title = to.meta.title;

    const path = ['/auth/login', '/auth/register'];
    if (
      to.path.indexOf('/accountshow/') !== -1 ||
      to.path.indexOf('/workshow/') !== -1 ||
      to.path.indexOf('/dataview/') !== -1 ||
      path.includes(to.path) ||
      to.path === '/callback' ||
      Userauth.isAuthenticatedUser()
    ) {
      return next();
    }
    next('/auth/login');

});

export default router;
