<template>
  <div class="111">
    <div class="card profile-box">
      <div class="card-body">
        <div class="media">
          <div class="media-body">
            <div class="greeting-user">
              <h4 class="f-w-600 mb-0">
                {{ $t("Welcome Back") }} {{ this.newYorkTime.slice(-9) }}
              </h4>
              <br />
              <p>
                {{ ymd }}({{ week }})
                <!-- {{ this.newYorkTime.slice(-9) }} -->
              </p>

              <p>
                (GMT+8)
                {{ $t("新加坡时间") }}
              </p>
              <div class="whatsnew-btn" style="min-width: 150px">
                <a class="btn btn-outline-white">
                  Buy
                  <!-- {{ $t("Whats New") }} ! -->
                </a>
              </div>
            </div>
          </div>
          <div v-if="false">
            <div class="clockbox">{{ hms }}</div>
            <!-- <div class="badge f-10 p-0" id="txt"></div> -->
          </div>
        </div>
        <div class="cartoon">
          <img
            class="img-fluid"
            src="@/assets/images/dashboard/cartoon.svg"
            alt="vector women with leptop"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
// import VueMoment from "vue-moment";
// import moment from "moment-timezone";
// // 设置纽约时区
// const nyZone = "America/New_York";
// // 设置时区
// moment.tz.setDefault(nyZone);

// Vue.use(VueMoment, {
//   moment,
// });
  export default {
    data() {
      return {
        options: {
          timeZone: 'Asia/Singapore',
          hour12: false, // 使用24小时制
        },
        // newYorkTime:'',
        newYorkTime: new Date().toLocaleString('en-US', {
          timeZone: 'Asia/Singapore',
          hour12: false,
        }), // 获取新加坡时间
        nowTime: '',
        hms: '', //时分秒
        ymd: '', //年月日
        week: '', //星期英文
        urlWindow: window.location.href,
      };
    },
    watch: {
      urlWindow(newVal) {
        if (newVal.endsWith('sharecode/index')) {

        }
      },
    },
    beforeDestroy() {
      // 在组件销毁前清除定时器
      clearInterval(this.timer);
      clearInterval(this.interval);
    },
    mounted() {
      this.timer = setInterval(() => {
        let timeDate = new Date();
        let year = timeDate.getFullYear();
        let mounth = timeDate.getMonth() + 1;
        let day = timeDate.getDate();
        let hours = timeDate.getHours();
        hours = hours >= 10 ? hours : '0' + hours;
        let minutes = timeDate.getMinutes();
        minutes = minutes >= 10 ? minutes : '0' + minutes;
        let seconds = timeDate.getSeconds();
        seconds = seconds >= 10 ? seconds : '0' + seconds;
        let chinaDate = timeDate.toDateString();
        let chinaDateArray = chinaDate.split(' ')[0];
        let week = timeDate.getDay();
        let weekArr = [
          '星期日',
          '星期一',
          '星期二',
          '星期三',
          '星期四',
          '星期五',
          '星期六',
        ];
        this.nowTime = `${year}/${mounth}/${day} (${weekArr[week]}) ${hours}:${minutes}:${seconds}`;

        this.hms = `${hours}:${minutes}:${seconds}`;
        this.ymd = `${year}/${mounth}/${day}`;
        this.week = `${chinaDateArray}`;
        console.log('setInterval');
      }, 1000);
      this.interval = setInterval(() => {
        let aa = new Date().toLocaleString('en-US', {
          timeZone: 'Asia/Singapore',
          hour12: false,
        });
        this.newYorkTime = aa + ' ';
      }, 1000);
    },
    methods: {},
  };
</script>
<style lang="scss" scoped>
@keyframes text-style-1 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
}
.clockbox {
  font-weight: 900;
  flex: 1;
  font-size: 30px;
  background-image: linear-gradient(to right, #fff, #fff, yellow, #fff, #fff);
  background-clip: text;
  font-size: 2.5rem;
  margin-right: 1rem;
  z-index: 999;
  margin-top: -1rem;
  -webkit-background-clip: text;
  background-size: 200% 100%;
  color: transparent;
  animation: text-style-1 2s infinite linear;
}
.f-w-600 {
  font-size: 20px;
  font-weight: 900 !important;
  text-shadow: 0 1px hsl(0, 0%, 85%), 0 1px hsl(0, 0%, 80%),
    0 1px hsl(0, 0%, 75%), 0 1px hsl(0, 0%, 70%), 0 1px hsl(0, 0%, 65%),
    0 1px 1px white;
}
.card .card-body {
  padding: 20px 15px !important;
  background-color: transparent;
}
</style>
