<template>
  <el-dropdown
    id="langddm"
    class="translate_wrapper ml-2"
    variant="light"
    size="sm"
    toggle-class="language-button"
    @command="changeLocale"
  >
    <template slot="button-content">
      <i v-if="langIcon != 'vn'" class="flag-icon" :class="langIcon"></i>
      <!-- <i style="width: 16px">  </i> -->
      <!-- <img class="flagvn" :src="getImgUrl('84.png')" alt="" /> -->

      <span class="name">{{ $i18n.locale }}</span>
    </template>
    <span
      class="el-dropdown-link"
      :style="isDataView ? 'color: #fff;font-weight:600' : ''"
    >
      <i class="flag-icon" :class="langIcon"></i>
      {{ $i18n.locale }}
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="(l, index) in localeOptions"
        :key="index"
        :command="l"
      >
        <i v-if="l.icon != 'vn'" class="flag-icon" :class="l.icon"></i>
        <img
          style="width: 19px; margin-right: 1px"
          v-if="l.icon == 'vn'"
          :src="getImgUrl('84.png')"
          alt=""
        />
        {{ l.name }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
  import { mapActions } from 'vuex';
  import { localeOptions } from '../../constants/config';

  export default {
    name: 'Language',
    data() {
      return {
        localeOptions,
        isDataView: false,
        langIcon: localStorage.getItem('currentLanguageIcon') || 'flag-icon-cn',
      };
    },
    watch: {
      '$i18n.locale'(to, from) {
        if (from !== to) {
          this.$router.go(this.$route.path);
        }
      },
    },
    created() {
    
      window.location.href.indexOf('dataview') > -1
        ? (this.isDataView = true)
        : (this.isDataView = false);
      console.log('localeOptions', this.langIcon);
    },
    methods: {
      getImgUrl(path) {
        return require('@/assets/images/guoqi/' + path);
      },
    ...mapActions(['setLang']),
      changeLocale(locale) {
        this.setLang(locale);
      },
    },
  };
</script>
<style lang="scss" scoped>
.flagvn {
  width: 16px;
}
</style>
