import config from '@/config';
import index from '@/auth/js/index';

export default {
    config: {
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
        },
        dataType: 'json',
    },
    getDeviceInfo() {
        const useragent = navigator.userAgent;
        const platform = navigator.platform;
        const screen = `${window.screen.width}x${window.screen.height}`;
        const language = navigator.language;
        const appversion = navigator.appVersion;
        const vendor = navigator.vendor;
        // 可以添加更多设备信息
        return { useragent, platform, screen, language,appversion,vendor };
    },
    request(options) {
        return new Promise((resolve, reject) => {
            let _config = null;
            _config = Object.assign({}, this.config, options);
            const lang = window.localStorage.getItem('currentLanguage');
            const loginToken = window.localStorage.getItem('webLoginToken');
            const  { useragent, platform, screen, language,appversion,vendor } = this.getDeviceInfo();
            _config.headers['Authorization'] = loginToken || '';
            _config.headers['Lang'] = lang || 'zh';
            _config.headers['useragent'] =  useragent;
            _config.headers['platform'] =  platform;
            _config.headers['screen'] =  screen;
            _config.headers['language'] =  language;
            _config.headers['appversion'] =  appversion;
            _config.headers['vendor'] =  vendor;
            fetch(_config.url, _config).then((res) => {
                if (res.status !== 200) {
                    reject({ errMsg: '', response: res });
                }
                return res.json();
            }).then((res) => {
                switch (res.code) {
                    case 1:
                        resolve(res);
                        break;
                    case -2:
                        index.Logout();
                        window.location.reload();
                        reject({ errMsg: res.msg, response: res });
                        break;
                    case -1:
                        reject({ errMsg: res.msg, response: res });
                        break;
                }

            }).catch(res => {
                reject({ errMsg: '', response: res });
            });
        });
    },
    get(url, param = [],options, domain = true) {
        let parArr = [];
        // eslint-disable-next-line no-unused-vars
        for (const paramElement in param) {
            parArr.push(`${paramElement}=${param[paramElement]}`);
        }
        if (parArr.length > 0){
            url = url + '?' + parArr.join('&');
        }

        options = options || {};
        options.url = domain ?  config.domain +'/web'+ url :  url;
        //options.body = JSON.stringify(data);
        options.method = 'GET';
        return this.request(options);
    },
    post(url, data, options, domain = true) {
        //console.log(check);
        if (!options) {
            options = {};
        }
        options.url = domain ? config.domain +'/web' + url : url;
        options.body = JSON.stringify(data);
        options.method = 'POST';
        return this.request(options);
    },
};
