<template>
  <div v-loading="listLoading">
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <!-- class="filter-container" -->
          <div :class="isPCShow ? 'card-body' : ''">
            <!-- <div class="radio radio-primary">
                <b-form-radio name="radio2" value="option1"
                  >Option 1</b-form-radio
                >
              </div>

              <div class="radio radio-secondary">
                <b-form-radio name="radio2" value="option2"
                  >Option 2</b-form-radio
                >
              </div>

              <div class="radio radio-success">
                <b-form-radio name="radio2" value="option3"
                  >Option 3</b-form-radio
                >
              </div>

              <div class="radio radio-info">
                <b-form-radio name="radio2" value="option4" disabled
                  >Option 4</b-form-radio
                >
              </div>

              <div class="radio radio-warning">
                <b-form-radio name="radio2" value="option5"
                  >Option 5</b-form-radio
                >
              </div> -->
            <div v-if="option.showSearchBt" style="margin: 10px 0 30px 0">
              <!--  style="top: -4px; width: 12rem;" -->
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: start;
                  flex-wrap: wrap;
                "
              >
                <div style="flex: 1.7; min-width: 350px">
                  <el-date-picker
                    v-model="listQuery.createdatstart"
                    :style="
                      isPCShow
                        ? { width: '12rem', 'margin-left': '5px' }
                        : { width: '7rem', 'margin-left': '5px' }
                    "
                    type="datetime"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :placeholder="$t('Please select a start time')"
                  />
                  <el-date-picker
                    v-model="listQuery.createdatend"
                    :style="
                      isPCShow
                        ? { width: '12rem', 'margin-left': '5px' }
                        : { width: '7rem', 'margin-left': '5px' }
                    "
                    type="datetime"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :placeholder="$t('Please select an end time')"
                  />
                  <span
                    v-for="(searchItem, searchIndex) in listSearchServer"
                    :key="searchIndex"
                    style="margin-left: 5px; line-height: 45px"
                  >
                    <el-input
                      v-if="searchItem.htype === 'text'"
                      v-model="listQuery[searchItem.key]"
                      type="text"
                      :style="isPCShow ? { width: '12rem' } : { width: '7rem' }"
                      :placeholder="$t('Please enter the') + searchItem.label"
                      class="filter-item"
                      @keyup.enter.native="handleFilter"
                    />

                    <el-input
                      v-if="searchItem.htype === 'number'"
                      v-model="listQuery[searchItem.key]"
                      type="number"
                      :style="isPCShow ? { width: '12rem' } : { width: '7rem' }"
                      :placeholder="$t('Please enter the') + searchItem.label"
                      class="filter-item"
                      @keyup.enter.native="handleFilter"
                    />

                    <el-select
                      v-if="searchItem.htype === 'select'"
                      v-model="listQuery[searchItem.key]"
                      :placeholder="$t('Please select a') + searchItem.label"
                      clearable
                      :style="isPCShow ? { width: '12rem' } : { width: '7rem' }"
                      class="filter-item"
                    >
                      <el-option
                        v-for="(item, key) in searchItem.select"
                        :key="key"
                        :label="item.title"
                        :value="item.id"
                      />
                    </el-select>
                  </span>
                </div>
                <div
                  style="
                    flex: 0.3;
                    height: 36px;
                    display: flex;
                    margin-top: 3px;
                    margin-left: 5px;
                  "
                >
                  <el-button
                    style="flex: 1"
                    class="filter-item"
                    icon="el-icon-delete-solid"
                    type="success"
                    plain
                    @click="resetFun"
                    >{{ $t("Reset") }}
                  </el-button>
                  <el-button
                    style="flex: 1"
                    class="filter-item"
                    type="primary"
                    icon="el-icon-search"
                    @click="handleFilter"
                    >{{ $t("Search") }}
                  </el-button>
                </div>
              </div>
            </div>
            <div class="filter-container" style="margin-top: 8px">
              <el-button
                v-if="option.showAddBt"
                class="filter-item"
                style="margin-left: 10px"
                type="primary"
                icon="el-icon-edit"
                @click="handleCreate"
                plain
                >{{ $t("Create") }}
              </el-button>
              <el-button
                class="filter-item"
                style="margin-left: 10px"
                type="danger"
                @click="toggleSelection()"
                icon="el-icon-delete"
                plain
                >{{ $t("Remove selected") }}
              </el-button>
              <!--      <el-button class="filter-item" style="margin-left: 10px;" type="warning" icon="el-icon-delete" @click="handleDelete">删除</el-button>-->
              <el-button
                v-if="option.showExportBt"
                v-waves
                :loading="downloadLoading"
                class="filter-item"
                type="info"
                icon="el-icon-download"
                plain
                @click="handleDownload"
                >{{ $t("Export") }}
              </el-button>
            </div>
            <div>
              <el-table
                :key="tableKey"
                :data="list"
                border
                fit
                highlight-current-row
                :show-summary="summar.switch"
                :summary-method="getSummar"
                style="width: 100%; border-radius: 10px"
                @sort-change="sortChange"
                @row-click="rowClick"
                @selection-change="handleSelectionChange"
                ref="multipleTable"
              >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column
                  v-for="(item, index) in listModelServer"
                  :min-width="
                    item.showtype === 'date' || item.showtype === 'timetext'
                      ? '180'
                      : '120'
                  "
                  v-if="item.show === '1' && showColums[item.key]"
                  :key="index"
                  :label="item.label"
                  :prop="item.key"
                  :sortable="item.sort === '1'"
                  align="center"
                  show-overflow-tooltip
                >
                  <template slot-scope="{ row }">
                    <span
                      v-if="
                        item.showtype === 'text' &&
                        item.key !== 'sharegroup_code'
                      "
                    >
                      {{ row[item.key] }}
                    </span>
                    <span v-if="item.showtype === 'timetext'">
                      <el-time-picker
                        v-model="row[item.key]"
                        format="HH:mm:ss"
                        style="width: 120px"
                        value-format="HH:mm:ss"
                        @change="updateData(row, 'time', '1')"
                      />
                    </span>
                    <el-button
                      type="text"
                      v-if="
                        item.showtype === 'text' &&
                        item.key === 'sharegroup_code'
                      "
                      >{{ row[item.key] }}
                    </el-button>
                    <span v-if="item.showtype === 'date'">
                      {{
                        row[item.key]
                          ? row[item.key]
                              .replace("+08:00", "")
                              .replace("T", " ")
                          : row[item.key]
                      }}
                    </span>
                    <!-- [ { "entitle": "port", "id": 0, "title": "port" }, { "entitle": "words", "id": 1, "title": "words" } ] words
 -->
                    <!-- 消费类型 -->
                    <!--                      <span
                          v-if="
                          item.showtype === 'arr' && item.edittype === 'radio'
                        "
                      >
                        <el-button
                            type="text"
                            v-for="(arritem, arrindex) in item.showarr"
                            v-if="item.showarr[arrindex].id === 0"
                            :key="arrindex"
                            v-show="arritem.id === row[item.key]"
                        >{{ item.showarr[arrindex].title }}
                        </el-button>
                        <span
                            style="color: rgb(28, 179, 155)"
                            v-for="(arritem, arrindex) in item.showarr"
                            v-if="item.showarr[arrindex].id === 1"
                            :key="arrindex"
                            v-show="arritem.id === row[item.key]"
                        >{{ item.showarr[arrindex].title }}
                        </span>
                      </span>-->
                    <span v-if="item.showtype === 'arr'">
                      <el-tag
                        v-for="(arritem, arrindex) in item.showarr"
                        :key="arrindex"
                        v-show="arritem.id === row[item.key]"
                        :type="arritem.tagtype"
                        >{{ item.showarr[arrindex].title }}
                      </el-tag>
                    </span>
                    <!--                      <span
                          v-if="item.showtype === 'arr' && item.key === 'sharegroup_status'">

                        <el-tag
                            v-for="(arritem, arrindex) in item.showarr"
                            :key="arrindex"
                            v-show="arritem.id === row[item.key]"
                            :type="arritem.tagtype"
                        >
                          <i v-if="row.sharegroup_password !== ''" class="el-icon-lock"></i>
                          <i v-else class="el-icon-unlock"></i>
                          {{ item.showarr[arrindex].title }}
                        </el-tag>
                      </span>-->

                    <span v-if="item.showtype === 'img'"
                      ><el-image
                        fit="fill"
                        :src="row[item.key]"
                        :preview-src-list="[row[item.key]]"
                    /></span>
                  </template>
                </el-table-column>

                <!-- 操作---- -->

                <el-table-column
                  :label="$t('Operation')"
                  align="center"
                  class-name="small-padding fixed-width"
                  width="150"
                  :fixed="tableFixed"
                >
                  <template slot="header" slot-scope="scope">
                    <span>{{ $t("Operation") }}</span>
                    <!-- $t("Display of columns") -->
                    <el-dropdown style="margin-left: 6px">
                      <i class="el-icon-setting"></i>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          style="border-bottom: 1px solid #dcdfe6"
                          command="sendModifyData"
                        >
                          <el-checkbox
                            v-model="allStatus"
                            @change="selectAllFun"
                            >{{ allStatus ? $t("CancelFull") : $t("Full") }}
                          </el-checkbox>
                        </el-dropdown-item>

                        <el-dropdown-item
                          command="sendModifyData"
                          v-for="(item, index) in listModelServer"
                          :key="index"
                          v-show="item.show === '1'"
                        >
                          <el-checkbox
                            v-model="showColums[item.key]"
                            @change="saveColmun"
                          >
                            {{ item.label }}
                          </el-checkbox>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                  <template slot-scope="{ row, $index }">
                    <el-button
                      icon="el-icon-edit"
                      size="mini"
                      type="text"
                      @click="handleUpdate(row)"
                    >
                      {{ $t("Edit") }}
                    </el-button>
                    <el-button
                      icon="el-icon-delete"
                      type="text"
                      @click="handleDelete(row, $index)"
                    >
                      {{ $t("Delete") }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>

              <pagination
                v-show="total > 0"
                :total="total"
                :page.sync="listQuery.page"
                :limit.sync="listQuery.limit"
                :page-sizes="pageSizelist"
                @pagination="getList"
              />
            </div>
            <!--开始-->

            <!--结束       -->
            <el-dialog
              :visible.sync="dialogSwitch.tagModalShare"
              :append-to-body="true"
              :title="$t('Share') + $t('Invitation Code')"
              width="80%"
            >
              <div class="modal-body">
                <form class="form-bookmark needs-validation" novalidate="">
                  <div class="form-row">
                    <div
                      class="form-group col-md-12"
                      style="
                        padding: 10px;
                        border: solid 1px #eeeeee;
                        border-bottom: 0;
                        margin-bottom: 0;
                        padding-top: 15px;
                        margin-top: 0;
                      "
                    >
                      <label
                        style="
                          background-color: rgba(0, 0, 0, 0.1);
                          padding: 10px;
                          border-radius: 5px;
                          min-width: 70px;
                          text-align: center;
                        "
                        >{{ $t("Invitation Code") }}:</label
                      >
                      <label style="margin-left: 20px">{{
                        temp.sharegroup_code
                      }}</label>
                    </div>
                    <div
                      class="form-group col-md-12"
                      style="
                        padding: 10px;
                        border: solid 1px #eeeeee;
                        border-bottom: 0;
                        margin-bottom: 0;
                        padding-top: 15px;
                        margin-top: 0;
                      "
                    >
                      <label
                        style="
                          background-color: rgba(0, 0, 0, 0.1);
                          padding: 10px;
                          border-radius: 5px;
                          min-width: 70px;
                          text-align: center;
                        "
                      >
                        {{ $t("Theory of conversation") }}:
                      </label>
                      <label style="margin-left: 20px">
                        {{ temp.sharegroup_total }}
                      </label>
                    </div>
                    <div
                      class="form-group col-md-12"
                      style="
                        padding: 10px;
                        border: solid 1px #eeeeee;
                        border-bottom: 0;
                        margin-bottom: 0;
                        padding-top: 15px;
                        margin-top: 0;
                      "
                    >
                      <label
                        style="
                          background-color: rgba(0, 0, 0, 0.1);
                          padding: 10px;
                          border-radius: 5px;
                          min-width: 70px;
                          text-align: center;
                        "
                        >{{ $t("Link") }}:</label
                      >
                      <label style="margin-left: 20px"
                        ><a
                          target="_blank"
                          :href="
                            config.domain +
                            '/web#/accountshow/' +
                            temp.sharegroup_code
                          "
                          >{{ $t("OpenUrl") }}</a
                        >
                        | <a @click="copyUrl">{{ $t("CopyUrl") }}</a> |
                        <a @click="copyAll">{{ $t("CopyAll") }}</a></label
                      >
                    </div>
                    <div0
                      class="form-group col-md-12"
                      style="
                        padding: 10px;
                        border: solid 1px #eeeeee;
                        padding-top: 15px;
                        margin-top: 0;
                      "
                    >
                      <label
                        style="
                          background-color: rgba(0, 0, 0, 0.1);
                          padding: 10px;
                          border-radius: 5px;
                          min-width: 70px;
                          text-align: center;
                        "
                        >{{ $t("Password") }}:</label
                      >
                      <label style="margin-left: 20px">{{
                        temp.sharegroup_password
                          ? temp.sharegroup_password
                          : $t("Password free")
                      }}</label>
                    </div0>
                  </div>
                </form>
              </div>
            </el-dialog>
            <el-dialog
              :visible.sync="dialogSwitch.createModalExample"
              :append-to-body="true"
              width="70%"
              :title="
                dialogStatus === 'create'
                  ? $t('Creating an Invitation Code')
                  : $t('Edit an Invitation Code')
              "
            >
              <el-form label-position="top" :model="temp">
                <el-form-item
                  v-for="(item, index) in listModelServer"
                  v-if="item.edit === '1'"
                  :key="index"
                  :label="item.label"
                  :prop="item.key"
                >
                  <label v-if="item.edittype === 'disable'">{{
                    temp[item.key]
                  }}</label>
                  <input
                    class="form-control"
                    v-if="item.edittype === 'text'"
                    v-model="temp[item.key]"
                    type="text"
                    :placeholder="$t('Please enter the') + item.label"
                    autocomplete="off"
                  />
                  <input
                    class="form-control"
                    v-if="item.edittype === 'number'"
                    v-model="temp[item.key]"
                    type="number"
                    :placeholder="$t('Please enter the') + item.label"
                    autocomplete="off"
                  />
                  <el-select
                    style="margin-left: 5px"
                    v-if="item.edittype === 'select'"
                    v-model="temp[item.key]"
                    class="filter-item"
                    :placeholder="$t('Please enter the') + item.label"
                  >
                    <el-option
                      v-for="(selectitem, selectkey) in item.editselect"
                      :key="selectkey"
                      :label="selectitem.title"
                      :value="selectitem.id"
                    />
                  </el-select>
                  <el-date-picker
                    v-if="item.edittype === 'date'"
                    v-model="temp[item.key]"
                    style="top: -4px; width: 200px"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :placeholder="$t('Please enter the') + item.label"
                  />
                  <el-time-picker
                    v-if="item.edittype === 'time'"
                    v-model="temp[item.key]"
                    style="top: -4px; width: 200px"
                    format="HH:mm:ss"
                    value-format="HH:mm:ss"
                    :placeholder="$t('Please enter the') + item.label"
                  />
                  <el-date-picker
                    v-if="item.edittype === 'datetime'"
                    v-model="temp[item.key]"
                    style="top: -4px; width: 200px"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :placeholder="$t('Please enter the') + item.label"
                  />
                  <el-radio-group
                    v-if="item.edittype === 'radio'"
                    v-model="temp[item.key]"
                  >
                    <el-radio
                      v-for="(radioItem, radioIndex) in item.editselect"
                      :key="radioIndex"
                      :label="radioItem.id"
                    >
                      {{ radioItem.title }}
                    </el-radio>
                  </el-radio-group>
                  <el-checkbox-group
                    v-if="item.edittype === 'checkbox'"
                    v-model="temp[item.key]"
                  >
                    <el-checkbox
                      v-for="(chboxItem, chboxIndex) in item.editselect"
                      :key="chboxIndex"
                      :label="chboxItem.id"
                    >
                      <span
                        v-if="chboxItem.title === '数据分析'"
                        style="color: red"
                        >{{ chboxItem.title }}
                        <el-tooltip
                          class="item"
                          :value="dialogSwitch.createModalExample"
                          effect="dark"
                          content="重新登录客户端将自动上传账号联系人分析数据"
                          placement="top-start"
                        >
                          <i class="el-icon-question"></i
                        ></el-tooltip>
                      </span>
                      <span v-else>
                        {{ chboxItem.title }}
                      </span>
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item
                  class="form-group col-md-12"
                  v-if="temp['sharegroup_type'] === 0"
                  :label="$t('Assigning a Port')"
                >
                  <input
                    class="form-control"
                    v-model="temp['sharegroup_total']"
                    type="number"
                    :placeholder="$t('Please enter the')"
                    autocomplete="off"
                  />
                </el-form-item>
              </el-form>

              <div slot="footer" class="dialog-footer">
                <button
                  class="btn btn-secondary"
                  type="button"
                  @click="
                    dialogStatus === 'create' ? createData() : updateData()
                  "
                >
                  {{ $t("Save") }}
                </button>
                <button
                  class="btn btn-primary ml-2"
                  type="button"
                  data-dismiss="modal"
                  @click="dialogSwitch.createModalExample = false"
                >
                  {{ $t("Cancel") }}
                </button>
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination';

  let t;
  export default {
    name: 'ShareGroup',
    components: { Pagination },
    data() {
      return {
        selectDate: [],
        isPCShow: true,
        windowWidth: window.innerWidth,
        allStatus: false,
        tableFixed: 'right',
        downloadLoading: false,
        showColums: {},
        dialogSwitch: {
          createModalExample: false,
          tagModalShare: false,
        },
        listQuery: {
          page: 1,
          limit: 20,
          sort: '',
          createdatstart: '',
          createdatend: '',
        },
        option: {
          showAddBt: false,
          showCatchEditBt: false,
          showExportBt: false,
          showSearchBt: true,
        },
        listLoading: true,
        pageSizelist: [],
        listSearchServer: [],
        listModelServer: [],
        listModelNum: 0,
        filter: null,
        list: [],
        total: 0,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15],
        tableKey: 0,
        selectMode: 'multi',
        selected: [],
        listdata: [],
        temp: {},
        dialogStatus: '',
        summar: {
          switch: false,
          data: {},
        },
        titleModelList: [],
      };
    },
    computed: {},
    mounted() {
      // Set the initial number of items
      t = this;
      t.getRenderData();
      t.getColumn();
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      t.tableFixed = t._isMobile() ? false : 'right';
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.windowWidth = window.innerWidth;
        // const windowWidth = window.innerWidth;
        this.isPCShow = this.windowWidth >= 768;
      },
      _isMobile() {
        return navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
      },
      resetFun() {
        // eslint-disable-next-line no-unused-vars
        for (const listSearchServerElement of t.listSearchServer) {
          t.$set(t.listQuery, listSearchServerElement.key, '');
        }
        // eslint-disable-next-line no-unused-vars
        for (const listQueryElement of this.listQuery) {
          this.$set(this.listQuery, listQueryElement, '');
        }
      },
      handleSelectionChange(e) {
        this.selectDate = e;
      // console.log("选中哈哈哈", e);
      },
      toggleSelection() {
        this.$refs.multipleTable.clearSelection();
      },
      rowClick(item, el) {
        if (el.label === '邀请码') {
          t.$copyText(item.sharegroup_code)
            .then((e) => {
              t.$toasted.show('复制成功', {
                theme: 'bubble',
                position: 'top-center',
                type: 'success',
                duration: 3000,
              });
            })
            .catch((e) => {
              t.$toasted.show('复制失败', {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 3000,
              });
            });
        }
      },
      selectAllFun(e) {
        !t.allStatus;
        let i = 0;
        t.listModelServer.forEach((item) => {
          if (item.show === '1') {
            if (!t.allStatus && i <= 5) {
              t.$set(t.showColums, item.key, true);
            } else if (!t.allStatus && i > 5) {
              t.$set(t.showColums, item.key, false);
            } else {
              t.$set(t.showColums, item.key, true);
            }
            i++;
          }
        });
        window.localStorage.setItem(
          'sharegroup_colmun',
          JSON.stringify(t.showColums)
        );
      },
      getColumn() {
        t.showColums = JSON.parse(
          window.localStorage.getItem('sharegroup_colmun') || '{}'
        );
      },
      saveColmun() {
        let arr = Object.values(t.showColums).filter((item) => item);
        t.allStatus = arr.length === t.listModelNum;
        window.localStorage.setItem(
          'sharegroup_colmun',
          JSON.stringify(t.showColums)
        );
      },
      getSummar(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            // 只找第一列放合计
            sums[index] = `${t.$t('combined')}`;
            return;
          }
          if (t.summar.data[column.property]) {
            // 判断当前prop绑定的ID
            const values = data.map((item) => item[column.property]); // 把对应一列中的之全部取出，放到一个数组中
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr); // 将values中的每个值转换为number类型
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          }
        });
        return sums;
      },
      copyUrl() {
        const text =
          t.config.domain + '/web#/accountshow/' + t.temp.sharegroup_code;
        t.$copyText(text)
          .then((e) => {
            t.$toasted.show('复制成功', {
              theme: 'bubble',
              position: 'top-center',
              type: 'success',
              duration: 3000,
            });
          })
          .catch((e) => {
            t.$toasted.show('复制失败', {
              theme: 'bubble',
              position: 'top-center',
              type: 'error',
              duration: 3000,
            });
          });
      },
      copyAll() {
        const text = `邀请码：${t.temp.sharegroup_code}
工单链接：${t.config.domain + '/web#/accountshow/' + t.temp.sharegroup_code}
访问密码：${t.temp.sharegroup_password}`;
        t.$copyText(text)
          .then((e) => {
            t.$toasted.show('复制成功', {
              theme: 'bubble',
              position: 'top-center',
              type: 'success',
              duration: 3000,
            });
          })
          .catch((e) => {
            t.$toasted.show('复制失败', {
              theme: 'bubble',
              position: 'top-center',
              type: 'error',
              duration: 3000,
            });
          });
      },
      handleOpen(item, index) {
        this.$router.replace({
          path: `/sharegroup/accountlist/${item.sharegroup_id}/${item.sharegroup_code}`,
        });
      },
      handleDownload() {
        this.downloadLoading = true;
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = ['timestamp', 'title', 'type', 'importance', 'status'];
        const filterVal = [
          'timestamp',
          'title',
          'type',
          'importance',
          'status',
        ];
        const data = this.formatJson(filterVal);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'table-list',
        });
        this.downloadLoading = false;
      });
      },
      formatJson(filterVal) {
        return this.list.map((v) =>
          filterVal.map((j) => {
            if (j === 'timestamp') {
              return parseTime(v[j]);
            } else {
              return v[j];
            }
          })
        );
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleUpdate(row) {
        this.temp = Object.assign({}, row); // copy obj
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in this.temp) {
          try {
            for (const modelDatum of this.listModelServer) {
              if (
                modelDatum.key === tempKey &&
                (modelDatum.edittype === 'number' || modelDatum.edittype.indexOf('Number') !== -1)
              ) {
                tempData[tempKey] = Number(tempData[tempKey]);
                break;
              }
            }
            if (typeof JSON.parse(this.temp[tempKey]) === 'object') {
              this.temp[tempKey] = JSON.parse(this.temp[tempKey]);
            }
          // eslint-disable-next-line no-empty
          } catch (e) {}
        }
        this.temp.password = '';
        this.temp.walletpassword = '';
        this.dialogStatus = 'update';
        t.dialogSwitch.createModalExample = true;
      },
      createData() {
        const tempData = Object.assign({}, this.temp);
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in tempData) {
          try {
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of this.listModelServer) {
              if (
                modelDatum.key === tempKey &&
                (modelDatum.edittype === 'number' || modelDatum.edittype.indexOf('Number') !== -1)
              ) {
                tempData[tempKey] = Number(tempData[tempKey]);
                break;
              }
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
          } catch (e) {
            console.log(e);
          }
        }
        tempData['sharegroup_total'] = Number(tempData['sharegroup_total']);

        t.request
          .post('/sharegroup/add', tempData)
          .then(() => {
            this.getList();
            t.dialogSwitch.createModalExample = false;
            this.$notify({
              title: '完成',
              message: '添加成功',
              type: 'success',
              duration: 2000,
            });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      handleCreate() {
        this.resetTemp();
        this.dialogStatus = 'create';
        this.temp['sharegroup_status'] = 1;
        this.temp['sharegroup_zerotime'] = '23:59:59';
        // this.temp['sharegroup_type'] = 0;
        t.dialogSwitch.createModalExample = true;
      },
      resetTemp() {
        const jsonTemp = {};
        // eslint-disable-next-line no-unused-vars
        for (const listModelServerKey in this.listModelServer) {
          switch (this.listModelServer[listModelServerKey]['edittype']) {
          case 'checkbox':
            jsonTemp[this.listModelServer[listModelServerKey]['key']] = [];
            break;
          default:
            jsonTemp[this.listModelServer[listModelServerKey]['key']] = '';
            break;
          }
          if (
            this.listModelServer[listModelServerKey]['key'] ===
            'sharegroup_applist'
          ) {
            jsonTemp['sharegroup_applist'] = [];
            // eslint-disable-next-line no-unused-vars
            for (const listModelServerElement of this.listModelServer[
              listModelServerKey
            ]['editselect']) {
              jsonTemp['sharegroup_applist'].push(listModelServerElement['id']);
            }
          }
        }
        this.temp = jsonTemp;
      },
      stopCode(row, key) {
        t.$confirm(
          `<div class="ant-modal-confirm-content"><div data-v-830c5818=""><div data-v-830c5818="">当邀请码分组被停用后：</div><div data-v-830c5818="">邀请码分组分享出去将无法打开工单；</div></div></div>`,
          '是否确定停用邀请码',
          {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning',
            dangerouslyUseHTMLString: true,
          }
        )
          .then(async () => {
            t.updateData(row, key, 0);
          })
          .catch((err) => {
            console.error(err);
          });
      },
      updateData(row, key, value) {
        if (row) {
          row[key] = value;
          this.temp = row;
        }
        const tempData = Object.assign({}, this.temp);
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in tempData) {
          try {
            for (const modelDatum of this.listModelServer) {
              if (
                modelDatum.key === tempKey &&
                (modelDatum.edittype === 'number' || modelDatum.edittype.indexOf('Number') !== -1)
              ) {
                tempData[tempKey] = Number(tempData[tempKey]);
                break;
              }
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
          } catch (e) {
            console.log(e);
          }
        /* if (typeof JSON.parse(this.temp[tempKey]) === 'array') {

        }*/
        }
        t.request
          .post('/sharegroup/edit', tempData)
          .then((res) => {
            t.getList();
            t.dialogSwitch.createModalExample = false;
            t.$toasted.show(res.msg, {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'success',
              duration: 3000,
            });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      handleShare(row, index = 0) {
        t.temp = row;
        t.dialogSwitch.tagModalShare = true;
      },
      handleDelete(row, index) {
        this.$confirm(`确认要删除吗?`, this.$t('warning'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning',
        })
          .then(async () => {
            await t.request.post('/sharegroup/delete', row);
            this.list.splice(index, 1);
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      getRenderData() {
        t.request
          .get('/sharegroup/render')
          .then((response) => {
            const res = response.data;
            let i = 0;
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of res.modelData) {
              if (modelDatum.show === '1') {
                t.listModelNum = i + 1;
                /* if (i > 5) {
                  i++;
                  continue;
                }*/
                // eslint-disable-next-line no-prototype-builtins
                if (!t.showColums.hasOwnProperty(modelDatum.key)) {
                  t.$set(t.showColums, modelDatum.key, true);
                }
                i++;
              }
            }
            t.saveColmun();
            t.option = res.option || {};
            t.summar = res.summar || {};
            t.listModelServer = res.modelData || [];
            t.listSearchServer = res.searchData || [];
            t.listQuery.sort = res.defaultSort || [];
            t.pageSizelist = res.pageSizelist || [];
            t.getList();
          })
          .catch((res) => {
            res.errMsg
              ? t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              })
              : t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
          });
      },
      getList() {
        this.listLoading = true;
        t.request
          .get('/sharegroup/list', this.listQuery)
          .then((response) => {
            this.list = response.data.items;
            this.total = response.data.total;
            // Just to simulate the time of the request
            this.listLoading = false;
          })
          .catch((res) => {
            this.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      sortChange(data) {
        const { prop, order } = data;
        t.sortByID(order, prop);
      },
      sortByID(order, prop) {
        if (order === 'ascending') {
          t.listQuery.sort = `${prop} ASC`;
        } else {
          t.listQuery.sort = `${prop} DESC`;
        }
        t.handleFilter();
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    },
  };
</script>

<style lang="scss" scoped>
.el-select-dropdown {
  /*background-color: unset ;*/
}

.el-input__inner {
  background-color: unset !important;
}

.pagination-container {
  background: unset;
}

.el-table__cell {
  background-color: unset;
}
</style>
