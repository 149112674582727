<template>
  <div v-loading="loading">
    <Breadcrumbs main="Project" :title="$t('Agency Center')" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 project-list">
          <div class="card">
            <div class="row m-0">
              <div class="col-sm-12 p-0">
                <b-tabs
                  content-class="mt-3"
                  v-model="tabActive"
                  small
                  align="center"
                  active-nav-item-class="font-weight-bold text-uppercase text-danger"
                  active-tab-class="font-weight-bold text-success"
                >
                  <b-tab>
                    <template v-slot:title>
                      <feather type="target"></feather>
                      {{ $t("Subordinate user") }}
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div
                              class="row"
                              style="width: 100%; position: relative"
                            >
                              <div
                                style="width: 100%"
                                v-loading="levelList.listLoading"
                              >
                                <div class="card">
                                  <div class="card-body">
                                    <div
                                      v-if="levelList.option.showSearchBt"
                                      class="filter-container"
                                    >
                                      <span
                                        v-for="(
                                          searchItem, searchIndex
                                        ) in levelList.listSearchServer"
                                        :key="searchIndex"
                                        style="margin-left: 5px"
                                      >
                                        <el-input
                                          v-if="searchItem.htype === 'text'"
                                          v-model="
                                            levelList.listQuery[searchItem.key]
                                          "
                                          type="text"
                                          :placeholder="
                                            $t('Please enter the') +
                                            searchItem.label
                                          "
                                          style="width: 160px"
                                          class="filter-item"
                                          @keyup.enter.native="
                                            handleLevelListFilter
                                          "
                                        />
                                        <el-input
                                          v-if="searchItem.htype === 'number'"
                                          v-model="
                                            levelList.listQuery[searchItem.key]
                                          "
                                          type="number"
                                          :placeholder="
                                            $t('Please enter the') +
                                            searchItem.label
                                          "
                                          style="width: 160px"
                                          class="filter-item"
                                          @keyup.enter.native="
                                            handleLevelListFilter
                                          "
                                        />
                                        <el-select
                                          v-if="searchItem.htype === 'select'"
                                          v-model="
                                            levelList.listQuery[searchItem.key]
                                          "
                                          :placeholder="
                                            $t('Please select a') +
                                            searchItem.label
                                          "
                                          clearable
                                          class="filter-item"
                                          style="width: 160px"
                                        >
                                          <el-option
                                            v-for="(
                                              item, key
                                            ) in searchItem.select"
                                            :key="key"
                                            :label="item.title"
                                            :value="item.id"
                                          />
                                        </el-select>
                                      </span>
                                      <el-button
                                        class="filter-item"
                                        style="margin-left: 10px"
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleLevelListFilter"
                                        >{{ $t("Search") }}
                                      </el-button>
                                      <el-dropdown style="margin-left: 15px">
                                        <a type="primary" size="mini"
                                          >{{ $t("Display of columns")
                                          }}<i
                                            class="el-icon-arrow-down el-icon--right"
                                          ></i
                                        ></a>

                                        <el-dropdown-menu slot="dropdown">
                                          <el-dropdown-item
                                            style="
                                              border-bottom: 1px solid #dcdfe6;
                                            "
                                            command="sendModifyData"
                                          >
                                            <el-checkbox
                                              v-model="allStatus"
                                              @change="selectAllFun"
                                              >{{
                                                allStatus
                                                  ? $t("CancelFull")
                                                  : $t("Full")
                                              }}
                                            </el-checkbox>
                                          </el-dropdown-item>
                                          <el-dropdown-item
                                            command="sendModifyData"
                                            v-for="(
                                              item, index
                                            ) in levelList.listModelServer"
                                            :key="index"
                                            v-show="item.show === '1'"
                                          >
                                            <el-checkbox
                                              v-model="
                                                levelList.showColums[item.key]
                                              "
                                              @change="saveLevelListColmun"
                                              >{{ item.label }}</el-checkbox
                                            >
                                          </el-dropdown-item>
                                        </el-dropdown-menu>
                                      </el-dropdown>
                                    </div>

                                    <div>
                                      <el-table
                                        :key="levelList.tableKey"
                                        :data="levelList.list"
                                        border
                                        fit
                                        highlight-current-row
                                        :show-summary="levelList.summar.switch"
                                        style="width: 100%; border-radius: 10px"
                                        @sort-change="sortLevelListChange"
                                      >
                                        <el-table-column
                                          v-for="(
                                            item, index
                                          ) in levelList.listModelServer"
                                          v-if="
                                            item.show === '1' &&
                                            levelList.showColums[item.key]
                                          "
                                          :key="index"
                                          :label="item.label"
                                          :prop="item.key"
                                          :sortable="item.sort === '1'"
                                          align="center"
                                          show-overflow-tooltip
                                        >
                                          <template slot-scope="{ row }">
                                            <span
                                              v-if="item.showtype === 'text'"
                                              >{{ row[item.key] }}</span
                                            >
                                            <span
                                                style="cursor: pointer;"
                                                @click="remarkEdit(row)"
                                              v-if="item.showtype === 'textRemark'"
                                              >{{ row[item.key] }} <i class="el-icon-edit"></i></span
                                            >
                                            <span
                                              v-if="item.showtype === 'date'"
                                            >
                                              {{
                                                row[item.key]
                                                  ? row[item.key]
                                                      .replace("+08:00", "")
                                                      .replace("T", " ")
                                                  : row[item.key]
                                              }}
                                            </span>
                                            <span
                                              v-if="item.showtype === 'arr'"
                                            >
                                              <el-tag
                                                v-for="(
                                                  arritem, arrindex
                                                ) in item.showarr"
                                                :key="arrindex"
                                                :type="arritem.tagtype"
                                                v-show="
                                                  arritem.id === row[item.key]
                                                "
                                                >{{
                                                  item.showarr[arrindex].title
                                                }}</el-tag
                                              >
                                            </span>
                                            <span v-if="item.showtype === 'img'"
                                              ><el-image
                                                :preview-src-list="[
                                                  row[item.key],
                                                ]"
                                                fit="fill"
                                                :src="row[item.key]"
                                            /></span>
                                          </template>
                                        </el-table-column>
                                      </el-table>

                                      <pagination
                                        v-show="levelList.total > 0"
                                        :total="levelList.total"
                                        :page.sync="levelList.listQuery.page"
                                        :limit.sync="levelList.listQuery.limit"
                                        :page-sizes="levelList.pageSizelist"
                                        @pagination="getLevelListList"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <feather type="info"></feather>
                      {{ $t("Commission and share") }}
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div
                              class="row"
                              style="width: 100%; position: relative"
                            >
                              <div
                                style="width: 100%"
                                v-loading="staticRechange.listLoading"
                              >
                                <el-card class="box-card">
                                  <div slot="header" class="clearfix">
                                    <span style="font-size: 16px;">{{$t('Reward description')}}</span>
                                    <el-button style="float: right; padding: 3px 0" type="text"
                                               v-clipboard:copy="config.domain +'/web/#/auth/register?parent=' +userinfo.id"
                                               v-clipboard:success="onCopySuccess"
                                               v-clipboard:error="onCopyError"
                                              >{{$t('Copy promotion link')}}</el-button>
                                  </div>
                                  <div class="text item" style="color: #909399;">
                                    <div>1.{{$t('Customers who register through your link will receive the transaction amount for each transaction')}}<span style="color: red">{{rechargePush * 100 }}%</span>作为奖励提成</div>
                                    <div>2.{{$t('The settlement period of the reward commission is 1-5 next month. Contact the customer service of Neptune to apply for the settlement of last month\'s commission')}}</div>
                                    <div>3.{{$t('These rewards can be earned by recommending users to use the product, and there is no limit to the amount of rewards and the time of royalties')}}</div>
                                    <div>4.{{$t('To meet the minimum agency standards to give settlement every month, the final interpretation right belongs to Haiwang')}}</div>
                                    <div style="margin-top: 10px">
                                      {{$t('Your proprietary registration link')}}：
                                      <a
                                          target="_blank"
                                          class="aUrl"
                                          :href="
                                        config.domain +
                                        '/web/#/auth/register?parent=' +
                                        userinfo.id
                                      "
                                      >{{
                                          config.domain +
                                          "/web/#/auth/register?parent=" +
                                          userinfo.id
                                        }}</a
                                      >
                                      {{$t('For customers who register through your link, you can see the status of all accounts under your name')}}
                                    </div>
                                  </div>
                                </el-card>
<!--                                <div style="margin: 10px">
                                  <div>
                                    提成比例为
                                    <span style="color: red">{{
                                      rechargePush * 100
                                    }}</span>
                                    %
                                  </div>

                                </div>-->
                                <div class="card" style="margin-top: 10px;">
                                  <div class="card-body">
                                    <div>
                                      <el-table
                                          :data="rebateList"
                                          border
                                          fit
                                          highlight-current-row
                                          style="width: 100%;"
                                      >
                                        <el-table-column :label="$t('Todays total')" align="center" prop="account" show-overflow-tooltip>
                                          <template slot-scope="{row}">
                                            <span>{{ row.todayRebate }}</span>
                                          </template>
                                        </el-table-column>
                                        <el-table-column  :label="$t('Total amount for the month')" align="center" show-overflow-tooltip>
                                          <template slot-scope="{row}">
                                            <span>{{ row.monthRebate }}</span>
                                            <!--          <svg-icon v-for="n in + row.updateip" :key="n" icon-class="star" class="meta-item__icon" />-->
                                          </template>
                                        </el-table-column>
                                        <el-table-column :label="$t('The amount settled this month')" align="center" show-overflow-tooltip>
                                          <template slot-scope="{row}">
                                            <span>{{ row.monthAlreadySettled }}</span>
                                          </template>
                                        </el-table-column>
                                        <el-table-column :label="$t('Outstanding amount for the month')" align="center" show-overflow-tooltip>
                                          <template slot-scope="{row}">
                                            <span>{{ row.monthNotSettled }}</span>
                                          </template>
                                        </el-table-column>
                                        <el-table-column  :label="$t('Last month\'s total')" align="center" show-overflow-tooltip>
                                          <template slot-scope="{row}">
                                            <span>{{ row.lostMonthRebate }}</span>
                                            <!--          <svg-icon v-for="n in + row.updateip" :key="n" icon-class="star" class="meta-item__icon" />-->
                                          </template>
                                        </el-table-column>
                                        <el-table-column  :label="$t('Amount settled last month')" align="center" show-overflow-tooltip>
                                          <template slot-scope="{row}">
                                            <span>{{ row.lostMonthAlreadySettled }}</span>
                                          </template>
                                        </el-table-column>
                                        <el-table-column :label="$t('Outstanding amount for last month')" align="center" show-overflow-tooltip>
                                          <template slot-scope="{row}">
                                            <span>{{ row.lostMonthNotSettled }}</span>
                                          </template>
                                        </el-table-column>
                                        <el-table-column  :label="$t('Total rebate')" align="center" show-overflow-tooltip>
                                          <template slot-scope="{row}">
                                            <span>{{ row.rebate }}</span>
                                          </template>
                                        </el-table-column>
                                        <el-table-column :label="$t('Total settled amount')" align="center" show-overflow-tooltip>
                                          <template slot-scope="{row}">
                                            <span>{{ row.alreadySettled }}</span>
                                          </template>
                                        </el-table-column>
                                        <el-table-column :label="$t('Total outstanding amount')" align="center" show-overflow-tooltip>
                                          <template slot-scope="{row}">
                                            <span>{{ row.notSettled }}</span>
                                          </template>
                                        </el-table-column>
                                      </el-table>
                                      <el-table
                                        :key="staticRechange.tableKey"
                                        :data="staticRechange.list"
                                        border
                                        fit
                                        highlight-current-row
                                        :show-summary="
                                          staticRechange.summar.switch
                                        "
                                        style="width: 100%; border-radius: 10px;margin-top: 10px;"
                                        @sort-change="sortstaticRechangeChange"
                                      >
                                        <el-table-column
                                          v-for="(
                                            item, index
                                          ) in staticRechange.listModelServer"
                                          v-if="item.show === '1'"
                                          :key="index"
                                          :label="item.label"
                                          :prop="item.key"
                                          :sortable="item.sort === '1'"
                                          align="center"
                                          show-overflow-tooltip
                                        >
                                          <template slot-scope="{ row }">
                                            <span
                                              v-if="item.showtype === 'text'"
                                              >{{ row[item.key] }}</span
                                            >
                                            <span
                                              v-if="item.showtype === 'date'"
                                            >
                                              {{
                                                row[item.key]
                                                  ? row[item.key]
                                                      .replace("+08:00", "")
                                                      .replace("T", " ")
                                                  : row[item.key]
                                              }}
                                            </span>
                                            <span
                                              v-if="item.showtype === 'arr'"
                                            >
                                              <el-tag
                                                v-for="(
                                                  arritem, arrindex
                                                ) in item.showarr"
                                                :type="arritem.tagtype"
                                                :key="arrindex"
                                                v-show="
                                                  arritem.id === row[item.key]
                                                "
                                                >{{
                                                  item.showarr[arrindex].title
                                                }}</el-tag
                                              >
                                            </span>
                                            <span v-if="item.showtype === 'img'"
                                              ><el-image
                                                fit="fill"
                                                :preview-src-list="[
                                                  row[item.key],
                                                ]"
                                                :src="row[item.key]"
                                            /></span>
                                          </template>
                                        </el-table-column>
                                      </el-table>

                                      <pagination
                                        v-show="staticRechange.total > 0"
                                        :total="staticRechange.total"
                                        :page.sync="
                                          staticRechange.listQuery.page
                                        "
                                        :limit.sync="
                                          staticRechange.listQuery.limit
                                        "
                                        :page-sizes="
                                          staticRechange.pageSizelist
                                        "
                                        @pagination="getstaticRechangeList"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Pagination from '@/components/Pagination';
  let t;
  export default {
    name: 'agentcenter',
    components: { Pagination },
    data() {
      return {
        tabActive: 0,
        allStatus: false,
        rechargePush: 0,
        levelList: {
          listQuery: {
            page: 1,
            limit: 10,
            sort: '',
          },
          listModelNum: 0,
          totalRows: 1,
          currentPage: 1,
          perPage: 10,
          pageOptions: [5, 10, 15],
          tableKey: 1,
          listLoading: false,
          list: [],
          showColums: {},

          summar: {
            switch: false,
            data: {},
          },
          total: 0,
          listModelServer: [],
          listSearchServer: [],
          title: '',
          option: {
            showAddBt: false,
            showCatchEditBt: false,
            showExportBt: false,
            showSearchBt: true,
          },
        },
        staticRechange: {
          listQuery: {
            page: 1,
            limit: 10,
            sort: '',
          },

          totalRows: 1,
          currentPage: 1,
          perPage: 10,
          pageOptions: [5, 10, 15],
          tableKey: 1,
          listLoading: false,
          list: [],
          showColums: {},
          summar: {
            switch: false,
            data: {},
          },
          total: 0,
          listModelServer: [],
          listSearchServer: [],
          title: '',
          option: {
            showAddBt: false,
            showCatchEditBt: false,
            showExportBt: false,
            showSearchBt: true,
          },
        },
        loading: false,
        userinfo: {},
        rebateList: [{
          todayRebate: 0.0,
          todaySystemRebate: 0.0,
          todayManualRebate: 0.0,
          monthRebate: 0.0,
          monthSystemRebate: 0.0,
          monthManualRebate: 0.0,
          rebate: 0.0,
          systemRebate: 0.0,
          manualRebate: 0.0,
          monthAlreadySettled: 0.0,
          monthNotSettled: 0.0,
          alreadySettled: 0.0,
          notSettled: 0.0,
          selectSettled: 0.0,
          selectNotSettled: 0.0
        }],
      };
    },
    computed: {
    ...mapState({
      allprojects: (state) => state.common.allprojects,
      doingprojects: (state) => state.common.doingprojects,
      doneprojects: (state) => state.common.doneprojects,
    }),
    },
    watch: {
      tabActive(newVal, oldVal) {
        if (newVal === 0) {
          t.handleLevelList();
        } else {
          t.handlestaticRechange();
        }
      },
    },
    mounted() {
      this.userinfo = JSON.parse(localStorage.getItem('userinfo'));

      this.$el.querySelector('.nav-tabs').style.justifyContent = 'center';
      t = this;
      t.getLevelListColumn();
      t.getstaticRechangeColumn();
      t.handleLevelList();
    },
    methods: {
      remarkEdit(row){
        console.log(row);
        this.$prompt('请输入备注', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue:row.agent_remark,
        }).then(({ value }) => {
          row.agent_remark = value;
          t.request
            .post('/agent/editRemark',row).then(res=>{
              this.$message({
                type: 'success',
                message: value
              });
            }).catch(err=>{
              console.error(err);
            });

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
      },
      onCopySuccess(e) { // 复制成功
        this.$message({
          message: '复制成功！',
          type: 'success'
        });
      },
      onCopyError(e) { // 复制失败
        this.$message({
          message: '复制失败！',
          type: 'error'
        });
      },
      tabchange(index) {
        console.log(index);
      
      },
      handleLevelList() {
        t.levelList.listLoading = true;
        t.request
          .get('/agent/levelrender')
          .then((response) => {
            const res = response.data;
            let i = 0;
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of res.modelData) {
              if (modelDatum.show === '1') {
                t.levelList.listModelNum = i + 1;
                /*if (i > 5) {
                i++;
                continue;
              }*/
                // eslint-disable-next-line no-prototype-builtins
                if (!t.levelList.showColums.hasOwnProperty(modelDatum.key)) {
                  t.$set(t.levelList.showColums, modelDatum.key, true);
                }
                i++;
              }
            }
            t.saveLevelListColmun();
            t.levelList.option = res.option ? res.option : [];
            t.levelList.summar = res.summar || {};
            t.levelList.listModelServer = res.modelData || [];
            t.levelList.listSearchServer = res.searchData || [];
            t.levelList.listQuery.sort = res.defaultSort || [];
            t.levelList.pageSizelist = res.pageSizelist || [];
            t.levelList.listLoading = false;
            t.getLevelListList();
          })
          .catch((res) => {
            t.levelList.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      getLevelListList() {
        t.levelList.listLoading = true;
        t.request
          .get('/agent/levellist', t.levelList.listQuery)
          .then((response) => {
            t.levelList.list = response.data.items;
            t.levelList.total = response.data.total;
            // Just to simulate the time of the request
            t.levelList.listLoading = false;
          })
          .catch((res) => {
            t.levelList.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      sortLevelListChange(data) {
        const { prop, order } = data;
        t.sortLevelListByID(order, prop);
      },
      sortLevelListByID(order, prop) {
        if (order === 'ascending') {
          t.levelList.listQuery.sort = `${prop} ASC`;
        } else {
          t.levelList.listQuery.sort = `${prop} DESC`;
        }
        t.handleLevelListFilter();
      },
      handleLevelListFilter() {
        this.levelList.listQuery.page = 1;
        this.getLevelListList();
      },
      getLevelListColumn() {
        t.levelList.showColums = JSON.parse(
          window.localStorage.getItem('levellist_colmun') || '{}'
        );
      },
      saveLevelListColmun() {
        let arr = Object.values(t.levelList.showColums).filter((item) => item);
        t.allStatus = arr.length === t.levelList.listModelNum;
        window.localStorage.setItem(
          'levellist_colmun',
          JSON.stringify(t.levelList.showColums)
        );
      },
      selectAllFun(e) {
        !t.allStatus;
        let i = 0;
        t.levelList.listModelServer.forEach((item) => {
          if (item.show === '1') {
            if (!t.allStatus && i <= 5) {
              t.$set(t.levelList.showColums, item.key, true);
            } else if (!t.allStatus && i > 5) {
              t.$set(t.levelList.showColums, item.key, false);
            } else {
              t.$set(t.levelList.showColums, item.key, true);
            }
            i++;
          }
        });
        window.localStorage.setItem(
          'levellist_colmun',
          JSON.stringify(t.showColums)
        );
      },

      handlestaticRechange() {
        t.staticRechange.listLoading = true;
        t.request
          .get('/agent/staticrender')
          .then((response) => {
            const res = response.data;
            let i = 0;
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of res.modelData) {
              if (modelDatum.show === '1') {
                if (i > 5) break;
                // eslint-disable-next-line no-prototype-builtins
                if (!t.staticRechange.showColums.hasOwnProperty(modelDatum.key)) {
                  this.$set(t.staticRechange.showColums, modelDatum.key, true);
                }
                i++;
              }
            }
            t.staticRechange.option = res.option ? res.option : [];
            t.staticRechange.summar = res.summar || {};
            t.rechargePush = res.rechargePush || 0;
            t.staticRechange.listModelServer = res.modelData || [];
            t.staticRechange.listSearchServer = res.searchData || [];
            t.staticRechange.listQuery.sort = res.defaultSort || [];
            t.staticRechange.pageSizelist = res.pageSizelist || [];
            t.staticRechange.listLoading = false;
            t.getstaticRechangeList();
          })
          .catch((res) => {
            t.staticRechange.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      getstaticRechangeList() {
        t.staticRechange.listLoading = true;
        t.request
          .get('/agent/staticlist', t.staticRechange.listQuery)
          .then((response) => {
            t.staticRechange.list = response.data.items;
            t.staticRechange.total = response.data.total;
            // Just to simulate the time of the request
            t.staticRechange.listLoading = false;
            this.rebateList = response.data.rebateData;
          })
          .catch((res) => {
            t.staticRechange.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      sortstaticRechangeChange(data) {
        const { prop, order } = data;
        t.sortstaticRechangeByID(order, prop);
      },
      sortstaticRechangeByID(order, prop) {
        if (order === 'ascending') {
          t.staticRechange.listQuery.sort = `${prop} ASC`;
        } else {
          t.staticRechange.listQuery.sort = `${prop} DESC`;
        }
        t.handlestaticRechangeFilter();
      },
      handlestaticRechangeFilter() {
        this.staticRechange.listQuery.page = 1;
        this.getstaticRechangeList();
      },
      getstaticRechangeColumn() {
        t.staticRechange.showColums = JSON.parse(
          window.localStorage.getItem('staticRechange_colmun') || '{}'
        );
      },
      savestaticRechangeColmun() {
        window.localStorage.setItem(
          'staticRechange_colmun',
          JSON.stringify(t.staticRechange.showColums)
        );
      },
    },
  };
</script>
<style scoped lang="scss">
.aUrl:link {
  color: #e74c3c;
}
.aUrl:hover {
  color: #28a745;
}
.aUrl:active {
  color: #3c45e7;
}
.downloadImgBack {
  width: 100%;
  display: flex;
  margin-top: 5%;

  .downloadImg {
    flex: 1.5;

    > img {
      object-fit: scale-down;
      width: 100%;
      height: 100%;
    }
  }

  .downloadTips {
    flex: 0.5;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-size: 1.5em;
      color: #000;
      padding-bottom: 8px;
      font-weight: 500;
      font-family: PingFangSC-Medium, sans-serif;
    }

    .tip {
      font-size: 1em;
      color: #999;
    }

    .down {
      margin-top: 20px;

      > button {
        padding: 0 35px;
        height: 40px;
        font-size: 16px;
        border-radius: 40px;
      }
    }

    .version {
      margin-top: 5px;
      display: inline-block;
      vertical-align: middle;
      color: #000;
      opacity: 0.5;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
    }
  }
}
</style>
