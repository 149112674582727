<template>
  <li class="profile-nav onhover-dropdown p-0 mr-0">
    <div class="media profile-media">
      <img
        class="b-r-10"
        src="../../assets/images/dashboard/profile.png"
        alt=""
      />
      <div class="media-body" style="display: block; margin-right: 20px">
        <span>{{ userinfo.email }}</span>
        <p class="mb-0 font-roboto">
          {{ userinfo.displayName || "HaiWang" }}
          <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div" style="opacity: 1">
      <li>
        <a @click="handleRechange" href="#"
          ><feather type="credit-card"></feather
          ><span>{{ $t("Rechange") }}</span></a
        >
      </li>
      <li>
        <a @click="handleRechangeLog" href="#"
          ><feather type="columns"></feather
          ><span>{{ $t("RechargeRecord") }}</span></a
        >
      </li>
      <li>
        <a @click="handleBuyLog" href="#"
          ><feather type="shopping-bag"></feather
          ><span>{{ $t("PurchaseRecord") }}</span></a
        >
      </li>
      <li>
        <a @click="handleChangePassword(true)" href="#"
          ><feather type="lock"></feather
          ><span>{{ $t("Change password") }}</span></a
        >
      </li>
      <!--      <li>
        <a href="#"><feather type="pocket"></feather><span>{{$t("Security query")}}</span></a>
      </li>-->
      <li v-if="userinfo.isagent === 1">
        <router-link to="agentcenter">
          <feather type="users"></feather>
          <span>{{ $t("Agency Center") }}</span>
        </router-link>
      </li>
      <li
        v-if="false"
        @click="handleRechange"
        style="cursor: pointer; display: flex"
      >
        <feather type="bookmark"></feather>
        <span
          style="
            color: rgba(0, 0, 0, 0.45);
            font-size: 14px;
            text-align: center;
          "
        >
          {{ $t("The balance of my") }}:
        </span>
        <span style="text-align: center"> ${{ wallet }}</span>
      </li>
      <!-- 公告 -->
      <!-- <li v-if="!isPCShow" style="position: relative; height: 25px">
        <span style="position: absolute; left: 35px; top: 3px">{{
          $t("Notitications")
        }}</span>
        <Notifications
          style="margin-left: -10px; margin-top: -18px"
          :radio-list="radioList"
        />
      </li> -->
      <li>
        <a @click="handleEntrylog" href="#"
          ><feather type="user"></feather><span>{{ $t("Entry log") }}</span></a
        >
      </li>
      <li>
        <a @click="logout">
          <feather type="log-in"></feather>
          <span>{{ $t("Log out") }}</span>
        </a>
      </li>
    </ul>
    <el-dialog
      :visible.sync="changePasswordData.showWindow"
      :title="$t('Change password')"
      :append-to-body="true"
      :width="isPCShow ? '60%' : '90%'"
    >
      <div class="modal-body" style="width: 100%">
        <form class="form-bookmark needs-validation" novalidate="">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>{{ $t("Old Password") }}</label>
              <input
                class="form-control"
                v-model="changePasswordData.oldpass"
                type="text"
                :placeholder="$t('Please enter the') + $t('Old Password')"
                autocomplete="off"
              />
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("New Password") }}</label>
              <input
                class="form-control"
                v-model="changePasswordData.newpass"
                type="password"
                :placeholder="$t('Please enter the') + $t('New Password')"
                autocomplete="off"
              />
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("Confirm Password") }}</label>
              <input
                class="form-control"
                v-model="changePasswordData.confirmpass"
                type="password"
                :placeholder="$t('Please enter the') + $t('Confirm Password')"
                autocomplete="off"
              />
            </div>
          </div>
          <button
            class="btn btn-secondary"
            type="button"
            @click="handleChangePassword(false)"
          >
            {{ $t("confirm") }}
          </button>
          <button
            class="btn btn-primary ml-2"
            type="button"
            data-dismiss="modal"
            @click="changePasswordData.showWindow = false"
          >
            {{ $t("Cancel") }}
          </button>
        </form>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogSwitch.tagModalRechange"
      :title="$t('Rechange')"
      :width="isPCShow ? '60%' : '90%'"
      :append-to-body="true"
    >
      <div class="modal-body" style="width: 100%; margin-top: -50px">
        <form class="form-bookmark needs-validation" novalidate="">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>{{ $t("Top-up instructions") }}</label>
              <div class="moneylistBackground">
                <span style="font-size: 12px" v-html="r_remark"></span>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("Amount of choice") }}</label>
              <div class="moneylistBackground">
                <div
                  v-for="(item, index) in moneyList"
                  v-bind:class="
                    item === rechangeData.money ? 'moneyActive' : ''
                  "
                  :key="index"
                  @click="
                    rechangeData.money = item;
                    inputAmount = false;
                  "
                >
                  {{ item }}$
                </div>
                <div
                  v-bind:class="inputAmount ? 'moneyActive' : ''"
                  @click="
                    rechangeData.money = '';
                    inputAmount = true;
                  "
                >
                  {{ $t("Input amount") }}
                </div>
              </div>
            </div>
            <div class="form-group col-md-12" v-if="inputAmount">
              <label>{{ $t("Input amount") }}&nbsp;($)</label>
              <input
                class="form-control"
                @input="moneyInputInt"
                v-model="rechangeData.money"
                type="number"
                :placeholder="$t('Please enter the') + $t('amount')"
                autocomplete="off"
              />
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("Type of payment") }}</label>
              <el-radio-group
                style="display: block; margin-top: 5px"
                v-model="rechangeData.paytype"
              >
                <el-radio :label="0">
                  <img src="../../assets/images/usdt.png" alt="usdt" />
                </el-radio>
                <el-radio :label="1">
                  <img src="../../assets/images/alipay.png" alt="alipay" />
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <button class="btn btn-secondary" type="button" @click="rechange">
            {{ $t("confirm") }}
          </button>
          <button
            class="btn btn-primary ml-2"
            type="button"
            data-dismiss="modal"
            @click="dialogSwitch.tagModalRechange = false"
          >
            {{ $t("Cancel") }}
          </button>
        </form>
      </div>
    </el-dialog>
    <!-- 充值记录 -->
    <el-dialog
      :visible.sync="dialogSwitch.contactModelRechangeLog"
      :title="rechangeLog.title"
      :width="isPCShow ? '70%' : '90%'"
      :append-to-body="true"
    >
      <div v-loading="rechangeLog.listLoading" style="width: 100%">
        <div :class="isPCShow ? 'card-body' : ''">
          <div v-if="rechangeLog.option.showSearchBt" class="filter-container">
            <el-date-picker
              v-model="rechangeLog.listQuery.createdatstart"
              :style="
                isPCShow ? { width: '200px', top: '-4px' } : { width: '8rem' }
              "
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :placeholder="$t('Please select a start time')"
            />
            <el-date-picker
              v-model="rechangeLog.listQuery.createdatend"
              :style="
                isPCShow
                  ? { width: '200px', 'margin-left': '5px' }
                  : { width: '8rem', 'margin-left': '10px' }
              "
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :placeholder="$t('Please select an end time')"
            />
            <span
              v-for="(searchItem, searchIndex) in rechangeLog.listSearchServer"
              :key="searchIndex"
              :style="isPCShow ? 'margin-left: 5px' : 'margin-top: 10px'"
            >
              <el-input
                v-if="searchItem.htype === 'text'"
                v-model="rechangeLog.listQuery[searchItem.key]"
                type="text"
                :placeholder="$t('Please enter the') + searchItem.label"
                :style="
                  isPCShow
                    ? { width: '200px' }
                    : {
                        width: '8rem',
                        'margin-right': '10px',
                        'margin-top': '10px',
                      }
                "
                class="filter-item"
                @keyup.enter.native="handleRechangeLogFilter"
              />
              <el-input
                v-if="searchItem.htype === 'number'"
                v-model="rechangeLog.listQuery[searchItem.key]"
                type="number"
                :placeholder="$t('Please enter the') + searchItem.label"
                :style="isPCShow ? { width: '200px' } : { width: '8rem' }"
                class="filter-item"
                @keyup.enter.native="handleRechangeLogFilter"
              />
              <el-select
                v-if="searchItem.htype === 'select'"
                v-model="rechangeLog.listQuery[searchItem.key]"
                :placeholder="$t('Please select a') + searchItem.label"
                clearable
                class="filter-item"
                :style="
                  isPCShow
                    ? { width: '200px' }
                    : { width: '8rem', 'margin-top': '10px' }
                "
              >
                <el-option
                  v-for="(item, key) in searchItem.select"
                  :key="key"
                  :label="item.title"
                  :value="item.id"
                />
              </el-select>
            </span>
            <el-button
              class="filter-item"
              :style="isPCShow ? 'margin-left: 10px' : 'margin-left: 0px'"
              type="primary"
              icon="el-icon-search"
              @click="handleRechangeLogFilter"
              >{{ $t("Search") }}
            </el-button>
            <el-dropdown style="margin-left: 15px">
              <a type="primary" size="mini"
                ><i class="el-icon-setting"></i>{{ $t("Display of columns")
                }}<i class="el-icon-arrow-down el-icon--right"></i
              ></a>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="sendModifyData"
                  v-for="(item, index) in rechangeLog.listModelServer"
                  :key="index"
                  v-show="item.show === '1'"
                >
                  <el-checkbox
                    v-model="rechangeLog.showColums[item.key]"
                    @change="saveRechangeLogColmun"
                    >{{ item.label }}</el-checkbox
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="filter-container">
            <!--      <el-button class="filter-item" style="margin-left: 10px;" type="warning" icon="el-icon-delete" @click="handleDelete">删除</el-button>-->
            <el-button
              v-if="rechangeLog.option.showExportBt"
              v-waves
              :loading="rechangeLog.downloadLoading"
              class="filter-item"
              type="primary"
              icon="el-icon-download"
              @click="handleRechangeLogDownload"
              >{{ $t("Export") }}
            </el-button>
          </div>
          <div>
            <el-table
              :key="rechangeLog.tableKey"
              :data="rechangeLog.list"
              border
              fit
              highlight-current-row
              :show-summary="rechangeLog.summar.switch"
              :summary-method="getRechangeLogSummar"
              style="width: 100%; border-radius: 10px"
              @sort-change="sortRechangeLogChange"
            >
              <el-table-column
                v-for="(item, index) in rechangeLog.listModelServer"
                v-if="item.show === '1' && rechangeLog.showColums[item.key]"
                :key="index"
                :label="item.label"
                :prop="item.key"
                :sortable="item.sort === '1'"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="{ row }">
                  <span v-if="item.showtype === 'text'">{{
                    row[item.key]
                  }}</span>
                  <span v-if="item.showtype === 'date'">
                    {{
                      row[item.key]
                        ? row[item.key].replace("+08:00", "").replace("T", " ")
                        : row[item.key]
                    }}
                  </span>
                  <span v-if="item.showtype === 'arr'">
                    <el-tag
                      v-for="(arritem, arrindex) in item.showarr"
                      :key="arrindex"
                      :type="arritem.tagtype"
                      v-show="arritem.id === row[item.key]"
                      >{{ item.showarr[arrindex].title }}</el-tag
                    >
                  </span>
                  <span v-if="item.showtype === 'img'"
                    ><el-image fit="fill" :src="row[item.key]"
                  /></span>
                </template>
              </el-table-column>
            </el-table>

            <pagination
              v-show="rechangeLog.total > 0"
              :total="rechangeLog.total"
              :page.sync="rechangeLog.listQuery.page"
              :limit.sync="rechangeLog.listQuery.limit"
              :page-sizes="rechangeLog.pageSizelist"
              @pagination="getRechangeLogList"
            />
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogSwitch.contactModelBuylog"
      :title="buyLog.title"
      :width="isPCShow ? '70%' : '90%'"
      :append-to-body="true"
    >
      <div v-loading="buyLog.listLoading" style="width: 100%">
        <div :class="isPCShow ? 'card-body' : ''">
          <div v-if="buyLog.option.showSearchBt" class="filter-container">
            <el-date-picker
              v-model="buyLog.listQuery.createdatstart"
              :style="
                isPCShow ? { width: '200px', top: '-4px' } : { width: '8rem' }
              "
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :placeholder="$t('Please select a start time')"
            />
            <el-date-picker
              v-model="buyLog.listQuery.createdatend"
              :style="
                isPCShow
                  ? { width: '200px', top: '-4px', 'margin-left': '5px' }
                  : { width: '8rem', 'margin-left': '10px' }
              "
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :placeholder="$t('Please select an end time')"
            />
            <span
              v-for="(searchItem, searchIndex) in buyLog.listSearchServer"
              :key="searchIndex"
              :style="isPCShow ? 'margin-left: 5px' : 'margin-top: 10px'"
            >
              <el-input
                v-if="searchItem.htype === 'text'"
                v-model="buyLog.listQuery[searchItem.key]"
                type="text"
                :placeholder="$t('Please enter the') + searchItem.label"
                :style="isPCShow ? { width: '200px' } : { width: '8rem' }"
                class="filter-item"
                @keyup.enter.native="handleBuyLogFilter"
              />
              <el-input
                v-if="searchItem.htype === 'number'"
                v-model="buyLog.listQuery[searchItem.key]"
                type="number"
                :placeholder="$t('Please enter the') + searchItem.label"
                :style="isPCShow ? { width: '200px' } : { width: '8rem' }"
                class="filter-item"
                @keyup.enter.native="handleBuyLogFilter"
              />
              <el-select
                v-if="searchItem.htype === 'select'"
                v-model="buyLog.listQuery[searchItem.key]"
                :placeholder="$t('Please select a') + searchItem.label"
                clearable
                class="filter-item"
                :style="
                  isPCShow
                    ? { width: '200px' }
                    : { width: '8rem', 'margin-top': '10px' }
                "
              >
                <el-option
                  v-for="(item, key) in searchItem.select"
                  :key="key"
                  :label="item.title"
                  :value="item.id"
                />
              </el-select>
            </span>
            <el-button
              class="filter-item"
              :style="
                isPCShow
                  ? 'margin-left: 10px'
                  : 'margin-left: 10px;margin-top: 10px'
              "
              type="primary"
              icon="el-icon-search"
              @click="handleBuyLogFilter"
              >{{ $t("Search") }}
            </el-button>
            <el-dropdown style="margin-left: 15px">
              <a type="primary" size="mini"
                ><i class="el-icon-setting"></i>{{ $t("Display of columns")
                }}<i class="el-icon-arrow-down el-icon--right"></i
              ></a>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="sendModifyData"
                  v-for="(item, index) in buyLog.listModelServer"
                  :key="index"
                  v-show="item.show === '1'"
                >
                  <el-checkbox
                    v-model="buyLog.showColums[item.key]"
                    @change="saveRechangeLogColmun"
                    >{{ item.label }}</el-checkbox
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="filter-container">
            <!--      <el-button class="filter-item" style="margin-left: 10px;" type="warning" icon="el-icon-delete" @click="handleDelete">删除</el-button>-->
            <el-button
              v-if="buyLog.option.showExportBt"
              v-waves
              :loading="buyLog.downloadLoading"
              class="filter-item"
              type="primary"
              icon="el-icon-download"
              @click="handleRechangeLogDownload"
              >{{ $t("Export") }}
            </el-button>
          </div>
          <div>
            <el-table
              :key="buyLog.tableKey"
              :data="buyLog.list"
              border
              fit
              highlight-current-row
              :show-summary="buyLog.summar.switch"
              :summary-method="getRechangeLogSummar"
              style="width: 100%; border-radius: 10px"
              @sort-change="sortBuyLogChange"
            >
              <el-table-column
                v-for="(item, index) in buyLog.listModelServer"
                v-if="item.show === '1' && buyLog.showColums[item.key]"
                :key="index"
                :label="item.label"
                :prop="item.key"
                :sortable="item.sort === '1'"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="{ row }">
                  <span v-if="item.showtype === 'text'">{{
                    row[item.key]
                  }}</span>
                  <span v-if="item.showtype === 'date'">
                    {{
                      row[item.key]
                        ? row[item.key].replace("+08:00", "").replace("T", " ")
                        : row[item.key]
                    }}
                  </span>
                  <span v-if="item.showtype === 'arr'">
                    <el-tag
                      v-for="(arritem, arrindex) in item.showarr"
                      :key="arrindex"
                      :type="arritem.tagtype"
                      v-show="arritem.id === row[item.key]"
                      >{{ item.showarr[arrindex].title }}</el-tag
                    >
                  </span>
                  <span v-if="item.showtype === 'img'"
                    ><el-image fit="fill" :src="row[item.key]"
                  /></span>
                </template>
              </el-table-column>
            </el-table>

            <pagination
              v-show="buyLog.total > 0"
              :total="buyLog.total"
              :page.sync="buyLog.listQuery.page"
              :limit.sync="buyLog.listQuery.limit"
              :page-sizes="buyLog.pageSizelist"
              @pagination="getBuyLogList"
            />
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogSwitch.showEntrylog"
      :title="entrylog.title"
      :width="isPCShow ? '70%' : '90%'"
      :append-to-body="true"
    >
      <div v-loading="entrylog.listLoading" style="width: 100%">
        <div :class="isPCShow ? 'card-body' : ''">
          <div v-if="entrylog.option.showSearchBt" class="filter-container">
            <el-date-picker
              v-model="entrylog.listQuery.createdatstart"
              :style="isPCShow ? { width: '200px' } : { width: '8rem' }"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :placeholder="$t('Please select a start time')"
            />
            <el-date-picker
              v-model="entrylog.listQuery.createdatend"
              :style="
                isPCShow
                  ? { width: '200px', 'margin-left': '5px' }
                  : { width: '8rem', 'margin-left': '10px' }
              "
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :placeholder="$t('Please select an end time')"
            />
            <span
              v-for="(searchItem, searchIndex) in entrylog.listSearchServer"
              :key="searchIndex"
              :style="isPCShow ? 'margin-left: 5px' : 'margin-top: 10px'"
            >
              <el-input
                v-if="searchItem.htype === 'text'"
                v-model="entrylog.listQuery[searchItem.key]"
                type="text"
                :placeholder="$t('Please enter the') + searchItem.label"
                :style="
                  isPCShow
                    ? { width: '200px' }
                    : {
                        width: '8rem',
                        'margin-right': '10px',
                        'margin-top': '10px',
                      }
                "
                class="filter-item"
                @keyup.enter.native="handleEntrylogFilter"
              />
              <el-input
                v-if="searchItem.htype === 'number'"
                v-model="entrylog.listQuery[searchItem.key]"
                type="number"
                :placeholder="$t('Please enter the') + searchItem.label"
                :style="isPCShow ? { width: '200px' } : { width: '8rem' }"
                class="filter-item"
                @keyup.enter.native="handleEntrylogFilter"
              />
              <el-select
                v-if="searchItem.htype === 'select'"
                v-model="entrylog.listQuery[searchItem.key]"
                :placeholder="$t('Please select a') + searchItem.label"
                clearable
                class="filter-item"
                :style="
                  isPCShow
                    ? { width: '200px' }
                    : { width: '8rem', 'margin-left': '10px' }
                "
              >
                <el-option
                  v-for="(item, key) in searchItem.select"
                  :key="key"
                  :label="item.title"
                  :value="item.id"
                />
              </el-select>
            </span>
            <el-button
              class="filter-item"
              style="margin-left: 10px"
              :style="isPCShow ? 'margin-left: 10px' : 'margin-left: 0px'"
              type="primary"
              icon="el-icon-search"
              @click="handleEntrylogFilter"
              >{{ $t("Search") }}
            </el-button>
            <el-dropdown style="margin-left: 15px">
              <a type="primary" size="mini"
                ><i class="el-icon-setting"></i>{{ $t("Display of columns")
                }}<i class="el-icon-arrow-down el-icon--right"></i
              ></a>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="sendModifyData"
                  v-for="(item, index) in entrylog.listModelServer"
                  :key="index"
                  v-show="item.show === '1'"
                >
                  <el-checkbox
                    v-model="entrylog.showColums[item.key]"
                    @change="saveRechangeLogColmun"
                    >{{ item.label }}</el-checkbox
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div>
            <el-table
              :key="entrylog.tableKey"
              :data="entrylog.list"
              border
              fit
              highlight-current-row
              style="width: 100%; border-radius: 10px"
              @sort-change="sorEntrylogChange"
            >
              <el-table-column
                v-for="(item, index) in entrylog.listModelServer"
                v-if="item.show === '1' && entrylog.showColums[item.key]"
                :key="index"
                :label="item.label"
                :prop="item.key"
                :sortable="item.sort === '1'"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="{ row }">
                  <span v-if="item.showtype === 'text'">{{
                    row[item.key]
                  }}</span>
                  <span v-if="item.showtype === 'date'">
                    {{
                      row[item.key]
                        ? row[item.key].replace("+08:00", "").replace("T", " ")
                        : row[item.key]
                    }}
                  </span>
                  <span v-if="item.showtype === 'arr'">
                    <el-tag
                      v-for="(arritem, arrindex) in item.showarr"
                      :key="arrindex"
                      :type="arritem.tagtype"
                      v-show="arritem.id === row[item.key]"
                      >{{ item.showarr[arrindex].title }}</el-tag
                    >
                  </span>
                  <span v-if="item.showtype === 'img'"
                    ><el-image fit="fill" :src="row[item.key]"
                  /></span>
                </template>
              </el-table-column>
            </el-table>

            <pagination
              v-show="entrylog.total > 0"
              :total="entrylog.total"
              :page.sync="entrylog.listQuery.page"
              :limit.sync="entrylog.listQuery.limit"
              :page-sizes="entrylog.pageSizelist"
              @pagination="getEntrylogList"
            />
          </div>
        </div>
      </div>
    </el-dialog>
  </li>
</template>

<script>
 
  import UserAuth from '../../auth/js/index';
  import Pagination from '@/components/Pagination';

  import Notifications from './notifications';
  let t;
  export default {
    name: 'Profile',
    components: { Pagination, Notifications },
    props: {
      showRechange: {
        default: false,
        type: Boolean,
      },
    },
    data() {
      return {
        wallet: 0,
        radioList: [],
        isPCShow: true,

        windowWidth: window.innerWidth,
        dialogSwitch: {
          tagModalRechange: false,
          contactModelRechangeLog: false,
          contactModelBuylog: false,
          showEntrylog: false,
        },
        moneyList: [100, 200, 300, 500, 1000],
        rechangeData: {
          money: 100,
          paytype: 0,
        },
        payType: [
          {
            id: 0,
            title: '支付宝',
            entitle: 'alipay',
            img: '../../assets/images/alipay.png',
          },
          {
            id: 1,
            title: '微信',
            entitle: 'wepay',
            img: '../../assets/images/wepay.png',
          },
        ],
        rechangeLog: {
          listQuery: {
            page: 1,
            limit: 10,
            sort: '',
          },

          totalRows: 1,
          currentPage: 1,
          perPage: 10,
          pageOptions: [5, 10, 15],
          tableKey: 2,
          listLoading: false,
          list: [],
          showColums: {},
          summar: {
            switch: false,
            data: {},
          },
          total: 0,
          listModelServer: [],
          listSearchServer: [],
          title: '',
          option: {
            showAddBt: false,
            showCatchEditBt: false,
            showExportBt: false,
            showSearchBt: true,
          },
        },
        r_remark: '',
        buyLog: {
          listQuery: {
            page: 1,
            limit: 10,
            sort: '',
          },
          totalRows: 1,
          currentPage: 1,
          perPage: 10,
          pageOptions: [5, 10, 15],
          tableKey: 1,
          listLoading: false,
          list: [],
          showColums: {},
          summar: {
            switch: false,
            data: {},
          },
          total: 0,
          listModelServer: [],
          listSearchServer: [],
          title: '',
          option: {
            showAddBt: false,
            showCatchEditBt: false,
            showExportBt: false,
            showSearchBt: true,
          },
        },
        entrylog: {
          listQuery: {
            page: 1,
            limit: 10,
            sort: '',
          },
          totalRows: 1,
          currentPage: 1,
          perPage: 10,
          pageOptions: [5, 10, 15],
          tableKey: 1,
          listLoading: false,
          list: [],
          showColums: {},
          summar: {
            switch: false,
            data: {},
          },
          total: 0,
          listModelServer: [],
          listSearchServer: [],
          title: '',
          option: {
            showAddBt: false,
            showCatchEditBt: false,
            showExportBt: false,
            showSearchBt: true,
          },
        },
        inputAmount: false,
        userinfo: {
          displayName: '',
          email: '',
          photoURL: '',
          remark: '',
          isagent: 0,
        },
        changePasswordData: {
          oldpass: '',
          newpass: '',
          confirmpass: '',
          showWindow: false,
        },
      };
    },
    watch: {
      showRechange(newVal, oldVal) {
        if (newVal) {
          this.handleRechange();
        }
      },
    },
    created() {
      t = this;
      this.userinfo = JSON.parse(localStorage.getItem('userinfo'));
      t.r_remark = window.localStorage.getItem('r_remark') || '';
      t.getRechangeLogColumn();
    // t.getData();
    },
    mounted() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.windowWidth = window.innerWidth;
        // const windowWidth = window.innerWidth;
        if (this.windowWidth < 768) {

          this.isPCShow = false;
        } else {
          this.isPCShow = true;
        }
      },
      // 影响数据库性能，考虑后续是否删除
      getData() {
        t.request
          .post('/indexinfo')
          .then((res) => {
            const data = res.data;
            t.radioList = data.radiolist;
            t.exptime = data.exptime;
            t.portSessions = data.portSessions;
            t.wallet = data.wallet;
            t.wordSessions = data.wordSessions;
          //t.calcExp();
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              console.error(res);
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      calcExp() {
        const expNum = t.exptime - Date.now() / 1000;
        t.expData.datetime = t.formatUninx(t.exptime * 1000);
        if (expNum <= 0) {
          t.expData.day = '已过期';
        } else {
          if (expNum < 60) {
            t.expData.day = `${expNum}秒`;
            return;
          }
          let expCalc = expNum / 60;
          if (expCalc < 60) {
            t.expData.day = `${Math.floor(expCalc)}分`;
            return;
          }
          expCalc = expCalc / 60;
          if (expCalc < 24) {
            t.expData.day = `${Math.floor(expCalc)}时`;
            return;
          }
          expCalc = expCalc / 24;

          t.expData.day = `${Math.floor(expCalc)}天`;
        }
      },

      moneyInputInt(e) {
        this.rechangeData.money = this.rechangeData.money.replace(/[^0-9]/g, '');
      },
      handleChangePassword(r) {
        if (r) {
          t.changePasswordData.showWindow = true;
        } else {
          t.request
            .post('/changePassword', t.changePasswordData)
            .then((res) => {
              //window.localStorage.setItem('usdtaddr',res.data.usdtaddr);
              t.$toasted.show(res.msg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'success',
                duration: 3000,
              });
              t.changePasswordData.oldpass = '';
              t.changePasswordData.newpass = '';
              t.changePasswordData.confirmpass = '';
              t.changePasswordData.showWindow = false;
            })
            .catch((res) => {
              if (res.errMsg) {
                t.$toasted.show(res.errMsg, {
                  theme: 'bubble',
                  position: 'bottom-center',
                  type: 'error',
                  duration: 3000,
                });
              } else {
                t.$toasted.show(t.$t('Server Error'), {
                  theme: 'bubble',
                  position: 'bottom-center',
                  type: 'error',
                  duration: 3000,
                });
              }
            });
        }
      },
      getRechangeLogColumn() {
        t.rechangeLog.showColums = JSON.parse(
          window.localStorage.getItem('rechange_log_colmun') || '{}'
        );
      },
      saveRechangeLogColmun() {
        window.localStorage.setItem(
          'rechange_log_colmun',
          JSON.stringify(t.rechangeLog.showColums)
        );
      },
      getRechangeLogSummar(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            // 只找第一列放合计
            sums[index] = `${t.$t('combined')}`;
            return;
          }
          if (t.summar.data[column.property]) {
            // 判断当前prop绑定的ID
            const values = data.map((item) => item[column.property]); // 把对应一列中的之全部取出，放到一个数组中
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr); // 将values中的每个值转换为number类型
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          }
        });
        return sums;
      },
      formatJson(filterVal) {
        return this.list.map((v) =>
          filterVal.map((j) => {
            if (j === 'timestamp') {
              return parseTime(v[j]);
            } else {
              return v[j];
            }
          })
        );
      },
      handleRechangeLogDownload() {
        this.downloadLoading = true;
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = ['timestamp', 'title', 'type', 'importance', 'status'];
        const filterVal = [
          'timestamp',
          'title',
          'type',
          'importance',
          'status',
        ];
        const data = this.formatJson(filterVal);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'table-list',
        });
        this.downloadLoading = false;
      });
      },
      handleRechangeLog() {
        t.dialogSwitch.contactModelRechangeLog = true;
        t.rechangeLog.listLoading = true;
        t.request
          .get('/rechangelog/render')
          .then((response) => {
            const res = response.data;
            let i = 0;
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of res.modelData) {
              if (modelDatum.show === '1') {
                //if (i >5)break;
                // eslint-disable-next-line no-prototype-builtins
                if (!t.rechangeLog.showColums.hasOwnProperty(modelDatum.key)) {
                  this.$set(t.rechangeLog.showColums, modelDatum.key, true);
                }
                i++;
              }
            }
            t.rechangeLog.title = t.$t('RechargeRecord');
            t.rechangeLog.option = res.option ? res.option : [];
            t.rechangeLog.summar = res.summar || {};
            t.rechangeLog.listModelServer = res.modelData || [];
            t.rechangeLog.listSearchServer = res.searchData || [];
            t.rechangeLog.listQuery.sort = res.defaultSort || [];
            t.rechangeLog.pageSizelist = res.pageSizelist || [];
            t.rechangeLog.listLoading = false;
            t.getRechangeLogList();
          })
          .catch((res) => {
            t.rechangeLog.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      getRechangeLogList() {
        t.rechangeLog.listLoading = true;
        t.request
          .get('/rechangelog/list', t.rechangeLog.listQuery)
          .then((response) => {
            t.rechangeLog.list = response.data.items;
            t.rechangeLog.total = response.data.total;
            // Just to simulate the time of the request
            t.rechangeLog.listLoading = false;
          })
          .catch((res) => {
            t.rechangeLog.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      sortRechangeLogChange(data) {
        const { prop, order } = data;
        t.sortRechangeLogByID(order, prop);
      },
      sortRechangeLogByID(order, prop) {
        if (order === 'ascending') {
          t.rechangeLog.listQuery.sort = `${prop} ASC`;
        } else {
          t.rechangeLog.listQuery.sort = `${prop} DESC`;
        }
        t.handleRechangeLogFilter();
      },
      handleRechangeLogFilter() {
        this.rechangeLog.listQuery.page = 1;
        this.getRechangeLogList();
      },
      getBuyLogColumn() {
        t.buyLog.showColums = JSON.parse(
          window.localStorage.getItem('buy_log_colmun') || '{}'
        );
      },
      saveBuyLogColmun() {
        window.localStorage.setItem(
          'buy_log_colmun',
          JSON.stringify(t.buyLog.showColums)
        );
      },
      getBuyLogSummar(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            // 只找第一列放合计
            sums[index] = `${t.$t('combined')}`;
            return;
          }
          if (t.summar.data[column.property]) {
            // 判断当前prop绑定的ID
            const values = data.map((item) => item[column.property]); // 把对应一列中的之全部取出，放到一个数组中
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr); // 将values中的每个值转换为number类型
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          }
        });
        return sums;
      },
      handleBuyLogDownload() {
        this.downloadLoading = true;
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = ['timestamp', 'title', 'type', 'importance', 'status'];
        const filterVal = [
          'timestamp',
          'title',
          'type',
          'importance',
          'status',
        ];
        const data = this.formatJson(filterVal);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'table-list',
        });
        this.downloadLoading = false;
      });
      },
      handleBuyLog() {
        t.dialogSwitch.contactModelBuylog = true;
        t.buyLog.listLoading = true;
        t.request
          .get('/buylog/render')
          .then((response) => {
            const res = response.data;
            let i = 0;
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of res.modelData) {
              if (modelDatum.show === '1') {
                //if (i >5)break;
                // eslint-disable-next-line no-prototype-builtins
                if (!t.buyLog.showColums.hasOwnProperty(modelDatum.key)) {
                  this.$set(t.buyLog.showColums, modelDatum.key, true);
                }
                i++;
              }
            }
            t.buyLog.title = t.$t('PurchaseRecord');
            t.buyLog.option = res.option ? res.option : [];
            t.buyLog.summar = res.summar || {};
            t.buyLog.listModelServer = res.modelData || [];
            t.buyLog.listSearchServer = res.searchData || [];
            t.buyLog.listQuery.sort = res.defaultSort || [];
            t.buyLog.pageSizelist = res.pageSizelist || [];
            t.buyLog.listLoading = false;
            t.getBuyLogList();
          })
          .catch((res) => {
            t.buyLog.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      getBuyLogList() {
        this.buyLog.listLoading = true;
        t.request
          .get('/buylog/list', this.buyLog.listQuery)
          .then((response) => {
            this.buyLog.list = response.data.items;
            this.buyLog.total = response.data.total;
            // Just to simulate the time of the request
            this.buyLog.listLoading = false;
          })
          .catch((res) => {
            this.buyLog.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      sortBuyLogChange(data) {
        const { prop, order } = data;
        t.sortBuyLogByID(order, prop);
      },
      sortBuyLogByID(order, prop) {
        if (order === 'ascending') {
          t.buyLog.listQuery.sort = `${prop} ASC`;
        } else {
          t.buyLog.listQuery.sort = `${prop} DESC`;
        }
        t.handleBuyLogFilter();
      },
      sortEntrylogByID(order, prop) {
        if (order === 'ascending') {
          t.buyLog.listQuery.sort = `${prop} ASC`;
        } else {
          t.buyLog.listQuery.sort = `${prop} DESC`;
        }
        t.handleBuyLogFilter();
      },
      sorEntrylogChange(data) {
        const { prop, order } = data;
        t.sortEntrylogByID(order, prop);
      },
      handleEntrylogFilter() {
        this.entrylog.listQuery.page = 1;
        this.getEntrylogList();
      },
      handleBuyLogFilter() {
        this.buyLog.listQuery.page = 1;
        this.getBuyLogList();
      },
      rechange() {
        if (t.rechangeData.paytype === 1) {
          return t.$toasted.show(
            '支付宝维护中,请联系客服(如需国际支付宝ALIPAY支付请联系客服)',
            {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'error',
              duration: 8000,
            }
          );
        }
        t.rechangeData.money = Number(t.rechangeData.money);
        t.request
          .post('/rechange/apply', t.rechangeData)
          .then((res) => {
            //window.localStorage.setItem('usdtaddr',res.data.usdtaddr);
            t.$router.push({
              name: 'jumprecharge',
              params: res.data,
            });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });

        this.dialogSwitch.tagModalRechange = false;
      },
      handleRechange() {
        this.dialogSwitch.tagModalRechange = true;
      },

      handleEntrylog() {
        t.dialogSwitch.showEntrylog = true;
        t.entrylog.listLoading = true;
        t.request
          .get('/entrylog/render')
          .then((response) => {
            const res = response.data;
            let i = 0;
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of res.modelData) {
              if (modelDatum.show === '1') {
                //if (i >5)break;
                // eslint-disable-next-line no-prototype-builtins
                if (!t.entrylog.showColums.hasOwnProperty(modelDatum.key)) {
                  this.$set(t.entrylog.showColums, modelDatum.key, true);
                }
                i++;
              }
            }
            t.entrylog.option = res.option ? res.option : [];
            t.entrylog.summar = res.summar || {};
            t.entrylog.listModelServer = res.modelData || [];
            t.entrylog.listSearchServer = res.searchData || [];
            t.entrylog.listQuery.sort = res.defaultSort || [];
            t.entrylog.pageSizelist = res.pageSizelist || [];
            t.entrylog.listLoading = false;
            t.getEntrylogList();
          })
          .catch((res) => {
            t.entrylog.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      getEntrylogList() {
        this.entrylog.listLoading = true;
        t.request
          .get('/entrylog/list', this.entrylog.listQuery)
          .then((response) => {
            this.entrylog.list = response.data.items;
            this.entrylog.total = response.data.total;
            // Just to simulate the time of the request
            this.entrylog.listLoading = false;
          })
          .catch((res) => {
            this.entrylog.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      logout: function () {

        UserAuth.Logout();
        this.$router.replace('/auth/login');

      },
    },
  };
</script>

<style lang="scss" scoped>
.onhover-dropdown:hover .onhover-show-div {
  visibility: visible;
}

.moneylistBackground {
  display: flex;
  flex-wrap: wrap;
  .moneyActive {
    color: #704af8;
    border-color: #704af8;
  }

  > div {
    width: 100px;
    padding: 10px 0;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    margin: 5px 0;
    margin-left: 5px;
    text-align: center;
  }

  > div:first-of-type {
    margin-left: 0;
  }
}
</style>
