<template>
  <div v-loading="listLoading">
    <!-- Container-fluid starts-->
    <div style="margin-top: -30px">
      <div class="row">
        <div class="col-md-12">
          <div>
            <!-- class="card" -->
            <!-- class="filter-container" -->
            <div>
              <!--  class="card-body" -->
              <el-form :label-position="tableFixed" :model="temp">
                <el-form-item
                  :label="$t('Purchase type')"
                  :style="
                    !this._isMobile()
                      ? ''
                      : 'position: relative; margin-bottom: 40px'
                  "
                >
                  <el-radio-group
                    v-model="temp.type"
                    :style="
                      !this._isMobile()
                        ? 'margin-top: 8px'
                        : 'position: absolute;top: 10px;'
                    "
                  >
                    <el-radio v-for="(item, index) in customTypeArr" :label="item.value" :value="item.label">
                      {{ item.label }}
                      <el-tooltip
                          placement="top"
                          effect="light"
                          :content="item.tip"
                          :key="index"
                      >
                        <i class="el-icon-info" style="margin-left: 5px"></i>
                      </el-tooltip>
                    </el-radio>

                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  v-if="temp.type === 0 || temp.type === 1"
                  :label="$t('Purchase ports')"
                >
                  <input
                    class="form-control"
                    v-model="temp['port']"
                    type="number"
                    autocomplete="off"
                    style="width: 100px"
                    min="5"
                  />
                  <el-tag type="info" effect="plain">
                    <span>{{ $t("Current port") }}:{{ port }}</span>
                    <span style="margin-left: 10px"
                      >{{ $t("Post-change port") }}:{{
                        temp.type === 0
                          ? port > temp.port
                            ? 0
                            : temp.port
                          : Number(port) + Number(temp.port)
                      }}</span
                    >
                  </el-tag>
                </el-form-item>
                <el-form-item
                  v-if="temp.type === 2"
                  :label="$t('Current port')"
                >
                  <span>{{ port }}</span>
                </el-form-item>
                <el-form-item :label="$t('Port unit price')">
                  <label>{{ temp["price"] || "~" }}$ /{{ $t("day") }}</label>
                </el-form-item>
                <el-form-item
                  label="购买时长"
                  v-if="temp.type === 0 || temp.type === 2"
                >
                  <el-select
                    v-model="temp.day"
                    style="width: 100px"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-tooltip
                    placement="right"
                    effect="light"
                    style="margin-left: 10px"
                  >
                    <template v-slot:content>
                      <p
                        style="
                          color: #00b961;
                          font-weight: bold;
                          line-height: 16px;
                        "
                      >
                        {{ $t("As low as 20% off quarterly") }}
                      </p>
                      <p style="color: #00b961; font-weight: bold">
                        {{ $t("Half a year as low as 30% discount") }}
                      </p>
                      <p
                        style="
                          color: #00b961;
                          font-weight: bold;
                          margin-bottom: 0;
                        "
                      >
                        {{ $t("As little as 60% off all year round") }}
                      </p>
                    </template>
                    <i class="el-icon-info"></i>
                  </el-tooltip>
                </el-form-item>
                <el-form-item
                  :label="$t('Remaining time')"
                  v-if="temp.type === 1"
                >
                  <label>{{ temp.day || "~" }}</label>
                </el-form-item>
                <el-form-item :label="$t('Expense discount')">
                  <label
                    >{{ temp["discount"] }}%
                    <el-tooltip
                      placement="right"
                      effect="light"
                      v-model="showDiscount"
                    >
                      <template v-slot:content>
                        <el-table
                          :data="SectionDays"
                          border
                          size="mini"
                          fit
                          max-height="250"
                          highlight-current-row
                          style="width: 100%"
                          ref="sectionTable"
                        >
                          <el-table-column
                            prop="start_port"
                            :label="$t('Port between')"
                            align="center"
                            min-width="100"
                          >
                            <template slot-scope="{ row, $index }">
                              {{
                                row.end_port
                                  ? `${row.start_port}-${row.end_port}`
                                  : `≥${row.start_port}`
                              }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="start_day"
                            :label="$t('Days between')"
                            align="center"
                            min-width="90"
                          >
                            <template slot-scope="{ row, $index }">
                              {{
                                row.end_day
                                  ? `${row.start_day}-${row.end_day}`
                                  : `≥${row.start_day}`
                              }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="unitprice"
                            :label="$t('Port unit price')"
                            align="center"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="discount"
                            :label="$t('Expense discount')"
                            align="center"
                          >
                            <template slot-scope="{ row, $index }">
                              <span>{{ row.discount }}%</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="discount"
                            :label="$t('Current scheme')"
                            align="center"
                          >
                            <template slot-scope="{ row, $index }">
                              <span
                                style="color: #00b961"
                                v-if="discountIndex === $index"
                              >
                                Yes
                              </span>
                              <span v-else>-</span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </template>
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </label>
                </el-form-item>
                <el-form-item :label="$t('Estimated price')">
                  <label>{{ temp["total_price"] || "~" }}</label>
                </el-form-item>
                <el-form-item :label="$t('Discounted price')">
                  <label>{{
                    temp["total_discount_price"] || temp["total_price"]
                  }}</label>
                </el-form-item>
                <el-form-item :label="$t('Due date')">
                  <label style="display: block; margin-bottom: 0">{{
                    temp["exp_date"] || "~"
                  }}</label>
                  <el-tag type="info" effect="plain">
                    <span
                      >{{ $t("Current expiration time") }}:{{ exptime }}</span
                    >
                  </el-tag>
                </el-form-item>
              </el-form>

              <div slot="footer" class="dialog-footer">
                <button
                  class="btn btn-secondary"
                  type="button"
                  @click="updateData"
                  :disabled="listLoading"
                >
                  {{ $t("Buy") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
  export default {
    name: 'CustomPorts',
    components: {},
    props: {
      customPort: {
        default: 5,
        type: Number,
      },
    },
    data() {
      return {
        showDiscount: false,
        selectDate: [],
        tableFixed: 'right',
        temp: {
          port: 5,
          price: 0,
          day: 30,
          discount: 0,
          total_price: 0,
          total_discount_price: 0,
          exp_date: '',
          type: 0,
        },
        listLoading: false,
        summar: {
          switch: false,
          data: {},
        },
        customTypeArr: [
          {
            value: 0,
            label: this.$t('Purchase ports'),
            tip: this.$t(
              'If it has expired, it can be purchased, and if it has not expired, it can be increased or renewed'
            ),
          },
          {
            value: 1,
            label: this.$t('Add a port'),
            tip: this.$t(
              'If the port does not expire, add ports. The calculation method is remaining duration x port unit price x number of ports'
            ),
          },
          {
            value: 2,
            label: this.$t('Continuous port'),
            tip: this.$t(
              'If the expiration date does not expire, the increase duration is calculated as the increase duration * port unit price * Number of existing ports'
            ),
          },
        ],
        options: [
          {
            value: 30,
            label: this.$t('Jan.'),
          },
          {
            value: 60,
            label: this.$t('February'),
          },
          {
            value: 90,
            label: this.$t('First quarter'),
          },
          {
            value: 180,
            label: this.$t('Half a year'),
          },
          {
            value: 365,
            label: this.$t('1 year'),
          },
        ],
        titleModelList: [],
        SectionDays: [
          {
            discount: 0,
            end_day: 60,
            end_port: 30,
            start_day: 1,
            start_port: 1,
            unitprice: 0.3,
          },
        ],
        exptime: '',
        port: 0,
        discountIndex: -1,
      };
    },
    computed: {},
    watch: {
      // eslint-disable-next-line no-unused-vars
      customPort(newVal, oldVal) {
        this.temp.port = newVal;
      },
      // eslint-disable-next-line no-unused-vars
      'temp.port'(newVal, oldVal) {
        this.handleSectionDay();
      },
      // eslint-disable-next-line no-unused-vars
      'temp.day'(newVal, oldVal) {
        this.handleSectionDay();
      },
      // eslint-disable-next-line no-unused-vars
      'temp.type'(newVal, oldVal) {
        if (newVal === 0) {
          this.temp.day = 30;
        } else if (newVal === 2) {
          this.temp.day = 30;
          this.temp.port = this.port;
        }
        this.handleSectionDay();
      },
      showDiscount(newVal, oldVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.tableScrollToRow(this.$refs.sectionTable, this.discountIndex);
          });
        }
      },
    },
    mounted() {
      // Set the initial number of items
      this.listLoading = true;
      this.getRenderData();
      this.tableFixed = this._isMobile() ? 'left' : 'right';
    },
    methods: {
      tableScrollToRow(tableElement, rowIndex) {
        const theTableRows = tableElement.bodyWrapper.querySelectorAll(
          '.el-table__body tbody .el-table__row'
        );
        let scrollTop = 0;
        for (let i = 0; i < theTableRows.length; i++) {
          if (i === rowIndex) {
            break;
          }
          scrollTop += theTableRows[i].offsetHeight;
        }
        tableElement.bodyWrapper.scrollTop = scrollTop;
      },

      handleSectionDay() {
        let currentDate = new Date();
        if (this.temp.type === 1) {
          this.temp.day = Math.floor(
            (new Date(this.exptime).getTime() - currentDate.getTime()) /
              1000 /
              60 /
              60 /
              24
          );
        } else if (this.temp.type === 2) {
          currentDate = new Date(this.exptime);
        }
        for (let i = 0; i < this.SectionDays.length; i++) {
          const argument = this.SectionDays[i];
          if (
            this.temp.port >= argument.start_port &&
            this.temp.port <= (argument.end_port || this.temp.port) &&
            this.temp.day >= argument.start_day &&
            this.temp.day <= (argument.end_day || this.temp.day)
          ) {
            this.temp.discount = argument.discount;
            this.temp.price = argument.unitprice;
            this.temp.total_price = this.roundToTwoDecimalPlaces(
              argument.unitprice * this.temp.port * this.temp.day
            );
            this.temp.total_discount_price = this.roundToTwoDecimalPlaces(
              (argument.discount / 100) *
                argument.unitprice *
                this.temp.port *
                this.temp.day
            );
            this.temp.exp_date =
              this.temp.type === 1
                ? this.exptime
                : this.addDays(currentDate, this.temp.day);
            this.discountIndex = i;

            break;
          }
        }
      },
      add0(m) {
        return m < 10 ? '0' + m : m;
      },
      roundToTwoDecimalPlaces(number) {
        return Math.round(number * 100) / 100;
      },
      addDays(date, days) {
        var milliseconds = date.getTime() + days * 24 * 60 * 60 * 1000;
        date.setTime(milliseconds);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        var d = date.getDate();
        var h = date.getHours();
        var mm = date.getMinutes();
        var s = date.getSeconds();
        return `${y}-${this.add0(m)}-${this.add0(d)} ${this.add0(h)}:${this.add0(
          mm
        )}:${this.add0(s)}`;
      },
      _isMobile() {
        return navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
      },
      augment() {
        const tempData = Object.assign({}, this.temp);
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in tempData) {
          try {
            if (!isNaN(tempData[tempKey])) {
              tempData[tempKey] = Number(tempData[tempKey]);
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
          } catch (e) {
            console.error(e);
          }
        }
        this.request
          .post('/buylog/augment', tempData)
          .then((res) => {
            this.buy(res);
          })
          .catch((res) => {
            this.listLoading = false;
            if (res.errMsg) {
              this.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            } else {
              this.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            }
          });
      },
      renew() {
        const tempData = Object.assign({}, this.temp);
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in tempData) {
          try {
            if (!isNaN(tempData[tempKey])) {
              tempData[tempKey] = Number(tempData[tempKey]);
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
          } catch (e) {
            console.log(e);
          }
        }
        this.request
          .post('/buylog/renew', tempData)
          .then((res) => {
            this.buy(res);
          })
          .catch((res) => {
            this.listLoading = false;
            if (res.errMsg) {
              this.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            } else {
              this.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            }
          });
      },
      updateData() {
        this.listLoading = true;
        switch (this.temp.type) {
        case 1:
          return this.augment();

        case 2:
          return this.renew();
        default:
          return this.update();
        }
      },
      update() {
        const tempData = Object.assign({}, this.temp);
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in tempData) {
          try {
            if (!isNaN(tempData[tempKey])) {
              tempData[tempKey] = Number(tempData[tempKey]);
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
          } catch (e) {
            console.log(e);
          }
        }
        this.request
          .post('/buylog/custom', tempData)
          .then((res) => {
            this.buy(res);
          })
          .catch((res) => {
            this.listLoading = false;
            if (res.errMsg) {
              this.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            } else {
              this.$toasted.show(this.$t('Server Error'), {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
            }
          });
      },
      buy(res) {
        const data = res.data.items;
        let notify = '';
        if (data.buylog_last_day > 0) {
          notify = `${this.$t(
            'Due to the maturity of your previous purchase, surplus'
          )}${data.buylog_last_day}${this.$t('God, you still have to pay')}${
            data.buylog_payprice
          }${this.$t('Is the payment confirmed?')}`;
        } else {
          notify = `${this.$t('You have to pay')}${data.buylog_payprice}${this.$t(
            'Is the payment confirmed?'
          )}`;
        }
        this.listLoading = false;
        this.$confirm(notify, this.$t('warning'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning',
        })
          .then(async () => {
            this.request
              .post('/buylog/buy', data)
              .then((respose) => {
                this.$toasted.show('Success', {
                  theme: 'bubble',
                  position: 'top-center',
                  type: 'success',
                  duration: 10 * 1000,
                });
                window.location.reload();
              })
              .catch((res) => {
                if (res.errMsg) {
                  this.$toasted.show(res.errMsg, {
                    theme: 'bubble',
                    position: 'top-center',
                    type: 'error',
                    duration: 10 * 1000,
                  });
                } else {
                  this.$toasted.show(this.$t('Server Error'), {
                    theme: 'bubble',
                    position: 'top-center',
                    type: 'error',
                    duration: 10 * 1000,
                  });
                }
              });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      getRenderData() {
        this.request
          .get('/buyport/list')
          .then((response) => {
            this.SectionDays = response.data.items;
            this.exptime = response.data.exptime;
            this.port = response.data.port;
            this.handleSectionDay();
          })
          .catch((res) => {
            res.errMsg
              ? this.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              })
              : this.$toasted.show(this.$t('Server Error'), {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 10 * 1000,
              });
          })
          .finally(() => {
            this.listLoading = false;
          });
      },

      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    },
  };
</script>

<style lang="scss" scoped>
.card .card-body {
  padding: 10px;
}
.el-form-item {
  margin-bottom: 14px;
}
.el-form-item--medium .el-form-item__label {
  margin-bottom: 0;
}
.el-select-dropdown {
  /*background-color: unset ;*/
}

.el-input__inner {
  background-color: unset !important;
}

.pagination-container {
  background: unset;
}

.el-table__cell {
  background-color: unset;
}
</style>
