// src/store/myModule.js

const state = {
  // 初始状态
  sharekey: '',
  sort: '',
  shareid: '',
  password: '',
};
const getters = {
  // 基于state的计算属性，用于从state中派生出一些状态
  // getSharey: (state) => state.sharekey,
};
const mutations = {
  // 同步更改状态的方法
  setShareData(state, newData) {
    state.sharekey = newData?.sharekey;
    state.sort = newData?.sort;
    state.shareid = newData?.shareid;
    state.password = newData?.password;
  },
};

const actions = {
  // 提交mutation的方法，可以包含异步操作
  setShareData({ commit }, newData) {
    commit('setShareData', newData);
  },
};

export default {
  namespaced: true, // 如果为true，那么在模块内部则需要在mutation和action的调用前添加模块名
  state,
  mutations,
  actions,
  getters,
};
