<template>
  <div>
    <!-- page-wrapper Start-->
    <div class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="login-card" style="flex-direction: column">
            <div style="width: 97%">
              <div
                style="
                  display: flex;
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  margin-top: 50px;
                  flex-direction: column;
                "
              >
                <img
                  class="img-fluid for-light"
                  src="../assets/images/logo/logo1.png"
                  alt="looginpage"
                  style=""
                />
                <p style="color: #909399">海王SCRIM跨填电商应用聚合平台</p>
              </div>
              <br />

              <div class="login-main">
                <b-card no-body v-if="isPCShow">
                  <b-tabs pills vertical id="PCTab">
                    <b-tab active>
                      <template #title>
                        <div style="text-align: center">
                          <img
                            src="../assets/images/login/firebase.svg"
                            id="firebase-tooltip"
                            style="margin-top: 3px"
                          />
                          <p
                            style="
                              font-size: 9px;
                              color: #000;
                              font-weight: 900;
                              margin-top: 2px;
                            "
                          >
                            {{ $t("Account") }}
                          </p>
                          <!-- <p v-if="!vnShow"
                            v-if="vnShow"
                            style="
                              font-size: 9px;
                              color: #000;
                              font-weight: 900;
                              margin-top: 2px;
                            "
                          >
                            {{ $t("Account") }}
                          </p> -->
                          <span>{{ $t("Account") }}{{ $t("Login") }}</span>
                        </div>
                      </template>
                      <b-card-text>
                        <form class="theme-form">
                          <h4>{{ $t("Sign in to account") }}</h4>
                          <p>
                            {{ $t("Enter your email & password to login") }}
                          </p>
                          <div class="form-group">
                            <label class="col-form-label">{{
                              $t("Email Address")
                            }}</label>
                            <input
                              v-model="email"
                              class="form-control"
                              type="email"
                              required=""
                              :placeholder="$t('Please enter email')"
                              :class="{
                                'is-invalid': submitted && !email,
                              }"
                            />
                            <div
                              v-show="submitted && !email"
                              class="invalid-feedback"
                            >
                              {{ $t("Email is required") }}
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-form-label">{{
                              $t("Password")
                            }}</label>
                            <input
                              v-model="password"
                              autocomplete=""
                              class="form-control"
                              :type="type"
                              name="login[password]"
                              required=""
                              :placeholder="
                                $t('Please enter your password number')
                              "
                              :class="{
                                'is-invalid': submitted && !email,
                              }"
                            />
                            <div
                              v-show="submitted && !password"
                              class="invalid-feedback"
                            >
                              {{ $t("Password is required") }}
                            </div>
                            <div class="show-hide" @click="showPassword">
                              <i
                                class="icofont"
                                v-bind:class="
                                  this.type === 'password'
                                    ? 'icofont-eye'
                                    : 'icofont-eye-blocked'
                                "
                              ></i>
                            </div>
                          </div>
                          <div class="form-group mb-0">
                            <div class="checkbox p-0">
                              <input
                                id="checkbox2"
                                v-model="rememberPassword"
                                type="checkbox"
                              />
                              <label class="text-muted" for="checkbox2"
                                >{{ $t("remember the password") }}
                              </label>
                            </div>
                            <div class="checkbox p-0 checkbox1">
                              <input
                                id="checkbox"
                                v-model="agree"
                                type="checkbox"
                              />
                              <label class="text-muted" for="checkbox"
                                >{{ $t("I have read and agree") }}
                                <el-button
                                  type="text"
                                  class="ml-2"
                                  @click.stop="readAgree"
                                >
                                  {{
                                    $t(
                                      "Offshore System Service Platform Agreement"
                                    )
                                  }}</el-button
                                >
                              </label>
                            </div>
                            <el-button
                              type="primary"
                              style="width: 100%"
                              @click="signUp"
                            >
                              {{ $t("Login") }}
                            </el-button>
                          </div>
                          <!--                          <h6 class="text-muted mt-4 or">Or Sign in with</h6>
                                                    <div class="social mt-4">
                                                      <div class="btn-showcase">
                                                        <a class="btn btn-light" @click="socialLogin">
                                                          <i class="fa fa-google txt-linkedin"></i>
                                                          Google
                                                        </a>
                                                        <a
                                                          class="btn btn-light"
                                                          @click="socialLoginTwitter"
                                                        >
                                                          <feather
                                                            type="twitter"
                                                            class="txt-twitter"
                                                          ></feather
                                                          >twitter</a
                                                        >
                                                        <a
                                                          class="btn btn-light"
                                                          @click="socialLoginFacebook"
                                                        >
                                                          <feather
                                                            type="facebook"
                                                            class="txt-fb"
                                                          ></feather
                                                          >facebook</a
                                                        >
                                                      </div>
                                                    </div>-->
                          <p class="mt-4 mb-0" style="position: relative">
                            {{ $t("Don't have account?") }}
                            <!-- <router-link
                              class="ml-2"
                              tag="a"
                              to="/auth/register"
                            >
                              {{ $t("Create Account") }}
                            </router-link> -->
                            <el-button @click="goRegister" type="text">{{
                              $t("Create Account")
                            }}</el-button>
                            <img
                              :class="isPCShow ? 'flagvn' : 'flagvnMobile'"
                              v-if="vnShow"
                              :src="getImgUrl('84.png')"
                              alt=""
                            />
                            <Language />
                          </p>
                        </form>
                      </b-card-text>
                    </b-tab>
                    <b-tab>
                      <template #title>
                        <div style="text-align: center">
                          <img
                            src="../assets/images/login/jwt.svg"
                            style="margin-top: 3px"
                          />
                          <p
                            style="
                              font-size: 9px;
                              color: #000;
                              font-weight: 900;
                              margin-top: 2px;
                            "
                          >
                            {{ $t("share code login") }}
                          </p>
                          <span>{{ $t("share code login") }}</span>
                        </div>
                      </template>
                      <b-card-text>
                        <div
                          class="alert alert-info"
                          style="
                            background-color: #1890ff;
                            border-color: #1890ff;
                          "
                        >
                          {{ $t("share code login") }}
                        </div>
                        <form class="theme-form" @submit.prevent="handleSubmit">
                          <div class="form-group">
                            <label for="username">{{ $t("Share code") }}</label>
                            <input
                              id="username"
                              type="text"
                              v-model="sharecode"
                              name="username"
                              class="form-control"
                              :placeholder="$t('Please enter your Share code')"
                              :class="{ 'is-invalid': submitted && !sharecode }"
                            />
                            <div
                              v-show="submitted && !sharecode"
                              class="invalid-feedback"
                            >
                              {{ $t("Share code is required") }}
                            </div>
                          </div>
                          <!-- 登录---- -->
                          <el-button
                            @click="shareHandle()"
                            style="width: 100%"
                            type="primary"
                          >
                            {{ $t("Login") }}</el-button
                          >
                          <!-- 换个写法 ，加个娇艳 -->
                          <div class="" v-if="false">
                            <router-link
                              class="btn el-button"
                              style="
                                width: 100%;
                                border-color: #1890ff !important;
                                color: #fff;
                                font-size: 14px;
                                padding: 10px 1.75rem;
                                background-color: #1890ff;
                              "
                              tag="a"
                              v-if="canNavigate"
                              :to="'/accountshow/' + sharecode"
                              >{{ $t("Login") }}
                            </router-link>
                          </div>

                          <p class="mt-4 mb-0" style="position: relative">
                            {{ $t("Don't have account?") }}
                            <el-button type="text" @click="goRegister">
                              {{ $t("Create Account") }}
                            </el-button>
                            <img
                              :class="isPCShow ? 'flagvn' : 'flagvnMobile'"
                              v-if="vnShow"
                              :src="getImgUrl('84.png')"
                              alt=""
                            />
                            <Language />
                          </p>
                        </form>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
                <b-card no-body v-if="!isPCShow">
                  <b-tabs
                    pills
                    vertical
                    class="mobileTab"
                    style="min-width: 100px"
                  >
                    <b-tab active>
                      <template #title>
                        <div style="text-align: center; width: 100px">
                          <img
                            src="../assets/images/login/firebase.svg"
                            id="firebase-tooltip"
                            style="margin-top: 3px; width: 20px"
                          />
                          <p
                            v-if="!vnShow"
                            style="
                              font-size: 9px;
                              color: #000;
                              font-weight: 900;
                              margin-top: 2px;
                            "
                          >
                            {{ $t("Account") }}
                          </p>
                          <p
                            v-if="vnShow"
                            style="
                              font-size: 9px;
                              color: #000;
                              font-weight: 900;
                              margin-top: 2px;
                            "
                          >
                            {{ $t("Account") }}
                          </p>
                          <!-- <span>{{ $t("Account") }}{{ $t("Login") }}</span> -->
                        </div>
                      </template>
                      <b-card-text>
                        <form class="theme-form">
                          <h4>{{ $t("Sign in to account") }}</h4>
                          <p>
                            {{ $t("Enter your email & password to login") }}
                          </p>
                          <div class="form-group">
                            <label class="col-form-label">{{
                              $t("Email Address")
                            }}</label>
                            <input
                              v-model="email"
                              class="form-control"
                              type="email"
                              required=""
                              :placeholder="$t('Please enter email')"
                              :class="{
                                'is-invalid': submitted && !email,
                              }"
                            />
                            <div
                              v-show="submitted && !email"
                              class="invalid-feedback"
                            >
                              {{ $t("Email is required") }}
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-form-label">{{
                              $t("Password")
                            }}</label>
                            <input
                              v-model="password"
                              autocomplete=""
                              class="form-control"
                              :type="type"
                              name="login[password]"
                              required=""
                              :placeholder="
                                $t('Please enter your password number')
                              "
                              :class="{
                                'is-invalid': submitted && !email,
                              }"
                            />
                            <div
                              v-show="submitted && !password"
                              class="invalid-feedback"
                            >
                              {{ $t("Password is required") }}
                            </div>
                            <div class="show-hide" @click="showPassword">
                              <i
                                class="icofont"
                                v-bind:class="
                                  this.type === 'password'
                                    ? 'icofont-eye'
                                    : 'icofont-eye-blocked'
                                "
                              ></i>
                            </div>
                          </div>
                          <div class="form-group mb-0">
                            <div class="checkbox p-0">
                              <input
                                id="checkbox2"
                                v-model="rememberPassword"
                                type="checkbox"
                              />
                              <label class="text-muted" for="checkbox2"
                                >{{ $t("remember the password") }}
                              </label>
                            </div>
                            <div class="checkbox p-0 checkbox1">
                              <input
                                id="checkbox"
                                v-model="agree"
                                type="checkbox"
                              />
                              <label class="text-muted" for="checkbox"
                                >{{ $t("I have read and agree") }}
                                <el-button
                                  type="text"
                                  class="ml-2"
                                  @click.stop="readAgree"
                                >
                                  {{
                                    $t(
                                      "Offshore System Service Platform Agreement"
                                    )
                                  }}</el-button
                                >
                              </label>
                            </div>
                            <el-button
                              type="primary"
                              style="width: 100%"
                              @click="signUp"
                            >
                              {{ $t("Login") }}
                            </el-button>
                          </div>
                          <!--                          <h6 class="text-muted mt-4 or">Or Sign in with</h6>
                                                    <div class="social mt-4">
                                                      <div class="btn-showcase">
                                                        <a class="btn btn-light" @click="socialLogin">
                                                          <i class="fa fa-google txt-linkedin"></i>
                                                          Google
                                                        </a>
                                                        <a
                                                          class="btn btn-light"
                                                          @click="socialLoginTwitter"
                                                        >
                                                          <feather
                                                            type="twitter"
                                                            class="txt-twitter"
                                                          ></feather
                                                          >twitter</a
                                                        >
                                                        <a
                                                          class="btn btn-light"
                                                          @click="socialLoginFacebook"
                                                        >
                                                          <feather
                                                            type="facebook"
                                                            class="txt-fb"
                                                          ></feather
                                                          >facebook</a
                                                        >
                                                      </div>
                                                    </div>-->
                          <p class="mt-4 mb-0" style="position: relative">
                            {{ $t("Don't have account?") }}
                            <!-- <router-link
                              class="ml-2"
                              tag="a"
                              to="/auth/register"
                            >
                              {{ $t("Create Account") }}
                            </router-link> -->
                            <el-button @click="goRegister" type="text">{{
                              $t("Create Account")
                            }}</el-button>
                            <img
                              :class="isPCShow ? 'flagvn' : 'flagvnMobile'"
                              v-if="vnShow"
                              :src="getImgUrl('84.png')"
                              alt=""
                            />
                            <Language />
                          </p>
                        </form>
                      </b-card-text>
                    </b-tab>
                    <b-tab>
                      <template #title>
                        <div style="text-align: center; width: 100px">
                          <img
                            src="../assets/images/login/jwt.svg"
                            style="margin-top: 3px; width: 20px"
                          />
                          <p
                            style="
                              font-size: 9px;
                              color: #000;
                              font-weight: 900;
                              margin-top: 2px;
                            "
                          >
                            {{ $t("share code login") }}
                          </p>
                          <span>{{ $t("share code login") }}</span>
                        </div>
                      </template>
                      <b-card-text>
                        <div
                          class="alert alert-info"
                          style="
                            background-color: #1890ff;
                            border-color: #1890ff;
                          "
                        >
                          {{ $t("share code login") }}
                        </div>
                        <form class="theme-form" @submit.prevent="handleSubmit">
                          <div class="form-group">
                            <label for="username">{{ $t("Share code") }}</label>
                            <input
                              id="username"
                              type="text"
                              v-model="sharecode"
                              name="username"
                              class="form-control"
                              :placeholder="$t('Please enter your Share code')"
                              :class="{ 'is-invalid': submitted && !sharecode }"
                            />
                            <div
                              v-show="submitted && !sharecode"
                              class="invalid-feedback"
                            >
                              {{ $t("Share code is required") }}
                            </div>
                          </div>
                          <!-- 登录---- -->
                          <el-button
                            @click="shareHandle()"
                            style="width: 100%"
                            type="primary"
                          >
                            {{ $t("Login") }}</el-button
                          >
                          <div class="" v-if="false">
                            <router-link
                              class="btn el-button"
                              style="
                                width: 100%;
                                border-color: #1890ff !important;
                                color: #fff;
                                font-size: 14px;
                                padding: 10px 1.75rem;
                                background-color: #1890ff;
                              "
                              tag="a"
                              :to="'/accountshow/' + sharecode"
                              >{{ $t("Login") }}
                            </router-link>
                          </div>

                          <p class="mt-4 mb-0" style="position: relative">
                            {{ $t("Don't have account?") }}
                            <el-button type="text" @click="goRegister">
                              {{ $t("Create Account") }}
                            </el-button>
                            <img
                              :class="isPCShow ? 'flagvn' : 'flagvnMobile'"
                              v-if="vnShow"
                              :src="getImgUrl('84.png')"
                              alt=""
                            />
                            <Language />
                          </p>
                        </form>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </div>
              <el-dialog
                :title="$t('Platform Agreement')"
                :show-close="true"
                :width="isPCShow ? '' : '90%'"
                :visible.sync="regModalAgreement"
              >
                <div class="modal-body">
                  <form class="form-bookmark needs-validation" novalidate="">
                    <div class="form-row">
                      <div
                        class="form-group col-md-12"
                        v-html="
                          $t(
                            'Offshore System Service Platform Agreement Content'
                          )
                        "
                      ></div>
                    </div>
                  </form>
                </div>
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="regModalAgreement = false">
                    确认并已知悉
                  </el-button>
                </div>
              </el-dialog>
            </div>
            <div
              style="
                margin-top: 30px;
                width: 100%;
                bottom: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
              "
            >
              <div>海王出海系列产品归属权为新加坡（HaiWang）所有</div>
              <div>
                注意:新加坡HaiWang旗下全系列产品，仅支持新币或美元付款，请认准官方客服，谨防上当受骗！
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- latest jquery-->
  </div>
</template>

<script>
  import Userauth from '../auth/js/index';
  import Language from '../components/header/language';

  let t;
  export default {
    name: 'login',
    components: {
      Language,
    },
    data() {
      return {
        vnShow: false,
        isPCShow: true,
        windowWidth: window.innerWidth,
        type: 'password',
        email: '',
        password: '',
        username: '',
        passwordjwt: '',
        submitted: false,
        agree: true,
        rememberPassword: false,
        regModalAgreement: false,
        sharecode: '',
        token: '',
        canNavigate: true,
      };
    },
    computed: {
      // JWT authentication
      loggingIn() {
        return this.$store.state.authentication.status.loggingIn;
      },
    },
    created() {
      // reset login status for JWT
      this.$store.dispatch('authentication/logout');
      t = this;
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      t.loadLoginData();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
      //console.log(this.$route.query);
      if (this.$route.query.account) {
        this.token = this.$route.query.token;
        this.email = this.$route.query.account;
        if (this.token && this.email) {
          this.$nextTick(() => {
            this.signAdminUp();
          });
        }
      }
      this.vnShow = localStorage.getItem('currentLanguage') == 'VI';
    },
    methods: {
      getImgUrl(path) {
        return require('@/assets/images/guoqi/' + path);
      },
      beforeRouteEnter(to, from, next) {
      
        if (!this.sharecode) {
          next(false); // 阻止导航
        } else {
          next(); // 允许导航
        }
      },
      shareHandle() {
        if (this.sharecode) {
          let a = '/accountshow/' + this.sharecode;
          console.log(a);
          this.$router.push(a);
        } else {
          t.$toasted.show(t.$t('Share code is required'), {
            theme: 'bubble',
            position: 'bottom-center',
            type: 'error',
            duration: 3000,
          });
          console.log('邀请码为空');
        }
      },
      handleResize() {
        t.windowWidth = window.innerWidth;
        // const windowWidth = window.innerWidth;
        if (t.windowWidth < 768) {
          console.log('窗口宽度小于768像素');
          t.isPCShow = false;
        } else {
          t.isPCShow = true;
        }
      },
      goRegister() {
        this.$router.push('/auth/register');
      },
      loadLoginData() {
        const { account, password } = JSON.parse(
          window.localStorage.getItem('loginData') ||
            `{"account":"","password":""}`
        );
        if (account) {
          t.rememberPassword = true;
          t.password = password;
          t.email = account;
        }
      },
      readAgree() {
        this.regModalAgreement = true;
      },
      // JWT authentication
      handleSubmit() {
        this.submitted = true;
        const { username, passwordjwt } = this;
        const { dispatch } = this.$store;
        if (username && passwordjwt) {
          dispatch('authentication/login', { username, passwordjwt });
        }
      },
      // show/hide password
      showPassword: function () {
        if (this.type === 'password') {
          this.type = 'text';
        } else {
          this.type = 'password';
        }
      },

      // Firebase login
      signUp: function () {
        if (!this.agree) {
          return t.$toasted.show(t.$t('Agree Error'), {
            theme: 'bubble',
            position: 'bottom-center',
            type: 'error',
            duration: 3000,
          });
        }
        this.submitted = true;
        this.request
          .post('/login', { account: this.email, password: this.password })
          .then((res) => {
            const data = res.data;
            if (t.rememberPassword) {
              const loginData = {
                account: t.email,
                password: t.password,
              };
              window.localStorage.setItem('loginData', JSON.stringify(loginData));
            } else {
              window.localStorage.removeItem('loginData');
            }
            window.localStorage.setItem('r_remark', data['r_remark']);
            Userauth.localLogin({
              logintoken: data.authtoken,
              user: {
                displayName: data.userinfo.remark,
                email: data.userinfo.account,
                isagent: data.userinfo.isagent,
                id: data.userinfo.id,
                photoURL: '',
              },
            });
            this.$notify({
              title: '欢迎回来',
              message: data.userinfo.account,
              type: 'success',
              duration: 3000,
            });
            this.$router.replace('/');
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      /**/
      },
      signAdminUp: function () {
        this.request
          .post('/loginToken', { account: this.email, token: this.token })
          .then((res) => {
            const data = res.data;
            window.localStorage.setItem('r_remark', data['r_remark']);
            Userauth.localLogin({
              logintoken: data.authtoken,
              user: {
                displayName: data.userinfo.remark,
                email: data.userinfo.account,
                isagent: data.userinfo.isagent,
                id: data.userinfo.id,
                photoURL: '',
              },
            });
            this.$notify({
              title: '欢迎回来',
              message: data.userinfo.account,
              type: 'success',
              duration: 3000,
            });
            this.$router.replace('/');
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      /**/
      },
      // Auth0 login
      login() {
        Userauth.login();
      },
    },
  };
</script>
<style scoped>
.mt-4 .mb-0 {
  position: relative;
}
.flagvn {
  position: absolute;
  right: 100px;
  width: 20px;
  top: 10px;
}
.flagvnMobile {
  position: absolute;
  right: 30px;
  width: 20px;
  top: 10px;
}
.checkbox1 label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 19px;
  height: 19px;
  left: 0;
  top: 7px;
  margin-left: -16px;
  border: 1px solid #efefef;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
@media only screen and (max-width: 575.98px) {
  #mobileTab #__BVID__14__BV_tab_controls_ {
    width: 100px !important;
    display: flex;
    position: relative;
    top: -20px;
  }
  /*  .login-card .login-main .card .tabs .col-auto {
      top: -80px;
      left: -20px;
  } */
}
@media only screen and (max-width: 99.98px) {
  .login-card .login-main .tabs .col-auto .nav .nav-item .nav-link:hover span {
    /* opacity: 1; */
    right: 0;
    top: -34px;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #1890ff !important;
  }
  /deep/
    .login-card
    .login-main
    .tabs
    .col-auto
    .nav
    .nav-item
    .nav-link:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    top: 12px;
    border-width: 5px 0 5px 5px;
    border-left-color: #1890ff !important;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    color: #fff;
    left: 14px;
    z-index: 2;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

.login-card .login-main .tabs .col-auto .nav .nav-item .nav-link span {
  position: absolute;
  right: -60px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-align: right;
  padding: 7px 10px;
  display: block;
  top: 0;
  border-radius: 5px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
  color: #fff;
  /* */
  background-color: #1890ff;
  -webkit-box-shadow: 0 0 37px rgb(8 21 66 / 10%);
  box-shadow: 0 0 37px rgb(8 21 66 / 10%);
}
</style>
