<template>
  <div v-loading="listLoading">
    <Breadcrumbs main="" :title="$t('Shorturl')"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div v-if="option.showSearchBt" style="margin: 10px 0 30px 0">
                <!--  style="top: -4px; width: 12rem;" -->
                <div
                    style="
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    flex-wrap: wrap;
                  "
                >
                  <div style="flex: 1.7; min-width: 350px">
                    <el-date-picker
                        v-model="listQuery.createdatstart"
                        :style="
                        isPCShow
                          ? { width: '12rem', 'margin-left': '5px' }
                          : { width: '9rem', 'margin-left': '5px' }
                      "
                        type="datetime"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :placeholder="$t('Please select a start time')"
                    />
                    <el-date-picker
                        v-model="listQuery.createdatend"
                        :style="
                        isPCShow
                          ? { width: '12rem', 'margin-left': '5px' }
                          : { width: '9rem', 'margin-left': '5px' }
                      "
                        type="datetime"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :placeholder="$t('Please select an end time')"
                    />
                    <span
                        v-for="(searchItem, searchIndex) in listSearchServer"
                        :key="searchIndex"
                        style="margin-left: 5px; line-height: 45px"
                    >
                      <el-input
                          v-if="searchItem.htype === 'text'"
                          v-model="listQuery[searchItem.key]"
                          type="text"
                          :style="
                          isPCShow ? { width: '12rem' } : { width: '9rem' }
                        "
                          :placeholder="$t('Please enter the') + searchItem.label"
                          class="filter-item"
                          @keyup.enter.native="handleFilter"
                      />

                      <el-input
                          v-if="searchItem.htype === 'number'"
                          v-model="listQuery[searchItem.key]"
                          type="number"
                          :style="
                          isPCShow ? { width: '12rem' } : { width: '9rem' }
                        "
                          :placeholder="$t('Please enter the') + searchItem.label"
                          class="filter-item"
                          @keyup.enter.native="handleFilter"
                      />

                      <el-select
                          v-if="searchItem.htype === 'select'"
                          v-model="listQuery[searchItem.key]"
                          :placeholder="$t('Please select a') + searchItem.label"
                          clearable
                          :style="
                          isPCShow ? { width: '12rem' } : { width: '9rem' }
                        "
                          class="filter-item"
                      >
                        <el-option
                            v-for="(item, key) in searchItem.select"
                            :key="key"
                            :label="item.title"
                            :value="item.id"
                        />
                      </el-select>
                    </span>
                  </div>
                  <div
                      style="
                      flex: 0.3;
                      display: flex;
                      height: 36px;
                      margin-top: 3px;
                      margin-left: 5px;
                    "
                  >
                    <el-button
                        style="flex: 1"
                        class="filter-item"
                        icon="el-icon-delete-solid"
                        type="success"
                        plain
                        @click="resetFun"
                    >{{ $t("Reset") }}
                    </el-button>
                    <el-button
                        style="flex: 1; margin-left: 10px"
                        class="filter-item"
                        icon="el-icon-search"
                        type="primary"
                        @click="handleFilter"
                    >{{ $t("Search") }}
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="filter-container">
                <el-button
                    v-if="option.showAddBt"
                    class="filter-item"
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-edit"
                    @click="handleCreate"
                    plain
                >{{ $t("Create New") }}
                </el-button>
                <el-button
                    class="filter-item"
                    style="margin-left: 10px"
                    type="danger"
                    @click="toggleSelection()"
                    icon="el-icon-delete"
                    plain
                >{{ $t("Remove selected") }}
                </el-button>
                <!--      <el-button class="filter-item" style="margin-left: 10px;" type="warning" icon="el-icon-delete" @click="handleDelete">删除</el-button>-->
                <el-button
                    v-if="option.showExportBt"
                    v-waves
                    :loading="downloadLoading"
                    class="filter-item"
                    type="info"
                    icon="el-icon-download"
                    plain
                    @click="handleDownload"
                >{{ $t("Export") }}
                </el-button>
              </div>
              <div>
                <el-table
                    :key="tableKey"
                    :data="list"
                    border
                    fit
                    highlight-current-row
                    :show-summary="summar.switch"
                    :summary-method="getSummar"
                    style="width: 100%; border-radius: 10px"
                    @sort-change="sortChange"
                    @row-click="tableClick"
                    @selection-change="handleSelectionChange"
                    ref="multipleTable"
                >
                  <el-table-column
                      type="selection"
                      width="40"
                  ></el-table-column>
                  <el-table-column
                      v-for="(item, index) in listModelServer"
                      v-if="item.show === '1' && showColums[item.key]"
                      :key="index"
                      :label="item.label"
                      :prop="item.key"
                      :sortable="item.sort === '1'"
                      align="center"
                      show-overflow-tooltip
                      :min-width="
                      item.key == 'short_shareid' || item.key == 'short_rule'
                        ? '125'
                        : item.key === 'short_errurl'
                        ? '150'
                        : ''
                    "
                  >
                    <template slot-scope="{ row }">
                      <span v-if="item.showtype === 'text'">{{
                          row[item.key]
                        }}</span>
                      <span v-if="item.showtype === 'date'">
                        {{
                          row[item.key]
                              ? row[item.key]
                                  .replace("+08:00", "")
                                  .replace("T", " ")
                              : row[item.key]
                        }}
                      </span>
                      <span v-if="item.showtype === 'arr'">
                        <el-tag
                            v-for="(arritem, arrindex) in item.showarr"
                            :key="arrindex"
                            :type="arritem.tagtype"
                            v-show="arritem.id === row[item.key]"
                        >
                          {{ arritem.title }}
                        </el-tag>
                      </span>
                      <span v-if="item.showtype === 'img'"
                      ><el-image
                          fit="fill"
                          :src="row[item.key]"
                          :preview-src-list="[row[item.key]]"
                      /></span>
                    </template>
                  </el-table-column>

                  <el-table-column
                      :label="$t('Operation')"
                      v-if="!listLoading"
                      align="center"
                      class-name="small-padding fixed-width"
                      :width="isPCShow ? '260' : '150'"
                      fixed="right"
                  >
                    <template slot="header" slot-scope="{ row, $index }">
                      <span>{{ $t("Operation") }}</span>
                      <el-dropdown style="margin-left: 15px">
                        <i class="el-icon-setting"></i>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                              style="border-bottom: 1px solid #dcdfe6"
                              command="sendModifyData"
                          >
                            <el-checkbox
                                v-model="allStatus"
                                @change="selectAllFun"
                            >{{ allStatus ? $t("CancelFull") : $t("Full") }}
                            </el-checkbox>
                          </el-dropdown-item>

                          <el-dropdown-item
                              command="sendModifyData"
                              v-for="(item, index) in listModelServer"
                              :key="index"
                              v-show="item.show === '1'"
                          >
                            <el-checkbox
                                @change="saveColmun"
                                v-model="showColums[item.key]"
                            >{{ item.label }}
                            </el-checkbox>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </template>
                    <template slot-scope="{ row, $index }">
                      <el-button
                          size="mini"
                          icon="el-icon-view"
                          @click="shortlogShow(row.short_id)"
                          style="margin-left: 0; margin-right: 10px"
                          type="text"
                      >
                        {{ $t("Records") }}
                      </el-button>
                      <el-button
                          size="mini"
                          icon="el-icon-pie-chart"
                          style="margin-left: 0; margin-right: 10px"
                          @click="shortlogChart(row.short_id)"
                          type="text"
                      >
                        {{ $t("Chart") }}
                      </el-button>
                      <el-button
                          size="mini"
                          style="margin-left: 0; margin-right: 10px"
                          icon="el-icon-edit"
                          @click="handleUpdate(row)"
                          type="text"
                      >
                        {{ $t("Edit") }}
                      </el-button>
                      <el-button
                          size="mini"
                          icon="el-icon-brush"
                          style="margin-left: 0; margin-right: 10px"
                          @click="handleDelete(row, $index)"
                          type="text"
                      >
                        {{ $t("Delete") }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>

                <div style="display: flex; align-items: center">
                  <pagination
                      v-show="total > 0"
                      :total="total"
                      :page.sync="listQuery.page"
                      :limit.sync="listQuery.limit"
                      :page-sizes="pageSizelist"
                      @pagination="getList"
                  />
                  <el-button
                      style="margin-left: 0; margin-top: 28px"
                      icon="el-icon-document-delete"
                      size="mini"
                      @click="clearRecord"
                  >
                    {{ $t("Clear record") }}
                    <i></i>
                  </el-button>

                  <!--  <el-dropdown style="margin-left: 5px;margin-top: 30px;" type="primary">

                    <el-dropdown-menu slot="dropdown">
                       <el-dropdown-item @click.native="handleOption(index)" :loading="downloadLoading"
                                         v-for="(item,index) in optionData"
                                         :key="index">
                         {{ item }}
                       </el-dropdown-item>
                     </el-dropdown-menu>
                  </el-dropdown>-->
                </div>
              </div>
              <el-dialog
                  :visible.sync="shortlogArr.show"
                  :append-to-body="true"
                  :destroy-on-close="true"
                  :title="$t('Skip record')"
                  :width="isPCShow ? '80%' : '90%'"
              >
                <shortlog-page
                    v-if="shortlogArr.show"
                    style="width: 100%"
                    :shortid="shortlogArr.shortid"
                ></shortlog-page>
              </el-dialog>
              <!-- 图表 -->
              <el-dialog
                  :visible.sync="shortlogArr.chartshow"
                  :append-to-body="true"
                  :destroy-on-close="true"
                  :title="$t('Charts')"
                  :width="isPCShow ? '80%' : '94%'"
              >
                <shortlogchart-page
                    v-if="shortlogArr.chartshow"
                    style="width: 100%; height: 100%"
                    :shortid="shortlogArr.shortid"
                ></shortlogchart-page>
              </el-dialog>
              <!--开始-->
              <!-- 新增 / 编辑 -->
              <el-dialog
                  :visible.sync="dialogSwitch.shortModalExample"
                  :title="
                  dialogStatus === 'create'
                    ? $t('Create') + $t('Shorturl')
                    : $t('Edit') + $t('Shorturl')
                "
                  :append-to-body="true"
                  :style="isPCShow ? 'margin-top:-10vh' : ''"
                  :width="isPCShow ? '70%' : '90%'"
              >
                <el-form label-position="top" :model="temp" style="width: 100%">
                  <!-- :label-position="isPCShow ? 'left' : 'top'" -->
                  <!-- 选择域名 -->
                  <el-form-item
                      :label="$t('Selecting a Domain Name')"
                      style="position: relative"
                      v-if="dialogStatus === 'create'"
                  >
                    <el-radio-group
                        v-model="temp['short_urlindex']"
                        class="filter-item"
                        :style="
                        isPCShow
                          ? 'position:absolute;top:-43px;left:100px'
                          : 'position:absolute;top:-43px;left:100px'
                      "
                        :placeholder="$t('Selecting a Domain Name')"
                    >
                      <el-radio
                          v-for="(selectitem, selectkey) in shorturlsys"
                          :key="selectkey"
                          :label="selectkey"
                      >
                        {{ selectitem }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <!-- 创建数量 -->
                  <el-form-item
                      style="position: relative"
                      :label="$t('Create quantity')"
                      v-if="dialogStatus === 'create'"
                  >
                    <el-input
                        v-model="temp['create_num']"
                        type="number"
                        style="width: 220px"
                        :style="
                        isPCShow
                          ? 'position:absolute;top:-50px;left:100px'
                          : 'position:absolute;top:-50px;left:100px'
                      "
                        :placeholder="$t('Please enter the')"
                        autocomplete="off"
                    />
                  </el-form-item>
                  <el-form-item
                      v-for="(item, index) in listModelServer"
                      :key="index"
                      v-if="item.edit === '1'"
                      style="position: relative"
                      :label="item.label"
                  >
                    <template v-slot:label>
                      <!-- 跳转规则 -->
                      <template v-if="item.edittype === 'radioRule'">
                        <span>{{ item.label }}</span>

                        <el-radio-group
                            class="filter-item"
                            :style="
                            isPCShow
                              ? 'margin-left: 40px; margin-top: 5px'
                              : 'margin-left: 0px'
                          "
                            v-model="temp[item.key]"
                        >
                          <el-radio
                              v-for="(radioItem, radioIndex) in item.editselect"
                              :key="radioIndex"
                              :label="radioItem.id"
                          >
                            {{ radioItem.title }}
                          </el-radio>
                        </el-radio-group>
                        <el-checkbox
                            v-model="temp.short_onlyonline"
                            v-if="temp['short_rule'] !== 2"
                            :false-label="0"
                            :true-label="1"
                            :style="
                            isPCShow ? 'margin-left: 20px; top: -2px' : ''
                          "
                        >仅在线
                        </el-checkbox
                        >
                        <span v-if="temp['short_rule'] === 1">
                          <el-input
                              style="width: auto; margin-left: 10px"
                              v-model="acclistArr.search"
                              type="text"
                              size="mini"
                              :placeholder="$t('Please enter the') + '内容'"
                              class="filter-item"
                              @keyup.enter.native="acclistChange"
                          />
                          <el-button
                              style="margin-left: 5px"
                              type="text"
                              size="mini"
                              icon="el-icon-search"
                              @click="acclistChange"
                          >{{ $t("Search") }}</el-button
                          >
                          <el-button
                              style="margin-left: 5px"
                              type="text"
                              size="mini"
                              icon="el-icon-check"
                              @click="selectAccountAll"
                          >{{ $t("Full") }}</el-button
                          >
                          <span style="margin-left: 10px"
                          >共计{{ acclistArr.count }}条</span
                          >
                        </span>
                        <span
                            style="
                            margin-left: 10px;
                            color: #ff1f75;
                            background-color: rgba(241, 255, 233, 0.5);
                          "
                            v-if="acclistArr.loading"
                        >loading...</span
                        >
                      </template>
                      <!-- 跳转绑定 -->
                      <template v-else-if="item.edittype === 'radioBindtype'">
                        <span>{{ item.label }}</span>
                        <el-radio-group
                            class="filter-item"
                            style="margin-left: 40px; margin-top: 5px"
                            v-model="temp[item.key]"
                        >
                          <el-radio
                              v-for="(radioItem, radioIndex) in item.editselect"
                              :key="radioIndex"
                              :label="radioItem.id"
                              :style="isPCShow ? '' : 'margin-right: 15px'"
                          >
                            {{ radioItem.title }}
                          </el-radio>
                        </el-radio-group>
                        <el-tooltip
                            class="item"
                            style="margin-left: 10px"
                            effect="dark"
                            content="开启后，同一个设备每次访问连接同一个号码，防止同一个用户添加多个号码"
                            placement="top-start"
                        >
                          <i class="el-icon-question"></i
                          ></el-tooltip>
                      </template>
                      <template v-else-if="item.edittype === 'radioType'">
                        <span>{{ item.label }}</span>
                        <el-radio-group
                            style="margin-left: 40px; margin-top: 5px"
                            v-model="temp[item.key]"
                        >
                          <el-radio
                              v-for="(radioItem, radioIndex) in item.editselect"
                              :key="radioIndex"
                              :label="radioItem.id"
                          >
                            {{ radioItem.title }}
                            <el-tooltip
                                content="将根据添加的顺序进行跳转访问"
                                v-if="radioIndex === 0"
                            >
                              <i class="el-icon-info"></i
                              ></el-tooltip>
                            <el-tooltip
                                content="将根据随机一条进行跳转访问"
                                v-if="radioIndex === 1"
                            >
                              <i class="el-icon-info"></i
                              ></el-tooltip>
                          </el-radio>
                        </el-radio-group>
                      </template>
                      <span
                          v-show="
                          item.key != 'short_remark' &&
                          item.key != 'short_text' &&
                          item.key != 'short_errurl'
                        "
                          v-else
                      >{{ item.label }}</span
                      >
                    </template>
                    <label
                        v-if="
                        item.edittype === 'disable' &&
                        item.key != 'short_remark' &&
                        item.key != 'short_text' &&
                        item.key != 'short_errurl'
                      "
                    >{{ temp[item.key] }}</label
                    >
                    <input
                        class="form-control"
                        v-if="
                        item.edittype === 'text' &&
                        item.key != 'short_remark' &&
                        item.key != 'short_text'
                      "
                        v-model="temp[item.key]"
                        type="text"
                        :placeholder="$t('Please enter the') + item.label"
                        autocomplete="off"
                    />
                    <input
                        class="form-control"
                        v-if="item.edittype === 'number'"
                        v-model="temp[item.key]"
                        type="number"
                        :placeholder="$t('Please enter the') + item.label"
                        autocomplete="off"
                    />

                    <b-textarea
                        v-if="item.edittype === 'atext'"
                        v-model="temp[item.key]"
                        :placeholder="
                        $t('Please enter the') +
                        item.label +
                        ' ' +
                        $t('One in a row')
                      "
                    ></b-textarea>
                    <el-select
                        style="margin-left: 5px"
                        v-if="
                        item.edittype === 'select' && item.key != 'short_errurl'
                      "
                        v-model="temp[item.key]"
                        class="filter-item"
                        :placeholder="$t('Please enter the') + item.label"
                    >
                      <el-option
                          v-for="(selectitem, selectkey) in item.editselect"
                          :key="selectkey"
                          :label="selectitem.title"
                          :value="selectitem.id"
                      />
                    </el-select>

                    <!-- <el-select
                      style="margin-left: 5px"
                      v-if="
                        item.edittype === 'select' && item.key == 'short_errurl'
                      "
                      v-model="temp[item.key]"
                      class="filter-item"
                      :placeholder="$t('Please enter the') + item.label"
                    >
                      <el-option
                        v-for="(selectitem, selectkey) in item.editselect"
                        :key="selectkey"
                        :label="selectitem.title"
                        :value="selectitem.id"
                      />
                    </el-select> -->
                    <!-- 邀请码 -->
                    <el-select
                        @change="delAccount(-1) && acclistChange()"
                        :style="
                        isPCShow
                          ? 'position:absolute;top:-50px;left:100px'
                          : 'position:absolute;top:-50px;left:100px'
                      "
                        v-if="item.edittype === 'selectShareid'"
                        v-model="temp[item.key]"
                        class="filter-item"
                        :placeholder="$t('Please enter the') + item.label"
                    >
                      <el-option
                          v-for="(selectitem, selectkey) in item.editselect"
                          :key="selectkey"
                          :label="selectitem.title"
                          :value="selectitem.id"
                      />
                    </el-select>
                    <el-date-picker
                        v-if="item.edittype === 'date'"
                        v-model="temp[item.key]"
                        style="top: -4px; width: 200px"
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :placeholder="$t('Please enter the') + item.label"
                    />
                    <el-time-picker
                        v-if="item.edittype === 'time'"
                        v-model="temp[item.key]"
                        style="top: -4px; width: 200px"
                        type="datetime"
                        format="HH:mm:ss"
                        value-format="HH:mm:ss"
                        :placeholder="$t('Please enter the') + item.label"
                    />
                    <el-date-picker
                        v-if="item.edittype === 'datetime'"
                        v-model="temp[item.key]"
                        style="top: -4px; width: 200px"
                        type="datetime"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :placeholder="$t('Please enter the') + item.label"
                    />
                    <el-radio-group
                        style="display: block; margin-top: 10px"
                        v-if="item.edittype === 'radio'"
                        v-model="temp[item.key]"
                    >
                      <el-radio
                          v-for="(radioItem, radioIndex) in item.editselect"
                          :key="radioIndex"
                          :label="radioItem.id"
                      >
                        {{ radioItem.title }}
                      </el-radio>
                    </el-radio-group>

                    <div
                        v-if="item.edittype === 'radioBindtype'"
                        :style="
                        isPCShow
                          ? 'margin-left: 100px; margin-top: -20px;'
                          : 'margin-top: -20px'
                      "
                    >
                      <el-tag type="info" v-if="temp['short_bindtype'] === 0"
                      >关闭后，同一个设备每次访问都将重新分配一个新的号码，<br
                          v-if="!isPCShow"
                      />
                        有可能一个用户添加多个号码
                      </el-tag
                      >
                      <el-tag type="info" v-if="temp['short_bindtype'] === 1"
                      >根据访问者的请求IP相同标记为同个设备
                      </el-tag
                      >
                      <el-tag
                          type="info"
                          v-else-if="temp['short_bindtype'] === 2"
                      >根据访问者的浏览器Cookie相同标记为同个设备
                      </el-tag
                      >
                    </div>
                    <div
                        v-if="item.edittype === 'radioRule'"
                        :style="
                        isPCShow
                          ? 'margin-left: 100px; margin-top: -20px;'
                          : 'margin-top: -20px'
                      "
                    >
                      <template v-if="temp['short_rule'] === 0">
                        <el-select
                            style="margin-top: 5px"
                            v-model="selectapp"
                            class="filter-item"
                            :placeholder="$t('Please enter the') + 'app'"
                        >
                          <el-option
                              v-for="(selectitem, selectkey) in applist"
                              :key="selectkey"
                              :label="selectitem.title"
                              :value="selectitem.id"
                          />
                        </el-select>
                        <template v-for="selectitem in applist">
                          <el-tag
                              v-if="selectitem.id === selectapp"
                              :style="isPCShow ? 'margin-left: 10px;' : ''"
                              type="info"
                          >
                            <template v-for="shareitem in sharelist">
                              <span
                                  v-if="shareitem.id === temp.short_shareid"
                                  :style="isPCShow ? 'margin-left: 10px;' : ''"
                              >当前邀清码[ {{ shareitem.title }} ]下，{{
                                  selectitem.title
                                }}的{{
                                  temp.short_onlyonline === 1
                                      ? "仅在线"
                                      : "所有"
                                }}号码</span
                              >
                            </template>
                          </el-tag>
                        </template>
                      </template>
                      <template v-if="temp['short_rule'] === 1">
                        <el-scrollbar style="margin-top: 5px">
                          <ul
                              class="list"
                              v-infinite-scroll="loadAcclistData"
                              style="height: 150px"
                              infinite-scroll-disabled="acclistDisabled"
                              infinite-scroll-delay="500"
                          >
                            <el-checkbox-group
                                v-model="acclistArr.acclistid"
                                style="
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
                              "
                            >
                              <template
                                  v-for="(item, groupi) in acclistArr.data"
                              >
                                <el-checkbox
                                    class="list-item"
                                    style="
                                    margin-left: 10px;
                                    flex: 0 0 calc((100% - 10px) / 6);
                                  "
                                    @change="selectAccount(item)"
                                    :key="groupi"
                                    :label="item.acclist_id"
                                >
                                  <div
                                      style="display: flex; flex-direction: row"
                                  >
                                    <div>
                                      <el-image
                                          :preview-src-list="[
                                          item['acclist_headimg'],
                                        ]"
                                          style="
                                          height: 40px;
                                          width: 40px;
                                          border-radius: 5px;
                                        "
                                          fit="fill"
                                          :src="
                                          item['acclist_headimg'] || noheadimg
                                        "
                                          @error="
                                          loadHeadimgErr(
                                            item,
                                            'acclist_headimg'
                                          )
                                        "
                                      />
                                    </div>
                                    <div style="margin-left: 10px">
                                      <div
                                          style="
                                          width: 200px;
                                          white-space: nowrap;
                                          overflow: hidden;
                                          text-overflow: ellipsis;
                                        "
                                      >
                                        {{ item.acclist_nickname }}
                                      </div>
                                      <div
                                          style="
                                          width: 200px;
                                          white-space: nowrap;
                                          overflow: hidden;
                                          text-overflow: ellipsis;
                                        "
                                      >
                                        {{ item.acclist_username }}
                                      </div>
                                    </div>
                                  </div>
                                </el-checkbox>
                              </template>
                            </el-checkbox-group>
                          </ul>
                        </el-scrollbar>
                        <el-collapse
                            v-if="selectAccdata.length > 0"
                            :value="['1']"
                        >
                          <el-collapse-item name="1">
                            <template v-slot:title>
                              <span>已选中列表</span>
                              <el-button
                                  @click="delAccount(-1)"
                                  style="margin-left: 10px"
                                  icon="el-icon-delete"
                                  type="text"
                              >清空选中
                              </el-button>
                              <span style="margin-left: 10px"
                              >共计{{ selectAccdata.length }}条</span
                              >
                            </template>
                            <el-scrollbar>
                              <ul
                                  class="list"
                                  style="
                                  height: 150px;
                                  display: flex;
                                  flex-direction: row;
                                  flex-wrap: wrap;
                                "
                              >
                                <el-alert
                                    type="success"
                                    @close="delAccount(alertIndex)"
                                    v-for="(
                                    alertItem, alertIndex
                                  ) in selectAccdata"
                                    :key="alertIndex+Math.random()"
                                    style="
                                    width: 19%;
                                    height: 60px;
                                    padding: 8px 2px;
                                    margin: 3px;
                                  "
                                >
                                  <template v-slot:title>
                                    <div
                                        style="display: flex; flex-direction: row"
                                    >
                                      <div>
                                        <el-image
                                            :preview-src-list="[
                                            alertItem['acclist_headimg'],
                                          ]"
                                            style="
                                            height: 40px;
                                            width: 40px;
                                            border-radius: 5px;
                                          "
                                            fit="fill"
                                            :src="
                                            alertItem['acclist_headimg'] ||
                                            noheadimg
                                          "
                                            @error="
                                            loadHeadimgErr(
                                              alertItem,
                                              'acclist_headimg'
                                            )
                                          "
                                        />
                                      </div>
                                      <div style="margin-left: 10px">
                                        <div
                                            style="
                                            width: 150px;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                          "
                                        >
                                          {{ alertItem.acclist_nickname }}
                                        </div>
                                        <div
                                            style="
                                            width: 150px;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                          "
                                        >
                                          {{ alertItem.acclist_username }}
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </el-alert>
                              </ul>
                            </el-scrollbar>
                          </el-collapse-item>
                        </el-collapse>
                      </template>
                      <template v-if="temp['short_rule'] === 2">
                        <b-textarea
                            v-model="temp['short_jumpurl']"
                            placeholder="请输入链接，一行一个"
                        ></b-textarea>
                      </template>
                    </div>

                    <el-checkbox-group
                        v-if="item.edittype === 'checkbox'"
                        v-model="temp[item.key]"
                    >
                      <el-checkbox
                          v-for="(chboxItem, chboxIndex) in item.editselect"
                          :key="chboxIndex"
                          :label="chboxItem.id"
                      >
                        {{ chboxItem.title }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <el-row
                      style="width: 100%"
                      :style="
                      isPCShow
                        ? 'margin-top:0px;top: 0px;'
                        : 'margin-top:0px'
                    "
                  >
                    <el-col :span="8" :xs="24">
                      <el-form-item :label="short_remark.label || ''">
                        <input
                            class="form-control"
                            v-model="temp['short_remark']"
                            type="text"
                            style="width: 220px; margin-top: -20px !important"
                            :placeholder="
                            $t('Please enter the') + short_remark.label
                          "
                            autocomplete="off"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" :xs="24">
                      <el-form-item :label="short_text.label || ''">
                        <input
                            class="form-control"
                            v-model="temp['short_text']"
                            type="text"
                            style="width: 220px; margin-top: -20px !important"
                            :placeholder="
                            $t('Please enter the') + short_text.label || ''
                          "
                            autocomplete="off"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="6" :xs="24">
                      <el-form-item :label="short_errurl.label">
                        <el-select
                            style="width: 220px; top: -20px !important"
                            v-model="temp['short_errurl']"
                            class="filter-item"
                            :placeholder="
                            $t('Please enter the') + short_errurl.label || ''
                          "
                        >
                          <el-option
                              v-for="(
                              selectitem, selectkey
                            ) in short_errurl.editselect"
                              :key="selectkey"
                              :label="selectitem.title"
                              :value="selectitem.id"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item>
                    <el-collapse value="false">
                      <el-collapse-item title="" name="1">
                        <template v-slot:title>
                          <span style="font-weight: bold;text-align: right">{{$t('More Settings')}}</span>
                        </template>
                        <div style="font-weight: 700;color: #606266;"
                             class="el-collapse-item__header el-form-item__label">{{$t('Powder feed setting')}}
                          <el-tooltip :content="$t('Set a target number of contacts per day for each account')">
                            <i style="margin-left: 5px;" class="el-icon-info"></i>
                          </el-tooltip>
                        </div>
                        <div style="display: flex;align-items: center;">
                          <input
                              class="form-control"
                              v-model="temp['short_quota']"
                              type="number"
                              :placeholder="$t('Please enter the') + $t('Powder feed setting')"
                              autocomplete="off"
                              style="width: 200px;"
                          />

                        </div>
                        <div style="margin-top: 10px;">
                          <div style="font-weight: 700;color: #606266;"
                               class="el-collapse-item__header el-form-item__label">
                            {{temp['short_permit_country'] === 0 ? $t('Shield') : $t('Permit')}}{{  $t('Country') }}
                            <el-tooltip
                                :content="$t('Will block the country simplified code, such as China -CN, Hong Kong -HK, Vietnam -VI, etc., you can consult the relevant country simplified code')">
                              <i style="margin-left: 5px;" class="el-icon-info"></i>
                            </el-tooltip>

                            <el-checkbox v-model="temp['short_permit_country']" size="mini" :false-label="0" :true-label="1" style="margin-left: 10px;">
                              {{$t('Only allow')}}
                              <el-tooltip placement="top" :content="$t('When checked, only populated countries are allowed to access the link')">
                                <span> <i class="el-icon-warning-outline" /></span>
                              </el-tooltip>
                            </el-checkbox>
                          </div>
<!--                          <b-textarea
                              v-model="temp['short_blocked_country']"
                              :placeholder="
                        $t('Please enter the') +
                      $t('Blocked country') +
                        $t('One in a row')
                      "
                          ></b-textarea>-->
                          <el-select
                              style="width: 100%;"
                              v-model="short_blocked_country"
                              @change="blockedDeviceChange(1)"
                              filterable
                              allow-create
                              multiple :placeholder="$t('Please select a')"
                          >
                            <el-option
                                v-for="(chboxItem, chboxIndex) in nationList"
                                :key="chboxIndex"
                                :label="$t(chboxItem)"
                                :value="chboxItem"
                            >
                              <span style="float: left">{{ $t(chboxItem) }}</span>
                              <span style="float: right; color: #8492a6; font-size: 13px">{{ chboxItem }}</span>
                            </el-option>
                          </el-select>
                        </div>
                        <div style="margin-top: 10px;">
                          <div style="font-weight: 700;color: #606266;"
                               class="el-collapse-item__header el-form-item__label">
                            {{ $t('Shielding equipment') }}
                            <el-tooltip
                                :content="$t('Will block the operating system such as Windows and macos, ios, android, Linux, Unix, fill out a line of a')">
                              <i style="margin-left: 5px;" class="el-icon-info"></i>
                            </el-tooltip>
                          </div>
                          <!--                        <b-textarea
                                                      v-model="temp['short_blocked_device']"
                                                      :placeholder="
                                                  $t('Please enter the') +
                                                  $t('Shielding equipment') +
                                                  $t('One in a row')
                                                "
                                                  ></b-textarea>-->
                          <el-select v-model="short_blocked_device" style="width: 100%;"
                                     @change="blockedDeviceChange(0)" multiple :placeholder="$t('Please select a')">
                            <el-option
                                v-for="item in ['Windows','Linux','Android','MacOS','IOS','Unix']"
                                :key="item"
                                :label="item"
                                :value="item">
                            </el-option>
                          </el-select>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </el-form-item>
                </el-form>

                <div slot="footer" class="dialog-footer">
                  <button
                      class="btn btn-secondary"
                      type="button"
                      @click="
                      dialogStatus === 'create' ? createData() : updateData()
                   "
                  >
                    {{ $t("Save") }}
                  </button>
                  <button
                      class="btn btn-primary ml-2"
                      type="button"
                      data-dismiss="modal"
                      @click="dialogSwitch.shortModalExample = false"
                  >
                    {{ $t("Cancel") }}
                  </button>
                </div>
              </el-dialog>
              <!--结束       -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination';
  import noheadimg from '@/assets/images/noheadimg.png';
  import ShortlogPage from '@/pages/sharecode/shortlog/shortlog.vue';
  import ShortlogchartPage from '@/pages/sharecode/shortlogchart/shortlogchart.vue';
  import ra from 'vue-rate-it/glyphs/ra';

  let t;
  export default {
    components: { ShortlogchartPage, ShortlogPage, Pagination },
    data() {
      return {
        isPCShow: true,
        windowWidth: window.innerWidth,
        short_errurl: {},
        short_text: {},
        short_remark: {},
        noheadimg: noheadimg,
        dialogSwitch: {
          shortModalExample: false,
        },
        acclistArr: {
          search: '',
          count: 0,
          loading: false,
          noMore: false,
          acclistid: [],
          page: 1,
          data: [],
          shareid: [],
        },
        shortlogArr: {
          shortid: 0,
          show: false,
          chartshow: false,
        },
        downloadLoading: false,
        showColums: {},
        allStatus: false,
        listQuery: {
          page: 1,
          limit: 20,
          sort: '',
        },
        option: {
          showAddBt: false,
          showCatchEditBt: false,
          showExportBt: false,
          showSearchBt: true,
        },
        applist: [],
        selectapp: 0,
        selectAccdata: [],
        shorturlsys: [],
        listLoading: true,
        pageSizelist: [],
        listSearchServer: [],
        listModelServer: [],
        filter: null,
        list: [],
        total: 0,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15],
        tableKey: 0,
        selectMode: 'multi',
        selected: [],
        listdata: [],
        temp: {},
        dialogStatus: '',
        summar: {
          switch: false,
          data: {},
        },
        sharelist: [],
        selectData: [],
        titleModelList: [],
        short_blocked_device: [],
        short_blocked_country: [],
        nationList: [
          'AF',
          'AL',
          'DZ',
          'AD',
          'AO',
          'AG',
          'AR',
          'AM',
          'AU',
          'AT',
          'AZ',
          'BS',
          'BH',
          'BD',
          'BB',
          'BY',
          'BE',
          'BZ',
          'BJ',
          'BT',
          'BO',
          'BA',
          'BW',
          'BR',
          'BN',
          'BG',
          'BF',
          'BI',
          'CV',
          'KH',
          'CM',
          'CA',
          'CF',
          'TD',
          'CL',
          'CN',
          'CO',
          'KM',
          'CG',
          'CD',
          'CR',
          'HR',
          'CU',
          'CY',
          'CZ',
          'DK',
          'DJ',
          'DM',
          'DO',
          'EC',
          'EG',
          'SV',
          'GQ',
          'ER',
          'EE',
          'SZ',
          'ET',
          'FJ',
          'FI',
          'FR',
          'GA',
          'GM',
          'GE',
          'DE',
          'GH',
          'GR',
          'GD',
          'GT',
          'GN',
          'GW',
          'GY',
          'HT',
          'HN',
          'HU',
          'IS',
          'IN',
          'ID',
          'IR',
          'IQ',
          'IE',
          'IL',
          'IT',
          'JM',
          'JP',
          'JO',
          'KZ',
          'KE',
          'KI',
          'KP',
          'KR',
          'KW',
          'KG',
          'LA',
          'LV',
          'LB',
          'LS',
          'LR',
          'LY',
          'LI',
          'LT',
          'LU',
          'MG',
          'MW',
          'MY',
          'MV',
          'ML',
          'MT',
          'MH',
          'MR',
          'MU',
          'MX',
          'FM',
          'MD',
          'MC',
          'MN',
          'ME',
          'MA',
          'MZ',
          'MM',
          'NA',
          'NR',
          'NP',
          'NL',
          'NZ',
          'NI',
          'NE',
          'NG',
          'NO',
          'OM',
          'PK',
          'PW',
          'PA',
          'PG',
          'PY',
          'PE',
          'PH',
          'PL',
          'PT',
          'QA',
          'RO',
          'RU',
          'RW',
          'KN',
          'LC',
          'VC',
          'WS',
          'SM',
          'ST',
          'SA',
          'SN',
          'RS',
          'SC',
          'SL',
          'SG',
          'SK',
          'SI',
          'SB',
          'SO',
          'ZA',
          'SS',
          'ES',
          'LK',
          'SD',
          'SR',
          'SE',
          'CH',
          'SY',
          'TW',
          'TJ',
          'TZ',
          'TH',
          'TL',
          'TG',
          'TO',
          'TT',
          'TN',
          'TR',
          'TM',
          'TV',
          'UG',
          'UA',
          'AE',
          'GB',
          'US',
          'UY',
          'UZ',
          'VU',
          'VA',
          'VE',
          'VN',
          'YE',
          'ZM',
          'ZW'
        ]
      };
    },
    computed: {
      acclistDisabled() {
        return this.acclistArr.loading || this.acclistArr.noMore;
      },
    },
    mounted() {
      // Set the initial number of items
      t = this;
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      t.getRenderData();
      t.getColumn();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      blockedDeviceChange(key) {
        if (key === 0) {
          this.temp['short_blocked_device'] = this.short_blocked_device.join('\n');
        } else {
          this.temp['short_blocked_country'] = this.short_blocked_country.join('\n');
        }
        console.log(this.temp['short_blocked_country']);
      //
      //this.$set(this.temp,key,this.key.join('\n'));
      },
      handleResize() {
        t.windowWidth = window.innerWidth;
        // const windowWidth = window.innerWidth;
        t.isPCShow = t.windowWidth >= 768;
      },
      ra() {
        return ra;
      },
      clearRecord() {
        if (this.selectData.length === 0) {
          return this.$toasted.show('至少选择一个', {
            theme: 'bubble',
            position: 'top-center',
            type: 'info',
            duration: 3000,
          });
        }
        this.$confirm(`确认要清除吗?`, this.$t('warning'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning',
        })
          .then(async () => {
            const idList = [];
            // eslint-disable-next-line no-unused-vars
            for (const selectDataElement of this.selectData) {
              idList.push(selectDataElement.short_id);
            }
            this.request
              .post('/short/clearRecord', { idList: idList })
              .then(() => {
                this.getList();
                t.dialogSwitch.shortModalExample = false;
                this.$notify({
                  title: '完成',
                  message: '清除成功',
                  type: 'success',
                  duration: 2000,
                });
              })
              .catch((res) => {
                if (res.errMsg) {
                  t.$toasted.show(res.errMsg, {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                } else {
                  t.$toasted.show(t.$t('Server Error'), {
                    theme: 'bubble',
                    position: 'bottom-center',
                    type: 'error',
                    duration: 3000,
                  });
                }
              });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      loadHeadimgErr(e, key) {
        e[key] = t.noheadimg;
      },
      handleSelectionChange(e) {
        this.selectData = e;
      // console.log("选中哈哈哈", e);
      },
      toggleSelection() {
        this.$refs.multipleTable.clearSelection();
      },
      acclistChange() {
        this.acclistArr.noMore = false;
        this.acclistArr.page = 1;
        this.acclistArr.data = [];
        this.acclistArr.acclistid = [];
        this.loadAcclistData();
      },
      shortlogShow(shortid = 0) {
        this.shortlogArr.shortid = shortid;
        this.shortlogArr.show = true;
      },
      shortlogChart(shortid = 0) {
        this.shortlogArr.shortid = shortid;
        this.shortlogArr.chartshow = true;
      },
      acclistSearch() {
        this.acclistArr.noMore = false;
        this.acclistArr.page = 1;
        this.acclistArr.data = [];
        this.loadAcclistData();
      },
      selectAccount(item) {
        // eslint-disable-next-line no-unused-vars
        for (const selectAccdatum of this.selectAccdata) {
          if (item.acclist_id === selectAccdatum.acclist_id) {
            return;
          }
        }
        this.selectAccdata.push(item);
      },
      selectAccountAll() {
        // eslint-disable-next-line no-unused-vars
        accFor: for (const item of this.acclistArr.data) {
          this.acclistArr.acclistid.push(item.acclist_id);
          // eslint-disable-next-line no-unused-vars
          for (const selectAccdatum of this.selectAccdata) {
            if (item.acclist_id === selectAccdatum.acclist_id) {
              continue accFor;
            }
          }
          this.selectAccdata.push(item);
        }
      },
      delAccount(index) {

        if (index === -1) return (this.selectAccdata = []);
        this.selectAccdata.splice(index, 1);
      },
      loadAcclistData() {
        this.acclistArr.loading = true;
        this.acclistArr.shareid = [this.temp.short_shareid];
        t.request
          .get('/short/getAcclist', this.acclistArr)
          .then((response) => {
            const items = response.data.items;
            if (items.length === 0) {
              this.acclistArr.noMore = true;
            } else {
              if (this.acclistArr.page === 1) {
                this.acclistArr.data = items;
              } else {
                this.acclistArr.data = [...this.acclistArr.data, ...items];
              }
            }
            // eslint-disable-next-line no-unused-vars
            for (const acclistidElement of this.acclistArr.acclistid) {
              // eslint-disable-next-line no-unused-vars
              for (const item of items) {
                if (acclistidElement === item.acclist_id) {
                  this.selectAccount(item);
                }
              }
            }

            this.acclistArr.count = response.data.total;
            // Just to simulate the time of the request
            this.acclistArr.loading = false;
            this.acclistArr.page++;
            this.$forceUpdate();
          })
          .catch((res) => {
            this.acclistArr.loading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      tableClick(item, index) {
        if (index.label === '操作') return;
        this.$copyText(item.short_url)
          .then((e) => {
            t.$toasted.show('复制成功', {
              theme: 'bubble',
              position: 'top-center',
              type: 'success',
              duration: 3000,
            });
          })
          .catch((e) => {
            t.$toasted.show('复制失败', {
              theme: 'bubble',
              position: 'top-center',
              type: 'error',
              duration: 3000,
            });
          });
      },
      selectAllFun(e) {
        !t.allStatus;
        let i = 0;
        t.listModelServer.forEach((item) => {
          if (item.show === '1') {
            if (!t.allStatus && i <= 5) {
              t.$set(t.showColums, item.key, true);
            } else if (!t.allStatus && i > 20) {
              t.$set(t.showColums, item.key, false);
            } else {
              t.$set(t.showColums, item.key, true);
            }
            i++;
          }
        });
        window.localStorage.setItem('short_colmun', JSON.stringify(t.showColums));
      },
      getColumn() {
        t.showColums = JSON.parse(
          window.localStorage.getItem('short_colmun') || '{}'
        );
      },
      saveColmun() {
        let arr = Object.values(t.showColums).filter((item) => item);
        t.allStatus = arr.length === t.listModelNum;
        window.localStorage.setItem('short_colmun', JSON.stringify(t.showColums));
      },
      getSummar(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            // 只找第一列放合计
            sums[index] = `${t.$t('combined')}`;
            return;
          }
          if (t.summar.data[column.property]) {
            // 判断当前prop绑定的ID
            const values = data.map((item) => item[column.property]); // 把对应一列中的之全部取出，放到一个数组中
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr); // 将values中的每个值转换为number类型
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          }
        });
        return sums;
      },
      handleOpen(item, index) {
        this.$router.replace({
          path: `/short/accountlist/${item.share_id}/${item.share_code}`,
        });
      },
      handleDownload() {
        this.downloadLoading = true;
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = ['timestamp', 'title', 'type', 'importance', 'status'];
        const filterVal = [
          'timestamp',
          'title',
          'type',
          'importance',
          'status',
        ];
        const data = this.formatJson(filterVal);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'table-list',
        });
        this.downloadLoading = false;
      });
      },
      formatJson(filterVal) {
        return this.list.map((v) =>
          filterVal.map((j) => {
            if (j === 'timestamp') {
              return parseTime(v[j]);
            } else {
              return v[j];
            }
          })
        );
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      resetFun() {
        // eslint-disable-next-line no-unused-vars
        for (const listSearchServerElement of t.listSearchServer) {
          t.$set(t.listQuery, listSearchServerElement.key, '');
        }
      },
      handleUpdate(row) {
        this.temp = Object.assign({}, row); // copy obj
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in this.temp) {
          try {
            if (typeof JSON.parse(this.temp[tempKey]) === 'object') {
              this.temp[tempKey] = JSON.parse(this.temp[tempKey]);
            }
          // eslint-disable-next-line no-empty
          } catch (e) {
          }
        }
        this.short_errurl = this.listModelServer.find(
          (x) => x.key == 'short_errurl'
        );
        this.short_text = this.listModelServer.find((x) => x.key == 'short_text');
        this.short_remark = this.listModelServer.find(
          (x) => x.key == 'short_remark'
        );
        this.acclistArr.data = [];
        this.acclistArr.noMore = false;
        this.selectAccdata = [];
        this.acclistArr.page = 1;
        this.temp.password = '';
        this.temp.walletpassword = '';

        this.short_blocked_device = this.temp['short_blocked_device'] ? this.temp['short_blocked_device'].split('\n') : [];
        this.short_blocked_country = this.temp['short_blocked_country'] ? this.temp['short_blocked_country'].split('\n') : [];
        this.dialogStatus = 'update';
        t.dialogSwitch.shortModalExample = true;
        this.$nextTick(() => {
          this.acclistArr.acclistid = this.temp.short_accountid;
          this.$forceUpdate();
        });
      },
      createData() {
        const tempData = Object.assign({}, this.temp);
        tempData['create_num'] = Number(tempData['create_num']);
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in tempData) {
          try {
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of this.listModelServer) {
              if (modelDatum.key === tempKey && (modelDatum.edittype === 'number' || modelDatum.edittype.indexOf('Number') !== -1)) {
                tempData[tempKey] = Number(tempData[tempKey]);
                break;
              }
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
          // eslint-disable-next-line no-empty
          } catch (e) {
          }
        }
        const accountid = [];
        const selectacc = [];
        if (tempData.short_rule === 1) {
          // eslint-disable-next-line no-unused-vars
          for (const selectAccdatum of this.selectAccdata) {
            accountid.push(selectAccdatum.acclist_id);
            selectacc.push({
              id: selectAccdatum.acclist_id,
              appid: selectAccdatum.acclist_appid,
              username: selectAccdatum.acclist_username,
              account: selectAccdatum.acclist_account,
            });
          }
        }
        tempData['accountid'] = JSON.stringify(accountid);
        tempData['accdata'] = selectacc;
        tempData['selectapp'] = this.selectapp;
        t.request
          .post('/short/add', tempData)
          .then(() => {
            this.getList();
            t.dialogSwitch.shortModalExample = false;
            this.$notify({
              title: '完成',
              message: '添加成功',
              type: 'success',
              duration: 2000,
            });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      handleCreate() {
        // 增加错误跳转初始值
        let errInitialValue = this.listModelServer.find(
          (x) => x.key === 'short_errurl'
        );
        this.short_errurl = this.listModelServer.find(
          (x) => x.key == 'short_errurl'
        );
        this.short_text = this.listModelServer.find((x) => x.key == 'short_text');
        this.short_remark = this.listModelServer.find(
          (x) => x.key == 'short_remark'
        );
        this.resetTemp();
        this.selectAccdata = [];
        this.temp['short_bindtype'] = 0;
        this.temp['short_rule'] = 0;
        this.temp['short_type'] = 0;
        this.temp['short_urlindex'] = 0;
        this.temp['create_num'] = 1;
        this.temp['short_onlyonline'] = 1;
        this.temp['short_accountid'] = '';
        this.temp['short_errurl'] = 0;
        this.dialogStatus = 'create';
        this.$nextTick(() => {
          this.temp.short_shareid = Number(this.sharelist[0].id);
        });
        t.dialogSwitch.shortModalExample = true;
      },
      resetTemp() {
        const jsonTemp = {};
        // eslint-disable-next-line no-unused-vars
        for (const listModelServerKey in this.listModelServer) {
          switch (this.listModelServer[listModelServerKey]['edittype']) {
          case 'checkbox':
            jsonTemp[this.listModelServer[listModelServerKey]['key']] = [];
            break;
          default:
            jsonTemp[this.listModelServer[listModelServerKey]['key']] = '';
            break;
          }
        }
        this.temp = jsonTemp;
      },
      updateData(row, key, value) {
        if (row) {
          row[key] = value;
          this.temp = row;
        }
        const tempData = Object.assign({}, this.temp);

        // eslint-disable-next-line no-unused-vars
        for (const tempKey in tempData) {
          try {
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of this.listModelServer) {
              if (
                modelDatum.key === tempKey &&
                (modelDatum.edittype === 'number' || modelDatum.edittype.indexOf('Number') !== -1)
              ) {
                tempData[tempKey] = Number(tempData[tempKey]);
                break;
              }
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
          } catch (e) {
            console.error(e);
          }
          /* if (typeof JSON.parse(this.temp[tempKey]) === 'array') {

      }*/

          const accountid = [];
          const selectacc = [];
          if (tempData.short_rule === 1) {
            // eslint-disable-next-line no-unused-vars
            for (const selectAccdatum of this.selectAccdata) {
              accountid.push(selectAccdatum.acclist_id);
              selectacc.push({
                id: selectAccdatum.acclist_id,
                appid: selectAccdatum.acclist_appid,
                username: selectAccdatum.acclist_username,
                account: selectAccdatum.acclist_account,
              });
            }
          }
          tempData['accountid'] = JSON.stringify(accountid);
          tempData['accdata'] = selectacc;
          tempData['selectapp'] = this.selectapp;
        }
        t.request
          .post('/short/edit', tempData)
          .then((res) => {
            t.getList();
            t.dialogSwitch.shortModalExample = false;
            t.$toasted.show(res.msg, {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'success',
              duration: 3000,
            });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      handleShare(row, index = 0) {
        t.temp = row;
        t.$bvModal.show('tag-modal-share');
      },
      handleDelete(row, index) {
        this.$confirm(`确认要删除吗?`, this.$t('warning'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning',
        })
          .then(async () => {
            await t.request.post('/short/delete', row);
            this.list.splice(index, 1);
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      getRenderData() {
        t.request
          .get('/short/render')
          .then((response) => {
            const res = response.data;
            let i = 0;
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of res.modelData) {
              if (modelDatum.show === '1') {
                if (i > 20) break;
                if (!t.showColums.hasOwnProperty(modelDatum.key)) {
                  this.$set(t.showColums, modelDatum.key, true);
                }
                i++;
              }
            }
            t.option = res.option || {};
            t.summar = res.summar || {};
            t.listModelServer = res.modelData || [];
            t.listSearchServer = res.searchData || [];
            t.listQuery.sort = res.defaultSort || [];
            t.pageSizelist = res.pageSizelist || [];
            t.shorturlsys = res.shorturlsys || [];
            t.applist = res.applist || [];
            t.sharelist = res.sharelist || [];
            t.getList();
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      getList() {
        this.listLoading = true;
        t.request
          .get('/short/list', this.listQuery)
          .then((response) => {
            this.list = response.data.items;
            this.total = response.data.total;
            // Just to simulate the time of the request
            this.listLoading = false;
          })
          .catch((res) => {
            this.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      sortChange(data) {
        const { prop, order } = data;
        t.sortByID(order, prop);
      },
      sortByID(order, prop) {
        if (order === 'ascending') {
          t.listQuery.sort = `${prop} ASC`;
        } else {
          t.listQuery.sort = `${prop} DESC`;
        }
        t.handleFilter();
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    },
  };
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 10px;
}

.el-select-dropdown {
  /*background-color: unset ;*/
}

.el-input__inner {
  background-color: unset !important;
}

.pagination-container {
  background: unset;
}

.el-table__cell {
  background-color: unset;
}
</style>
