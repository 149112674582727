<template>
  <div class="header-wrapper row m-0">
    <SearchBar />
    <Logo />
    <div class="left-header col horizontal-wrapper pl-0" v-if="isPCShow">
      <ul class="horizontal-menu">
        <!--        <Sharecode />
                <Download />-->
      </ul>
    </div>
    <div class="nav-right col-8 pull-right right-header p-0">
      <ul
        class="nav-menus"
        style="flex-wrap: wrap; width: 100%; min-width: 350px"
      >
        <li class="language-nav" style="position: relative">
          <img class="flagvn" :src="getImgUrl('84.png')" alt="" />

          <Language />
        </li>

        <Notifications :radio-list="radioList" />
        <li>
          <Mode />
        </li>
        <!--        <Message />-->
        <!--        <li class="maximize">
                  <Maximize />
                </li>-->
        <!--        <li>
          <div style="color: rgba(0,0,0,.45);font-size: 14px;">{{ $t("The words number") }}</div>
          <div style="text-align: center;">{{ wordSessions }}</div>
        </li>

        <li>
          <div style="color: rgba(0,0,0,.45);font-size: 14px;">{{ $t("The session number") }}</div>
          <div style="text-align: center;">{{ portSessions }}</div>
        </li>

        <li id="direction-tooltip-bottom">
          <div style="color: rgba(0,0,0,.45);font-size: 14px;">{{ $t("Remaining validity period") }}</div>
          <div style="text-align: center;">{{expData.day}}</div>
        </li>-->

        <!-- v-if="isPCShow" -->
        <li @click="walletClick" style="cursor: pointer; display: flex">
          <div
            style="
              color: rgba(0, 0, 0, 0.45);
              font-size: 14px;
              text-align: center;
            "
          >
            {{ $t("The balance of my") }}:
          </div>
          <span style="text-align: center">${{ wallet }}</span>
        </li>
        <Profile :showRechange="showRechange" />
      </ul>
    </div>
    <b-tooltip
      target="direction-tooltip-bottom"
      triggers="hover"
      placement="bottom"
      >{{ expData.datetime }} 过期</b-tooltip
    >
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import Language from './language';
  import Notifications from './notifications';
  import Mode from './mode';
  import Profile from './profile';
  import Logo from './logo';
  import SearchBar from './search';

  let t;
  export default {
    name: 'Search',
    components: {
      Language,
      Notifications,
      Mode,
      Profile,
      Logo,
      SearchBar,
    },
    data() {
      return {
        bookmark: false,
        radioList: [],
        isPCShow: true,
        windowWidth: window.innerWidth,
        exptime: 0,
        portSessions: '',
        wallet: 0,
        wordSessions: '',
        expData: {
          day: '',
          datetime: '',
        },
        showRechange: false,
      };
    },
    computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
      searchOpen: (state) => state.menu.searchOpen,
    }),
    },
    created() {
      t = this;
      t.getData();
    },
    mounted() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      getImgUrl(path) {
        return require('@/assets/images/guoqi/' + path);
      },
      handleResize() {
        t.windowWidth = window.innerWidth;
        // const windowWidth = window.innerWidth;
        if (t.windowWidth < 768) {
       
          t.isPCShow = false;
        } else {
          t.isPCShow = true;
        }
      },
      walletClick() {
        this.showRechange = true;
        setTimeout(() => {
          this.showRechange = false;
        }, 1000);
      },
      calcExp() {
        const expNum = t.exptime - Date.now() / 1000;
        t.expData.datetime = t.formatUninx(t.exptime * 1000);
        if (expNum <= 0) {
          t.expData.day = '已过期';
        } else {
          if (expNum < 60) {
            t.expData.day = `${expNum}秒`;
            return;
          }
          let expCalc = expNum / 60;
          if (expCalc < 60) {
            t.expData.day = `${Math.floor(expCalc)}分`;
            return;
          }
          expCalc = expCalc / 60;
          if (expCalc < 24) {
            t.expData.day = `${Math.floor(expCalc)}时`;
            return;
          }
          expCalc = expCalc / 24;

          t.expData.day = `${Math.floor(expCalc)}天`;
        }
      },
      add0(m) {
        return m < 10 ? '0' + m : m;
      },
      formatUninx(unix = 0) {
        //unix是整数，否则要parseInt转换
        var time = new Date(unix);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return `${y}-${t.add0(m)}-${t.add0(d)} ${t.add0(h)}:${t.add0(
          mm
        )}:${t.add0(s)}`;
      },
      getData() {
        t.request
          .post('/indexinfo')
          .then((res) => {
            const data = res.data;
            t.radioList = data.radiolist;
            t.exptime = data.exptime;
            t.portSessions = data.portSessions;
            t.wallet = data.wallet;
            t.wordSessions = data.wordSessions;
            t.calcExp();
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      search_open() {
        this.$store.state.menu.searchOpen = true;
      },
      bookmark_open() {
        this.bookmark = !this.bookmark;
      },
    },
  };
</script>

<style lang="scss" scoped>
.tooltip {
  z-index: 99;
}
.flagvn {
  position: absolute;
  left: 10px;
  width: 20px;
  top: 3px;
}
</style>
