<template>
  <div>
    <Breadcrumbs main="" :title="$t('Merchant recharge')"/>

    <div class="container-fluid">
      <div class="row">
        <div class="main">
          <div class="content">
            <div class="hint">
              请使用支持波场网络（tron）的钱包或交易软件进行转账
            </div>
            <h2 class="meta">
              <div class="amount">
                {{recharge.rechange_price}} <span class="unit">USDT</span>
              </div>
              <span class="parse-action" data-ref="#parse-amount" @click="copyText(`${recharge.rechange_price}`)">
                      <img src="@/assets/images/parse.png" alt="">
              </span>
            </h2>
            <div class="meta">
              <div class="address">
                {{usdtaddr}}
              </div>
              <span class="parse-action"  @click="copyText(usdtaddr)">
                      <img src="@/assets/images/parse.png" alt="">
              </span>
            </div>
            <div class="qrcode">
              <img :src="usdtcodeimg" alt="qrcode">
            </div>
            <div class="timer">
              <ul class="downcount" v-if="expData.e === 0">
                <li>
                  <span class="hours">{{expData.h}}</span>
                  <p class="hours_ref">时</p>
                </li>
                <li class="seperator">:</li>
                <li>
                  <span class="minutes">{{expData.m}}</span>
                  <p class="minutes_ref">分</p>
                </li>
                <li class="seperator">:</li>
                <li>
                  <span class="seconds">{{expData.s}}</span>
                  <p class="seconds_ref">秒</p>
                </li>
              </ul>
              <div v-else style="margin-top: 30px;">
               <el-tag :type="eTextType[expData.e]" style="font-size: 26px;padding: 10px 15px;height: auto;" size="small" effect="dark">{{eTextArr[expData.e]}}</el-tag>
              </div>
            </div>
            <div class="divider"></div>
            <ol class="remark">
              <li>
                请在30分钟之内完成付款，超时付款不会到账。
              </li>
              <li>
                转账金额需与上方显示的订单金额一致，否则系统不到账。
              </li>
              <li>
                点击金额和地址区域可直接复制。
              </li>
              <li>
                如扫码后无法付款，请直接粘贴地址及金额进行付款操作。
              </li>
              <li>
                如出现转账金额与订单金额不一致系统未到账的情况，请联系管理员。
              </li>
              <li>
                如显示【已过期】，需要重新选择金额，然后在进行扫码或者复制金额/地址。
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  let t;
  export default {
    name: 'usdtrecharge',
    data() {
      return {
        recharge: {
          rechange_created_at: '2023-01-09T14:39:51+08:00',
          rechange_createint: 1673246390,
          rechange_id: 10,
          rechange_money: 100,
          rechange_orderid: 'ORDER202301091439506735930002',
          rechange_price: 100,
          rechange_price_int: 10000,
          rechange_readme: '',
          rechange_rechangeno: '',
          rechange_rechangetype: 1,
          rechange_status: 0,
          rechange_updated_at: '0001-01-01T00:00:00Z',
          rechange_userid: 1,
        },
        usdtaddr:'',
        usdtcodeimg:'',
        expData:{
          h:'',
          m:'',
          s:'',
          e:0,
        },
        eTextArr:['请及时支付','已过期','支付成功','支付失败'],
        eTextType:['info','danger','success','warning'],
        eInt:0
      }
      ;
    },
    mounted() {
      t = this;
      if (!t.$route.params.usdtaddr){
        t.$router.back();
        return;
      }
      t.usdtaddr = t.$route.params.usdtaddr;
      t.usdtcodeimg = t.$route.params.usdtcodeimg;
      t.recharge = t.$route.params.item;
      t.calcExp();
    },
    methods: {
      getStatus(){

        t.request
          .post('/rechange/status', t.recharge)
          .then(res => {
            //window.localStorage.setItem('usdtaddr',res.data.usdtaddr);
            if (t.expData.e === 0 && res.data.item.rechange_status !== 0){
              switch (res.data.item.rechange_status) {
              case 1:
                t.expData.e = 2;
                break;
              case 2:
                t.expData.e = 3;
                break;
              default:
                break;
              }
            }
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      calcSecond(){
        setTimeout(()=>{
          t.calcExp();
          t.eInt++;
          if (t.expData.e === 0 && t.eInt % 8 === 0){
            t.getStatus();
          }
          if (t.eInt>=8){
            t.eInt = 0;
          }

        },1e3);
      },
      calcExp() {
        if (t.expData.e > 0) return;
        let nowTime = Date.now();
        const inputTime = t.recharge.rechange_createint* 1000;
        const remain = (inputTime - nowTime) / 1000;
        if (remain <= 0) {
          t.expData.e = 1;
        }else{
          t.expData.e = 0;
          var h = parseInt(remain / 60 / 60 % 24);
          t.expData.h = h < 10 ? '0' + h : h;
          var m = parseInt(remain / 60 % 60);
          t.expData.m = m < 10 ? '0' + m : m;
          var s = parseInt(remain % 60);
          t.expData.s = s < 10 ? '0' + s : s;
        }
        t.calcSecond();
      },
      add0(m){
        return m<10?'0'+m:m;
      },
      copyText(text = '') {
        t.$copyText(text)
          .then((e) => {
            t.$toasted.show('复制成功', {
              theme: 'bubble',
              position: 'top-center',
              type: 'success',
              duration: 3000,
            });
          })
          .catch((e) => {
            t.$toasted.show('复制失败', {
              theme: 'bubble',
              position: 'top-center',
              type: 'error',
              duration: 3000,
            });
          });
      },
    }
  };

</script>

<style scoped lang="scss">
.container-fluid {
  padding: 30px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
  border-top: 6px solid #25b864;
  text-align: center;
  min-height: 80vh;
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  align-items: center;

  .hint {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.5em;
  }

  .meta {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;

    .amount {
      position: relative;
      display: inline-block;
    }

    .parse-action {
      cursor: copy;
      margin-left: 10px;

      > img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .qrcode {
    width: 240px;
    height: 240px;
    margin: 0 auto;
    border: 1px solid #f5f6f7;
    overflow: hidden;

    > img {
      width: 100%;
      border: none;
      display: block;
    }
  }

  .timer {
    .downcount {
      list-style: none;
      margin: 15px 0;
      padding: 0;

      .seperator {
        font-size: 28px;
        line-height: 28px;
        vertical-align: top;
      }

      > li {
        display: inline-block;

        > span {
          font-size: 28px;
          font-weight: 300;
          line-height: 28px;
        }

        > p {
          margin-top: 10px;
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    border-top: 1px dashed #f2f2f2;
    margin: 30px 0;
  }

  .remark {
    text-align: left;
    margin-left: 30px;

    > li {
      font-size: 16px;
      margin: 10px 0;
      line-height: 1.5em;
    }
  }
}
</style>
