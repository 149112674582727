<template>
  <div>
    <Breadcrumbs main="Project" :title="$t('Platform Agreement')" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row" :style="isPCShow ? '' : 'margin-left:0px'">
        <div class="col-md-12 project-list">
          <div class="card">
            <div class="row m-0">
              <div class="col-sm-12 p-0">
                <div class="card">
                  <div class="card-body">
                    <div
                      class="row"
                      :style="
                        isPCShow
                          ? { width: '100%', position: 'relative' }
                          : {
                              width: '100%',
                              position: 'relative',
                              'margin-left': '0px',
                            }
                      "
                    >
                      <div
                        v-html="
                          $t(
                            'Offshore System Service Platform Agreement Content'
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    data() {
      return {
        isPCShow: true,
        windowWidth: window.innerWidth,
      };
    },
    computed: {
    ...mapState({
      allprojects: (state) => state.common.allprojects,
      doingprojects: (state) => state.common.doingprojects,
      doneprojects: (state) => state.common.doneprojects,
    }),
    },
    mounted() {
      // Set the initial number of items

      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.windowWidth = window.innerWidth;
        // const windowWidth = window.innerWidth;
        this.isPCShow = this.windowWidth >= 768;
      },
      getImgUrl(path) {
        return require('@/assets/images/' + path);
      },
    },
  };
</script>
<style scoped lang="scss"></style>
