<!--
    =================== Component detail ====================
    File Name: PxCard.vue
    Description: Glovbal Card Component
    =================== Component detail ====================
-->

<template>
  <div>
    <b-card no-body :class="{ 'full-card': fullCard }">
      <template v-slot:header v-if="showHeader || this.$slots.headerCustom">
        <h5 v-if="title">{{ title }}</h5>

        <!-- header actions slot -->
        <div v-if="showAction">
          <slot name="headerAction">
            <div class="card-header-right">
              <ul
                class="list-unstyled card-option"
                :class="{ openaction: openActions }"
              >
                <li>
                  <!-- <i @click="openCardActions()" :class="openActions ? 'icofont icofont-double-right' : 'fa fa-spin fa-cog'"></i> -->
                  <i class="fa fa-spin fa-cog"></i>
                </li>
                <li @click="openFullCard()">
                  <i
                    class="icofont full-card"
                    :class="fullCard ? 'icofont-resize' : 'icofont-maximize'"
                  ></i>
                </li>
                <li @click="toggleCard()">
                  <i
                    class="icofont minimize-card"
                    :class="cardToggle ? 'icofont-minus' : 'icofont-plus'"
                  ></i>
                </li>
              </ul>
            </div>
          </slot>
        </div>

        <!-- header actions slot -->
        <div v-if="showButton">
          <slot name="headerAction">
            <div class="card-header-right">
              <ul class="list-unstyled" :class="{ openaction: openActions }">
                <li>
                  <!-- <i @click="openCardActions()" :class="openActions ? 'icofont icofont-double-right' : 'fa fa-spin fa-cog'"></i> -->
                  <el-button
                    type="primary"
                    @click="buttonClick"
                    v-if="buttonTitle"
                    ><span
                      style="color: #fff; font-weight: 900; margin-top: 0px"
                    >
                      {{ buttonTitle
                      }}<i
                        class="el-icon-s-goods el-icon--right"
                        style="color: #fff"
                      ></i>
                    </span>
                  </el-button>
                  <el-button
                    type="primary"
                    style="color: #fff"
                    @click="buttonClick2"
                    v-if="buttonTitle2"
                    ><span
                      style="color: #fff; font-weight: 900; margin-top: 0px"
                    >
                      {{ buttonTitle2
                      }}<i
                        class="el-icon-s-goods el-icon--right"
                        style="color: #fff"
                      ></i> </span
                  ></el-button>
                </li>
              </ul>
            </div>
          </slot>
        </div>

        <!-- header custom content -->
        <slot name="headerCustom"></slot>
      </template>

      <!-- card body with no-padding -->
      <b-card-body
        v-if="this.$slots.default"
        class="p-0"
        :style="cardToggle ? { display: 'block' } : { display: 'none' }"
      >
        <slot></slot>
      </b-card-body>
      <!-- card body with padding -->
      <b-card-body
        v-else
        :style="cardToggle ? { display: 'block' } : { display: 'none' }"
      >
        <slot name="with-padding"></slot>
      </b-card-body>

      <!-- footer -->
      <template v-slot:footer v-if="this.$slots.footer">
        <slot name="footer"></slot>
      </template>

      <!-- card custom content -->
      <slot name="custom-content"></slot>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "px-card",
  props: {
    title: String,
    actions: {
      type: Boolean,
      default: true,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    buttonTitle2: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      openActions: false,
      fullCard: false,
      cardToggle: true,
    };
  },
  computed: {
    showHeader() {
      return this.title;
    },
    showAction() {
      return this.$slots.headerAction || this.actions;
    },
  },
  methods: {
    openCardActions() {
      this.openActions = !this.openActions;
    },
    openFullCard() {
      this.fullCard = !this.fullCard;
    },
    toggleCard() {
      this.cardToggle = !this.cardToggle;
    },
    buttonClick() {
      this.$emit("buttonClick", {});
    },
    buttonClick2() {
      this.$emit("buttonClick2", {});
    },
  },
};
</script>

<style lang="scss">
.el-button {
  > span {
    margin-top: 0 !important;
  }
}
</style>
