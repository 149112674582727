<template>
  <div style="height: 70vh">
    <el-collapse v-model="activeNames">
      <el-collapse-item title="跳转链接次数统计" name="1">
        <div
          style="display: flex; flex-direction: column; justify-content: center"
        >
          <div ref="echartsUrl" style="width: 100%; height: 250px"></div>
          <el-radio-group
            style="text-align: center"
            @change="loadUrl"
            v-model="url.radio"
          >
            <el-radio :label="0">昨天</el-radio>
            <el-radio :label="1">今天</el-radio>
          </el-radio-group>
        </div>
      </el-collapse-item>
      <el-collapse-item title="系统/浏览器" name="2">
        <div
          style="
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            width: 100%;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              min-width: 350px;
              flex: 1;
            "
          >
            <div ref="echartsSystem" style="width: 100%; height: 250px"></div>
            <el-radio-group
              style="text-align: center; margin-top: 10px"
              @change="loadSystem"
              v-model="system.radio"
            >
              <el-radio :label="0">昨天</el-radio>
              <el-radio :label="1">今天</el-radio>
            </el-radio-group>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              min-width: 350px;
              flex: 1;
            "
          >
            <div ref="echartsBrowser" style="width: 100%; height: 250px"></div>
            <el-radio-group
              style="text-align: center; margin-top: 10px"
              @change="loadBrowser"
              v-model="browser.radio"
            >
              <el-radio :label="0">昨天</el-radio>
              <el-radio :label="1">今天</el-radio>
            </el-radio-group>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="国家/每小时流量" name="3">
        <div
          style="
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            min-width: 350px;
            width: 100%;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex: 1;
            "
          >
            <div ref="echartsCountry" style="width: 100%; height: 250px"></div>
            <el-radio-group
              style="text-align: center; margin-top: 10px"
              @change="loadCountry"
              v-model="country.radio"
            >
              <el-radio :label="0">昨天</el-radio>
              <el-radio :label="1">今天</el-radio>
            </el-radio-group>
          </div>
          <!--          <div style="display: flex;flex-direction: column;justify-content: center;flex: 1;">
            <div ref="echartsIp" style="width: 100%;height: 250px;"></div>
            <el-radio-group style="text-align: center;margin-top: 10px;" @change="loadIp" v-model="ip.radio">
              <el-radio :label="0">昨天</el-radio>
              <el-radio :label="1">今天</el-radio>
            </el-radio-group>
          </div>-->
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              min-width: 350px;
              flex: 1;
            "
          >
            <div ref="echartsRate" style="width: 100%; height: 250px"></div>
            <el-radio-group
              style="text-align: center; margin-top: 10px"
              @change="loadRate"
              v-model="rate.radio"
            >
              <el-radio :label="0">昨天</el-radio>
              <el-radio :label="1">今天</el-radio>
            </el-radio-group>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div ref="echartsContainer" style="width: 30%; height: 50px"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';
  let t = null;
  const piePatternSrc = '';
  const bgPatternSrc = '';
  const piePatternImg = new Image();
  const bgPatternImg = new Image();
  export default {
    name: 'ShortlogchartPage',
    props: {
      shortid: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        activeNames: ['1', '2', '3', '4'],
        url: {
          yesterday: [],
          today: [],
          radio: 1,
        },
        browser: {
          yesterday: [],
          today: [],
          radio: 1,
        },
        country: {
          yesterday: [],
          today: [],
          radio: 1,
        },
        system: {
          yesterday: [],
          today: [],
          radio: 1,
        },
        ip: {
          yesterday: [],
          today: [],
          radio: 1,
        },
        rate: {
          yesterday: [],
          today: [],
          radio: 1,
        },
      };
    },
    mounted() {
      t = this;
      this.loadlist();
    },
    methods: {
      loadlist() {
        this.request
          .get('/shortlog/chart', { shortlogShortid: this.shortid })
          .then((res) => {
            this.loadchart(res.data);
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },

      loadchart(item) {
        this.loadUrlyesterday(item.yesterday);
        this.loadUrltoday(item.today);
        this.loadUrl();
        this.loadBrowseryesterday(item.yesterday);
        this.loadBrowsertoday(item.today);
        this.loadBrowser();
        this.loadCountryyesterday(item.yesterday);
        this.loadCountrytoday(item.today);
        this.loadCountry();
        this.loadSystemyesterday(item.yesterday);
        this.loadSystemtoday(item.today);
        this.loadSystem();
        this.loadRateyesterday(item.yesterday);
        this.loadRatetoday(item.today);
        this.loadRate();
      /*this.loadIp();
        this.loadIpyesterday(item.yesterday);
        this.loadIptoday(item.today);
        this.loadIp();*/
      },
      loadBrowser() {
        const chartData =
          this.browser.radio === 0 ? this.browser.yesterday : this.browser.today;
        // console.log("loadBrowser=>chartData", chartData);
        let xAxisData = chartData.legendData;
        const option = {
          title: {
            text: '浏览器次数',
            left: 'center',
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: {
                show: true,
                readOnly: false,
                optionToContent: function (opt) {
                  var axisData = opt.xAxis[0].data; //x轴作为条件，y轴需改成yAxis[0].data;
                  var series = opt.series;
                  var tdHeads = '<td  style="padding:0 30px">名称</td>';
                  series.forEach(function (item) {
                    tdHeads +=
                      '<td style="padding: 0 50px">' + item.name + '</td>';
                  });
                  var table =
                    '<table border="1" style="margin-left:40px;border-collapse:collapse;font-size:14px;text-align:center;background-color:#fff"><tbody><tr>' +
                    tdHeads +
                    '</tr>';
                  var tdBodys = '';
                  for (var i = 0, l = axisData.length; i < l; i++) {
                    for (var j = 0; j < series.length; j++) {
                      if (typeof series[j].data[i] == 'object') {
                        tdBodys += '<td>' + series[j].data[i].value + '</td>';
                      } else {
                        tdBodys += '<td>' + series[j].data[i] + '</td>';
                      }
                    }
                    table +=
                      '<tr><td style="padding: 0 10px">' +
                      axisData[i] +
                      '</td>' +
                      tdBodys +
                      '</tr>';
                    tdBodys = '';
                  }
                  table += '</tbody></table>';
                  return table;
                },
              },
              restore: { show: false },
              saveAsImage: { show: true },
              magicType: { show: true, type: ['line', 'bar'] },
            },
          },
          tooltip: {
            // trigger: "axis",
            // axisPointer: {
            //   type: "cross",
            // },
            // tooltip 用于控制鼠标滑过或点击时的提示框（下一章展开讲）
            trigger: 'axis',
            axisPointer: {
              // 坐标轴指示器配置项。
              type: 'cross', // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
              axis: 'auto', // 指示器的坐标轴。
              snap: true, // 坐标轴指示器是否自动吸附到点上
            },
            showContent: true,
          },
          grid: {
            top: '20%',
            left: '3%',
            right: '10%',
            bottom: '5%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            // prettier-ignore
            data: xAxisData,
          },
          yAxis: {
            name: '次数',
            type: 'value',
          },
          legend: {
            top: 'bottom',
          },
          series: [
            {
              name: '浏览器名称',
              type: 'line',
              radius: '55%',
              center: ['50%', '50%'],
              roseType: 'area',
              itemStyle: {
                borderRadius: 8,
              },
              barMaxWidth: 30,
              data: chartData.seriesData,
            },
          ],
        };
        var myChart = echarts.init(this.$refs.echartsBrowser);
        myChart.setOption(option);
      },
      loadBrowseryesterday(data) {
        const chartData = { legendData: [], seriesData: [] };
        // eslint-disable-next-line no-unused-vars
        const seriesValue = {};
        // eslint-disable-next-line no-unused-vars
        for (const itemElement of data) {
          //console.log(itemElement);
          if (seriesValue[itemElement.shortlog_browser]) {
            seriesValue[itemElement.shortlog_browser]++;
          } else {
            chartData.legendData.push(itemElement.shortlog_browser);
            seriesValue[`${itemElement.shortlog_browser}`] = 1;
          }
        }
        // eslint-disable-next-line no-unused-vars
        for (const legendDatum of chartData.legendData) {
          chartData.seriesData.push({
            name: legendDatum,
            value: seriesValue[legendDatum],
          });
        }
        this.browser.yesterday = chartData;
      },
      loadBrowsertoday(data) {
        const chartData = { legendData: [], seriesData: [] };
        // eslint-disable-next-line no-unused-vars
        const seriesValue = {};
        // eslint-disable-next-line no-unused-vars
        for (const itemElement of data) {
          //console.log(itemElement);
          if (seriesValue[itemElement.shortlog_browser]) {
            seriesValue[itemElement.shortlog_browser]++;
          } else {
            chartData.legendData.push(itemElement.shortlog_browser);
            seriesValue[`${itemElement.shortlog_browser}`] = 1;
          }
        }
        // eslint-disable-next-line no-unused-vars
        for (const legendDatum of chartData.legendData) {
          chartData.seriesData.push({
            name: legendDatum,
            value: seriesValue[legendDatum],
          });
        }
        this.browser.today = chartData;
      },
      loadSystem() {
        const chartData =
          this.system.radio === 0 ? this.system.yesterday : this.system.today;
        const option = {
          title: {
            text: '系统次数',
            left: 'center',
          },
          legend: {
            top: 'bottom',
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              restore: { show: false },
              saveAsImage: { show: true },
            },
          },
          tooltip: {
            trigger: 'item',
          // formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          series: [
            {
              name: '系统名称',
              type: 'pie',
              radius: ['40%', '70%'],
              center: ['50%', '70%'],
              startAngle: 180,
              endAngle: 360,

              data: chartData.seriesData,
            },
          ],
        };
        var myChart = echarts.init(this.$refs.echartsSystem);
        myChart.setOption(option);
      },
      loadSystemyesterday(data) {
        const chartData = { legendData: [], seriesData: [] };
        // eslint-disable-next-line no-unused-vars
        const seriesValue = {};
        // eslint-disable-next-line no-unused-vars
        for (const itemElement of data) {
      
          if (seriesValue[itemElement.shortlog_os]) {
            seriesValue[itemElement.shortlog_os]++;
          } else {
            chartData.legendData.push(itemElement.shortlog_os);
            seriesValue[`${itemElement.shortlog_os}`] = 1;
          }
        }
        // eslint-disable-next-line no-unused-vars
        for (const legendDatum of chartData.legendData) {
          chartData.seriesData.push({
            name: legendDatum,
            value: seriesValue[legendDatum],
          });
        }
        this.system.yesterday = chartData;
      },
      loadSystemtoday(data) {
        const chartData = { legendData: [], seriesData: [] };
        // eslint-disable-next-line no-unused-vars
        const seriesValue = {};
        // eslint-disable-next-line no-unused-vars
        for (const itemElement of data) {
          //console.log(itemElement);
          if (seriesValue[itemElement.shortlog_os]) {
            seriesValue[itemElement.shortlog_os]++;
          } else {
            chartData.legendData.push(itemElement.shortlog_os);
            seriesValue[`${itemElement.shortlog_os}`] = 1;
          }
        }
        // eslint-disable-next-line no-unused-vars
        for (const legendDatum of chartData.legendData) {
          chartData.seriesData.push({
            name: legendDatum,
            value: seriesValue[legendDatum],
          });
        }
        this.system.today = chartData;
      },
      loadIp() {
        const chartData = this.ip.radio === 0 ? this.ip.yesterday : this.ip.today;
        const option = {
          title: {
            text: 'IP次数',
            left: 'center',
            textStyle: {
              color: '#235894',
            },
          },
          legend: {
            top: 'bottom',
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              restore: { show: false },
              saveAsImage: { show: true },
            },
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          series: [
            {
              name: 'IP地址',
              type: 'pie',
              selectedMode: 'single',
              selectedOffset: 30,
              clockwise: true,
              label: {
                fontSize: 18,
                color: '#235894',
              },
              labelLine: {
                lineStyle: {
                  color: '#235894',
                },
              },
              itemStyle: {
                opacity: 0.7,
                color: {
                  image: piePatternImg,
                  repeat: 'repeat',
                },
                borderWidth: 3,
                borderColor: '#235894',
              },
              data: chartData.seriesData,
            },
          ],
        };
        var myChart = echarts.init(this.$refs.echartsIp);
        myChart.setOption(option);
      },
      loadIpyesterday(data) {
        const chartData = { legendData: [], seriesData: [] };
        // eslint-disable-next-line no-unused-vars
        const seriesValue = {};
        // eslint-disable-next-line no-unused-vars
        for (const itemElement of data) {
          //console.log(itemElement);
          if (seriesValue[itemElement.shortlog_ip]) {
            seriesValue[itemElement.shortlog_ip]++;
          } else {
            chartData.legendData.push(itemElement.shortlog_ip);
            seriesValue[`${itemElement.shortlog_ip}`] = 1;
          }
        }
        // eslint-disable-next-line no-unused-vars
        for (const legendDatum of chartData.legendData) {
          chartData.seriesData.push({
            name: legendDatum,
            value: seriesValue[legendDatum],
          });
        }
        this.ip.yesterday = chartData;
      },
      loadIptoday(data) {
        const chartData = { legendData: [], seriesData: [] };
        // eslint-disable-next-line no-unused-vars
        const seriesValue = {};
        // eslint-disable-next-line no-unused-vars
        for (const itemElement of data) {
          //console.log(itemElement);
          if (seriesValue[itemElement.shortlog_ip]) {
            seriesValue[itemElement.shortlog_ip]++;
          } else {
            chartData.legendData.push(itemElement.shortlog_ip);
            seriesValue[`${itemElement.shortlog_ip}`] = 1;
          }
        }
        // eslint-disable-next-line no-unused-vars
        for (const legendDatum of chartData.legendData) {
          chartData.seriesData.push({
            name: legendDatum,
            value: seriesValue[legendDatum],
          });
        }
        this.ip.today = chartData;
      },
      loadCountry() {
        const chartData =
          this.country.radio === 0 ? this.country.yesterday : this.country.today;
        const option = {
          title: {
            text: '国家次数',
            left: 'center',
          },
          legend: {
            top: 'bottom',
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              restore: { show: false },
              saveAsImage: { show: true },
            },
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          series: [
            {
              name: '国家简称',
              type: 'pie',
              radius: ['40%', '70%'],
              center: ['50%', '50%'],
              avoidLabelOverlap: false,
              padAngle: 5,
              itemStyle: {
                borderRadius: 8,
              },
              data: chartData.seriesData,
            },
          ],
        };
        var myChart = echarts.init(this.$refs.echartsCountry);
        myChart.setOption(option);
      },
      loadCountryyesterday(data) {
        const chartData = { legendData: [], seriesData: [] };
        // eslint-disable-next-line no-unused-vars
        const seriesValue = {};
        // eslint-disable-next-line no-unused-vars
        for (const itemElement of data) {
          //console.log(itemElement);
          if (seriesValue[this.$t(itemElement.shortlog_country)]) {
            seriesValue[this.$t(itemElement.shortlog_country)]++;
          } else {
            chartData.legendData.push(this.$t(itemElement.shortlog_country));
            seriesValue[`${this.$t(itemElement.shortlog_country)}`] = 1;
          }
        }
        // eslint-disable-next-line no-unused-vars
        for (const legendDatum of chartData.legendData) {
          chartData.seriesData.push({
            name: legendDatum,
            value: seriesValue[legendDatum],
          });
        }
        this.country.yesterday = chartData;
      },
      loadCountrytoday(data) {
        const chartData = { legendData: [], seriesData: [] };
        // eslint-disable-next-line no-unused-vars
        const seriesValue = {};
        // eslint-disable-next-line no-unused-vars
        for (const itemElement of data) {
          //console.log(itemElement);
          if (seriesValue[this.$t(itemElement.shortlog_country)]) {
            seriesValue[this.$t(itemElement.shortlog_country)]++;
          } else {
            chartData.legendData.push(this.$t(itemElement.shortlog_country));
            seriesValue[`${this.$t(itemElement.shortlog_country)}`] = 1;
          }
        }
        // eslint-disable-next-line no-unused-vars
        for (const legendDatum of chartData.legendData) {
          chartData.seriesData.push({
            name: legendDatum,
            value: seriesValue[legendDatum],
          });
        }
        this.country.today = chartData;
      },
      loadUrl() {
        const chartData =
          this.url.radio === 0 ? this.url.yesterday : this.url.today;
        console.log('loadUrl=>chartData', chartData);
        let xAxisData = chartData.legendData;
        const option = {
          title: {
            left: 'center',
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: {
                show: true,
                readOnly: false,
                optionToContent: function (opt) {
                  var axisData = opt.xAxis[0].data; //x轴作为条件，y轴需改成yAxis[0].data;
                  var series = opt.series;
                  var tdHeads = '<td  style="padding:0 30px">名称</td>';
                  series.forEach(function (item) {
                    tdHeads +=
                      '<td style="padding: 0 50px">' + item.name + '</td>';
                  });
                  var table =
                    '<table border="1" style="margin-left:40px;border-collapse:collapse;font-size:14px;text-align:center;background-color:#fff"><tbody><tr>' +
                    tdHeads +
                    '</tr>';
                  var tdBodys = '';
                  for (var i = 0, l = axisData.length; i < l; i++) {
                    for (var j = 0; j < series.length; j++) {
                      if (typeof series[j].data[i] == 'object') {
                        tdBodys += '<td>' + series[j].data[i].value + '</td>';
                      } else {
                        tdBodys += '<td>' + series[j].data[i] + '</td>';
                      }
                    }
                    table +=
                      '<tr><td style="padding: 0 10px">' +
                      axisData[i] +
                      '</td>' +
                      tdBodys +
                      '</tr>';
                    tdBodys = '';
                  }
                  table += '</tbody></table>';
                  return table;
                },
              },
              restore: { show: false },
              saveAsImage: { show: true },
              magicType: { show: true, type: ['line', 'bar'] },
            },
          },
          tooltip: {
            // trigger: "axis",
            // axisPointer: {
            //   type: "cross",
            // },
            // tooltip 用于控制鼠标滑过或点击时的提示框（下一章展开讲）
            trigger: 'axis',
            axisPointer: {
              // 坐标轴指示器配置项。
              type: 'cross', // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
              axis: 'auto', // 指示器的坐标轴。
              snap: true, // 坐标轴指示器是否自动吸附到点上
            },
            showContent: true,
          },
          grid: {
            top: '20%',
            left: '3%',
            right: '10%',
            bottom: '5%',
            containLabel: true,
          },
          legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 10,
            top: 20,
            bottom: 20,
            data: chartData.legendData,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            // prettier-ignore
            data: xAxisData,
          },
          yAxis: {
            name: '次数',
            type: 'value',
          },
          series: [
            {
              name: '链接',
              type: 'bar',
              radius: '55%',
              center: ['50%', '50%'],
              roseType: 'area',
              itemStyle: {
                borderRadius: 8,
              },
              barMaxWidth: 30,
              data: chartData.seriesData,
            },
          // {
          //   name: "链接",
          //   type: "line",
          //   radius: "55%",
          //   center: ["40%", "50%"],
          //   data: chartData.seriesData,
          //   emphasis: {
          //     itemStyle: {
          //       shadowBlur: 10,
          //       shadowOffsetX: 0,
          //       shadowColor: "rgba(0, 0, 0, 0.5)",
          //     },
          //   },
          // },
          ],
        };

        var myChart = echarts.init(this.$refs.echartsUrl);
        myChart.setOption(option);
      },
      loadUrlyesterday(data) {
        const chartData = { legendData: [], seriesData: [] };
        // eslint-disable-next-line no-unused-vars
        const seriesValue = {};
        // eslint-disable-next-line no-unused-vars
        for (const itemElement of data) {
          //console.log(itemElement);
          if (seriesValue[itemElement.shortlog_jumpurl]) {
            seriesValue[itemElement.shortlog_jumpurl]++;
          } else {
            chartData.legendData.push(itemElement.shortlog_jumpurl);
            seriesValue[`${itemElement.shortlog_jumpurl}`] = 1;
          }
        }
        // eslint-disable-next-line no-unused-vars
        for (const legendDatum of chartData.legendData) {
          chartData.seriesData.push({
            name: legendDatum,
            value: seriesValue[legendDatum],
          });
        }
        this.url.yesterday = chartData;
      },
      loadUrltoday(data) {
        const chartData = { legendData: [], seriesData: [] };
        // eslint-disable-next-line no-unused-vars
        const seriesValue = {};
        // eslint-disable-next-line no-unused-vars
        for (const itemElement of data) {
          //console.log(itemElement);
          if (seriesValue[itemElement.shortlog_jumpurl]) {
            seriesValue[itemElement.shortlog_jumpurl]++;
          } else {
            chartData.legendData.push(itemElement.shortlog_jumpurl);
            seriesValue[`${itemElement.shortlog_jumpurl}`] = 1;
          }
        }
        // eslint-disable-next-line no-unused-vars
        for (const legendDatum of chartData.legendData) {
          chartData.seriesData.push({
            name: legendDatum,
            value: seriesValue[legendDatum],
          });
        }
        this.url.today = chartData;
      },
      loadRate() {
        const chartData =
          this.rate.radio === 0 ? this.rate.yesterday : this.rate.today;
        const option = {
          title: {
            text: '每小时流量',
            left: 'center',
          },
          // tooltip: {
          //   trigger: "axis",
          //   axisPointer: {
          //     type: "cross",
          //   },
          // },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              // dataView: { show: true, readOnly: false },
              dataView: {
                show: true,
                readOnly: false,
                optionToContent: function (opt) {
                  var axisData = opt.xAxis[0].data; //x轴作为条件，y轴需改成yAxis[0].data;
                  var series = opt.series;
                  var tdHeads = '<td  style="padding:0 30px">名称</td>';
                  series.forEach(function (item) {
                    tdHeads +=
                      '<td style="padding: 0 50px">' + item.name + '</td>';
                  });
                  var table =
                    '<table border="1" style="margin-left:40px;border-collapse:collapse;font-size:14px;text-align:center;background-color:#fff"><tbody><tr>' +
                    tdHeads +
                    '</tr>';
                  var tdBodys = '';
                  for (var i = 0, l = axisData.length; i < l; i++) {
                    for (var j = 0; j < series.length; j++) {
                      if (typeof series[j].data[i] == 'object') {
                        tdBodys += '<td>' + series[j].data[i].value + '</td>';
                      } else {
                        tdBodys += '<td>' + series[j].data[i] + '</td>';
                      }
                    }
                    table +=
                      '<tr><td style="padding: 0 10px">' +
                      axisData[i] +
                      '</td>' +
                      tdBodys +
                      '</tr>';
                    tdBodys = '';
                  }
                  table += '</tbody></table>';
                  return table;
                },
              },
              magicType: { show: true, type: ['line', 'bar'] },
              restore: { show: true },
              saveAsImage: { show: true },
            },
          },
          tooltip: {
            // tooltip 用于控制鼠标滑过或点击时的提示框（下一章展开讲）
            trigger: 'axis',
            axisPointer: {
              // 坐标轴指示器配置项。
              type: 'cross', // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
              axis: 'auto', // 指示器的坐标轴。
              snap: true, // 坐标轴指示器是否自动吸附到点上
            },
            showContent: true,
          },
          grid: {
            top: '20%',
            left: '3%',
            right: '10%',
            bottom: '5%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            // prettier-ignore
            data: ['00', '01', '02', '03','04', '05', '06', '07', '08','09', '10', '11', '12', '13','14', '15', '16', '17', '18','19', '20', '21', '22', '23'],
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value}',
            },
            axisPointer: {
              snap: true,
            },
          },
          visualMap: {
            show: false,
            dimension: 0,
            pieces: [
              {
                lte: 6,
                color: 'green',
              },
              {
                gt: 6,
                lte: 8,
                color: 'red',
              },
              {
                gt: 8,
                lte: 14,
                color: 'green',
              },
              {
                gt: 14,
                lte: 17,
                color: 'red',
              },
              {
                gt: 17,
                color: 'green',
              },
            ],
          },
          series: [
            {
              name: 'Electricity',
              type: 'line',
              smooth: true,
              // prettier-ignore
              data: chartData.seriesData,
            },
          ],
        };

        var myChart = echarts.init(this.$refs.echartsRate);
        myChart.setOption(option);
      },
      loadRateyesterday(data) {
        const chartData = { legendData: [], seriesData: [] };
        for (let i = 0; i < 24; i++) {
          let seriesValue = 0;
          // eslint-disable-next-line no-unused-vars
          for (const itemElement of data) {
            const date = new Date(itemElement.shortlog_created);
            if (date.getHours() === i) {
              seriesValue++;
            }
          }
          chartData.seriesData.push(seriesValue);
        }

        this.rate.yesterday = chartData;
      },
      loadRatetoday(data) {
        const chartData = { legendData: [], seriesData: [] };
        // eslint-disable-next-line no-unused-vars

        for (let i = 0; i < 24; i++) {
          let seriesValue = 0;
          // eslint-disable-next-line no-unused-vars
          for (const itemElement of data) {
            const date = new Date(itemElement.shortlog_created);

            if (date.getHours() === i) {

              seriesValue++;
            }
          }
          chartData.seriesData.push(seriesValue);
        }


        this.rate.today = chartData;
      },
    },
  };
</script>

<style scoped></style>
