
const serverurl = '' || window.location.origin; //''; // ''也可以填现行的这个  window.location.origin //后台的域名填到这里 npm run build:prod 进行打包 等待xx
// https://www.haiwang.app https://test2.haiwang.app http://192.168.5.1:8070
export default {
  domain: serverurl || window.location.origin,
  websocket:
    serverurl.indexOf('https') !== -1
      ? `wss${serverurl.replace('https', '')}/ws`
      : `ws${serverurl.replace('http', '')}/ws`,
  debug: true,
};
