<template>
  <li class="onhover-dropdown">
    <div class="notification-box">
      <feather type="bell" @click="notification_open()"></feather
      >
      <span class="badge badge-pill badge-secondary" v-if="radioList.length - this.readList.readNum > 0">{{radioList.length - this.readList.readNum}} </span>
    </div>
    <div
        class="onhover-show-div notification-dropdown"
        :class="{ active: notification }"
    >
      <h6 class="f-18 mb-0 dropdown-title">{{ $t("Notitications") }}</h6>
      <ul>
        <li class="b-l-primary border-4" v-for="(item,index) in radioList" :key="index" @click="openRadio(index)">
          <p style="margin-top: -14px;">{{ item.title }}
            <span style="margin-top: 15px;line-height: 0;" :class="readList[item.id] ? '' : 'font-primary'">{{readList[item.id] ? $t('read') : $t('unread')}}</span>
          </p>
          <p style="margin-top: -5px;position: absolute;font-size: 12px;">{{ (item.updated_at.replace("T"," ")).replace("+08:00","") }}</p>
        </li>
      </ul>
    </div>
    <el-dialog :visible.sync="dialogSwitch.tagModalRadio" hide-footer :title="radioItem.title" :append-to-body="true">
      <div class="modal-body">
        <form class="form-bookmark needs-validation" novalidate="">
          <div v-html="radioItem.details"></div>
        </form>
      </div>
      <div slot="footer" class="dialog-footer">
        <button class="btn btn-primary ml-2" type="button" data-dismiss="modal"
                @click="dialogSwitch.tagModalRadio = false">
          {{ $t('confirm') }}
        </button>
      </div>
    </el-dialog>
  </li>
</template>

<script>

  export default {
    name: 'Notifications',
    props: {
      radioList: {
        type: Array
      }
    },
    data() {
      return {
        dialogSwitch:{
          tagModalRadio:false,
        },
        notification: false,
        radioItem:{},
        readList:{
          readNum:0
        },
      };
    },
    created() {
      this.loadRead();
    },
    methods: {
      loadRead(){
        this.readList = JSON.parse(localStorage.getItem('radioReadList') || `{"readNum":0}`);
      },
      saveRead(){
        window.localStorage.setItem('radioReadList',JSON.stringify(this.readList));
      },
      openRadio(i = 0){
        this.radioItem = this.radioList[i];
        if (!this.readList[this.radioItem.id]){
          this.readList['readNum']++;
        }
        this.readList[this.radioItem.id] = true;
        this.saveRead();
        this.dialogSwitch.tagModalRadio = true;
      },
      notification_open() {
        this.notification = !this.notification;
      },
    },
  };
</script>
