<template>
  <div>


  </div>
</template>

<script>
  let t;
  export default {
    name: 'jumprecharge',
    data() {
      return {

      };
    },
    created() {
      t = this;
      t.$router.replace({
        name:'usdtrecharge',
        params:t.$route.params,
      });
    },
    methods: {

    }
  };

</script>

<style scoped lang="scss">

</style>
