var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('Breadcrumbs',{attrs:{"main":"Project","title":_vm.$t('Download software')}}),_c('div',{class:_vm.isPCShow ? 'container-fluid' : 'container-fluid-mobile'},[_c('div',{class:_vm.isPCShow ? 'row' : 'row-mobile'},[_c('div',{class:_vm.isPCShow
            ? 'col-md-12 project-list'
            : 'project-list-mobile  project-list'},[_c('div',{staticClass:"card"},[_c('div',[_c('div',{staticClass:"col-sm-12 p-0",attrs:{"id":"downloadTabName"}},[_c('b-tabs',{attrs:{"content-class":"mt-3","active-nav-item-class":"font-weight-bold text-uppercase text-danger","active-tab-class":"font-weight-bold text-success"}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather',{attrs:{"type":"target"}}),_vm._v(" Windows ")]},proxy:true}])},[_c('b-card-text',[_c('div',{staticClass:"col-sm-12 p-0"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row",staticStyle:{"width":"100%","position":"relative","margin-left":"0"}},[_c('div',{class:_vm.isPCShow
                                  ? 'downloadImgBack'
                                  : 'downloadImgBackMobile'},[_c('div',{staticClass:"downloadImg"},[_c('img',{attrs:{"src":require("../../assets/images/down.png")}})]),_c('div',{staticClass:"downloadTips"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.downloadData.name)+" For Windows ")]),_c('div',{staticClass:"tip",domProps:{"innerHTML":_vm._s(_vm.downloadData.content)}}),_c('div',{staticClass:"down"},[_c('el-button',{attrs:{"type":""},on:{"click":function($event){return _vm.openUrl(
                                        _vm.downloadData.windows_version_exe
                                      )}}},[_vm._v(" "+_vm._s(_vm.downloadData.windows_download_name)+" ")])],1),_c('div',{staticClass:"version"},[_vm._v(" "+_vm._s(_vm.downloadData.windows_version)+" ("+_vm._s(_vm.downloadData.windows_updated)+") ")])])])])])])])])],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather',{attrs:{"type":"info"}}),_vm._v(" MacOs ")]},proxy:true}])},[_c('b-card-text',[_c('div',{staticClass:"col-sm-12 p-0"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row",staticStyle:{"width":"100%","position":"relative"}},[_c('div',{class:_vm.isPCShow
                                  ? 'downloadImgBack'
                                  : 'downloadImgBackMobile'},[_c('div',{staticClass:"downloadImg"},[_c('img',{attrs:{"src":require("../../assets/images/down.png")}})]),_c('div',{staticClass:"downloadTips"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.downloadData.name)+" For Mac ")]),_c('div',{staticClass:"tip",domProps:{"innerHTML":_vm._s(_vm.downloadData.content)}}),_c('div',{staticClass:"down",staticStyle:{"display":"flex"}},[_c('el-button',{staticStyle:{"flex":"1"},on:{"click":function($event){return _vm.openUrl(_vm.downloadData.mac_version_exe)}}},[_vm._v(" "+_vm._s(_vm.downloadData.mac_download_nmae)+" ")]),_c('el-button',{staticStyle:{"flex":"1"},on:{"click":function($event){return _vm.openUrl(
                                        _vm.downloadData.mac_amr_version_exe || ''
                                      )}}},[_vm._v(" "+_vm._s(_vm.downloadData.mac_amr_download_nmae || "amr")+" ")])],1),_c('div',{staticClass:"version"},[_vm._v(" "+_vm._s(_vm.downloadData.mac_version)+" ("+_vm._s(_vm.downloadData.mac_updated)+") ")])])])])])])])])],1)],1)],1)])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }