<template>
  <div v-loading="listLoading">
    <Breadcrumbs main="" :title="$t('Dashboard')" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <!-- class="filter-container" -->
            <div class="card-body">
              <div v-if="option.showSearchBt" style="margin: 10px 0 30px 0">
                <!--  style="top: -4px; width: 12rem;" -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    flex-wrap: wrap;
                  "
                >
                  <div style="flex: 1.6; min-width: 350px">
                    <el-date-picker
                      v-model="listQuery.createdatstart"
                      :style="
                        isPCShow
                          ? { width: '12rem', 'margin-left': '5px' }
                          : { width: '9rem', 'margin-left': '5px' }
                      "
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :placeholder="$t('Please select a start time')"
                    />
                    <el-date-picker
                      v-model="listQuery.createdatend"
                      :style="
                        isPCShow
                          ? { width: '12rem', 'margin-left': '5px' }
                          : { width: '9rem', 'margin-left': '5px' }
                      "
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :placeholder="$t('Please select an end time')"
                    />
                    <span
                      v-for="(searchItem, searchIndex) in listSearchServer"
                      :key="searchIndex"
                      style="margin-left: 5px; line-height: 45px"
                    >
                      <el-input
                        v-if="searchItem.htype === 'text'"
                        v-model="listQuery[searchItem.key]"
                        type="text"
                        :style="
                          isPCShow ? { width: '12rem' } : { width: '9rem' }
                        "
                        :placeholder="$t('Please enter the') + searchItem.label"
                        class="filter-item"
                        @keyup.enter.native="handleFilter"
                      />

                      <el-input
                        v-if="searchItem.htype === 'number'"
                        v-model="listQuery[searchItem.key]"
                        type="number"
                        :style="
                          isPCShow ? { width: '12rem' } : { width: '9rem' }
                        "
                        :placeholder="$t('Please enter the') + searchItem.label"
                        class="filter-item"
                        @keyup.enter.native="handleFilter"
                      />

                      <el-select
                        v-if="searchItem.htype === 'select'"
                        v-model="listQuery[searchItem.key]"
                        :placeholder="$t('Please select a') + searchItem.label"
                        clearable
                        :style="isPCShow ? { width: '12rem' } : { width: '9rem' }"
                        class="filter-item"
                      >
                        <el-option
                          v-for="(item, key) in searchItem.select"
                          :key="key"
                          :label="item.title"
                          :value="item.id"
                        />
                      </el-select>
                    </span>
                  </div>
                  <div
                    style="
                      flex: 0.3;
                      display: flex;
                      height: 36px;
                      margin-top: 3px;
                      margin-left: 5px;
                    "
                  >
                    <el-button
                      style="flex: 1"
                      class="filter-item"
                      icon="el-icon-delete-solid"
                      type="success"
                      plain
                      @click="resetFun"
                      >{{ $t("Reset") }}
                    </el-button>
                    <el-button
                      style="flex: 1"
                      class="filter-item"
                      type="primary"
                      icon="el-icon-search"
                      @click="handleFilter"
                      >{{ $t("Search") }}
                    </el-button>
                  </div>
                </div>
              </div>
              <div
                class="filter-container"
                style="margin-top: 8px; margin-left: -5px"
              >
                <el-button
                  v-if="option.showAddBt"
                  class="filter-item"
                  style="margin-left: 10px"
                  type="primary"
                  icon="el-icon-edit"
                  @click="handleCreate"
                  plain
                  >{{ $t("Creating an Invitation Code") }}
                </el-button>
                <el-button
                  class="filter-item"
                  style="margin-left: 10px"
                  type="danger"
                  @click="toggleSelection()"
                  icon="el-icon-delete"
                  plain
                  >{{ $t("Remove selected") }}
                </el-button>
                <!--      <el-button class="filter-item" style="margin-left: 10px;" type="warning" icon="el-icon-delete" @click="handleDelete">删除</el-button>-->
                <el-button
                  v-if="option.showExportBt"
                  v-waves
                  :loading="downloadLoading"
                  class="filter-item"
                  type="info"
                  icon="el-icon-download"
                  plain
                  @click="handleDownload"
                  >{{ $t("Export") }}
                </el-button>
                <el-button
                  class="filter-item"
                  style="margin-left: 10px"
                  type="success"
                  icon="el-icon-files"
                  @click="handleSharegroup"
                  plain
                  >{{ $t("Share Group") }}
                </el-button>

                <el-dropdown
                  v-if="!isPCShow"
                  style="margin-left: 10px"
                  type="primary"
                >
                  <el-button
                    icon="el-icon-thumb"
                    class="filter-item"
                    type="primary"
                    plain
                    >{{ $t("Batch operations")
                    }}<i class="el-icon-arrow-down el-icon--right"></i
                  ></el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native="handleOption(index)"
                      :loading="downloadLoading"
                      v-for="(item, index) in optionData"
                      :key="index"
                    >
                      {{ item }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div>
                <el-table
                  :key="tableKey"
                  :data="list"
                  border
                  fit
                  highlight-current-row
                  :show-summary="summar.switch"
                  :summary-method="getSummar"
                  style="width: 100%; border-radius: 10px"
                  @sort-change="sortChange"
                  @row-click="rowClick"
                  @selection-change="handleSelectionChange"
                  ref="multipleTable"
                >
                  <el-table-column type="selection" width="55">
                  </el-table-column>
                  <el-table-column
                    v-for="(item, index) in listModelServer"
                    :min-width="
                      item.showtype === 'date'
                        ? '180'
                        : item.key === 'share_total' ||
                          item.key == 'share_zerotime' ||
                          item.key == 'Meta Business'
                        ? '150'
                        : item.key == 'share_accdata' ||
                          item.key == 'share_code' ||
                          item.key == 'Google Voice'
                        ? '120'
                        : '100'
                    "
                    v-if="item.show === '1' && showColums[item.key]"
                    :key="index"
                    :label="item.label"
                    :width="item.twidth"
                    :prop="item.key"
                    :sortable="item.sort === '1'"
                    align="center"
                    show-overflow-tooltip
                  >
                    <template slot-scope="{ row }">
                      <span
                        v-if="
                          item.showtype === 'text' &&
                          item.key !== 'share_code' &&
                          item.key != 'share_online' &&
                          item.key !== 'share_total'
                        "
                      >
                        {{ row[item.key] }}
                      </span>
                      <span v-if="item.key == 'share_total'">
                        {{ row["share_total1"] }}
                      </span>
                      <span v-if="item.showtype === 'timetext'">
                        <el-time-picker
                          v-model="row[item.key]"
                          format="HH:mm:ss"
                          style="width: 120px"
                          value-format="HH:mm:ss"
                          @change="updateData(row, 'time', '1')"
                        />
                      </span>
                      <el-button
                        type="text"
                        v-if="
                          item.showtype === 'text' && item.key === 'share_code'
                        "
                        >{{ row[item.key] }}
                      </el-button>
                      <span
                        v-if="
                          item.showtype === 'date' &&
                          item.key !== 'share_created'
                        "
                      >
                        {{
                          row[item.key]
                            ? row[item.key]
                                .replace("+08:00", "")
                                .replace("T", " ")
                            : row[item.key]
                        }}
                      </span>
                      <span
                        v-if="item.key == 'share_created'"
                        style="font-size: 13px; line-height: 16px"
                      >
                        {{
                          row[item.key]
                            ? row[item.key]
                                .replace("+08:00", "")
                                .replace("T", " ")
                            : row[item.key]
                        }}
                        <br />
                        {{
                          row["share_logined"]
                            ? row["share_logined"]
                                ?.replace("+08:00", "")
                                .replace("T", " ")
                            : row["share_logined"]
                        }}
                      </span>
                      <!-- [ { "entitle": "port", "id": 0, "title": "port" }, { "entitle": "words", "id": 1, "title": "words" } ] words
 -->
                      <!-- 消费类型 -->
                      <!--                      <span
                          v-if="
                          item.showtype === 'arr' && item.edittype === 'radio'
                        "
                      >
                        <el-button
                            type="text"
                            v-for="(arritem, arrindex) in item.showarr"
                            v-if="item.showarr[arrindex].id === 0"
                            :key="arrindex"
                            v-show="arritem.id === row[item.key]"
                        >{{ item.showarr[arrindex].title }}
                        </el-button>
                        <span
                            style="color: rgb(28, 179, 155)"
                            v-for="(arritem, arrindex) in item.showarr"
                            v-if="item.showarr[arrindex].id === 1"
                            :key="arrindex"
                            v-show="arritem.id === row[item.key]"
                        >{{ item.showarr[arrindex].title }}
                        </span>
                      </span>-->
                      <span v-if="item.showtype === 'arr'">
                        <el-tag
                          v-for="(arritem, arrindex) in item.showarr"
                          :key="arrindex"
                          v-show="arritem.id === row[item.key]"
                          :type="arritem.tagtype"
                          >{{ item.showarr[arrindex].title }}
                        </el-tag>
                      </span>
                      <!--                      <span
                          v-if="item.showtype === 'arr' && item.key === 'share_status'">

                        <el-tag
                            v-for="(arritem, arrindex) in item.showarr"
                            :key="arrindex"
                            v-show="arritem.id === row[item.key]"
                            :type="arritem.tagtype"
                        >
                          <i v-if="row.share_password !== ''" class="el-icon-lock"></i>
                          <i v-else class="el-icon-unlock"></i>
                          {{ item.showarr[arrindex].title }}
                        </el-tag>
                      </span>-->

                      <span v-if="item.showtype === 'img'">
                        <el-image
                          fit="fill"
                          :src="row[item.key]"
                          :preview-src-list="[row[item.key]]"
                        />
                      </span>
                    </template>
                  </el-table-column>

                  <!-- 操作---- -->

                  <el-table-column
                    :label="$t('Operation')"
                    align="center"
                    class-name="small-padding fixed-width"
                    width="250"
                    :fixed="tableFixed"
                  >
                    <template slot="header" slot-scope="scope">
                      <span>{{ $t("Operation") }}</span>
                      <!-- $t("Display of columns") -->
                      <el-dropdown style="margin-left: 6px">
                        <i class="el-icon-setting"></i>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            style="border-bottom: 1px solid #dcdfe6"
                            command="sendModifyData"
                          >
                            <el-checkbox
                              v-model="allStatus"
                              @change="selectAllFun"
                              >{{ allStatus ? $t("CancelFull") : $t("Full") }}
                            </el-checkbox>
                          </el-dropdown-item>

                          <el-dropdown-item
                            command="sendModifyData"
                            v-for="(item, index) in listModelServer"
                            :key="index"
                            v-show="item.show === '1'"
                          >
                            <el-checkbox
                              v-model="showColums[item.key]"
                              @change="saveColmun"
                            >
                              {{ item.label }}
                            </el-checkbox>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </template>
                    <template slot-scope="{ row, $index }">
                      <el-button
                        icon="el-icon-view"
                        type="text"
                        size="mini"
                        @click="handleOpen(row, $index)"
                      >
                        {{ $t("To view") }}
                      </el-button>
                      <el-button
                        size="mini"
                        icon="el-icon-unlock"
                        v-if="row.share_status === 0"
                        type="text"
                        @click="updateData(row, 'share_status', 1)"
                      >
                        {{ $t("Enable") }}
                      </el-button>
                      <el-button
                        icon="el-icon-share"
                        size="mini"
                        v-if="row.share_status === 1"
                        type="text"
                        @click="handleShare(row, $index)"
                      >
                        {{ $t("Share") }}
                      </el-button>
                      <el-button
                        icon="el-icon-edit"
                        size="mini"
                        type="text"
                        @click="handleUpdate(row)"
                      >
                        {{ $t("Edit") }}
                      </el-button>
                      <el-button
                        icon="el-icon-warning-outline"
                        size="mini"
                        v-if="row.share_status === 1"
                        type="text"
                        @click="stopCode(row, 'share_status')"
                      >
                        {{ $t("Disable") }}
                      </el-button>
                      <el-button
                        icon="el-icon-delete"
                        v-if="row.share_status === 0"
                        type="text"
                        @click="handleDelete(row, $index)"
                      >
                        {{ $t("Delete") }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>

                <div
                  class="pagination-container"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: start;
                  "
                >
                  <pagination
                    v-show="total > 0"
                    :total="total"
                    :page.sync="listQuery.page"
                    :limit.sync="listQuery.limit"
                    :page-sizes="pageSizelist"
                    @pagination="getList"
                  />
                  <!--                  <el-button
                                        v-waves
                                        :loading="downloadLoading"
                                        style="margin-left: 0px;margin-top: 30px;"

                                        size="mini"
                                        icon="el-icon-download"
                                        plain
                                        @click="handleDownloadExcel"
                                    >{{ $t("Export") + $t("Excel") }}
                                    </el-button>-->
                  <el-dropdown
                    v-if="isPCShow"
                    style="margin-left: 5px; margin-top: 30px"
                    type="primary"
                  >
                    <el-button icon="el-icon-thumb" size="mini"
                      >{{ $t("Batch operations")
                      }}<i class="el-icon-arrow-down el-icon--right"></i
                    ></el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        @click.native="handleOption(index)"
                        :loading="downloadLoading"
                        v-for="(item, index) in optionData"
                        :key="index"
                      >
                        {{ item }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <!--开始-->
              <el-dialog
                :visible.sync="groupArr.groupShow"
                :append-to-body="true"
                :destroy-on-close="true"
                :title="$t('Share Group')"
                width="80%"
              >
                <div class="modal-body">
                  <ul class="list">
                    <el-radio-group v-model="groupArr.groupid">
                      <el-radio
                        v-for="(item, groupi) in groupArr.data"
                        :key="groupi"
                        :label="item.sharegroup_id"
                        >{{ item.sharegroup_name }}</el-radio
                      >
                    </el-radio-group>
                  </ul>
                  <button
                    style="margin-top: 15px"
                    class="btn btn-secondary"
                    type="button"
                    @click="groupMoveHandle"
                  >
                    {{ $t("Save") }}
                  </button>
                </div>
              </el-dialog>
              <!--结束       -->
              <el-dialog
                :visible.sync="dialogSwitch.tagModalShare"
                :append-to-body="true"
                :title="$t('Share') + $t('Invitation Code')"
                width="80%"
              >
                <div class="modal-body">
                  <form class="form-bookmark needs-validation" novalidate="">
                    <div class="form-row">
                      <div
                        class="form-group col-md-12"
                        style="
                          padding: 10px;
                          border: solid 1px #eeeeee;
                          border-bottom: 0;
                          margin-bottom: 0;
                          padding-top: 15px;
                          margin-top: 0;
                        "
                      >
                        <label
                          style="
                            background-color: rgba(0, 0, 0, 0.1);
                            padding: 10px;
                            border-radius: 5px;
                            min-width: 70px;
                            text-align: center;
                          "
                          >{{ $t("Invitation Code") }}:</label
                        >
                        <label style="margin-left: 20px">{{
                          temp.share_code
                        }}</label>
                      </div>
                      <div
                        class="form-group col-md-12"
                        style="
                          padding: 10px;
                          border: solid 1px #eeeeee;
                          border-bottom: 0;
                          margin-bottom: 0;
                          padding-top: 15px;
                          margin-top: 0;
                        "
                      >
                        <label
                          style="
                            background-color: rgba(0, 0, 0, 0.1);
                            padding: 10px;
                            border-radius: 5px;
                            min-width: 70px;
                            text-align: center;
                          "
                        >
                          {{ $t("Theory of conversation") }}:
                        </label>
                        <label style="margin-left: 20px">
                          {{ temp.share_total }}
                        </label>
                      </div>
                      <div
                        class="form-group col-md-12"
                        style="
                          padding: 10px;
                          border: solid 1px #eeeeee;
                          border-bottom: 0;
                          margin-bottom: 0;
                          padding-top: 15px;
                          margin-top: 0;
                        "
                      >
                        <label
                          style="
                            background-color: rgba(0, 0, 0, 0.1);
                            padding: 10px;
                            border-radius: 5px;
                            min-width: 70px;
                            text-align: center;
                          "
                          >{{ $t("Link") }}:</label
                        >
                        <label style="margin-left: 20px"
                          ><a
                            target="_blank"
                            :href="
                              config.domain +
                              '/web#/accountshow/' +
                              temp.share_code
                            "
                            >{{ $t("Open") }}</a
                          >
                          <!-- :href="
                          'http://localhost:8083' +
                          '/web#/accountshow/' +
                          temp.share_code
                        " -->
                          <!-- :href="
                          config.domain +
                          '/web#/accountshow/' +
                          temp.share_code
                        " -->
                          | <a @click="copyUrl">{{ $t("CopyUrl") }}</a> |
                          <a @click="copyAll">{{ $t("CopyAll") }}</a></label
                        >
                      </div>
                      <div
                        class="form-group col-md-12"
                        style="
                          padding: 10px;
                          border: solid 1px #eeeeee;
                          border-bottom: 0;
                          margin-bottom: 0;
                          padding-top: 15px;
                          margin-top: 0;
                        "
                      >
                        <label
                          style="
                            background-color: rgba(0, 0, 0, 0.1);
                            padding: 10px;
                            border-radius: 5px;
                            min-width: 70px;
                            text-align: center;
                          "
                          >{{ $t("Password") }}:</label
                        >
                        <label style="margin-left: 20px">{{
                          temp.share_password
                            ? temp.share_password
                            : $t("Password free")
                        }}</label>
                      </div>
                      <div
                        class="form-group col-md-12"
                        style="
                          padding: 10px;
                          border: solid 1px #eeeeee;
                          padding-top: 15px;
                          margin-top: 0;
                        "
                      >
                        <label
                          style="
                            background-color: rgba(0, 0, 0, 0.1);
                            padding: 10px;
                            border-radius: 5px;
                            min-width: 70px;
                            text-align: center;
                          "
                          >{{ $t("Remark") }}:</label
                        >
                        <label style="margin-left: 20px">{{
                          temp.share_remark
                        }}</label>
                      </div>
                    </div>
                  </form>
                </div>
              </el-dialog>
              <el-dialog
                :visible.sync="dialogSwitch.showSharegroup"
                :append-to-body="true"
                :destroy-on-close="true"
                :title="$t('Share Group')"
                width="80%"
              >
                <div class="modal-body" style="width: 100%">
                  <share-group
                    width="80%"
                    v-if="dialogSwitch.showSharegroup"
                  ></share-group>
                </div>
              </el-dialog>
              <!-- 创建邀请码PC -->
              <el-dialog
                v-if="isPCShow"
                :visible.sync="dialogSwitch.createModalExample"
                :append-to-body="true"
                width="70%"
                :title="
                  dialogStatus === 'create'
                    ? $t('Creating an Invitation Code')
                    : $t('Edit an Invitation Code') +' - '+ temp['share_code']
                "
              >
                <el-form
                  label-position="right"
                  label-width="150px"
                  :inline="true"
                  :model="temp"
                >
                  <el-form-item
                    v-if="dialogStatus === 'create'"
                    :label="$t('Share Code Type')"
                  >
                    <el-radio-group v-model="temp['share_type']">
                      <el-radio
                        v-for="(radioItem, radioIndex) in ['端口', '字数']"
                        :key="radioIndex"
                        :label="radioIndex"
                      >
                        {{ radioItem }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>

                  <br v-if="temp['share_type'] === 0" />
                  <el-form-item
                    v-if="temp['share_type'] === 0"
                    style="min-width: 350px"
                    :label="$t('Assigning a Port')"
                  >
                    <input
                      class="form-control"
                      v-model="temp['share_total']"
                      type="number"
                      style="top: -4px"
                      :placeholder="
                        $t('Please enter the') +
                        $t('The number of ports to allocate')
                      "
                      autocomplete="off"
                    />
                  </el-form-item>
                  <br />
                  <el-form-item
                    v-for="(item, index) in listModelServer"
                    v-if="item.edit === '1'"
                    :key="index"
                    :label="item.label"
                    :prop="item.key"
                  >
                    <label v-if="item.edittype === 'disable'">{{
                      temp[item.key]
                    }}</label>
                    <input
                      class="form-control"
                      v-if="item.edittype === 'text'"
                      v-model="temp[item.key]"
                      type="text"
                      style="top: -4px; width: 200px"
                      :placeholder="$t('Please enter the') + item.label"
                      autocomplete="off"
                    />
                    <input
                      class="form-control"
                      v-if="item.edittype === 'number'"
                      v-model="temp[item.key]"
                      type="number"
                      style="top: -4px; width: 200px"
                      :placeholder="$t('Please enter the') + item.label"
                      autocomplete="off"
                    />
                    <el-date-picker
                      v-if="item.edittype === 'date'"
                      v-model="temp[item.key]"
                      style="
                        top: -4px;
                        width: 200px;
                        height: calc(1.5em + 0.75rem + 2px);
                      "
                      type="date"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      :placeholder="$t('Please enter the') + item.label"
                    />
                    <el-time-picker
                      v-if="item.edittype === 'time'"
                      v-model="temp[item.key]"
                      style="
                        top: -4px;
                        width: 200px;
                        height: calc(1.5em + 0.75rem + 2px);
                      "
                      format="HH:mm:ss"
                      value-format="HH:mm:ss"
                      :placeholder="$t('Please enter the') + item.label"
                    />
                    <el-date-picker
                      v-if="item.edittype === 'datetime'"
                      v-model="temp[item.key]"
                      style="
                        top: -4px;
                        width: 200px;
                        height: calc(1.5em + 0.75rem + 2px);
                      "
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :placeholder="$t('Please enter the') + item.label"
                    />
                    <el-radio-group
                      v-if="item.edittype === 'radio'"
                      v-model="temp[item.key]"
                    >
                      <el-radio
                        v-for="(radioItem, radioIndex) in item.editselect"
                        :key="radioIndex"
                        :label="radioItem.id"
                      >
                        {{ radioItem.title }}
                      </el-radio>
                    </el-radio-group>
                    <el-checkbox-group
                      v-if="item.edittype === 'checkbox'"
                      v-model="temp[item.key]"
                      :style="{
                        width: 'calc(100% - 150px)',
                        marginTop: '-45px',
                        marginLeft: '150px',
                      }"
                      class="formWidth130_item"
                    >
                      <el-checkbox
                        v-for="(chboxItem, chboxIndex) in item.editselect"
                        :key="chboxIndex"
                        :label="chboxItem.id"
                      >
                        {{ chboxItem.title }}
                      </el-checkbox>
                    </el-checkbox-group>
                    <el-checkbox-group
                      v-if="item.edittype === 'checkboxPrivilege'"
                      v-model="temp[item.key]"
                    >
                      <el-checkbox
                        v-for="(chboxItem, chboxIndex) in item.editselect"
                        :key="chboxIndex"
                        :label="chboxItem.id"
                        v-if="
                          !isNaN(temp.share_type) &&
                          showPrivage[temp.share_type][`${chboxItem.id}`]
                        "
                      >
                        <span
                          v-if="chboxItem.title === '数据分析'"
                          style="color: red"
                          >{{ chboxItem.title }}
                          <el-tooltip
                            class="item"
                            :value="dialogSwitch.createModalExample"
                            effect="dark"
                            :content="
                              $t(
                                'Re-login client will automatically upload account contact analysis data = counte'
                              )
                            "
                            placement="top-start"
                          >
                            <i class="el-icon-question"></i
                          ></el-tooltip>
                        </span>
                        <span v-else>
                          {{ chboxItem.title }}
                        </span>
                      </el-checkbox>
                    </el-checkbox-group>

                    <el-select
                      style="top: -4px; width: 200px"
                      v-if="item.edittype === 'select'"
                      v-model="temp[item.key]"
                      class="filter-item"
                      :placeholder="$t('Please enter the') + item.label"
                    >
                      <el-option
                        v-for="(selectitem, selectkey) in item.editselect"
                        :key="selectkey"
                        :label="selectitem.title"
                        :value="selectitem.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-form>

                <div slot="footer" class="dialog-footer">
                  <button
                    class="btn btn-secondary"
                    type="button"
                    @click="
                      dialogStatus === 'create' ? createData() : updateData()
                    "
                  >
                    {{ $t("Save") }}
                  </button>
                  <button
                    class="btn btn-primary ml-2"
                    type="button"
                    data-dismiss="modal"
                    @click="dialogSwitch.createModalExample = false"
                  >
                    {{ $t("Cancel") }}
                  </button>
                </div>
              </el-dialog>
              <!-- 创建邀请码移动 -->
              <el-dialog
                :visible.sync="dialogSwitch.createModalExample"
                :append-to-body="true"
                v-if="!isPCShow"
                width="90%"
                :title="
                  dialogStatus === 'create'
                    ? $t('Creating an Invitation Code')
                    : $t('Edit an Invitation Code') + ' - '+ temp['share_code']
                "
              >
                <el-form
                  label-position="top"
                  label-width="150px"
                  :inline="true"
                  :model="temp"
                >
                  <el-form-item
                    v-if="dialogStatus === 'create'"
                    :label="$t('Share Code Type')"
                  >
                    <el-radio-group v-model="temp['share_type']">
                      <el-radio
                        v-for="(radioItem, radioIndex) in ['端口', '字数']"
                        :key="radioIndex"
                        :label="radioIndex"
                      >
                        {{ radioItem }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <br v-if="temp['share_type'] == 0" />
                  <el-form-item
                    v-if="temp['share_type'] == 0"
                    :label="$t('Assigning a Port')"
                  >
                    <input
                      class="form-control"
                      v-model="temp['share_total']"
                      type="number"
                      style="top: -4px"
                      :placeholder="
                        $t('Please enter the') +
                        $t('The number of ports to allocate')
                      "
                      autocomplete="off"
                    />
                  </el-form-item>
                  <br v-if="temp['share_type'] == 0" />
                  <el-form-item
                    v-for="(item, index) in listModelServer"
                    v-if="item.edit === '1'"
                    :key="index"
                    :label="item.label"
                    :prop="item.key"
                  >
                    <label v-if="item.edittype === 'disable'">{{
                      temp[item.key]
                    }}</label>
                    <input
                      class="form-control"
                      v-if="item.edittype === 'text'"
                      v-model="temp[item.key]"
                      type="text"
                      style="top: -4px; width: 200px"
                      :placeholder="$t('Please enter the') + item.label"
                      autocomplete="off"
                    />
                    <input
                      class="form-control"
                      v-if="item.edittype === 'number'"
                      v-model="temp[item.key]"
                      type="number"
                      style="top: -4px; width: 200px"
                      :placeholder="$t('Please enter the') + item.label"
                      autocomplete="off"
                    />
                    <el-date-picker
                      v-if="item.edittype === 'date'"
                      v-model="temp[item.key]"
                      style="
                        top: -4px;
                        width: 200px;
                        height: calc(1.5em + 0.75rem + 2px);
                      "
                      type="date"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      :placeholder="$t('Please enter the') + item.label"
                    />
                    <el-time-picker
                      v-if="item.edittype === 'time'"
                      v-model="temp[item.key]"
                      style="
                        top: -4px;
                        width: 200px;
                        height: calc(1.5em + 0.75rem + 2px);
                      "
                      format="HH:mm:ss"
                      value-format="HH:mm:ss"
                      :placeholder="$t('Please enter the') + item.label"
                    />
                    <el-date-picker
                      v-if="item.edittype === 'datetime'"
                      v-model="temp[item.key]"
                      style="
                        top: -4px;
                        width: 200px;
                        height: calc(1.5em + 0.75rem + 2px);
                      "
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :placeholder="$t('Please enter the') + item.label"
                    />
                    <el-radio-group
                      v-if="item.edittype === 'radio'"
                      v-model="temp[item.key]"
                    >
                      <el-radio
                        v-for="(radioItem, radioIndex) in item.editselect"
                        :key="radioIndex"
                        :label="radioItem.id"
                      >
                        {{ radioItem.title }}
                      </el-radio>
                    </el-radio-group>
                    <el-checkbox-group
                      v-if="item.edittype === 'checkbox'"
                      v-model="temp[item.key]"
                      class="formWidth130_item"
                    >
                      <el-checkbox
                        v-for="(chboxItem, chboxIndex) in item.editselect"
                        :key="chboxIndex"
                        :label="chboxItem.id"
                      >
                        {{ chboxItem.title }}
                      </el-checkbox>
                    </el-checkbox-group>
                    <el-checkbox-group
                      v-if="item.edittype === 'checkboxPrivilege'"
                      v-model="temp[item.key]"
                    >
                      <el-checkbox
                        v-for="(chboxItem, chboxIndex) in item.editselect"
                        :key="chboxIndex"
                        :label="chboxItem.id"
                        v-if="
                          !isNaN(temp.share_type) &&
                          showPrivage[temp.share_type][`${chboxItem.id}`]
                        "
                      >
                        <span
                          v-if="chboxItem.title === '数据分析'"
                          style="color: red"
                          >{{ chboxItem.title }}
                          <el-tooltip
                            class="item"
                            :value="dialogSwitch.createModalExample"
                            effect="dark"
                            :content="
                              $t(
                                'Re-login client will automatically upload account contact analysis data = counte'
                              )
                            "
                            placement="top-start"
                          >
                            <i class="el-icon-question"></i
                          ></el-tooltip>
                        </span>
                        <span v-else>
                          {{ chboxItem.title }}
                        </span>
                      </el-checkbox>
                    </el-checkbox-group>

                    <el-select
                      style="top: -4px; width: 200px"
                      v-if="item.edittype === 'select'"
                      v-model="temp[item.key]"
                      class="filter-item"
                      :placeholder="$t('Please enter the') + item.label"
                    >
                      <el-option
                        v-for="(selectitem, selectkey) in item.editselect"
                        :key="selectkey"
                        :label="selectitem.title"
                        :value="selectitem.id"
                      />
                    </el-select>
                  </el-form-item>
                  <!-- <el-form-item
                    v-if="temp['share_type'] === 0"
                    style="min-width: 350px"
                    :label="$t('Assigning a Port')"
                  >
                    <input
                      class="form-control"
                      v-model="temp['share_total']"
                      type="number"
                      style="top: -4px; width: 200px"
                      :placeholder="
                        $t('Please enter the') +
                        $t('The number of ports to allocate')
                      "
                      autocomplete="off"
                    />
                  </el-form-item> -->
                </el-form>

                <div
                  slot="footer"
                  class="dialog-footer"
                  style="text-align: left"
                >
                  <button
                    class="btn btn-secondary"
                    type="button"
                    @click="
                      dialogStatus === 'create' ? createData() : updateData()
                    "
                  >
                    {{ $t("Save") }}
                  </button>
                  <button
                    class="btn btn-primary ml-2"
                    type="button"
                    data-dismiss="modal"
                    @click="dialogSwitch.createModalExample = false"
                  >
                    {{ $t("Cancel") }}
                  </button>
                </div>
              </el-dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination';
  import ShareGroup from '@/pages/sharecode/sharegroup/sharegroup.vue';
  import checkbox from 'element-ui/packages/checkbox';

  let t;
  export default {
    components: { ShareGroup, Pagination },
    data() {
      return {
        optionData: ['移至某分组', '批量禁用', '批量启用', '批量删除','批量回收'],
        selectDate: [],
        allStatus: false,
        tableFixed: 'right',
        downloadLoading: false,
        showColums: {},
        dialogSwitch: {
          createModalExample: false,
          tagModalShare: false,
          showSharegroup: false,
        },
        listQuery: {
          page: 1,
          limit: 5,
          sort: '',
          createdatstart: '',
          createdatend: '',
        },
        showPrivage: [
          { 1: true, 2: true, 3: true, 4: true, 5: true, 6: true },
          { 1: true, 4: true },
        ],
        option: {
          showAddBt: false,
          showCatchEditBt: false,
          showExportBt: false,
          showSearchBt: true,
        },
        listLoading: true,
        pageSizelist: [],
        listSearchServer: [],
        listModelServer: [],
        listModelNum: 0,
        filter: null,
        list: [],
        total: 0,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15],
        tableKey: 0,
        selectMode: 'multi',
        selected: [],
        listdata: [],
        temp: {},
        dialogStatus: '',
        summar: {
          switch: false,
          data: {},
        },
        titleModelList: [],
        groupArr: {
          count: 0,
          loading: false,
          noMore: false,
          groupid: 0,
          page: 1,
          data: [],
          groupShow: false,
        },

        isPCShow: true,
        windowWidth: window.innerWidth,
        concactArr: [],
        share_created_label: '',
        share_logined_label: '',
      };
    },
    computed: {
      checkbox() {
        return checkbox;
      },
    },
    mounted() {
      // Set the initial number of items
      t = this;
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      t.getRenderData();
      t.getColumn();
      t.tableFixed = t._isMobile() ? false : 'right';
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        t.windowWidth = window.innerWidth;
        // const windowWidth = window.innerWidth;
        t.isPCShow = t.windowWidth >= 768;
      },
      groupMoveHandle() {
        const shareidList = [];
        // eslint-disable-next-line no-unused-vars
        for (const selectDateElement of this.selectDate) {
          shareidList.push(selectDateElement.share_id);
        }
        t.request
          .post('/sharegroup/move', {
            shareid: shareidList,
            groupid: this.groupArr.groupid,
          })
          .then(() => {
            t.groupArr.groupShow = false;
            this.$notify({
              title: '完成',
              message: '更新成功',
              type: 'success',
              duration: 2000,
            });
            this.getList();
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      groupArrLoadData() {
        this.groupArr.loading = true;
        t.request
          .get('/workshare/grouplist', this.groupArr)
          .then((response) => {
            this.groupArr.data = response.data.items;
            this.groupArr.count = response.data.total;
            // Just to simulate the time of the request
            this.groupArr.page++;
            this.groupArr.loading = false;
          })
          .catch((res) => {
            this.groupArr.loading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      _isMobile() {
        const flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        return flag;
      },
      handleOption(index = 0) {
        if (this.selectDate.length <= 0) {
          return this.$toasted.show(this.$t('Choose at least one'), {
            theme: 'bubble',
            position: 'bottom-center',
            type: 'info',
            duration: 3000,
          });
        }
        switch (index) {
        case 0:
          this.groupArrLoadData();
          this.groupArr.groupShow = true;
          break;
        case 1: //批量禁用
          this.handleBatch(index);
          break;
        case 2: //批量启用
          this.handleBatch(index);
          break;
        case 3: //批量删除
          this.handleBatch(index);
          break;
        case 4: //批量删除

          t.$confirm(
            this.$t(`Only disabled invitation codes can be reclaimed in batches`),
            this.$t('warning'),
            {
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('Cancel'),
              type: 'warning',
              dangerouslyUseHTMLString: true,
            }
          )
            .then(async () => {
              this.handleBatch(index);
            })
            .catch((err) => {
              console.error(err);
            });
          break;
        default:
          this.handleBatch(index);
          break;
        }
      },
      handleBatch(index = 1) {
        const shareidList = [];
        // eslint-disable-next-line no-unused-vars
        for (const selectDateElement of this.selectDate) {
          shareidList.push(selectDateElement.share_id);
        }
        t.request
          .post('/sharecode/handleBatch', { shareid: shareidList, index: index })
          .then((res) => {
            this.getList();
            this.$notify({
              title: '完成',
              message: res.msg,
              type: 'success',
              duration: 2000,
            });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      resetFun() {
        // eslint-disable-next-line no-unused-vars
        for (const listSearchServerElement of t.listSearchServer) {
          t.$set(t.listQuery, listSearchServerElement.key, '');
        }
        // eslint-disable-next-line no-unused-vars
        for (const listQueryElement of this.listQuery) {
          this.$set(this.listQuery, listQueryElement, '');
        }
      },
      handleSelectionChange(e) {
        this.selectDate = e;
      // console.log("选中哈哈哈", e);
      },
      toggleSelection() {
        this.$refs.multipleTable.clearSelection();
      },
      rowClick(item, el) {
        if (el.label === '邀请码') {
          t.$copyText(item.share_code)
            .then((e) => {
              t.$toasted.show('复制成功', {
                theme: 'bubble',
                position: 'top-center',
                type: 'success',
                duration: 3000,
              });
            })
            .catch((e) => {
              t.$toasted.show('复制失败', {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 3000,
              });
            });
        }
      },
      selectAllFun(e) {
        !t.allStatus;
        let i = 0;
        t.listModelServer.forEach((item) => {
          if (item.show === '1') {
            if (!t.allStatus && i <= 5) {
              t.$set(t.showColums, item.key, true);
            } else if (!t.allStatus && i > 5) {
              t.$set(t.showColums, item.key, false);
            } else {
              t.$set(t.showColums, item.key, true);
            }
            i++;
          }
        });
        window.localStorage.setItem(
          'codelist_colmun',
          JSON.stringify(t.showColums)
        );
      },
      getColumn() {
        t.showColums = JSON.parse(
          window.localStorage.getItem('codelist_colmun') || '{}'
        );
      },
      saveColmun() {
        let arr = Object.values(t.showColums).filter((item) => item);
        t.allStatus = arr.length === t.listModelNum;
        window.localStorage.setItem(
          'codelist_colmun',
          JSON.stringify(t.showColums)
        );
      },
      getSummar(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            // 只找第一列放合计
            sums[index] = `${t.$t('combined')}`;
            return;
          }
          if (t.summar.data[column.property]) {
            // 判断当前prop绑定的ID
            const values = data.map((item) => item[column.property]); // 把对应一列中的之全部取出，放到一个数组中
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr); // 将values中的每个值转换为number类型
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          }
        });
        return sums;
      },
      copyUrl() {
        const text = t.config.domain + '/web#/accountshow/' + t.temp.share_code;
        t.$copyText(text)
          .then((e) => {
            t.$toasted.show('复制成功', {
              theme: 'bubble',
              position: 'top-center',
              type: 'success',
              duration: 3000,
            });
          })
          .catch((e) => {
            t.$toasted.show('复制失败', {
              theme: 'bubble',
              position: 'top-center',
              type: 'error',
              duration: 3000,
            });
          });
      },
      copyAll() {

        let password = t.temp.share_password ? t.temp.share_password : '';
        let share_remark = t.temp.share_remark ? t.temp.share_remark : '';
        const text = `邀请码：${t.temp.share_code}
工单链接：${t.config.domain + '/web#/accountshow/' + t.temp.share_code}
访问密码：${password}
备注信息：${share_remark}`;
        t.$copyText(text)
          .then((e) => {
            t.$toasted.show('复制成功', {
              theme: 'bubble',
              position: 'top-center',
              type: 'success',
              duration: 3000,
            });
          })
          .catch((e) => {
            t.$toasted.show('复制失败', {
              theme: 'bubble',
              position: 'top-center',
              type: 'error',
              duration: 3000,
            });
          });
      },
      handleOpen(item, index) {
        this.$router.replace({
          path: `/sharecode/accountlist/${item.share_id}/${item.share_code}`,
        });
      },
      handleDownload() {
        this.downloadLoading = true;
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = ['timestamp', 'title', 'type', 'importance', 'status'];
        const filterVal = [
          'timestamp',
          'title',
          'type',
          'importance',
          'status',
        ];
        const data = this.formatJson(filterVal);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'table-list',
        });
        this.downloadLoading = false;
      });
      },
      formatJson(filterVal) {
        return this.list.map((v) =>
          filterVal.map((j) => {
            if (j === 'timestamp') {
              return parseTime(v[j]);
            } else {
              return v[j];
            }
          })
        );
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleUpdate(row) {
        this.temp = Object.assign({}, row); // copy obj
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in this.temp) {
          try {
            if (typeof JSON.parse(this.temp[tempKey]) === 'object') {
              this.temp[tempKey] = JSON.parse(this.temp[tempKey]);
            }
          // eslint-disable-next-line no-empty
          } catch (e) {}
        }
        this.temp.password = '';
        this.temp.walletpassword = '';
        this.dialogStatus = 'update';
        t.dialogSwitch.createModalExample = true;
      },
      in_array(search, array = []) {
        // eslint-disable-next-line no-unused-vars
        for (const val of array) {
          if (val == search) {
            return true;
          }
        }
        return false;
      },
      createData() {
        const tempData = Object.assign({}, this.temp);
        if (tempData['share_type'] === '') {
          return t.$toasted.show('请选择邀请码类型', {
            theme: 'bubble',
            position: 'bottom-center',
            type: 'error',
            duration: 3000,
          });
        }
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in tempData) {
          try {
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of this.listModelServer) {
              if (
                modelDatum.key === tempKey &&
                (modelDatum.edittype === 'number' || modelDatum.edittype.indexOf('Number') !== -1)
              ) {
                tempData[tempKey] = Number(tempData[tempKey]);
                break;
              }
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }
        tempData['share_total'] = Number(tempData['share_total']);

        t.request
          .post('/sharecode/add', tempData)
          .then(() => {
            this.getList();
            t.dialogSwitch.createModalExample = false;
            this.$notify({
              title: '完成',
              message: '添加成功',
              type: 'success',
              duration: 2000,
            });//share_privilege
            this.$message(
              { showClose:true,type:'',dangerouslyUseHTMLString:true,duration:10*1000,message: `<p style="font-weight: bold;">${this.$t('Invite code work order permission prompt')}</p>
<p>${this.$t('Multi-device login')} <span style="color: ${this.in_array(1,this.temp['share_privilege']) ? 'green' : 'red'}">${this.in_array(1,this.temp['share_privilege']) ? this.$t('Open') : this.$t('Not')}</span> ${this.$t('(Aquaman client multi-device login)')}</p>
<p>${this.$t('Address book details')} <span style="color: ${this.in_array(2,this.temp['share_privilege']) ? 'green' : 'red'}">${this.in_array(2,this.temp['share_privilege']) ? this.$t('Open') : this.$t('Not')}</span> (${this.$t('Invite code account to view contacts')})</p>
<p>${this.$t('Analysis repetition')} <span style="color: ${this.in_array(3,this.temp['share_privilege']) ? 'green' : 'red'}">${this.in_array(3,this.temp['share_privilege']) ? this.$t('Open') : this.$t('Not')}</span> (${this.$t('Contact analysis repeat into powder view')})</p>
<p>${this.$t('Move out account')} <span style="color: ${this.in_array(4,this.temp['share_privilege']) ? 'green' : 'red'}">${this.in_array(4,this.temp['share_privilege']) ? this.$t('Open') : this.$t('Not')}</span> (${this.$t('The invitation code can remove offline accounts and data')})</p>
<p>${this.$t('Heavy powder labeling')} <span style="color: ${this.in_array(6,this.temp['share_privilege']) ? 'green' : 'red'}">${this.in_array(6,this.temp['share_privilege']) ? this.$t('Open') : this.$t('Not')}</span> (${this.$t('Invite code data for repeated contact viewing and marking')})</p>
<p>${this.$t('Data analysis')} <span style="color: ${this.in_array(5,this.temp['share_privilege']) ? 'green' : 'red'}">${this.in_array(5,this.temp['share_privilege']) ? this.$t('Open') : this.$t('Not')}</span> (${this.$t('Counter record contact function, only :WS,TG,LINE,Zalo')})</p>

<p style="margin-top: 10px;text-align: center;">10秒后自动关闭</p>
` });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      handleCreate() {
        this.resetTemp();
        this.temp.share_privilege = [1, 2, 3];
        this.dialogStatus = 'create';
        this.temp['share_status'] = 1;
        this.temp['share_type'] = 0;
        this.temp['share_zerotime'] = '23:59:59';
        // this.temp['share_type'] = 0;
        t.dialogSwitch.createModalExample = true;
      },
      handleSharegroup() {
        t.dialogSwitch.showSharegroup = true;
      },
      resetTemp() {
        const jsonTemp = {};
        // eslint-disable-next-line no-unused-vars
        for (const listModelServerKey in this.listModelServer) {
          switch (this.listModelServer[listModelServerKey]['edittype']) {
          case 'checkbox':
            jsonTemp[this.listModelServer[listModelServerKey]['key']] = [];
            break;
          default:
            jsonTemp[this.listModelServer[listModelServerKey]['key']] = '';
            break;
          }
          if (
            this.listModelServer[listModelServerKey]['key'] === 'share_applist'
          ) {
            jsonTemp['share_applist'] = [];
            // eslint-disable-next-line no-unused-vars
            for (const listModelServerElement of this.listModelServer[
              listModelServerKey
            ]['editselect']) {
              jsonTemp['share_applist'].push(listModelServerElement['id']);
            }
          }
        }
        this.temp = jsonTemp;
      },
      stopCode(row, key) {
        t.$confirm(
          `<div class="ant-modal-confirm-content"><div data-v-830c5818=""><div data-v-830c5818="">当邀请码被停用后：</div><div data-v-830c5818="">系统将回收已分配的会话数；</div><div data-v-830c5818="">客户端将关闭所有会话并退出；</div><div data-v-830c5818="">邀请码分享链接依旧可查看记录；</div><div data-v-830c5818="">将不再允许使用此邀请码。</div><br data-v-830c5818=""><div data-v-830c5818="" style="color: rgb(250, 173, 20);">停用后将进入系统回收倒计时，在15天后自动清空邀请码相关数据。</div></div></div>`,
          '是否确定停用邀请码',
          {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning',
            dangerouslyUseHTMLString: true,
          }
        )
          .then(async () => {
            t.updateData(row, key, 0);
          })
          .catch((err) => {
            console.error(err);
          });
      },
      updateData(row, key, value) {
        if (row) {
          row[key] = value;
          this.temp = row;
        }
        const tempData = Object.assign({}, this.temp);
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in tempData) {
          try {
            for (const modelDatum of this.listModelServer) {
              if (
                modelDatum.key === tempKey &&
                (modelDatum.edittype === 'number' || modelDatum.edittype.indexOf('Number') !== -1)
              ) {
                tempData[tempKey] = Number(tempData[tempKey]);
                break;
              }
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
          } catch (e) {
            console.log(e);
          }
        /* if (typeof JSON.parse(this.temp[tempKey]) === 'array') {

        }*/
        }
        tempData['share_total'] = Number(tempData['share_total']);
        t.request
          .post('/sharecode/edit', tempData)
          .then((res) => {
            t.getList();
            t.dialogSwitch.createModalExample = false;
            t.$toasted.show(res.msg, {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'success',
              duration: 3000,
            });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      handleShare(row, index = 0) {
        t.temp = row;
        t.dialogSwitch.tagModalShare = true;
      },
      handleDelete(row, index) {
        this.$confirm(`确认要删除吗?`, this.$t('warning'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning',
        })
          .then(async () => {
            await t.request.post('/sharecode/delete', row);
            this.list.splice(index, 1);
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      getRenderData() {
        t.request
          .get('/sharecode/render')
          .then((response) => {
            const res = response.data;
            let i = 0;

            t.concactArr = res.modelData;
            t.share_created_label = res.modelData.find(
              (x) => x.key == 'share_created'
            ).label;
            t.share_logined_label = res.modelData.find(
              (x) => x.key == 'share_logined'
            ).label;

            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of res.modelData) {
              // 合并分配端口/使用端口
              // 合并创建时间/最近登录

              if (modelDatum.key == 'share_total') {
                modelDatum.label = `${
                  t.concactArr.find((x) => x.key == 'share_total').label
                }/${t.concactArr.find((x) => x.key == 'share_online').label}`;
              }
              if (modelDatum.key == 'share_created') {
                modelDatum.label = `${
                  t.concactArr.find((x) => x.key == 'share_created').label
                }/${t.concactArr.find((x) => x.key == 'share_logined').label}`;
              }
              if (
                modelDatum.show === '1' &&
                modelDatum.key !== 'share_online' &&
                modelDatum.key !== 'share_logined'
              ) {
                // console.log("modelDatum", modelDatum);
                // 过滤掉使用端口和最近登录，计算合并后的数组长度
                t.listModelNum = i + 1;
                /* if (i > 5) {
                  i++;
                  continue;
                }*/
                // eslint-disable-next-line no-prototype-builtins
                if (!t.showColums.hasOwnProperty(modelDatum.key)) {
                  t.$set(t.showColums, modelDatum.key, true);
                }
                i++;
              }
            }
            t.saveColmun();
            t.option = res.option || {};
            t.summar = res.summar || {};
            t.listModelServer =
              res.modelData.filter(
                (x) => x.key !== 'share_online' && x.key !== 'share_logined'
              ) || [];
            t.listSearchServer = res.searchData || [];
            t.listQuery.sort = res.defaultSort || [];
            t.pageSizelist = res.pageSizelist || [];
            t.getList();
          })
          .catch((res) => {
            res.errMsg
              ? t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              })
              : t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
          });
      },
      // 列表搜索
      getList() {
        this.listLoading = true;
        t.request
          .get('/sharecode/list', this.listQuery)
          .then((response) => {
            let arr = response.data.items;
            this.list = arr.map((x) => {
              x.share_total1 = `${x.share_total}/${x.share_online}`;
              // x.share_created = `${x.share_created
              //   .replace("+08:00", "")
              //   .replace("T", " ")}/${x.share_logined
              //   .replace("+08:00", "")
              //   .replace("T", " ")}`;
              return x;
            });

            this.total = response.data.total;
            // Just to simulate the time of the request
            this.listLoading = false;
          })
          .catch((res) => {
            this.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      sortChange(data) {
        const { prop, order } = data;
        t.sortByID(order, prop);
      },
      sortByID(order, prop) {
        if (order === 'ascending') {
          t.listQuery.sort = `${prop} ASC`;
        } else {
          t.listQuery.sort = `${prop} DESC`;
        }
        t.handleFilter();
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    },
  };
</script>

<style lang="scss" scoped>
.el-select-dropdown {
  /*background-color: unset ;*/
}

.el-input__inner {
  background-color: unset !important;
}

.pagination-container {
  background: unset;
}

.el-table__cell {
  background-color: unset;
}
.el-form-item .formWidth130_item .el-form-item--medium .el-form-item__content {
  width: calc(100% - 150px) !important;
  margin-top: -45px;
  margin-left: 130px;
}
.form-control {
  font-size: inherit;
}
</style>
