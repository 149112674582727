var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"sidebar-main",attrs:{"id":"sidebar-main"}},[_c('li',{staticClass:"left-arrow",class:{
      'd-none':
        _vm.layout.settings.layout_type == 'rtl'
          ? _vm.hideLeftArrowRTL
          : _vm.hideLeftArrow,
    },on:{"click":function($event){_vm.layoutobject.split(' ').includes('horizontal-wrapper') &&
      _vm.layout.settings.layout_type === 'rtl'
        ? _vm.scrollToLeftRTL()
        : _vm.scrollToLeft()}}},[_c('feather',{attrs:{"type":"arrow-left"}})],1),_c('Navmenu'),(_vm.rightArrow > 1 && _vm.rightArrow <= _vm.numArrows)?_c('li',{staticClass:"right-arrow",on:{"click":function($event){_vm.layoutobject == 'horizontal_sidebar' &&
      _vm.layout.settings.layout_type == 'rtl'
        ? _vm.scrollToRightRTL()
        : _vm.scrollToRight()}}},[_c('feather',{attrs:{"type":"arrow-right"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }