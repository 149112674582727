<template>
  <div v-loading="listLoading">
    <Breadcrumbs main="" :title="$t('Multiplex order sharing')" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <!-- class="filter-container" -->
            <div class="card-body">
              <div v-if="option.showSearchBt" style="margin: 10px 0 30px 0">
                <!--  style="top: -4px; width: 12rem;" -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    flex-wrap: wrap;
                  "
                >
                  <div style="flex: 1.7; min-width: 350px">
                    <el-date-picker
                      v-model="listQuery.createdatstart"
                      :style="
                        isPCShow
                          ? { width: '12rem', 'margin-left': '5px' }
                          : { width: '9rem', 'margin-left': '5px' }
                      "
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :placeholder="$t('Please select a start time')"
                    />
                    <el-date-picker
                      v-model="listQuery.createdatend"
                      :style="
                        isPCShow
                          ? { width: '12rem', 'margin-left': '5px' }
                          : { width: '9rem', 'margin-left': '5px' }
                      "
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :placeholder="$t('Please select an end time')"
                    />
                    <span
                      v-for="(searchItem, searchIndex) in listSearchServer"
                      :key="searchIndex"
                      style="margin-left: 5px; line-height: 45px"
                    >
                      <el-input
                        v-if="searchItem.htype === 'text'"
                        v-model="listQuery[searchItem.key]"
                        type="text"
                        :style="
                          isPCShow ? { width: '12rem' } : { width: '9rem' }
                        "
                        :placeholder="$t('Please enter the') + searchItem.label"
                        class="filter-item"
                        @keyup.enter.native="handleFilter"
                      />

                      <el-input
                        v-if="searchItem.htype === 'number'"
                        v-model="listQuery[searchItem.key]"
                        type="number"
                        :style="
                          isPCShow ? { width: '12rem' } : { width: '9rem' }
                        "
                        :placeholder="$t('Please enter the') + searchItem.label"
                        class="filter-item"
                        @keyup.enter.native="handleFilter"
                      />

                      <el-select
                        v-if="searchItem.htype === 'select'"
                        v-model="listQuery[searchItem.key]"
                        :placeholder="$t('Please select a') + searchItem.label"
                        clearable
                        :style="
                          isPCShow ? { width: '12rem' } : { width: '9rem' }
                        "
                        class="filter-item"
                      >
                        <el-option
                          v-for="(item, key) in searchItem.select"
                          :key="key"
                          :label="item.title"
                          :value="item.id"
                        />
                      </el-select>
                    </span>
                  </div>
                  <div
                    style="
                      flex: 0.3;
                      display: flex;
                      height: 36px;
                      margin-top: 3px;
                      margin-left: 5px;
                    "
                  >
                    <el-button
                      style="flex: 1"
                      class="filter-item"
                      icon="el-icon-delete-solid"
                      type="success"
                      plain
                      @click="resetFun"
                      >{{ $t("Reset") }}
                    </el-button>
                    <el-button
                      style="flex: 1"
                      class="filter-item"
                      type="primary"
                      icon="el-icon-search"
                      @click="handleFilter"
                      >{{ $t("Search") }}
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="filter-container" style="margin-top: 8px">
                <el-button
                  v-if="option.showAddBt"
                  class="filter-item"
                  style="margin-left: 10px"
                  type="primary"
                  icon="el-icon-edit"
                  @click="handleCreate"
                  plain
                  >{{ $t("Create") }}
                </el-button>
                <el-button
                  class="filter-item"
                  style="margin-left: 10px"
                  type="danger"
                  @click="toggleSelection()"
                  icon="el-icon-delete"
                  plain
                  >{{ $t("Remove selected") }}
                </el-button>
                <!--      <el-button class="filter-item" style="margin-left: 10px;" type="warning" icon="el-icon-delete" @click="handleDelete">删除</el-button>-->
                <!-- <el-button
                  v-if="option.showExportBt"
                  v-waves
                  :loading="downloadLoading"
                  class="filter-item"
                  type="info"
                  icon="el-icon-download"
                  plain
                  @click="handleDownload"
                  >{{ $t("Export") }}
                </el-button> -->
              </div>
              <div>
                <el-table
                  :key="tableKey"
                  :data="list"
                  border
                  fit
                  highlight-current-row
                  :show-summary="summar.switch"
                  :summary-method="getSummar"
                  style="width: 100%; border-radius: 10px"
                  @sort-change="sortChange"
                  @row-click="rowClick"
                  @selection-change="handleSelectionChange"
                  ref="multipleTable"
                >
                  <el-table-column
                    type="selection"
                    width="55"
                  ></el-table-column>
                  <el-table-column
                    v-for="(item, index) in listModelServer"
                    :min-width="
                      item.showtype === 'date' || item.showtype === 'timetext'
                        ? '180'
                        : '120'
                    "
                    v-if="item.show === '1' && showColums[item.key]"
                    :key="index"
                    :label="item.label"
                    :prop="item.key"
                    :sortable="item.sort === '1'"
                    align="center"
                    show-overflow-tooltip
                  >
                    <template slot-scope="{ row }">
                      <span
                        v-if="
                          item.showtype === 'text' && item.key !== 'share_code'
                        "
                      >
                        {{ row[item.key] }}
                      </span>
                      <span v-if="item.showtype === 'timetext'">
                        <el-time-picker
                          v-model="row[item.key]"
                          format="HH:mm:ss"
                          style="width: 120px"
                          value-format="HH:mm:ss"
                          @change="updateData(row, 'time', '1')"
                        />
                      </span>
                      <el-button
                        type="text"
                        v-if="
                          item.showtype === 'text' && item.key === 'share_code'
                        "
                        >{{ row[item.key] }}
                      </el-button>
                      <span v-if="item.showtype === 'date'">
                        {{
                          row[item.key]
                            ? row[item.key]
                                .replace("+08:00", "")
                                .replace("T", " ")
                            : row[item.key]
                        }}
                      </span>
                      <!-- [ { "entitle": "port", "id": 0, "title": "port" }, { "entitle": "words", "id": 1, "title": "words" } ] words
 -->
                      <!-- 消费类型 -->
                      <!--                      <span
                                                v-if="
                                                item.showtype === 'arr' && item.edittype === 'radio'
                                              "
                                            >
                                              <el-button
                                                  type="text"
                                                  v-for="(arritem, arrindex) in item.showarr"
                                                  v-if="item.showarr[arrindex].id === 0"
                                                  :key="arrindex"
                                                  v-show="arritem.id === row[item.key]"
                                              >{{ item.showarr[arrindex].title }}
                                              </el-button>
                                              <span
                                                  style="color: rgb(28, 179, 155)"
                                                  v-for="(arritem, arrindex) in item.showarr"
                                                  v-if="item.showarr[arrindex].id === 1"
                                                  :key="arrindex"
                                                  v-show="arritem.id === row[item.key]"
                                              >{{ item.showarr[arrindex].title }}
                                              </span>
                                            </span>-->
                      <span v-if="item.showtype === 'arr'">
                        <el-tag
                          v-for="(arritem, arrindex) in item.showarr"
                          :key="arrindex"
                          v-show="arritem.id === row[item.key]"
                          :type="arritem.tagtype"
                          >{{ item.showarr[arrindex].title }}
                        </el-tag>
                      </span>
                      <!--                      <span
                                                v-if="item.showtype === 'arr' && item.key === 'share_status'">

                                              <el-tag
                                                  v-for="(arritem, arrindex) in item.showarr"
                                                  :key="arrindex"
                                                  v-show="arritem.id === row[item.key]"
                                                  :type="arritem.tagtype"
                                              >
                                                <i v-if="row.workshare_password !== ''" class="el-icon-lock"></i>
                                                <i v-else class="el-icon-unlock"></i>
                                                {{ item.showarr[arrindex].title }}
                                              </el-tag>
                                            </span>-->

                      <span v-if="item.showtype === 'img'"
                        ><el-image
                          fit="fill"
                          :src="row[item.key]"
                          :preview-src-list="[row[item.key]]"
                      /></span>
                    </template>
                  </el-table-column>

                  <!-- 操作---- -->

                  <el-table-column
                    :label="$t('Operation')"
                    align="center"
                    class-name="small-padding fixed-width"
                    :width="isPCShow ? 250 : 140"
                    :fixed="tableFixed"
                  >
                    <template slot="header" slot-scope="scope">
                      <span>{{ $t("Operation") }}</span>
                      <!-- $t("Display of columns") -->
                      <el-dropdown style="margin-left: 6px">
                        <i class="el-icon-setting"></i>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            style="border-bottom: 1px solid #dcdfe6"
                            command="sendModifyData"
                          >
                            <el-checkbox
                              v-model="allStatus"
                              @change="selectAllFun"
                              >{{ allStatus ? $t("CancelFull") : $t("Full") }}
                            </el-checkbox>
                          </el-dropdown-item>

                          <el-dropdown-item
                            command="sendModifyData"
                            v-for="(item, index) in listModelServer"
                            :key="index"
                            v-show="item.show === '1'"
                          >
                            <el-checkbox
                              v-model="showColums[item.key]"
                              @change="saveColmun"
                            >
                              {{ item.label }}
                            </el-checkbox>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </template>
                    <template slot-scope="{ row, $index }">
                      <el-button
                        icon="el-icon-view"
                        type="text"
                        size="mini"
                        @click="handleOpen(row, $index)"
                        >{{ $t("To view") }}
                      </el-button>
                      <!--                      <el-button
                                                size="mini"
                                                icon="el-icon-unlock"
                                                v-if="row.workshare_status === 0"
                                                type="text"
                                                @click="updateData(row, 'share_status', 1)"
                                            >{{ $t("Enable") }}
                                            </el-button>-->
                      <el-button
                        icon="el-icon-share"
                        size="mini"
                        v-if="row.workshare_status === 1"
                        type="text"
                        @click="handleShare(row, $index)"
                        >{{ $t("Share") }}
                      </el-button>
                      <el-button
                        icon="el-icon-edit"
                        size="mini"
                        type="text"
                        @click="handleUpdate(row)"
                      >
                        {{ $t("Edit") }}
                      </el-button>
                      <!--                      <el-button
                                                icon="el-icon-warning-outline"
                                                size="mini"
                                                v-if="row.workshare_status === 1"
                                                type="text"
                                                @click="stopCode(row, 'share_status')"
                                            >
                                              {{ $t("Disable") }}
                                            </el-button>-->
                      <el-button
                        icon="el-icon-delete"
                        type="text"
                        @click="handleDelete(row, $index)"
                      >
                        {{ $t("Delete") }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>

                <pagination
                  v-show="total > 0"
                  :total="total"
                  :page.sync="listQuery.page"
                  :limit.sync="listQuery.limit"
                  :page-sizes="pageSizelist"
                  @pagination="getList"
                />
              </div>
              <!--开始-->

              <!--结束       -->
              <el-dialog
                :visible.sync="dialogSwitch.tagModalShare"
                :append-to-body="true"
                :title="$t('Multiplex order sharing')"
                width="80%"
              >
                <div class="modal-body">
                  <form class="form-bookmark needs-validation" novalidate="">
                    <div class="form-row">
                      <div
                        class="form-group col-md-12"
                        style="
                          padding: 10px;
                          border: solid 1px #eeeeee;
                          border-bottom: 0;
                          margin-bottom: 0;
                          padding-top: 15px;
                          margin-top: 0;
                        "
                      >
                        <label
                          style="
                            background-color: rgba(0, 0, 0, 0.1);
                            padding: 10px;
                            border-radius: 5px;
                            min-width: 70px;
                            text-align: center;
                          "
                          >{{ $t("Work order name") }}:</label
                        >
                        <label style="margin-left: 20px">{{
                          temp.workshare_name
                        }}</label>
                      </div>
                      <div
                        class="form-group col-md-12"
                        style="
                          padding: 10px;
                          border: solid 1px #eeeeee;
                          border-bottom: 0;
                          margin-bottom: 0;
                          padding-top: 15px;
                          margin-top: 0;
                        "
                      >
                        <label
                          style="
                            background-color: rgba(0, 0, 0, 0.1);
                            padding: 10px;
                            border-radius: 5px;
                            min-width: 70px;
                            text-align: center;
                          "
                          >{{ $t("Work order number") }}:</label
                        >
                        <label style="margin-left: 20px">{{
                          temp.workshare_id
                        }}</label>
                      </div>
                      <div
                        class="form-group col-md-12"
                        style="
                          padding: 10px;
                          border: solid 1px #eeeeee;
                          border-bottom: 0;
                          margin-bottom: 0;
                          padding-top: 15px;
                          margin-top: 0;
                        "
                      >
                        <label
                          style="
                            background-color: rgba(0, 0, 0, 0.1);
                            padding: 10px;
                            border-radius: 5px;
                            min-width: 70px;
                            text-align: center;
                          "
                          >{{ $t("Link") }}:</label
                        >
                        <label style="margin-left: 20px"
                          ><a
                            target="_blank"
                            :href="
                              config.domain +
                              '/web#/workshow/' +
                              temp.workshare_id
                            "
                            >{{ $t("OpenUrl") }}</a
                          >
                          | <a @click="copyUrl">{{ $t("CopyUrl") }}</a> |
                          <a @click="copyAll">{{ $t("CopyAll") }}</a></label
                        >
                      </div>
                      <div
                        class="form-group col-md-12"
                        style="
                          padding: 10px;
                          border: solid 1px #eeeeee;
                          padding-top: 15px;
                          margin-top: 0;
                        "
                      >
                        <label
                          style="
                            background-color: rgba(0, 0, 0, 0.1);
                            padding: 10px;
                            border-radius: 5px;
                            min-width: 70px;
                            text-align: center;
                          "
                          >{{ $t("Password") }}:</label
                        >
                        <label style="margin-left: 20px">{{
                          temp.workshare_password
                            ? temp.workshare_password
                            : $t("Password free")
                        }}</label>
                      </div>
                    </div>
                  </form>
                </div>
              </el-dialog>
              <!-- 创建/编辑工单 -->
              <el-dialog
                :visible.sync="dialogSwitch.createModalExample"
                :append-to-body="true"
                :width="isPCShow ? '70%' : '90%'"
                style="top: -10vh"
                :destroy-on-close="true"
                :title="
                  dialogStatus === 'create'
                    ? $t('Creating an Invitation Code')
                    : $t('Edit an Invitation Code')
                "
              >
                <el-form
                  :model="temp"
                  v-loading="
                    groupArr.loading || shareArr.loading || acclistArr.loading
                  "
                  label-position="top"
                  style="width: 100%"
                >
                  <template v-for="(item, index) in listModelServer">
                    <el-form-item
                      v-if="item.edit === '1' && showList[item.key]"
                      :key="index"
                      :prop="item.key"
                    >
                      <template v-slot:label>
                        <div v-if="item.edittype === 'shareArr'">
                          <span v-if="groupArr.groupid > 0"
                            >{{ item.label
                            }}<el-checkbox
                              style="margin-left: 10px"
                              @change="shareALl"
                              v-model="workshareCheck.share"
                              >全部</el-checkbox
                            >
                            <span
                              style="
                                margin-left: 10px;
                                color: #fa194e;
                                background-color: rgba(219, 252, 222, 0.5);
                              "
                              v-if="shareArr.loading"
                              >loading...</span
                            >
                          </span>
                        </div>
                        <div v-else-if="item.edittype === 'accArr'">
                          <span v-if="shareArr.shareid.length > 0"
                            >{{ item.label
                            }}<el-checkbox
                              style="margin-left: 10px"
                              @change="accAll"
                              v-if="workshareCheck.app"
                              v-model="workshareCheck.acclist"
                              >全部</el-checkbox
                            >
                            <span
                              style="margin-left: 10px"
                              v-if="!workshareCheck.acclist"
                              ><el-input
                                style="width: auto"
                                v-model="acclistArr.search"
                                type="text"
                                size="mini"
                                :placeholder="
                                  $t('Please enter the') + item.label
                                "
                                class="filter-item"
                                @keyup.enter.native="acclistChange"
                              />
                              <el-button
                                style="margin-left: 5px"
                                type="text"
                                size="mini"
                                icon="el-icon-search"
                                @click="acclistChange"
                                >{{ $t("Search") }}</el-button
                              >
                              <el-button
                                style="margin-left: 5px"
                                type="text"
                                size="mini"
                                icon="el-icon-check"
                                @click="selectAccountAll"
                                >{{ $t("Full") }}</el-button
                              >
                              <span style="margin-left: 10px"
                                >共计{{ acclistArr.count }}条</span
                              >
                            </span>
                            <span
                              style="
                                margin-left: 10px;
                                color: #ff1f75;
                                background-color: rgba(241, 255, 233, 0.5);
                              "
                              v-if="acclistArr.loading"
                              >loading...</span
                            >
                          </span>
                        </div>
                        <div
                          style="margin-bottom: -20px"
                          v-else-if="item.edittype === 'appArr'"
                        >
                          <span
                            >{{ item.label }}
                            <el-checkbox
                              style="margin-left: 10px"
                              v-model="workshareCheck.app"
                              @change="appAll"
                              >全部</el-checkbox
                            >
                            <span
                              style="margin-left: 10px; color: rebeccapurple"
                              v-if="applistArr.loading"
                              >loading...</span
                            >
                          </span>
                        </div>
                        <div v-else>{{ item.label }}</div>
                      </template>

                      <label v-if="item.edittype === 'disable'">{{
                        temp[item.key]
                      }}</label>
                      <input
                        class="form-control"
                        style="width: auto; font-size: 0.8rem"
                        v-if="item.edittype === 'text'"
                        v-model="temp[item.key]"
                        type="text"
                        :placeholder="$t('Please enter the') + item.label"
                        autocomplete="off"
                      />
                      <el-scrollbar v-if="item.edittype === 'groupArr'">
                        <ul
                          class="list"
                          v-infinite-scroll="groupArrLoadData"
                          infinite-scroll-disabled="groupDisabled"
                          infinite-scroll-delay="1000"
                        >
                          <el-radio-group
                            v-model="groupArr.groupid"
                            @change="groupChange"
                          >
                            <span
                              class="list-item"
                              style="margin-left: 10px"
                              v-for="(item, groupi) in groupArr.data"
                              :key="groupi"
                            >
                              <el-radio :label="item.sharegroup_id">{{
                                item.sharegroup_name
                              }}</el-radio>
                            </span>
                            <el-radio
                              style="margin-left: 10px"
                              v-if="groupArr.loading"
                              :disabled="true"
                              >loading...
                            </el-radio>
                          </el-radio-group>
                        </ul>
                      </el-scrollbar>
                      <el-scrollbar v-if="item.edittype === 'shareArr'">
                        <ul
                          class="list"
                          v-infinite-scroll="loadShareData"
                          v-if="!workshareCheck.share"
                          infinite-scroll-disabled="shareDisabled"
                          infinite-scroll-delay="1000"
                        >
                          <el-checkbox-group
                            v-model="shareArr.shareid"
                            @change="acclistChange"
                          >
                            <span
                              class="list-item"
                              style="margin-left: 10px"
                              v-for="(item, groupi) in shareArr.data"
                              :key="groupi"
                            >
                              <el-checkbox :label="item.share_id">{{
                                item.share_code
                              }}</el-checkbox>
                            </span>
                          </el-checkbox-group>
                        </ul>
                      </el-scrollbar>
                      <div v-if="item.edittype === 'accArr'">
                        <el-scrollbar>
                          <ul
                            class="list"
                            v-infinite-scroll="loadAcclistData"
                            v-if="!workshareCheck.acclist"
                            style="height: 150px"
                            infinite-scroll-disabled="acclistDisabled"
                            infinite-scroll-delay="500"
                          >
                            <el-checkbox-group
                              v-model="acclistArr.acclistid"
                              style="
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
                              "
                            >
                              <template
                                v-for="(item, groupi) in acclistArr.data"
                              >
                                <el-checkbox
                                  class="list-item"
                                  style="
                                    margin-left: 10px;
                                    flex: 0 0 calc((100% - 10px) / 6);
                                  "
                                  @change="selectAccount(item)"
                                  :key="groupi"
                                  :label="item.acclist_id"
                                >
                                  <div
                                    style="display: flex; flex-direction: row"
                                  >
                                    <div>
                                      <el-image
                                        :preview-src-list="[
                                          item['acclist_headimg'],
                                        ]"
                                        style="
                                          height: 40px;
                                          width: 40px;
                                          border-radius: 5px;
                                        "
                                        fit="fill"
                                        :src="
                                          item['acclist_headimg'] || noheadimg
                                        "
                                        @error="
                                          loadHeadimgErr(
                                            item,
                                            'acclist_headimg'
                                          )
                                        "
                                      />
                                    </div>
                                    <div style="margin-left: 10px">
                                      <div
                                        style="
                                          width: 200px;
                                          white-space: nowrap;
                                          overflow: hidden;
                                          text-overflow: ellipsis;
                                        "
                                      >
                                        {{ item.acclist_nickname }}
                                      </div>
                                      <div
                                        style="
                                          width: 200px;
                                          white-space: nowrap;
                                          overflow: hidden;
                                          text-overflow: ellipsis;
                                        "
                                      >
                                        {{ item.acclist_username }}
                                      </div>
                                    </div>
                                  </div>
                                </el-checkbox>
                              </template>
                            </el-checkbox-group>
                          </ul>
                        </el-scrollbar>
                        <el-collapse
                          v-if="
                            selectAccdata.length > 0 && !workshareCheck.acclist
                          "
                          :value="['1']"
                        >
                          <el-collapse-item name="1">
                            <template v-slot:title>
                              <span>已选中列表</span>
                              <el-button
                                @click="delAccount(-1)"
                                style="margin-left: 10px"
                                icon="el-icon-delete"
                                type="text"
                                >清空选中
                              </el-button>
                              <span style="margin-left: 10px"
                                >共计{{ selectAccdata.length }}条</span
                              >
                            </template>
                            <el-scrollbar>
                              <ul
                                class="list"
                                style="
                                  height: 150px;
                                  display: flex;
                                  flex-direction: row;
                                  flex-wrap: wrap;
                                "
                              >
                                <el-alert
                                  type="success"
                                  @close="delAccount(alertIndex)"
                                  v-for="(
                                    alertItem, alertIndex
                                  ) in selectAccdata"
                                  :key="alertIndex"
                                  style="
                                    width: 19%;
                                    height: 60px;
                                    padding: 8px 2px;
                                  "
                                >
                                  <template v-slot:title>
                                    <div
                                      style="display: flex; flex-direction: row"
                                    >
                                      <div>
                                        <el-image
                                          :preview-src-list="[
                                            alertItem['acclist_headimg'],
                                          ]"
                                          style="
                                            height: 40px;
                                            width: 40px;
                                            border-radius: 5px;
                                          "
                                          fit="fill"
                                          :src="
                                            alertItem['acclist_headimg'] ||
                                            noheadimg
                                          "
                                          @error="
                                            loadHeadimgErr(
                                              alertItem,
                                              'acclist_headimg'
                                            )
                                          "
                                        />
                                      </div>
                                      <div style="margin-left: 10px">
                                        <div
                                          style="
                                            width: 150px;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                          "
                                        >
                                          {{ alertItem.acclist_nickname }}
                                        </div>
                                        <div
                                          style="
                                            width: 150px;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                          "
                                        >
                                          {{ alertItem.acclist_username }}
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </el-alert>
                              </ul>
                            </el-scrollbar>
                          </el-collapse-item>
                        </el-collapse>
                      </div>

                      <el-scrollbar
                        style="margin-bottom: 0"
                        v-if="item.edittype === 'appArr'"
                      >
                        <el-checkbox-group
                          v-model="applistArr.appid"
                          v-if="!workshareCheck.app"
                          @change="acclistChange"
                        >
                          <el-checkbox
                            v-for="(item, groupi) in applistArr.data"
                            :key="groupi"
                            :label="item.app_id"
                          >
                            {{ item.app_name }}
                          </el-checkbox>
                        </el-checkbox-group>
                      </el-scrollbar>
                      <el-scrollbar
                        style="margin-bottom: -20px !important"
                        v-if="item.edittype === 'privilegeArr'"
                      >
                        <el-checkbox-group v-model="privilegeArr.privilegeid">
                          <el-checkbox
                            v-for="(item, groupi) in privilegeArr.data"
                            :key="groupi"
                            :label="item.id"
                          >
                            {{ item.title }}
                          </el-checkbox>
                        </el-checkbox-group>
                      </el-scrollbar>

                      <input
                        class="form-control"
                        v-if="item.edittype === 'number'"
                        style="font-size: 0.8rem"
                        v-model="temp[item.key]"
                        type="number"
                        :placeholder="$t('Please enter the') + item.label"
                        autocomplete="off"
                      />
                      <el-select
                        style="margin-left: 5px"
                        v-if="item.edittype === 'select'"
                        v-model="temp[item.key]"
                        class="filter-item"
                        :placeholder="$t('Please enter the') + item.label"
                      >
                        <el-option
                          v-for="(selectitem, selectkey) in item.editselect"
                          :key="selectkey"
                          :label="selectitem.title"
                          :value="selectitem.id"
                        />
                      </el-select>
                      <el-date-picker
                        v-if="item.edittype === 'date'"
                        v-model="temp[item.key]"
                        style="top: -4px; width: 200px"
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :placeholder="$t('Please enter the') + item.label"
                      />
                      <el-time-picker
                        v-if="item.edittype === 'time'"
                        v-model="temp[item.key]"
                        style="top: -4px; width: 200px"
                        format="HH:mm:ss"
                        value-format="HH:mm:ss"
                        :placeholder="$t('Please enter the') + item.label"
                      />
                      <el-date-picker
                        v-if="item.edittype === 'datetime'"
                        v-model="temp[item.key]"
                        style="top: -4px; width: 200px"
                        type="datetime"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :placeholder="$t('Please enter the') + item.label"
                      />
                      <el-radio-group
                        v-if="item.edittype === 'radio'"
                        v-model="temp[item.key]"
                      >
                        <el-radio
                          v-for="(radioItem, radioIndex) in item.editselect"
                          :key="radioIndex"
                          :label="radioItem.id"
                        >
                          {{ radioItem.title }}
                        </el-radio>
                      </el-radio-group>
                      <el-checkbox-group
                        v-if="item.edittype === 'checkbox'"
                        v-model="temp[item.key]"
                      >
                        <el-checkbox
                          v-for="(chboxItem, chboxIndex) in item.editselect"
                          :key="chboxIndex"
                          :label="chboxItem.id"
                        >
                          <span
                            v-if="chboxItem.title === '数据分析'"
                            style="color: red"
                            >{{ chboxItem.title }}
                            <el-tooltip
                              class="item"
                              :value="dialogSwitch.createModalExample"
                              effect="dark"
                              content="重新登录客户端将自动上传账号联系人分析数据"
                              placement="top-start"
                            >
                              <i class="el-icon-question"></i
                            ></el-tooltip>
                          </span>
                          <span v-else>
                            {{ chboxItem.title }}
                          </span>
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </template>
                </el-form>

                <div slot="footer" class="dialog-footer">
                  <button
                    class="btn btn-secondary"
                    type="button"
                    @click="
                      dialogStatus === 'create' ? createData() : updateData()
                    "
                  >
                    {{ $t("Save") }}
                  </button>
                  <button
                    class="btn btn-primary ml-2"
                    type="button"
                    data-dismiss="modal"
                    @click="dialogSwitch.createModalExample = false"
                  >
                    {{ $t("Cancel") }}
                  </button>
                </div>
              </el-dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination';
  import noheadimg from '@/assets/images/noheadimg.png';

  let t;
  export default {
    components: { Pagination },
    data() {
      return {
        isPCShow: true,
        windowWidth: window.innerWidth,
        noheadimg: noheadimg,
        workshareCheck: {
          share: false,
          app: false,
          acclist: false,
        },
        groupArr: {
          count: 0,
          loading: false,
          noMore: false,
          groupid: 0,
          page: 1,
          data: [],
        },
        selectAccdata: [],
        selectDate: [],
        allStatus: false,
        tableFixed: 'right',
        downloadLoading: false,
        showColums: {},
        dialogSwitch: {
          createModalExample: false,
          tagModalShare: false,
          showSharegroup: false,
        },
        listQuery: {
          page: 1,
          limit: 5,
          sort: '',
          createdatstart: '',
          createdatend: '',
        },
        option: {
          showAddBt: false,
          showCatchEditBt: false,
          showExportBt: false,
          showSearchBt: true,
        },
        listLoading: true,
        pageSizelist: [],
        listSearchServer: [],
        listModelServer: [],
        listModelNum: 0,
        filter: null,
        list: [],
        total: 0,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15],
        tableKey: 0,
        selectMode: 'multi',
        selected: [],
        listdata: [],
        temp: {},
        dialogStatus: '',
        summar: {
          switch: false,
          data: {},
        },
        titleModelList: [],
        shareArr: {
          count: 0,
          loading: false,
          noMore: false,
          groupid: 0,
          page: 1,
          shareid: [],
          data: [],
        },
        applistArr: {
          count: 0,
          loading: false,
          appid: [],
          data: [],
        },
        privilegeArr: {
          count: 0,
          loading: false,
          privilegeid: [],
          data: [],
        },
        acclistArr: {
          search: '',
          count: 0,
          loading: false,
          noMore: false,
          acclistid: [],
          page: 1,
          shareid: [],
          appid: [],
          data: [],
        },
        showList: {},
      };
    },
    computed: {
      groupDisabled() {
        return this.groupArr.loading || this.groupArr.noMore;
      },
      acclistDisabled() {
        return this.acclistArr.loading || this.acclistArr.noMore;
      },
      shareDisabled() {
        return this.shareArr.loading || this.shareArr.noMore;
      },
    },
    mounted() {
      // Set the initial number of items
      t = this;
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      t.getRenderData();
      t.getColumn();
      t.tableFixed = t._isMobile() ? false : 'right';
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        t.windowWidth = window.innerWidth;
        // const windowWidth = window.innerWidth;
        t.isPCShow = t.windowWidth >= 768;
      },
      loadHeadimgErr(e, key) {
        e[key] = t.noheadimg;
      },
      shareALl() {
        this.groupChange();
      //this.acclistChange();
      },
      appAll() {
        this.acclistArr.appid = [];
      },
      accAll() {},
      groupChange() {
        this.shareArr.page = 1;
        this.shareArr.data = [];
        this.shareArr.shareid = [];
        this.acclistArr.data = [];
        this.acclistArr.page = 1;
        this.acclistArr.noMore = false;
        this.loadShareData();
      },
      loadShareData() {
        this.shareArr.loading = true;
        this.shareArr.groupid = this.groupArr.groupid;
        t.request
          .get('/workshare/getShare', this.shareArr)
          .then((response) => {
            const items = response.data.items;
            if (items.length === 0) {
              this.shareArr.noMore = true;
            } else {
              if (this.shareArr.page === 1) {
                this.shareArr.data = items;
              } else {
                this.shareArr.data = [...this.shareArr.data, ...items];
              }
            }
            this.shareArr.count = response.data.total;
            // Just to simulate the time of the request
            this.shareArr.loading = false;
            this.shareArr.page++;
            if (this.workshareCheck.share === true) {
              const s = [];
              // eslint-disable-next-line no-unused-vars
              for (const datum of this.shareArr.data) {
                s.push(datum.share_id);
              }
              this.shareArr.shareid = s;
              this.acclistChange();
            }
            this.$set(this.showList, 'workshare_shareid', true);
            this.$forceUpdate();
          })
          .catch((res) => {
            this.shareArr.loading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      acclistChange() {
        this.acclistArr.noMore = false;
        this.acclistArr.page = 1;
        this.acclistArr.data = [];
        this.acclistArr.acclistid = [];
        this.loadAcclistData();
      },
      acclistSearch() {
        this.acclistArr.noMore = false;
        this.acclistArr.page = 1;
        this.acclistArr.data = [];
        this.loadAcclistData();
      },
      selectAccount(item) {
        // eslint-disable-next-line no-unused-vars
        for (const selectAccdatum of this.selectAccdata) {
          if (item.acclist_id === selectAccdatum.acclist_id) {
            return;
          }
        }
        this.selectAccdata.push(item);
      },
      selectAccountAll() {
        // eslint-disable-next-line no-unused-vars
        accFor: for (const item of this.acclistArr.data) {
          this.acclistArr.acclistid.push(item.acclist_id);
          // eslint-disable-next-line no-unused-vars
          for (const selectAccdatum of this.selectAccdata) {
            if (item.acclist_id === selectAccdatum.acclist_id) {
              continue accFor;
            }
          }
          this.selectAccdata.push(item);
        }
      },
      delAccount(index) {
        if (index === -1) return (this.selectAccdata = []);
        this.selectAccdata.splice(index, 1);
      },
      loadAcclistData() {
        this.acclistArr.loading = true;
        this.acclistArr.shareid = this.shareArr.shareid;
        this.acclistArr.appid = this.applistArr.appid;
        t.request
          .get('/workshare/getAcclist', this.acclistArr)
          .then((response) => {
            const items = response.data.items;
            if (items.length === 0) {
              this.acclistArr.noMore = true;
            } else {
              if (this.acclistArr.page === 1) {
                this.acclistArr.data = items;
              } else {
                this.acclistArr.data = [...this.acclistArr.data, ...items];
              }
            }
            // eslint-disable-next-line no-unused-vars
            for (const acclistidElement of this.acclistArr.acclistid) {
              // eslint-disable-next-line no-unused-vars
              for (const item of items) {
                if (acclistidElement === item.acclist_id) {
                  this.selectAccount(item);
                }
              }
            }

            this.acclistArr.count = response.data.total;
            // Just to simulate the time of the request
            this.acclistArr.loading = false;
            this.acclistArr.page++;
            this.$set(this.showList, 'workshare_acclistid', true);
            this.$forceUpdate();
          })
          .catch((res) => {
            this.acclistArr.loading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      groupArrLoadData() {
        this.groupArr.loading = true;
        t.request
          .get('/workshare/grouplist', this.groupArr)
          .then((response) => {
            this.groupArr.data = response.data.items;
            this.groupArr.count = response.data.total;
            // Just to simulate the time of the request
            this.groupArr.loading = false;
            this.groupArr.page++;
            this.groupArr.noMore = true;
          })
          .catch((res) => {
            this.groupArr.loading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      _isMobile() {
        const flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        return flag;
      },
      resetFun() {
        // eslint-disable-next-line no-unused-vars
        for (const listSearchServerElement of t.listSearchServer) {
          t.$set(t.listQuery, listSearchServerElement.key, '');
        }
        // eslint-disable-next-line no-unused-vars
        for (const listQueryElement of this.listQuery) {
          this.$set(this.listQuery, listQueryElement, '');
        }
      },
      handleSelectionChange(e) {
        this.selectDate = e;
      // console.log("选中哈哈哈", e);
      },
      toggleSelection() {
        this.$refs.multipleTable.clearSelection();
      },
      rowClick(item, el) {
        if (el.label === '工单号') {
          t.$copyText(item.workshare_id)
            .then((e) => {
              t.$toasted.show('复制成功', {
                theme: 'bubble',
                position: 'top-center',
                type: 'success',
                duration: 3000,
              });
            })
            .catch((e) => {
              t.$toasted.show('复制失败', {
                theme: 'bubble',
                position: 'top-center',
                type: 'error',
                duration: 3000,
              });
            });
        }
      },
      selectAllFun(e) {
        !t.allStatus;
        let i = 0;
        t.listModelServer.forEach((item) => {
          if (item.show === '1') {
            if (!t.allStatus && i <= 5) {
              t.$set(t.showColums, item.key, true);
            } else if (!t.allStatus && i > 5) {
              t.$set(t.showColums, item.key, false);
            } else {
              t.$set(t.showColums, item.key, true);
            }
            i++;
          }
        });
        window.localStorage.setItem(
          'workshare_colmun',
          JSON.stringify(t.showColums)
        );
      },
      getColumn() {
        t.showColums = JSON.parse(
          window.localStorage.getItem('workshare_colmun') || '{}'
        );
      },
      saveColmun() {
        let arr = Object.values(t.showColums).filter((item) => item);
        t.allStatus = arr.length === t.listModelNum;
        window.localStorage.setItem(
          'workshare_colmun',
          JSON.stringify(t.showColums)
        );
      },
      getSummar(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            // 只找第一列放合计
            sums[index] = `${t.$t('combined')}`;
            return;
          }
          if (t.summar.data[column.property]) {
            // 判断当前prop绑定的ID
            const values = data.map((item) => item[column.property]); // 把对应一列中的之全部取出，放到一个数组中
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr); // 将values中的每个值转换为number类型
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          }
        });
        return sums;
      },
      copyUrl() {
        const text = t.config.domain + '/web#/workshow/' + t.temp.workshare_id;
        t.$copyText(text)
          .then((e) => {
            t.$toasted.show('复制成功', {
              theme: 'bubble',
              position: 'top-center',
              type: 'success',
              duration: 3000,
            });
          })
          .catch((e) => {
            t.$toasted.show('复制失败', {
              theme: 'bubble',
              position: 'top-center',
              type: 'error',
              duration: 3000,
            });
          });
      },
      copyAll() {
        const text = `工单名称:${t.temp.workshare_name}
工单号：${t.temp.workshare_id}
工单链接：${t.config.domain + '/web#/workshow/' + t.temp.workshare_id}
访问密码：${t.temp.workshare_password}`;
        t.$copyText(text)
          .then((e) => {
            t.$toasted.show('复制成功', {
              theme: 'bubble',
              position: 'top-center',
              type: 'success',
              duration: 3000,
            });
          })
          .catch((e) => {
            t.$toasted.show('复制失败', {
              theme: 'bubble',
              position: 'top-center',
              type: 'error',
              duration: 3000,
            });
          });
      },
      handleOpen(item, index) {
        window.open(`/web/#/workshow/${item.workshare_id}`);
      },
      handleDownload() {
        this.downloadLoading = true;
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = ['timestamp', 'title', 'type', 'importance', 'status'];
        const filterVal = [
          'timestamp',
          'title',
          'type',
          'importance',
          'status',
        ];
        const data = this.formatJson(filterVal);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'table-list',
        });
        this.downloadLoading = false;
      });
      },
      formatJson(filterVal) {
        return this.list.map((v) =>
          filterVal.map((j) => {
            if (j === 'timestamp') {
              return this.parseTime(v[j]);
            } else {
              return v[j];
            }
          })
        );
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleUpdate(row) {
        this.resetTemp();
        this.workshareCheck.acclist = false;
        this.workshareCheck.share = false;
        this.workshareCheck.app = false;
        this.groupArr.data = [];
        this.shareArr.data = [];
        this.acclistArr.data = [];
        this.groupArr.noMore = false;
        this.groupArr.page = 1;
        this.shareArr.noMore = false;
        this.shareArr.page = 1;
        this.acclistArr.noMore = false;
        this.selectAccdata = [];
        this.acclistArr.page = 1;
        this.temp = Object.assign({}, row); // copy obj
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in this.temp) {
          try {
            this.showList[tempKey] = true;
            if (typeof JSON.parse(this.temp[tempKey]) === 'object') {
              this.temp[tempKey] = JSON.parse(this.temp[tempKey]);
            }
          // eslint-disable-next-line no-empty
          } catch (e) {}
        }
        this.temp.password = '';
        this.temp.walletpassword = '';
        this.dialogStatus = 'update';
        this.showList['workshare_groupid'] = false;
        t.dialogSwitch.createModalExample = true;
        this.$nextTick(() => {
          this.groupArr.groupid = this.temp.workshare_groupid;
          this.privilegeArr.privilegeid = this.temp.workshare_privilege;
          if (
            this.temp.workshare_appid.length === 1 &&
            this.temp.workshare_appid[0] === 0
          ) {
            this.workshareCheck.app = true;
          } else {
            this.applistArr.appid = this.temp.workshare_appid;
          }
          if (
            this.temp.workshare_shareid.length === 1 &&
            this.temp.workshare_shareid[0] === 0
          ) {
            this.workshareCheck.share = true;
          } else {
            this.shareArr.shareid = this.temp.workshare_shareid;
          }
          if (
            this.temp.workshare_acclistid.length === 1 &&
            this.temp.workshare_acclistid[0] === 0
          ) {
            this.workshareCheck.acclist = true;
          } else {
            this.acclistArr.acclistid = this.temp.workshare_acclistid;
          }

          this.$forceUpdate();
        });
      },
      createData() {
        const tempData = Object.assign({}, this.temp);
        if (tempData['workshare_name'] === '') {
          return t.$toasted.show('请输入工单名称', {
            theme: 'bubble',
            position: 'bottom-center',
            type: 'error',
            duration: 3000,
          });
        }
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in tempData) {
          try {
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of this.listModelServer) {
              if (
                modelDatum.key === tempKey &&
                (modelDatum.edittype === 'number' || modelDatum.edittype.indexOf('Number') !== -1)
              ) {
                tempData[tempKey] = Number(tempData[tempKey]);
                break;
              }
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
          // eslint-disable-next-line no-empty
          } catch (e) {}
        }
        if (this.groupArr.groupid === 0) {
          return t.$toasted.show('请选择分组', {
            theme: 'bubble',
            position: 'bottom-center',
            type: 'error',
            duration: 3000,
          });
        }
        tempData.workshare_groupid = this.groupArr.groupid;
        if (this.workshareCheck.share === true) {
          tempData['workshare_shareid'] = `[0]`;
        } else {
          if (this.shareArr.shareid.length === 0) {
            return t.$toasted.show('工单号至少选择一个', {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'error',
              duration: 3000,
            });
          }
          tempData['workshare_shareid'] = JSON.stringify(this.shareArr.shareid);
        }
        if (this.workshareCheck.app === true) {
          tempData['workshare_appid'] = `[0]`;
        } else {
          if (this.applistArr.appid.length === 0) {
            return t.$toasted.show('APP至少选择一个', {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'error',
              duration: 3000,
            });
          }
          tempData['workshare_appid'] = JSON.stringify(this.applistArr.appid);
        }
        if (this.workshareCheck.acclist === true) {
          tempData['workshare_acclistid'] = `[0]`;
        } else {
          if (this.selectAccdata.length === 0) {
            return t.$toasted.show('帐号至少选择一个', {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'error',
              duration: 3000,
            });
          }
          const accid = [];
          // eslint-disable-next-line no-unused-vars
          for (const selectAccdatum of this.selectAccdata) {
            accid.push(selectAccdatum.acclist_id);
          }
          tempData['workshare_acclistid'] = JSON.stringify(accid);
        }
        tempData['workshare_privilege'] = JSON.stringify(
          this.privilegeArr.privilegeid
        );
        t.request
          .post('/workshare/add', tempData)
          .then(() => {
            this.getList();
            t.dialogSwitch.createModalExample = false;
            this.$notify({
              title: '完成',
              message: '添加成功',
              type: 'success',
              duration: 2000,
            });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      handleCreate() {
        this.resetTemp();
        this.temp.workshare_privilege = [1, 2, 3];
        this.dialogStatus = 'create';
        this.selectAccdata = [];
        this.temp['share_status'] = 1;
        this.temp['share_zerotime'] = '23:59:59';
        // this.temp['share_type'] = 0;
        t.dialogSwitch.createModalExample = true;
      },
      handleSharegroup() {
        t.dialogSwitch.showSharegroup = true;
      },
      resetTemp() {
        const jsonTemp = {};
        // eslint-disable-next-line no-unused-vars
        for (const listModelServerKey in this.listModelServer) {
          switch (this.listModelServer[listModelServerKey]['edittype']) {
          case 'checkbox':
            jsonTemp[this.listModelServer[listModelServerKey]['key']] = [];
            break;
          default:
            jsonTemp[this.listModelServer[listModelServerKey]['key']] = '';
            break;
          }
          this.showList[this.listModelServer[listModelServerKey]['key']] = true;
          if (
            this.listModelServer[listModelServerKey]['key'] === 'share_applist'
          ) {
            jsonTemp['share_applist'] = [];
            // eslint-disable-next-line no-unused-vars
            for (const listModelServerElement of this.listModelServer[
              listModelServerKey
            ]['editselect']) {
              jsonTemp['share_applist'].push(listModelServerElement['id']);
            }
          }
        }
        jsonTemp['workshare_status'] = 1;
        this.temp = jsonTemp;
        this.showList['workshare_shareid'] = false;
        this.showList['workshare_acclistid'] = false;
        this.groupArr.groupid = 0;
        this.acclistArr.appid = [];
        this.acclistArr.shareid = [];
        this.acclistArr.acclistid = [];
        this.privilegeArr.privilegeid = [2, 3, 4];
        this.workshareCheck.app = true;
      },
      stopCode(row, key) {
        t.$confirm(
          `<div class="ant-modal-confirm-content"><div data-v-830c5818=""><div data-v-830c5818="">当工单号被停用后：</div><div data-v-830c5818="">系统将回收已分配的会话数；</div><div data-v-830c5818="">客户端将关闭所有会话并退出；</div><div data-v-830c5818="">工单号分享链接依旧可查看记录；</div><div data-v-830c5818="">将不再允许使用此工单号。</div><br data-v-830c5818=""><div data-v-830c5818="" style="color: rgb(250, 173, 20);">停用后将进入系统回收倒计时，在15天后自动清空工单号相关数据。</div></div></div>`,
          '是否确定停用工单号',
          {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning',
            dangerouslyUseHTMLString: true,
          }
        )
          .then(async () => {
            t.updateData(row, key, 0);
          })
          .catch((err) => {
            console.error(err);
          });
      },
      updateData(row, key, value) {
        if (row) {
          row[key] = value;
          this.temp = row;
        }
        const tempData = Object.assign({}, this.temp);
        // eslint-disable-next-line no-unused-vars
        for (const tempKey in tempData) {
          try {
            for (const modelDatum of this.listModelServer) {
              if (
                modelDatum.key === tempKey &&
                (modelDatum.edittype === 'number' || modelDatum.edittype.indexOf('Number') !== -1)
              ) {
                tempData[tempKey] = Number(tempData[tempKey]);
                break;
              }
            }
            if (typeof tempData[tempKey] === 'object') {
              tempData[tempKey] = JSON.stringify(tempData[tempKey]);
            }
          } catch (e) {
            console.log(e);
          }
        /* if (typeof JSON.parse(this.temp[tempKey]) === 'array') {

        }*/
        }
        if (this.groupArr.groupid === 0) {
          return t.$toasted.show('请选择分组', {
            theme: 'bubble',
            position: 'bottom-center',
            type: 'error',
            duration: 3000,
          });
        }
        if (this.workshareCheck.share === true) {
          tempData['workshare_shareid'] = `[0]`;
        } else {
          if (this.shareArr.shareid.length === 0) {
            return t.$toasted.show('工单号至少选择一个', {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'error',
              duration: 3000,
            });
          }
          tempData['workshare_shareid'] = JSON.stringify(this.shareArr.shareid);
        }
        if (this.workshareCheck.app === true) {
          tempData['workshare_appid'] = `[0]`;
        } else {
          if (this.applistArr.appid.length === 0) {
            return t.$toasted.show('APP至少选择一个', {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'error',
              duration: 3000,
            });
          }
          tempData['workshare_appid'] = JSON.stringify(this.applistArr.appid);
        }
        if (this.workshareCheck.acclist === true) {
          tempData['workshare_acclistid'] = `[0]`;
        } else {
          if (this.acclistArr.acclistid.length === 0) {
            return t.$toasted.show('帐号至少选择一个', {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'error',
              duration: 3000,
            });
          }
          tempData['workshare_acclistid'] = JSON.stringify(
            this.acclistArr.acclistid
          );
        }
        tempData['workshare_privilege'] = JSON.stringify(
          this.privilegeArr.privilegeid
        );
        t.request
          .post('/workshare/edit', tempData)
          .then((res) => {
            t.getList();
            t.dialogSwitch.createModalExample = false;
            t.$toasted.show(res.msg, {
              theme: 'bubble',
              position: 'bottom-center',
              type: 'success',
              duration: 3000,
            });
          })
          .catch((res) => {
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      handleShare(row, index = 0) {
        t.temp = row;
        t.dialogSwitch.tagModalShare = true;
      },
      handleDelete(row, index) {
        this.$confirm(`确认要删除吗?`, this.$t('warning'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning',
        })
          .then(async () => {
            await t.request.post('/workshare/delete', row);
            this.list.splice(index, 1);
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          })
          .catch((err) => {
            console.error(err);
          });
      },
      getRenderData() {
        t.request
          .get('/workshare/render')
          .then((response) => {
            const res = response.data;
            let i = 0;
            // eslint-disable-next-line no-unused-vars
            for (const modelDatum of res.modelData) {
              if (modelDatum.show === '1') {
                t.listModelNum = i + 1;
                /* if (i > 5) {
                  i++;
                  continue;
                }*/
                // eslint-disable-next-line no-prototype-builtins
                if (!t.showColums.hasOwnProperty(modelDatum.key)) {
                  t.$set(t.showColums, modelDatum.key, true);
                }
                i++;
              }
            }
            t.saveColmun();
            t.option = res.option || {};
            t.summar = res.summar || {};
            t.listModelServer = res.modelData || [];
            t.listSearchServer = res.searchData || [];
            t.listQuery.sort = res.defaultSort || [];
            t.pageSizelist = res.pageSizelist || [];
            t.applistArr.data = res.applist || [];
            t.privilegeArr.data = res.privilege || [];
            t.getList();
          })
          .catch((res) => {
            res.errMsg
              ? t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              })
              : t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
          });
      },
      getList() {
        this.listLoading = true;
        t.request
          .get('/workshare/list', this.listQuery)
          .then((response) => {
            this.list = response.data.items;
            this.total = response.data.total;
            // Just to simulate the time of the request
            this.listLoading = false;
          })
          .catch((res) => {
            this.listLoading = false;
            if (res.errMsg) {
              t.$toasted.show(res.errMsg, {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            } else {
              t.$toasted.show(t.$t('Server Error'), {
                theme: 'bubble',
                position: 'bottom-center',
                type: 'error',
                duration: 3000,
              });
            }
          });
      },
      sortChange(data) {
        const { prop, order } = data;
        t.sortByID(order, prop);
      },
      sortByID(order, prop) {
        if (order === 'ascending') {
          t.listQuery.sort = `${prop} ASC`;
        } else {
          t.listQuery.sort = `${prop} DESC`;
        }
        t.handleFilter();
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    },
  };
</script>

<style lang="scss" scoped>
.el-select-dropdown {
  /*background-color: unset ;*/
}

.el-input__inner {
  background-color: unset !important;
}

.pagination-container {
  background: unset;
}

.el-table__cell {
  background-color: unset;
}
</style>
